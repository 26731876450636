/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DoctorBasicDto } from './doctor-basic-dto';
import { MedicalServiceDto } from './medical-service-dto';

/**
 * 
 * @export
 * @interface DoctorPricelistItemWithDoctorInfoDto
 */
export interface DoctorPricelistItemWithDoctorInfoDto {
    /**
     * 
     * @type {number}
     * @memberof DoctorPricelistItemWithDoctorInfoDto
     */
    'additionalTimeGrossPrice'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorPricelistItemWithDoctorInfoDto
     */
    'docplannerEnabled'?: boolean;
    /**
     * 
     * @type {DoctorBasicDto}
     * @memberof DoctorPricelistItemWithDoctorInfoDto
     */
    'doctor'?: DoctorBasicDto;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorPricelistItemWithDoctorInfoDto
     */
    'doctorPackageOnly'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DoctorPricelistItemWithDoctorInfoDto
     */
    'durationFrom'?: number;
    /**
     * 
     * @type {number}
     * @memberof DoctorPricelistItemWithDoctorInfoDto
     */
    'durationTo'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorPricelistItemWithDoctorInfoDto
     */
    'dutyEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DoctorPricelistItemWithDoctorInfoDto
     */
    'grossPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof DoctorPricelistItemWithDoctorInfoDto
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorPricelistItemWithDoctorInfoDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {MedicalServiceDto}
     * @memberof DoctorPricelistItemWithDoctorInfoDto
     */
    'medicalService'?: MedicalServiceDto;
    /**
     * 
     * @type {string}
     * @memberof DoctorPricelistItemWithDoctorInfoDto
     */
    'patientType'?: DoctorPricelistItemWithDoctorInfoDtoPatientTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DoctorPricelistItemWithDoctorInfoDto
     */
    'visitOrder'?: DoctorPricelistItemWithDoctorInfoDtoVisitOrderEnum;
    /**
     * 
     * @type {string}
     * @memberof DoctorPricelistItemWithDoctorInfoDto
     */
    'visitType'?: DoctorPricelistItemWithDoctorInfoDtoVisitTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DoctorPricelistItemWithDoctorInfoDtoPatientTypeEnum {
    Infant = 'INFANT',
    Child = 'CHILD',
    Youth = 'YOUTH',
    Adult = 'ADULT',
    Senior = 'SENIOR'
}
/**
    * @export
    * @enum {string}
    */
export enum DoctorPricelistItemWithDoctorInfoDtoVisitOrderEnum {
    Each = 'EACH',
    First = 'FIRST',
    Next = 'NEXT'
}
/**
    * @export
    * @enum {string}
    */
export enum DoctorPricelistItemWithDoctorInfoDtoVisitTypeEnum {
    Remote = 'REMOTE',
    Stationary = 'STATIONARY',
    Absent = 'ABSENT'
}


