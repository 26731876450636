import Enums from "./enums/enums";
import ServerErrors from "./errors/server-errors";

export default {
  "translations": {
    "$vuetify": {
      "noDataText": "Žádná data",
      "dataFooter": {
        "itemsPerPageAll": "Ukázat vše",
        "pageText": "Navigovat:"
      },
      "dataTable": {
        "sortBy": "Seřazeno podle",
        "ariaLabel": {
          "sortNone": "sortNone"
        },
        "itemsPerPageText": "Na stránku:"
      },
      "timepicker": {
        "am": "dopoledne",
        "pm": "odpoledne"
      },
      "dataIterator": {
        "loadingText": "Načítání dat"
      },
      "badge": "odznak",
      "datePicker": {
        "prevMonthAriaLabel": "Předchozí měsíc",
        "nextMonthAriaLabel": "Příští měsíc"
      }
    },
    "view": {
      "admin": {
        "settings": {
          "ewusPasswordChanged": "Heslo EWUŚ bylo změněno",
          "save": "Uložit",
          "ewusPasswordTitle": "Změna hesla EWUŚ",
          "ewusPasswordLabel": "Nové heslo",
          "removeAccount": "Odstranit účet",
          "registrationDate": "Datum registrace",
          "registrationSource": "Registrováno uživatelem",
          "registeredBy": {
            "PATIENT": "Trpěliví",
            "DOCPLANNER": "Znany lekarz",
            "APPOINTING_WIDGET": "Widget"
          },
          "deleteAccount": "Odstranit účet",
          "accountNotForDeletion": "Tento účet nelze odstranit",
          "accountDeletionConfirmation": "Opravdu chcete tento účet odstranit?",
          "yes": "Ano",
          "no": "Ne",
          "accountRemovedMsg": "Účet pacienta byl odstraněn"
        },
        "notes": {
          "title": "Poznámky",
          "current": "Aktuální",
          "archive": "Archiv",
          "mine": "těžit"
        },
        "noteDetails": {
          "added": "Čas vytvoření",
          "patient": "Trpěliví",
          "pesel": "Pesel",
          "address": "Adresa",
          "phone": "Telefon",
          "content": "Obsah",
          "sharedBy": "Sdíleno uživatelem",
          "moveToArchive": "Přesunout do archivu",
          "save": "Uložit",
          "reply": "Odpověď",
          "initialNote": "Poznámka lékaře",
          "yes": "Ano",
          "no": "Ne",
          "addedFiles": "Přidané soubory"
        },
        "reassignment": {
          "noReplacementAvailable": "Žádný alternativní lékař za stejnou cenu",
          "backToVisitToMoveTable": "Zpět na seznam schůzek, které mají být nahrazeny",
          "visitNotFoundRedirection": "Nie znaleziono wizyty, powrócisz do strony głównej",
          "reassignmentSummary": "souhrn",
          "visitNotToMove": "Návštěvu nelze přesunout, protože její stav již byl aktualizován.",
          "title": "Změna doktora",
          "currentVisitDate": "Datum návštěvy",
          "currentVisitHeader": "Změna na návštěvu",
          "additionalInfo": "Dodatečné informace",
          "upcomingVisit": "Nadcházející návštěva pacienta",
          "patient": "Trpěliví",
          "doctor": "Doktor",
          "currentVisitFromPackageHeader": "Změna pro návštěvu z balíčku",
          "cannotReassignDueToRelatedVisit": "Návštěvu nelze přeřadit z důvodu souvislosti s jinou již uskutečněnou návštěvou"
        },
        "patients": {
          "selectPatient": "Vyberte pacienta",
          "name": "Jméno",
          "email": "E-mail",
          "pesel": "Pesel"
        },
        "certificates": {
          "issuingDoctor": "Lékař vydává potvrzení",
          "changeLbl": "Přeměna",
          "selectDoctorLbl": "Vyberte lékaře",
          "hide": "Skrýt"
        }
      },
      "visits": {
        "DoctorVisits": {
          "titleLbl": "Mé návštěvy",
          "returnLbl": "vrátit se",
          "noDataLbl": "Žádné návštěvy",
          "noResultsLbl": "Nebyly nalezeny žádné návštěvy",
          "filters": {
            "visitTime": {
              "all": "Všechno",
              "future": "Budoucnost",
              "past": "Minulý"
            },
            "searchPatientLbl": "Hledat pacienta",
            "advancedFiltersLbl": "Pokročilé vyhledávání",
            "advancedFilters": {
              "from": "Datum od",
              "betweenDatesLbl": "dělat",
              "till": "Datum do",
              "type": "Typ návštěvy",
              "typeAll": "Všechno",
              "clearBtn": "Průhledná",
              "searchBtn": "Vyhledávání"
            }
          },
          "table": {
            "headers": {
              "state": "Stát",
              "lastName": "Příjmení",
              "firstName": "Jméno",
              "pesel": "PESEL",
              "age": "Stáří",
              "visitType": "Typ návštěvy",
              "visitCategory": "Kategorie",
              "visitStatus": "Postavení",
              "dateFrom": "Datum návštěvy",
              "sinceLast": "Od posledního"
            },
            "actions": {
              "summary": "Shrnutí návštěvy",
              "start": "Návštěva"
            }
          }
        }
      },
      "patients": {
        "DoctorPatients": {
          "titleLbl": "Pacienti",
          "returnLbl": "vrátit se",
          "noDataLbl": "Žádní pacienti",
          "noResultsLbl": "Nebyly nalezeny žádné návštěvy",
          "filters": {
            "scope": {
              "all": "Všichni pacienti",
              "my": "Moji pacienti"
            },
            "search": "Vyhledávání",
            "advancedFiltersLbl": "Pokročilé vyhledávání",
            "from": "z",
            "birthDate": "Datum narození",
            "till": "na",
            "clearBtn": "Průhledná",
            "searchBtn": "Vyhledávání",
            "tabs": {
              "personal": "Osobní údaje",
              "address": "Podrobnosti adresy",
              "biomedic": "Biomedicínská data",
              "medic": "Lékařské údaje"
            }
          },
          "table": {
            "headers": {
              "lastName": "Příjmení",
              "firstName": "Jméno",
              "medicalHistory": "Zdravotní historie",
              "pesel": "PESEL",
              "age": "Stáří",
              "sex": "Sex",
              "leadDoctor": "Vedoucí lékař",
              "personalData": "Osobní data",
              "email": "E-mailem",
              "phone": "Telefonní číslo",
              "city": "Město",
              "zipCode": "PSČ",
              "country": "Země",
              "bloodType": "Krevní skupina",
              "rh": "Rh",
              "weight": "Hmotnost",
              "height": "Výška",
              "hasEpilepsy": "Má epilepsii?",
              "mainIcd10": "Hlavní diagnóza",
              "comorbidities": "Komorbidity",
              "vns": "Implantované VNS",
              "activeSubstance": "Účinná látka",
              "product": "Lék",
              "therapyType": "Typ terapie"
            }
          }
        }
      },
      "visit": {
        "Visit": {
          "titleLbl": "Návštěva",
          "returnLbl": "vrátit se",
          "printLbl": "vytisknout",
          "survey": "Průzkum 0",
          "documentation": "Navštivte dokumentaci",
          "visitDocumentation": "Dokumentace z návštěvy",
          "calendar": "Kalendář akcí",
          "eDiagnosisBtn": "e-diagnostika",
          "eTherapyBtn": "e-terapie",
          "medHistoryBtn": "zdravotní historie",
          "messHistoryBtn": "historie zpráv",
          "notesBtn": "poznámky",
          "observations": "pozorování",
          "diagnosis": "diagnostika (komorbidity)",
          "seizureDiagnosis": "diagnóza (záchvaty)",
          "medication": "léky",
          "recommendations": "doporučení",
          "prescriptionBtn": "předpis",
          "certificateBtn": "osvědčení",
          "referralBtn": "doporučení",
          "changeTherapyBtn": "změnit terapii",
          "cancel": "zrušení",
          "save": "Uložit",
          "end": "Dokončit",
          "visitLeaveConfirmMsg": "Opravdu chcete opustit návštěvu?",
          "visitSaveConfirmMsg": "Opravdu chcete uložit a opustit návštěvu?",
          "visitEndConfirmMsg": "Chcete návštěvu ukončit? Po jeho ukončení nebudou možné žádné další změny",
          "dataSavedMsg": "Údaje o návštěvě jsou uloženy",
          "visitEndedMsg": "Návštěva byla ukončena",
          "dataSaveErrorMsg": "Chyba při ukládání údajů o návštěvě",
          "messageHistoryEmpty": "S tímto pacientem nejsou žádné zprávy",
          "drugsImported": "Byly zahájeny lékové terapie",
          "drugTherapiesInEditionMode": "Lékové terapie jsou v edičním režimu. Návštěvu nelze uložit.",
          "emptyRecommendations": "Doporučení se neposkytují. Návštěvu nelze ukončit.",
          "referrals": {
            "title": "doporučení",
            "removeDialogTitle": "Opravdu chcete toto doporučení odebrat?",
            "addReferral": "Doporučení",
            "addDialogTitle": "Nové doporučení",
            "noAvailableServices": "Pro tohoto pacienta nejsou k dispozici žádné služby",
            "consultationService": "Typ konzultace",
            "doctor": "Doktor",
            "description": "Popis",
            "cancel": "zrušení",
            "empty": "Na tuto návštěvu nebylo vydáno žádné doporučení",
            "scheduled1Month": "1 měsíc",
            "scheduled2Months": "2 měsíce",
            "scheduled3Months": "3 měsíce",
            "scheduled6Months": "6 měsíců",
            "scheduled1Year": "1 rok",
            "scheduled1month": "1 měsíc",
            "scheduled2month": "2 měsíce",
            "scheduled3month": "3 měsíce",
            "scheduled6month": "6 měsíců",
            "scheduled12month": "1 rok",
            "scheduledDate": "Plánovaný termín",
            "availableFree": {
              "title": "Nevydali jste doporučení pro bezplatné návštěvy",
              "subtitle": "Po této návštěvě má pacient nárok na 1 bezplatnou návštěvu jedné ze služeb",
              "subtitle2": "Vraťte se ke svému plánu péče a vydejte příslušné doporučení!",
              "back": "Vraťte se k návštěvě a vydejte doporučení",
              "endWithoutReferral": "Dokončit bez doporučení",
              "visitDescription": "Po této návštěvě má pacient nárok na bezplatnou návštěvu následujících služeb",
              "visitAddTitle": "doporučení k bezplatné návštěvě"
            },
            "doctorPackage": "Lékařský balíček",
            "doctorPackageQuestion": "Doporučení pro lékařský balíček?"
          },
          "prescriptions": {
            "title": "předpisy",
            "addPrescription": "Předpis",
            "empty": "Na tuto návštěvu nebyl vydán předpis"
          },
          "certificates": {
            "title": "certifikáty",
            "addCertificate": "Osvědčení",
            "empty": "Pro tuto návštěvu nebyl vydán žádný certifikát"
          },
          "ended": {
            "titleLbl": "Telekonference skončila",
            "questionMsg": "Chcete tuto návštěvu ukončit a vrátit se na hlavní obrazovku?",
            "confirmationMsg": "Děkujeme za účast na návštěvě"
          },
          "saving": {
            "warningMsg": "Návštěva se automaticky uloží za {no} minut",
            "autoSaveMsg": "Návštěva byla automaticky uložena. Návštěvu prosím okamžitě ukončete"
          },
          "icd10": {
            "title": "diagnóza"
          },
          "leaveDialog": {
            "leave": "Odejít bez uložení",
            "saveAndLeave": "Uložit a odejít",
            "endAndLeave": "Končit a odejít"
          },
          "absentConsultationInfo": "Absentující konzultace probíhá bez účasti pacienta",
          "wrongDataDialog": {
            "title": "Vaše data nelze v tuto chvíli uložit.",
            "description": "Kontaktujte prosím zákaznickou kancelář Neurosphera Epilepsy Therapy Center."
          },
          "additionalObservations": "Další postřehy",
          "notMandatoryLbl": "není povinné pole",
          "observationsPlaceholder": "Pozorování se vyplní automaticky při vyplňování sekce návštěv",
          "additionalObservationsPlaceholder": "Doplňte další postřehy",
          "addObservationLbl": "Uložte pozorování",
          "leadDoctorHeader": "Vedoucí lékař",
          "documentationTabs": {
            "visitSurvey": "Navštivte průzkum",
            "therapyArrangement": "Uspořádání terapie",
            "healthCare": "Plán péče",
            "recommendations": "Doporučení",
            "certificates": "Certifikáty",
            "appoint": "jmenovat",
            "skip": "nejmenovat",
            "referralsSaved": "Doporučení byla uložena",
            "carePlan": {
              "addNext": "Přidat další",
              "remove": "Odstranit",
              "loading": "Načítání dat...",
              "referralListEmpty": "Seznam doporučení je prázdný",
              "epilepsy": {
                "name": "Návštěva epilepsie",
                "title": "další epileptická návštěva – domluvení termínu",
                "date": "Datum návštěvy"
              },
              "eeg": {
                "name": "EEG vyšetření",
                "title": "EEG vyšetření - objednání",
                "date": "Eeg datum",
                "service": "Typ Eeg"
              },
              "consultation": {
                "name": "Konzultace",
                "title": "Konzultace NSPH - domluvení termínu",
                "date": "Termín konzultace",
                "service": "Typ konzultace"
              },
              "other": {
                "name": "jiný",
                "title": "Externí doporučení",
                "emptyList": "Seznam vydaných externích doporučení pro tuto návštěvu je prázdný",
                "addNextEReferral": "Přidejte elektronické doporučení",
                "isEReferral": "E-doporučení",
                "referralCode": "Referenční kód",
                "noDataLbl": "Seznam doporučení je prázdný"
              },
              "message": {
                "name": "Zpráva pro lékaře",
                "title": "návštěva zprávy - domluvení schůzky",
                "date": "Datum návštěvy"
              }
            }
          },
          "recommendationTemplate": {
            "title": "šablona doporučení",
            "examinationNames": "Jména zkoušek",
            "template": {
              "message": "Napište prosím o zdravotním stavu zprávu ošetřujícímu lékaři",
              "therapy": "Léky dle schématu v medikamentózní terapii a na účet pacienta v systému Neuroterminál v záložce terapie",
              "urgentContact": "V případě urgentního kontaktu s lékařem nebo nepředvídaných okolností volejte recepci +48 609 463 191",
              "noTherapy": "Bez farmakologické léčby",
              "calendar": "Udržujte, prosím, kalendář událostí na pacientském portálu v systému Neuroterminál",
              "headMr": "Udělejte prosím magnetickou rezonanci hlavy",
              "medicalExaminations": "Proveďte prosím další testy a výsledky přiložte do zdravotnické dokumentace v systému Neuroterminál"
            }
          },
          "epicrisis": "Epikrize",
          "noteZeroObservations": "Poznámka 0 pozorování",
          "editNoteZeroObservations": "Upravit poznámku 0 pozorování",
          "saveObservationLbl": "Uložit poznámku 0 pozorování",
          "downloadTestRecommendations": "Stáhněte si zkušební doporučení",
          "mainDoctor": "Hlavní lékař",
          "supervisors": "Dozorci"
        },
        "ictal": {
          "diagnosis": {
            "title": "Vážená paní, vážený pane",
            "fillSurveyMsg": "Zatím nebyly definovány žádné záchvaty. Vyplňte průzkum 0",
            "ictalDiagnose": "Diagnostikujte pomocí Ictall",
            "ictalDiagnoseOneMoreTime": "Diagnostikujte ještě jednou",
            "patientSeizureType": "Vybraný typ záchvatu (definovaný pacientem)",
            "suggestedDiagnoseMsg": "Potenciální diagnóza pro",
            "subtitle1": "Pamatujte, že naše",
            "subtitle2": "AI robot – Ictal®",
            "subtitle3": "- je inovativní nástroj, který vám pomůže a bude se neustále učit pomáhat vám ještě lépe, ale",
            "subtitle4": "nejedná se o zdravotnický prostředek a nelze jej použít k posouzení skutečného klinického stavu vašich pacientů.",
            "subtitle5": "Celá knihovna epilepsie ontologie byla vyvinuta tak, aby přesně rozpoznávala typ epileptického záchvatu podle nejnovější klasifikace MLPP, takže nástroj lze použít k posouzení hypotetických klinických stavů pro vzdělávací nebo školicí účely."
          },
          "outcome": {
            "saveLbl": "Uložit diagnostiku",
            "reasonLbl": "Důvod volby této diagnózy",
            "selectDiagnosisMsg": "Vyberte diagnózu",
            "calcDiagnosisMsg": "Generování diagnózy ..",
            "otherDiagnosis1Lbl": "jiný",
            "otherDiagnosis2Lbl": "diagnóza",
            "approveLbl": "souhlasím",
            "rejectLbl": "Nesouhlasím",
            "fixCritical": "Opravte kritické otázky",
            "updatedByLbl": "Podle diagnózy"
          },
          "therapy": {
            "title": "Vážená paní, vážený pane",
            "ictalCheck": "Šek",
            "seizureType": "Typ záchvatu",
            "type": {
              "1": "Já monoterapie",
              "2": "II Monoterapie",
              "3": "III Monoterapie",
              "4": "Já Politerapie",
              "5": "II Politerapie",
              "6": "III Politerapie",
              "0": "ICTAL návrh",
              "7": "Další politerapie:"
            },
            "drug": {
              "description": {
                "lastUsed": "Naposledy použitý:",
                "color": {
                  "undefined": "Droga nebyla dosud použita.",
                  "orange": "Droga byla použita dříve: žádný účinek / slabý účinek.",
                  "red": "Droga byla použita dříve: negativní účinek, stav pacienta se zhoršil.",
                  "green": "Droga byla použita dříve: uspokojivý účinek."
                }
              }
            },
            "filter": {
              "availableInPL": "Droga dostupná v Polsku",
              "refundedInPL": "Droga vrácena v Polsku",
              "reproductiveAgeOptional": "Reprodukční věk"
            },
            "notFound": "ICTAL nedokázal přizpůsobit léčbu pacientovi.",
            "apply": "Aplikujte terapii",
            "proposal": "Možné možnosti terapie",
            "subtitle1": "Pamatujte, že naše",
            "subtitle2": "AI robot – Ictal®",
            "subtitle3": "- je inovativní nástroj, který vám pomůže a bude se neustále učit pomáhat vám ještě lépe, ale",
            "subtitle4": "nejedná se o zdravotnický prostředek a nelze jej použít k posouzení skutečného klinického stavu vašich pacientů.",
            "subtitle5": "Celý proces terapie epilepsie byl vyvinut týmem odborníků a na základě pokynů předních lékařských vědeckých společností zabývajících se epilepsií, takže nástroj lze použít k posouzení hypotetických klinických stavů pro účely vzdělávání nebo školení."
          }
        },
        "doctorNotes": {
          "title": "Poznámky",
          "subtitle": "Je to místo, kam si můžete uložit své soukromé poznámky o tomto pacientovi",
          "addNote": "Přidat poznámku",
          "save": "Uložit změny",
          "saveSuccess": "Poznámka byla uložena",
          "modified": "Naposledy změněno",
          "editCancel": "Zrušit změny",
          "edit": "Upravit poznámku",
          "delete": "Smazat poznámku",
          "askDelete": "Opravdu chcete tuto poznámku smazat?",
          "deleteSuccess": "Poznámka byla smazána",
          "publicVisible": "Viditelné pro všechny lékaře",
          "sharedBy": "Sdíleno",
          "limitedShare": "Viditelné pro vybrané lékaře",
          "doctor": "Doktor",
          "forAdmin": "Viditelné pro admin",
          "repliedTo": "W odpowiedzi na",
          "sharedByPatient": "Sdíleno pacientem pro nepřítomnou konzultaci",
          "absentConsultation": "Chybějící konzultace",
          "addFiles": "Přidat soubor"
        },
        "diagnosis": {
          "confirmTitle": "Změna diagnózy",
          "confirmText": "Jste si jisti, že nechcete změnit diagnózu?",
          "updatedMsg": "Aktualizovaná diagnóza pro tento typ záchvatu"
        },
        "patientMessageHistory": {
          "title": "Historie zpráv pacientů",
          "emptyList": "Historie zpráv pacientů je prázdná"
        }
      },
      "landing": {
        "common": {
          "menu": {
            "observe": "Sledujte nás",
            "link": {
              "database": "NT - databáze",
              "doctors": "Pro lékaře",
              "contact": "Kontakt",
              "login": "Přihlásit se",
              "register": "Registrovat"
            },
            "terms": {
              "title": "Podmínky použití",
              "z1": "Organizační předpisy",
              "z2": "Předpisy pro poskytování elektronických služeb",
              "z3": "Zásady ochrany osobních údajů společnosti Neurosphera",
              "z4": "Zásady ochrany osobních údajů neuroterminálu",
              "payuInstallments": "PayU Splátky - FAQ"
            }
          },
          "contact": {
            "title": "Kontaktujte nás",
            "media": {
              "visit": "Navštiv nás"
            },
            "dataTitle": "Kontaktujte nás",
            "formTitle": "Kontaktní formulář",
            "form": {
              "email": "Poslat email",
              "phone": "Zavolejte nám",
              "message": "Zpráva",
              "info": "Nejčastěji sedíme, myslíme si, že se točí elita, do té doby se dočasně odehrává čas od času do konce světa. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo subsequent. Duis aute irure dolor in",
              "send": "Poslat zprávu",
              "ApproveDataProcessing": "Dobrovolně souhlasím se zpracováním mých osobních údajů společností Neuroterminal S.A. za účelem doručení mé žádosti. Podrobné informace o zásadách ochrany osobních údajů a vašich právech naleznete v",
              "policy": "Zásady ochrany osobních údajů",
              "feedback": {
                "header": "Děkujeme za zaslání vaší zprávy!",
                "content": "Brzy vás budeme kontaktovat\ntelefonicky nebo e-mailem"
              }
            }
          },
          "help": {
            "title": "Máte otázky?"
          }
        },
        "main": {
          "title1": "Moderní epilepsie",
          "title2": "léčebný systém",
          "bullet1": "Pohodlné konzultace telemedicíny\nod 99 PLN",
          "bullet2": "Přístup k nejlepším\nepileptologové",
          "bullet3": "Komplexní lékařská péče\nbez opuštění domova",
          "arrangeConsultation": "Domluvte si schůzku",
          "features": {
            "consultations": {
              "title": "Telemedicína\nkonzultace",
              "description": "Provádějte online návštěvy\nprostřednictvím videokonferencí"
            },
            "emergency": {
              "title": "Nouzový\npřípady",
              "description": "24 hodinový SOS hovor\ns lékaři"
            },
            "panel": {
              "title": "Trpěliví\npanel",
              "description": "Zdravotní historie,\nlékařské dokumenty\na doporučení\nna jednom místě"
            },
            "edocuments": {
              "title": "Elektronické recepty,\nnemocné listy",
              "description": "a doporučení\nbez opuštění domova"
            },
            "doctors": {
              "title": "\nLékaři",
              "description": "Přístup k nejlepším epileptologům\nbez front"
            },
            "quickAccess": {
              "title": "Rychlý\npřístup",
              "description": "Konzultace s lékaři\nbez front"
            }
          },
          "subHeaderBullet1": "Zůstaňte v kontaktu se svým lékařem,\nkdo používá modul umělé inteligence\nsledovat účinnost vaší léčby.",
          "subHeaderBullet2": "Získejte přístup k nejlepším neurologům v Polsku\nbez opuštění domova.",
          "subHeaderBullet3": "Připojte se k Neuroterminalu zdarma\na začít používat inovativní řešení\nléčit epilepsii!",
          "howItWorks": "Jak to funguje?",
          "steps": {
            "choose": {
              "title": "Volba pacienta\nlékař",
              "description": "z databáze specialistů"
            },
            "appoint": {
              "title": "Dělá\nschůzka",
              "description": "na návštěvu telemedicíny\nve vhodnou dobu"
            },
            "diagnose": {
              "title": "Lékař diagnostikuje",
              "description": "a zvolí terapii.\nSystém umělé inteligence iCtal\npomáhá mu s tím"
            },
            "documents": {
              "title": "Přidává lékařské\ndokumenty",
              "description": ""
            },
            "contact": {
              "title": "Doktor zůstává\nve styku",
              "description": "s pacientem,\npravidelně sleduje pokrok\nimplementované léčby"
            }
          },
          "opinions": "Názory uživatelů",
          "register": "Chcete -li si domluvit schůzku, vytvořte si účet"
        },
        "database": {
          "epilepsy": {
            "title": "Co je epilepsie?",
            "subheader1": "Epilepsie je zdravotní stav\ncharakterizované opakujícími se záchvaty.",
            "subheader2": "Výsledkem je záchvat\ndočasně změněno\nfunkce mozku\nzapříčiněno\nabnormální nebo nadměrné\nelektrické výboje\nuvnitř mozkových buněk."
          },
          "seizure": {
            "title": "Jak záchvat\nvypadat jako?",
            "subheader": "Příznaky záchvatů se mohou velmi lišit.",
            "bullet1": "Někteří lidé s epilepsií\njednoduše „ztratit kontakt“\nna několik sekund během záchvatu",
            "bullet2": "Další lidé padají na podlahu\ns křečemi končetin",
            "footer": "Klasifikace typu záchvatu je velmi důležitá\na pomáhá lékaři naplánovat potřebnou léčbu.\nJediný záchvat neznamená, že máte epilepsii."
          },
          "factors": {
            "title": "Provokující faktory",
            "subheader": "Mohou také způsobit záchvaty\nznámými a reverzibilními faktory,\njako",
            "bullet1": "Žádný spánek",
            "bullet2": "Alkohol\nvybrání",
            "bullet3": "Nízký obsah cukru",
            "seizureRecognition": "Nejméně dva nevyprovokované záchvaty\njsou obvykle nutné k diagnostice epilepsie.",
            "seizuresRelatedTo": "Záchvaty mohou být spojeny s",
            "relatedToBullet1": "Poškození mozku",
            "relatedToBullet2": "Rodinná tendence",
            "relatedToBullet3": "Často jejich příčina\nje zcela neznámý"
          },
          "whoCanSick": {
            "title": "Kdo může onemocnět?",
            "subheader": "Epilepsie může postihnout kohokoli,\nbez ohledu na pohlaví a věk.",
            "bullet1": "Většina záchvatů se vyskytuje u dětí\ndo 1 roku věku,\nzatímco starší lidé mají obvykle nárůst\nve výskytu po 65 letech.",
            "bullet2": "Odhaduje se, že až 1% lidí\ntrpí epilepsií (asi 400 000 lidí v Polsku)."
          },
          "treatment": {
            "title": "Jak se léčí epilepsie?",
            "bullet1": "Drogy mohou účinně kontrolovat záchvaty\npřibližně v 70%\npacientů s epilepsií",
            "bullet1Header": "Léky",
            "bullet2": "U pacientů s nekontrolovanou epilepsií\nneurochirurgie může poskytnout „lék“\nodstraněním zdroje\nzáchvatů a epilepsie.",
            "bullet2Header": "Chirurgická operace",
            "footer": "Léčba drogami nebo někdy chirurgický zákrok\nmůže ovládat záchvaty u většiny lidí s epilepsií.\nNěkteří lidé vyžadují celoživotní léčbu ke zvládnutí záchvatů,\nale ostatní nakonec přestanou. Některé děti s epilepsií\nmůže vyrůst ze záchvatů s věkem."
          },
          "impact": {
            "title": "Jak epilepsie ovlivňuje\nkaždodenní život?",
            "subheader": "Záchvaty a epilepsie mohou ovlivnit",
            "bullet1": "Bezpečnost",
            "bullet2": "Vztahy",
            "bullet3": "Práce",
            "bullet4": "Řízení auta",
            "bullet5": "A mnoho dalšího",
            "footer": "Vnímání veřejnosti a léčba lidí s epilepsií\njsou často většími problémy než skutečné záchvaty."
          },
          "help": {
            "title": "Neuroterminální vůle\nvám pomůže léčit epilepsii",
            "bullet1Header": "Pohodlí",
            "bullet1": "Zařídíte\nkonzultace s lékařem\nbez opuštění domova.",
            "bullet2Header": "Inovace",
            "bullet2": "Používáme umělé\nzpravodajské systémy\na moderní analytické nástroje\nk léčbě epilepsie.",
            "bullet3Header": "Bezpečnost",
            "bullet3": "Získáte přístup\nna 24hodinovou neurologickou směnu\nkterý vám poskytne\nokamžitá pomoc.",
            "makeAnAppointment": "Domluvte si schůzku"
          }
        },
        "doctors": {
          "title1": "Stát se doktorem",
          "titleIn": "v",
          "title2": "NEUROTERMINÁL",
          "subtitle": "Konzultujte pacienty online\npomocí nejnovější\ntechnologie.",
          "register": "Přihlásit se",
          "features": {
            "consultations": {
              "title": "Telemedicína\nkonzultace",
              "description": "Provádějte návštěvy online\nprostřednictvím videokonferencí\nnebo chatovat"
            },
            "digitalOffice": {
              "title": "Digitální\nkancelář",
              "description": "Všechny návštěvy a lékařská data\nvašich pacientů\nna jednom místě"
            },
            "eDocuments": {
              "title": "\nE-dokumenty",
              "description": "Vytvářejte předpisy,\nnemocenské listy a doporučení\nonline"
            },
            "flexibleSchedule": {
              "title": "Flexibilní\nplán",
              "description": "Naplánujte si konzultace\nv termínech, které jsou pro vás výhodné\n7 dní v týdnu"
            },
            "system": {
              "title": "Systém\nNeuroterminální",
              "description": "Použijte inovativní\nsystém umělé inteligence\ndiagnostikovat pacienty"
            },
            "patients": {
              "title": "\nPacienti",
              "description": "Vést své pacienty\na rychle získat nové"
            }
          },
          "steps": {
            "register": {
              "title": "Přihlásit se",
              "description": ""
            },
            "training": {
              "title": "Projít tréninkem",
              "description": "jak používat neuroterminal\nplatforma a chování\ntelemedicínské konzultace"
            },
            "schedule": {
              "title": "Nastavit konzultace\nplán",
              "description": "volně plánovat\nvaše dostupnost"
            },
            "pricing": {
              "title": "Upřesněte ceník",
              "description": "váš plat\nna konzultaci"
            },
            "consultations": {
              "title": "Konzultovat",
              "description": "telemedicína online prostřednictvím\nneuroterminální platforma\nprostřednictvím videa nebo chatu"
            },
            "monitor": {
              "title": "Monitor\npacientů",
              "description": "a postup jejich léčby"
            }
          },
          "firstSteps": "První kroky"
        },
        "patients": {},
        "removePatient": {
          "title": "Potvrďte odebrání účtu",
          "subTitle1": "Účet registrovaný na",
          "subTitle2": "budou odstraněny",
          "why": "Proč chcete odebrat účet",
          "whyPrompt": "Vaše odpověď je pro nás velmi důležitá. Pomůže nám to zlepšit naše služby.",
          "removeBtn": "Odstraňte svůj účet z Neuroterminálu",
          "accountRemoved": "Váš účet byl odstraněn",
          "noAccount": "Účet neexistuje nebo jej nelze odstranit",
          "accountRemovedDescription": "Nezapomeňte, že se můžete kdykoli zaregistrovat na Neuroterminálu a rezervovat si návštěvu v Neurosphera."
        }
      },
      "ictal": {
        "lab": {
          "title": "IctalLab",
          "subtitle": "Chcete -li spustit automatickou diagnostiku, zadejte vektor nebo načtěte pacienta.",
          "diagnose": "Diagnostikovat",
          "clear": "Průhledná",
          "inputVector": "Vstupní vektor",
          "loadPatient": "Vyberte pacienta",
          "changePatient": "Vyměňte pacienta",
          "selectedPatient": "Vybraný pacient",
          "survey": "Průzkum",
          "diagnosis": "Diagnóza",
          "factorsConfiguration": "Konfigurace faktorů",
          "sum": "Součet",
          "therapy": "Terapie",
          "check": "Šek",
          "selectTherapy": "Vyberte typ terapie",
          "selectedTherapy": "Vybraný typ terapie",
          "selectSeizureType": "Vyberte typy záchvatů",
          "selectedSeizureType": "Vybrané typy záchvatů",
          "drugAvailableInPl": "Droga dostupná v Polsku",
          "drugRefunded": "Náhrada léku",
          "patientIsPregnant": "Pacientka je těhotná",
          "patientWithSomaticDiseases": "Pacient se somatickými chorobami",
          "patientInOlderAge": "Pacient ve vyšším věku",
          "patientOfChildbearingAge": "Pacient v plodném věku",
          "patientAge": "Věk pacienta",
          "recommendedTherapy": "Doporučená terapie",
          "errors": {
            "factorsError": "Součet koeficientů by měl být 100",
            "vectorLengthError": "Nesprávná délka vstupního vektoru",
            "vectorInvalidError": "Nesprávný vstupní vektor"
          },
          "factors": {
            "nn": "NN",
            "knn": "KNN",
            "jacc": "Jaccard",
            "jaro": "Jaro",
            "sodi": "Sørensen – Kostky",
            "over": "Překrytí",
            "tver": "Tversky: α = 1, β = 1"
          },
          "surveyHeaders": {
            "name": "Otázka",
            "always": "Vždy",
            "veryOften": "Velmi často",
            "often": "Často",
            "sometimes": "Někdy",
            "rarely": "Zřídka",
            "veryRarely": "Velmi zřídka",
            "never": "Nikdy",
            "veryLong": "Velmi dlouhá",
            "long": "Dlouho",
            "bitLong": "Trochu dlouhý",
            "variable": "Variabilní",
            "short": "Krátký",
            "veryShort": "Velmi krátké",
            "ultraShort": "Ultra krátký"
          },
          "seizuresTableHeaders": {
            "seizureType": "Typ záchvatu",
            "probability": "Pravděpodobnost [ %]"
          },
          "selectUserDialog": {
            "title": "Vyberte pacienta",
            "noPatientsFound": "Nebyli nalezeni žádní pacienti."
          },
          "configuration": {
            "subtitle": "Konfigurace",
            "drugCatalog": {
              "title": "Katalog léků",
              "add": "Přidejte novou drogu"
            },
            "factors": {
              "save": "Uložit faktory",
              "coefModifiedMsg": "Koeficienty uloženy"
            }
          },
          "drugs": {
            "deleteConfirmationDialogText": "Smazáním léku se smažou také údaje o tréninku motoru Ictal. Všechna data spojená s tímto lékem budou odstraněna. Přejete si pokračovat?",
            "updateDrugDialogTitle": "Aktualizujte lék",
            "createDrugDialogTitle": "Přidejte drogu",
            "save": "Uložit",
            "cancel": "zrušení",
            "validation": {
              "minAgeRequired": "Je vyžadován minimální věk",
              "minAgeRange": "Minimální věk musí být číslo v rozsahu 0 - 120",
              "weightRequired": "Je vyžadována hmotnost",
              "weightRange": "Hmotnost musí být číslo v rozmezí -3 - 3"
            },
            "legend": {
              "title": "Drogy - legenda",
              "column": "Sloupec",
              "value": "Hodnota",
              "thrAbove": "Dosažen věkový práh; věk nad stanovenou prahovou hodnotou",
              "thrBelow": "Věkový práh nebyl dosažen; věk pod stanovenou prahovou hodnotou",
              "drugAvailable": "Droga dostupná v PL",
              "drugNotAvailable": "Droga není k dispozici v PL",
              "drugRefunded": "Lék vrácený v PL",
              "drugNotRefunded": "Lék nebyl vrácen v PL",
              "usageForbidden": "Zakázáno",
              "usageVeryRare": "Velmi zřídka používané",
              "usageRare": "Zřídka používané",
              "usageAmbivalent": "Ambivalentní",
              "usageSometimes": "Někdy používané",
              "usageOften": "Často používaný",
              "usageRecommended": "Doporučeno"
            }
          },
          "usedDrugs": "Drogy, které mají být odfiltrovány (implikované - extrémní zhoršení)",
          "drugProposal": "Návrh na drogy",
          "wageSum": "Součet mezd (vyšší - lepší)",
          "patientSex": {
            "label": "Sex",
            "type": {
              "MALE": "mužský",
              "FEMALE": "ženský"
            }
          },
          "experts": {
            "titleLbl": "Odborní lékaři",
            "addExpertMsg": "Přidejte odborníka",
            "editExpertMsg": "Expert na úpravy",
            "deleteExpertTitleMsg": "Odebrat odborníka",
            "deleteExpertDescMsg": "Odstranění odborníka bude mít za následek odstranění všech údajů o školení motoru Ictal spojených s tímto odborníkem. Chceš pokračovat?",
            "saveMsg": "Údaje odborníků upraveny",
            "save": "Uložit",
            "cancel": "zrušení",
            "validation": {
              "weightRange": "Hmotnost musí být číslo v rozsahu 0 - 1"
            }
          },
          "recommendations": {
            "titleLbl": "Drogy doporučené odborníky",
            "addRecommendationLbl": "Přidejte doporučení",
            "saveMsg": "Vektor doporučení pro lék uložen",
            "save": "Uložit",
            "cancel": "zrušení",
            "dialog": {
              "titleLbl": "Doporučené léky",
              "expertLbl": "Expert",
              "therapyLbl": "Terapie"
            }
          },
          "epilepsies": {
            "titleLbl": "Typy epilepsie",
            "addMsg": "Přidejte typ epilepsie",
            "editMsg": "Upravit typ epilepsie",
            "deleteTitleMsg": "Odstraňte typ epilepsie",
            "deleteDescMsg": "Odebrání typu epilepsie bude mít za následek odstranění všech údajů o tréninku motoru Ictal spojených s tímto typem epilepsie. Chceš pokračovat?",
            "saveMsg": "Data typů epilepsie upravena",
            "save": "Uložit",
            "cancel": "zrušení"
          },
          "ediagnosis": {
            "titleLbl": "E-diagnostika",
            "headers": {
              "eventsTrailer": "Dlouho před záchvatem (hodiny, dny)",
              "eventsBefore": "Těsně před záchvatem (sekundy)",
              "eventsStart": "Začátek záchvatu - první symptom",
              "eventsNext": "Další příznaky záchvatu",
              "eventsEnd": "Konec záchvatu",
              "eventsAfter": "Po záchvatu",
              "eventsFeature": "Celková charakteristika záchvatu"
            },
            "dialog": {
              "titleLbl": "E-diagnostický vektor",
              "epilepsyLbl": "Typ epilepsie",
              "save": "Uložit",
              "cancel": "zrušení",
              "saveMsg": "Vektor E-diagnostiky upraven"
            },
            "events": {
              "trailerPrecise": "Přesná předpověď (koncová)",
              "trailerUnspecified": "Predikce nespecifikována (koncová)",
              "beforeAura": "Aura (před)",
              "beforeSpecificStimuli": "Specifické podněty (dříve)",
              "beforeNystagmus": "Nystagmus (před)",
              "beforeImmobility": "Nehybnost (dříve)",
              "startFaint": "Mdloby (začátek)",
              "startLossConsciousness": "Ztráta vědomí (začátek)",
              "startBodyConvulsions": "Tělesné křeče (začátek)",
              "startBodyPartConvulsions": "Křeče v části těla (začátek)",
              "startFaceConvulsions": "Křeče v obličeji (začátek)",
              "startBodyStiffness": "Ztuhlost těla (začátek)",
              "startLimbEjection": "Vysunutí končetiny (start)",
              "startEyeMovementDisorders": "Poruchy pohybu očí (začátek)",
              "startEyeTurnPernament": "Otočení očí trvalé (start)",
              "startBodyTurn": "Otočení těla (start)",
              "startGroundFall": "Pád země (začátek)",
              "startBitTongue": "Kousací jazyk (začátek)",
              "startShout": "Křik (start)",
              "startSounds": "Zvuky (start)",
              "startMotorAutomatisms": "Automatizace motoru (start)",
              "startImmobility": "Nehybnost (začátek)",
              "startLaugh": "Smějící se (začátek)",
              "startChuck": "Upínání (start)",
              "startCry": "Pláč (start)",
              "startEpigastricSymptoms": "Epigastrické příznaky (začátek)",
              "startDejaVu": "Deja vu (začátek)",
              "startVisualHallucinations": "Vizuální halucinace (začátek)",
              "startAuditoryHallucinations": "Sluchové halucinace (začátek)",
              "startEyelidsMyoclonus": "Oční víčka myoklonus (začátek)",
              "startMyoclonus": "Myoclonus (začátek)",
              "nextFaint": "Mdloby (další)",
              "nextLossConsciousness": "Ztráta vědomí (další)",
              "nextBodyConvulsions": "Tělesné křeče (další)",
              "nextBodyPartConvulsions": "Křeče v části těla (další)",
              "nextFaceConvulsions": "Křeče v obličeji (další)",
              "nextBodyStiffness": "Ztuhlost těla (další)",
              "nextEyeMovementDisorders": "Poruchy pohybu očí (další)",
              "nextEyeTurnPernament": "Otočit oko trvale (další)",
              "nextBodyTurn": "Otočení těla (další)",
              "nextGroundFall": "Pozemní pád (další)",
              "nextBitTongue": "Kousavý jazyk (další)",
              "nextUrinating": "Močení (další)",
              "nextDefecation": "Defekce (další)",
              "nextShout": "Křičí (další)",
              "nextSounds": "Zvuky (další)",
              "nextAggression": "Agrese (další)",
              "nextMotorAutomatisms": "Motorový automatismus (další)",
              "nextImmobility": "Nehybnost (další)",
              "nextLaugh": "Smějící se (další)",
              "nextChuck": "Upínání (další)",
              "nextCry": "Pláč (další)",
              "nextEpigastricSymptoms": "Epigastrické příznaky (další)",
              "nextDejaVu": "Deja vu (další)",
              "nextVisualHallucinations": "Vizuální halucinace (další)",
              "nextAuditoryHallucinations": "Sluchové halucinace (další)",
              "nextEyelidsMyoclonus": "Oční víčka myoklonus (další)",
              "endAnotherSeizure": "Další záchvat (konec)",
              "endBodyConvulsions": "Tělesné křeče (konec)",
              "endBodyPartConvulsions": "Křeče v části těla (konec)",
              "endFaceConvulsions": "Křeče v obličeji (konec)",
              "endEyeMovementDisorders": "Poruchy pohybu očí (konec)",
              "endEyeTurnPernament": "Otočit oko trvale (konec)",
              "endBodyTurn": "Otočení těla (konec)",
              "endGroundFall": "Pád země (konec)",
              "endUrinating": "Močení (konec)",
              "endDefecation": "Defekce (konec)",
              "endSounds": "Zvuky (konec)",
              "endAggression": "Agresivita (konec)",
              "endMotorAutomatisms": "Motorový automatismus (konec)",
              "endImmobility": "Nehybnost (konec)",
              "endLaugh": "Smějící se (konec)",
              "endChuck": "Upínání (konec)",
              "endCry": "Pláč (konec)",
              "endVomit": "Zvracení (konec)",
              "afterEyeMovementDisorders": "Poruchy pohybu očí (po)",
              "afterEyeTurnPernament": "Otočit oko trvale (po)",
              "afterUrinating": "Močení (po)",
              "afterDefecation": "Defekce (po)",
              "afterConfusion": "Zmatek (po)",
              "afterSleep": "Spánek (po)",
              "afterAggression": "Agrese (po)",
              "afterMotorAutomatisms": "Motorový automatismus (po)",
              "afterVomit": "Zvracení (po)",
              "afterHighTemperature": "Vysoká teplota (po)",
              "featureMany": "Mnoho (funkce)",
              "featureDayOccurrence": "Výskyt dne (funkce)",
              "featureNightOccurrence": "Noční výskyt (funkce)",
              "featureAwakeningOccurrence": "Probuzení (funkce)",
              "featureMorningOccurrence": "Ranní výskyt (funkce)",
              "featureDuration": "Trvání (funkce)"
            }
          },
          "model": {
            "search": "Vyhledávání",
            "manage": "Spravovat",
            "drug": {
              "general": "Všeobecné údaje",
              "weights": "Závaží",
              "shortName": "Krátké jméno",
              "name": "název",
              "minAge": "Minimální věk",
              "plAvailability": "K dispozici v PL",
              "plRefunded": "Vráceno v PL",
              "reproductiveAgeWeight": "Reprodukční věk",
              "pregnantWeight": "Těhotná",
              "oldAgeWeight": "Starý věk",
              "somaticDiseaseWeight": "Somatická nemoc"
            },
            "expert": {
              "description": "Expert",
              "factor": "Faktor"
            },
            "epilepsy": {
              "name": "název"
            }
          },
          "algorithmSteps": {
            "filterName": "Filtr",
            "order": "Objednat",
            "usedFilters": "Použité filtry",
            "filters": {
              "SeizureDrugFilter": "Výběr Durga pomocí záchvatů",
              "UsedDrugFilter": "Odstranění použitých léků",
              "TherapyDrugFilter": "Pořadí léků podle doporučení expoerts (hmotnost)",
              "WeightDrugFilter": "Výpočet hmotnosti"
            },
            "tableModel": {
              "drugName": "název",
              "drugShortName": "Krátké jméno",
              "minAge": "Minimální věk",
              "oldAgeWeight": "Vyšší věk",
              "pregnantWeight": "Těhotenství",
              "reproductiveAgeWeight": "Reprodukční věk",
              "somaticDiseaseWeight": "Somatická onemocnění",
              "weightSum": "Součet hmotnosti"
            }
          },
          "furtherPoliOffset": "Další číslo politerapie (začíná na 0)"
        }
      },
      "duties": {
        "Duties": {
          "duty": "Plánovaná povinnost",
          "availableDuty": "Dostupné clo",
          "notAvailableDuty": "Neplatná povinnost",
          "selectedDuty": "Vybraný",
          "blockedDuty": "Není dostupný",
          "cancelledDuty": "Vybráno pro zrušení",
          "dutyToChange": "Žádost o převzetí povinnosti",
          "changes": {
            "approve": {
              "text": "Uložit změny",
              "tooltipInstruction": "Vyberte si cla z dostupných a uložte kliknutím na toto tlačítko"
            },
            "discard": {
              "text": "Zrušit změny"
            }
          }
        },
        "confirmationDialog": {
          "text": "Vaše změny nebudou uloženy. Chcete je zahodit?"
        },
        "youAreNotAllowedDialog": {
          "title": "Vyžadována oprávnění",
          "text": "Nemáte oprávnění k registraci povinností. Pro více informací nás kontaktujte."
        },
        "obligatoryDutyDialog": {
          "title": "Provoz není povolen",
          "subtitle": "Podle pravidel obchodních podmínek nelze vybrané clo zrušit.",
          "text": "Pro více informací zkontrolujte naše",
          "termsAndConditions": "Smluvní podmínky",
          "close": "Zavřít"
        },
        "dutiesLimitAchievedDialog": {
          "title": "Provoz není povolen",
          "subtitle": "Na tento týden jste dosáhli limitu služby.",
          "text": "Pro více informací zkontrolujte naše",
          "termsAndConditions": "Smluvní podmínky",
          "close": "Zavřít"
        }
      },
      "medicalHistory": {
        "MedicalHistory": {
          "title": "Zdravotní historie",
          "return": "Vrátit se",
          "show": "Ukázat",
          "psychomotorDevelopment": "Psychomotorický vývoj",
          "comorbidities": "Souběžnosti",
          "notDiagnosedLbl": "Není diagnostikováno",
          "bloodType": "Krevní skupina",
          "height": "Výška",
          "weight": "Hmotnost",
          "bmi": "BMI",
          "tabs": {
            "psychomotorDevelopment": "Psychomotorický vývoj",
            "seizuresHistory": "Historie záchvatů",
            "seizuresCharacteristics": "Charakteristika záchvatů",
            "comorbidities": "Souběžnosti",
            "treatment": "Léčba"
          },
          "treatment": {
            "title": "Historie léčby a návštěvy",
            "currentTherapy": "Současná terapie",
            "biomedicalData": "Biomedicínská data",
            "comorbiditiesData": "Údaje o komorbiditách",
            "previousTherapies": "Historie terapií",
            "number": "Ne",
            "medicine": "Lék",
            "dosage": "Dávkování",
            "effect": "Účinek",
            "noCurrentTherapy": "Žádná současná terapie",
            "start": "Start",
            "end": "Konec",
            "noPreviousTherapies": "Historie terapií je prázdná",
            "treat": "léčba",
            "visits": "návštěvy"
          },
          "survey": {
            "title": "Průzkum 0"
          },
          "calendar": {
            "title": "Kalendář akcí",
            "visitCalendarLabel": "Kalendář návštěv"
          },
          "prescriptions": {
            "title": "E-recepty"
          },
          "consultations": {
            "title": "Návštěvy",
            "noConsultations": "Žádné konzultace",
            "observations": "Pozorování",
            "recomendation": "Doporučení",
            "doctor": "Doktor",
            "date": "datum",
            "type": "Typ",
            "category": "Kategorie",
            "none": "Žádný"
          },
          "icd10": {
            "title": "Diagnóza",
            "current": "Aktuální diagnóza",
            "history": "Dějiny",
            "from": "Z",
            "added": "Přidal",
            "removed": "Odstraněno",
            "noCurrentIcd10": "Žádná aktuální diagnóza",
            "noIcd10History": "Historie diagnostiky je prázdná"
          },
          "bioMedInfo": {
            "edit": "Upravit"
          },
          "visitCalendar": {
            "title": "Názor lékaře a pacienta",
            "opinionTypeHeader": "Typ názorů",
            "seizureEvaluation": "Závažnost záchvatů",
            "wellBeing": "Pohoda",
            "treatmentRating": "Účinnost léčby",
            "sideEffects": "Vedlejší efekty",
            "treatmentSafety": "Bezpečnost léčby",
            "patientOpinion": "Názor pacienta",
            "doctorOpinion": "Názor lékaře",
            "noData": "Žádná data z průzkumů",
            "noVisits": "Žádné návštěvy"
          },
          "epicrisis": {
            "title": "Epikrize"
          }
        },
        "healthSurvey": {
          "title": "Průzkum 0",
          "readOnlyAccess": "Náhled pouze pro čtení. Průzkum může vyplnit lékař při první návštěvě",
          "noAnswer": "Žádná odpověď",
          "allDisabled": "Průzkum 0 nelze po první návštěvě upravit",
          "partCompleted": "Tato část průzkumu byla dokončena",
          "currentTabDisabled": "Tato část průzkumu zdraví je dokončena a po první návštěvě ji nelze upravit",
          "emptyFilterSet": "V seznamu jsou zobrazeny pouze otázky bez odpovědi.",
          "surveyUpdatedByDoctor": "Doktor právě aktualizoval průzkum v části „{sectionName}“",
          "filters": {
            "show": "Ukázat",
            "all": "Všechno",
            "empty": "Prázdný"
          },
          "hintPopup": {
            "title": "Nevíte, jak odpovědět?",
            "content": "Neboj se! Nechte obtížnou otázku nezodpovězenou. Lékař vám pomůže vyplnit chybějící části průzkumu během prvního konferenčního hovoru."
          },
          "SeizureHistory": {
            "introTitle": "Zajímalo by nás, jak dlouho a jaké záchvaty máte.",
            "introSubtitle": "Je to velmi důležité. Poznámka: toto je vaše anamnéza, kterou lze určit pouze tímto způsobem, neexistuje žádný jiný způsob, jak ji popsat, zkuste proto získat přesná fakta a data!",
            "next": "další",
            "edit": "Upravit",
            "back": "Předchozí",
            "historyCanBeModified": "Vaše historie záchvatů již byla inicializována. Můžete jej upravit před první návštěvou.",
            "historyCannotBeModified": "Vaši historii záchvatů nelze po první návštěvě upravit.",
            "return": "Vrátit se",
            "outroTitle": "Gratulujeme, odpověděli jste na všechny otázky týkající se vaší historie záchvatů.",
            "outroSubtitle": "Děkuju!",
            "save": "Uložit",
            "generatingInProgress": "Probíhá generování historie záchvatů. Prosím, čekejte...",
            "saveSuccess": "Historie záchvatů byla úspěšně uložena",
            "generateSuccess": "Historie záchvatů byla úspěšně vygenerována",
            "stages": {
              "firstSeizure": "První záchvat",
              "quantityAndRegularity": "Množství a pravidelnost",
              "frequency": "Frekvence",
              "worsePeriods": "Horší období",
              "betterPeriods": "Lepší období",
              "breaks": "Přestávky"
            },
            "seizureTypesForm": {
              "title": "Určete počet typů svých záchvatů a pojmenujte je (maximálně 3)",
              "modifyWarning": "Pozornost! Po úpravě typů záchvatů je třeba předefinovat historii záchvatů a charakteristiky záchvatů",
              "modifyDisabled": "Typy záchvatů nelze upravit, protože záchvaty",
              "typeName": "Název typu záchvatu",
              "addType": "Přidejte typ záchvatu"
            },
            "firstSeizureForm": {
              "title": "Zadejte datum prvního záchvatu",
              "subtitle1": "Vraťte se a pamatujte si každý první záchvat.",
              "subtitle2": "Určete datum svého prvního záchvatu co nejpřesněji.",
              "subtitle3": "Říkáme tomu záchvat {name} (jeho typ definujeme později společně s vedoucím lékařem).",
              "sickSince": "Dobře, takže jsi nemocný z:"
            },
            "quantityAndRegularity": {
              "title": "Záchvaty jako první pokračují dodnes, ukazují, zda:",
              "quantityYes": "Od tohoto prvního záchvatu se objevily dodnes",
              "quantityNo": "Záchvat jako ten první se již neopakoval",
              "quantityUndefined": "Bylo jich tolik, že je nemožné uvést přesné číslo",
              "seizuresQuantity": "Počet záchvatů",
              "title2": "Vyskytovaly se pravidelně, tj. Přibližně ve stejných intervalech",
              "yes": "Ano",
              "no": "Ne",
              "knowsAllDates": "Znáte data všech záchvatů?",
              "regularOccurrence": "Probíhají epizody pravidelně?"
            },
            "frequency": {
              "title": "Zadejte frekvenci - zadejte, kolik záchvatů se obvykle vyskytuje v daném časovém období:",
              "in": "v"
            },
            "worsePeriods": {
              "title": "Byly horší období, kdy byly záchvaty častější?",
              "subtitle": "Identifikujte horší období, kdy se záchvaty objevovaly častěji než obvykle",
              "date": "datum",
              "frequency": "Frekvence záchvatů",
              "to": "na",
              "remove": "Odstranit",
              "addPeriod": "Přidejte tečku"
            },
            "betterPeriods": {
              "title": "Byla lepší období, kdy byly záchvaty méně časté?",
              "subtitle": "Identifikujte lepší období, kdy se záchvaty objevovaly méně často než obvykle"
            },
            "breaks": {
              "title": "Došlo k přestávkám, když nedošlo k žádnému záchvatu?",
              "subtitle": "Identifikujte přestávky, pokud nedošlo k žádnému záchvatu",
              "date": "datum",
              "to": "na",
              "remove": "Odstranit",
              "add": "Přidat období"
            },
            "knowsAllDates": {
              "subtitle": "Vyplňte data následujících záchytů",
              "date": "datum",
              "remove": "Odstranit",
              "add": "Přidat datum"
            }
          },
          "Intro": {
            "title1": "Informace, které zde uvedete, budou sdíleny s lékařem. Pomohou vám správně diagnostikovat a léčit vás.",
            "fillSurvey": "Vyplňte průzkum zdraví",
            "inVisit": {
              "title1": "Telekonzultace probíhá.",
              "title2": "Je načase vyplnit dotazník společně se svým lékařem.",
              "title3": "Váš lékař vám pomůže odpovědět na nejasné otázky.",
              "next": "další"
            }
          },
          "PsychomotorDevelopment": {
            "introTitle": "Psychomotorický vývoj",
            "introSubtitle1": "V tomto okamžiku chceme zjistit, jaký je váš celkový psycho-fyzický stav.",
            "introSubtitle2": "Díky vašim odpovědím lékař zvolí optimální terapii.",
            "introSubtitle3": "Pamatujte si, že pokud je jakákoli otázka nejasná, můžete na ni odpovědět se svým lékařem během prvního konferenčního hovoru.",
            "stages": {
              "psychoPhysicalState": "Fyzický a duševní stav",
              "senses": "Smysly",
              "drugs": "Drogy"
            },
            "psychoPhysicalState": {
              "overall": "Určete psychomotorický vývoj",
              "retardation": "Určete psychomotorickou retardaci (postižení)",
              "iq": "Určete IQ pacienta",
              "notTested": "Netestováno",
              "tested": "Vyzkoušeno",
              "iqValue": "Zadejte hodnotu IQ",
              "speech": "Aktivní a pasivní řeč. (Mluvení a porozumění řeči)",
              "mentalDisorders": "Historie psychiatrických onemocnění",
              "neurologicalSyndrome": "Potvrzený neurologický syndrom",
              "walk": "Procházka",
              "sex": "Sex"
            },
            "senses": {
              "nystagmus": "Má pacient nystagmus?",
              "vision": "Vidění",
              "hearing": "Sluch"
            },
            "drugs": {
              "smoking": "Kouření",
              "alcohol": "Alkohol",
              "narcotics": "Narkotika"
            },
            "mentalDisorders": {
              "psychosis": "Psychóza v současné době nebo v minulosti",
              "depression": "Deprese v současné době nebo v minulosti",
              "autism": "Diagnostikován autismus",
              "adhd": "ADHD a podobně",
              "mentalDisordersNotKnown": "Chybí nebo není znám"
            },
            "neurologicalSyndrome": {
              "piramidalDeficit": "Piramidální deficit",
              "brainDeficit": "Mozek",
              "sensoryDisturbance": "Senzorické rušení",
              "peripharalParesis": "Peripharální paréza",
              "noNeurologicalSyndrome": "Není nebo není známo"
            }
          },
          "SeizuresCharacteristics": {
            "introTitle": "Charakteristiky záchvatů",
            "introSubtitle1": "Nezapomeňte, že popisujete typický záchvat nebo záchvat. Při popisu jednoho záchvatu můžete uvést přesně to, co se stalo. V případě vícenásobných záchvatů se některé z nich mohou navzájem mírně lišit, takže uvedete jejich náhodné vlastnosti. Popíšete zde stavy před záchvatem: upoutávky, okamžité události a fáze záchvatu (začátek, pokračování, konec a událost po záchvatu). Poskytnete také obecné informace o záchvatu.",
            "introSubtitle2": "Každá informace je důležitá a může mít dopad na nahrazení diagnózy lékařem, snažte se být co nejpřesnější a nejpřesnější.",
            "question": "Otázka",
            "seizure": "Typ záchvatu",
            "emptySeizureTypesInfo": "Nejprve musíte definovat typy záchvatů v záložce 'Historie záchvatů'",
            "stages": {
              "trailers": "Záchvat\nproroctví",
              "before": "Okamžitý předzáchvat\nporucha",
              "start": "První symptom\nzáchvatu",
              "next": "Další\nzáchvatový symptom",
              "end": "Příznaky\nkonec záchvatu",
              "after": "Po\nzáchvat",
              "feature": "Záchvat\nfunkce",
              "premonition": "Předtucha"
            },
            "trailers": {
              "trailerPrecise": "Existují (nebo došlo pouze k 1 zabavení) přívěsy konkrétních přístavů?",
              "trailerPreciseDescription": "Predikce je víceméně přesná předzvěst záchvatu. Explicitní upoutávkou máme na mysli konkrétní pocit, který lze popsat: úzkost, smutek, vzrušení atd. Někdy je upoutávka nedefinovaná, těžko popsatelná pacientem. Je to dlouhý jev, vyskytuje se hodiny (méně často dny) před záchvatem, což jej odlišuje od aury, která záchvatu bezprostředně předchází. U některých pacientů se schopnost vnímat záchvat objeví až po nějaké době.",
              "trailerUnspecified": "Existují (nebo došlo pouze k 1 záchvatu) blíže neurčené upoutávky na záchvat?",
              "trailerUnspecifiedDescription": ""
            },
            "before": {
              "beforeAura": "Existuje aura před záchvatem (záchvaty)?",
              "beforeAuraDescription": "Předtucha záchvatu (nazývaná aura) je subjektivní, různě pociťovaný stav, který signalizuje, že se blíží záchvat (hned nebo na neurčitý čas). Stává se, že po auře nedojde k žádnému záchvatu. Pacient nemusí zpočátku vědět, že má paroxysmální auru, obvykle k tomuto uvědomění dojde po následných útocích na auru.",
              "beforeSpecificStimuli": "Může být záchvat / záchvaty způsoben specifickými podněty?",
              "beforeSpecificStimuliDescription": "Př. náhlý nebo specifický zvuk, náraz nebo bolest, přerušované světlo, záblesk nebo jiný jev. Někdy je záchvat reflexní reakcí na něco.",
              "beforeNystagmus": "Existuje nějaký patologický pohyb očí a / nebo nystagmus před záchvatem (záchvaty)?",
              "beforeNystagmusDescription": "Nystagmus je rychlý pohyb knoflíků, obvykle v opačných směrech. Dalšími pohyby jsou rychlé otáčení knoflíků nebo jejich otáčení.",
              "beforeImmobility": "Existuje / jsou před záchvatem (záchvaty) ticho, náhlá imobilizace nebo zastavení pohybu?",
              "beforeImmobilityDescription": "Jsou chvíle, kdy si všimneme, že se pacient přestane hýbat a zastaví se. Může to být symptom před záchvatem."
            },
            "start": {
              "startFaint": "Začaly / začaly záchvaty výpadkem proudu?",
              "startFaintDescription": "Ztráta vědomí je nejhlubším typem ztráty kontaktu, kontroly a vnímání. Znamená to, že se mozek nějak „vypne“, takže zůstane jen tlukot srdce a dech. Vždy je doprovázen pádem, nedostatečnou reaktivitou a úplným nedostatkem kontaktu. Trvání se liší.",
              "startLossConsciousness": "Začaly / začaly záchvaty / ztráty ztrátou nebo částečnou ztrátou vědomí?",
              "startLossConsciousnessDescription": "Při bezvědomí dochází k automatické ztrátě vědomí. Dojde -li pouze ke ztrátě vědomí, je narušen kontakt s okolím a vědomé myšlení, kontakt a reakce. Pacient může stát, někdy provádět různé motorické činnosti, dokonce i mluvit, ale logický a vědomý kontakt a reakce jsou narušeny / ztraceny.",
              "startBodyConvulsions": "Začaly / začaly záchvaty / záchvaty celého těla?",
              "startBodyConvulsionsDescription": "Křeče jsou rychlé, rytmické pohyby svalů, které se stahují a rytmicky uvolňují. Nejde o chvějící se, lámavé nebo pomalé pohyby; jsou obvykle nesmyslné bez směru pohybu.",
              "startBodyPartConvulsions": "Začaly záchvaty / začaly křeče v části těla? (jedna nebo více končetin, trup, hemiplegie, ale NE celé tělo)",
              "startBodyPartConvulsionsDescription": "Křeč je rychlý, rytmický pohyb svalů, které se stahují a rytmicky uvolňují. Nejde o chvějící se, lámavé nebo pomalé pohyby; jsou obvykle nesmyslné bez směru pohybu. Částečné (fokální) křeče se mohou „toulat“, to znamená pohybovat se (např. Z dolní končetiny do horní končetiny)",
              "startFaceConvulsions": "Začaly / začaly záchvaty / křeče v obličeji?",
              "startFaceConvulsionsDescription": "Záchvaty jsou rychlé, rytmické pohyby svalů, které se stahují a rytmicky uvolňují. Nejde o chvějící se, lámavé nebo pomalé pohyby; jsou obvykle nesmyslné bez směru pohybu. Částečné (fokální) křeče mohou „bloudit“, tedy posouvat se, podobně - obličejové křeče mohou postihnout celý obličej nebo jeho část či stranu.",
              "startBodyStiffness": "Začíná / začíná záchvat (y) generalizovaným ztuhnutím těla?",
              "startBodyStiffnessDescription": "Tato ztuhlost se nazývá fázový / tonický záchvat, který je vyjádřen tetanickou kontrakcí a extrémním napětím ve všech svalech v těle.",
              "startLimbEjection": "Začal (y) záchvat (y) / katapultováním 4končetiny nebo náhlou flexí?",
              "startLimbEjectionDescription": "K tomu dochází na začátku některých záchvatů, většinou u dětí, a může to být symptom takzvaných flexních záchvatů.",
              "startEyeMovementDisorders": "Jsou poruchy pohybu očí (jako ty, které jsou uvedeny výše) počátečním příznakem záchvatu (záchvatů)?",
              "startEyeMovementDisordersDescription": "Nystagmus je rychlý pohyb knoflíků, obvykle v opačných směrech. Dalšími pohyby jsou rychlé otáčení knoflíků nebo jejich otáčení.",
              "startEyeTurnPernament": "Mohou se oční bulvy na začátku záchvatu (záchvatů) obrátit na jednu stranu?",
              "startEyeTurnPernamentDescription": "Toto otočení může být doleva, doprava, nahoru a dolů. Může to být rychlé a pomalé, obvykle chvíli trvá, než se knoflíky vrátí do mezipolohy.",
              "startBodyTurn": "Může se nástup záchvatu / záchvatů projevit jako pomalý nebo rychlý návrat těla na jednu (pravou nebo levou) stranu?",
              "startBodyTurnDescription": "Tato fráze je často spojena s kroucením oční bulvy, na které jsme se ptali v předchozí otázce.",
              "startGroundFall": "Spadne na zem na začátku záchvatu / záchvatů?",
              "startGroundFallDescription": "Pád je definován jako víceméně náhlé převrácení pacienta, které může být: „tuhé“, „ochablé“, rychlé a pomalé, klouzání po předmětech, částečně kontrolované nebo nekontrolované.",
              "startBitTongue": "Kousnete si jazyk nebo jakoukoli část úst na začátku záchvatu (záchvatů)?",
              "startBitTongueDescription": "Obvykle - má -li k takovému kousnutí dojít - obvykle se objeví na začátku záchvatu.",
              "startShout": "Křičí pacient na začátku záchvatu (záchvatů)?",
              "startShoutDescription": "Jde o násilný a obvykle hlasitý výkřik víceméně artikulovaný; může to být zvuk, mohou to být jednotlivá slova",
              "startSounds": "Dělá pacient na začátku záchvatu (záchvatů) nějaké zvuky nebo vokalizace?",
              "startSoundsDescription": "Tentokrát jde o tiché, delší zvuky, výroky a zvuky, které jsou složené nebo zcela bezvýznamné, každý.",
              "startSpeechStop": "Zastaví se řeč na začátku záchvatu?",
              "startSpeechStopDescription": "Je to situace, kdy pacient nemůže mluvit nebo nereaguje na otázky. Zjistit příčinu je často nemožné.",
              "startMotorAutomatisms": "Provádí pacient na začátku záchvatu (záchvatů) jednoduché nebo složité motorické automatizace?",
              "startMotorAutomatismsDescription": "Pacient může nevědomky provádět nějaké opakující se pohyby nebo gesta, tomu se říká automatismy.",
              "startImmobility": "Existuje nějaká nehybnost nebo úplná nehybnost na začátku záchvatu (záchvatů)?",
              "startImmobilityDescription": "Pacient může „zemřít“ v pohybu, což je součást nástupu záchvatu.",
              "startLaugh": "Může se nemocný člověk smát na začátku záchvatu / záchvatů?",
              "startLaughDescription": "Toto je vzácný příznak, smích může být prudký, náhlý nebo pomalý.",
              "startChuck": "Dochází na začátku záchvatu / záchvatů k cinkání, plácnutí nebo slinění?",
              "startChuckDescription": "Říká se tomu vegetativní příznaky, které se obvykle nerealizují.",
              "startCry": "Je na začátku záchvatu (záchvatů) pláč?",
              "startCryDescription": "Tento výkřik lze nazvat emocionálním symptomem při fokálních záchvatech.",
              "startEpigastricSymptoms": "Rozvíjíte epigastrické příznaky na začátku záchvatu / záchvatů?",
              "startEpigastricSymptomsDescription": "Příznaky jako jsou tyto: pocit „bloudění žaludku“, „přetékání střeva“ nebo pocit naplnění žaludku obsahem.",
              "startDejaVu": "Zažíváte na začátku záchvatu / záchvatů časové bludy (deja vu a deja vecu)?",
              "startDejaVuDescription": "To jsou příznaky takzvaných deja vu a deja vecu, tj. Pocit, že „se něco stalo“, „řekl jsem to dříve“ nebo „tato situace již nastala“; je možné stát se nereálným a cítit se dezorientovaný v čase.",
              "startVisualHallucinations": "Zažíváte na začátku záchvatu (záchvatů) zrakové halucinace?",
              "startVisualHallucinationsDescription": "Takové halucinace (halucinace) mohou být jednoduché a složitější: člověk může vidět něco, co tam není, předměty se mohou zmenšovat a zvětšovat, může se objevit cikcak různých tvarů a barev atd.",
              "startAuditoryHallucinations": "Zažíváte sluchové halucinace na začátku záchvatu / záchvatů?",
              "startAuditoryHallucinationsDescription": "Tyto halucinace (halucinace) mohou být jednoduché a složitější: můžete slyšet něco, co tam není, mohou to být zvuky, slova, píšťaly atd.",
              "startFeelingFearOrAnxiety": "Začíná záchvat pocity strachu nebo úzkosti?",
              "startFeelingFearOrAnxietyDescription": "Tento pocit může vzniknout bez zjevného důvodu, obvykle náhle.",
              "startEyelidsMyoclonus": "Je myoklonus víčka na začátku záchvatu (záchvatů)?",
              "startEyelidsMyoclonusDescription": "Myoklonus očních víček je rychlé a rychlé trhání očních víček, která obvykle „vyskočí“ na několik sekund, což může, ale nemusí souviset s abnormálními pohyby očí.",
              "startMyoclonus": "Existuje myoklonus na začátku záchvatu / záchvatů?",
              "startMyoclonusDescription": "Myoclonus je náhlé a násilné trhnutí (prudší a obvykle větší než křeče) obecného svalu nebo jen určitých částí těla nebo svalových skupin.",
              "startLossConsciousnessPartially": "Částečná ztráta vědomí",
              "startLossConsciousnessPartiallyDescription": "Byla částečná ztráta vědomí prvním příznakem záchvatu? Narušil jakoukoli oblast vědomí",
              "startRhythmicConvulsions": "Rytmické křeče",
              "startRhythmicConvulsionsDescription": "Byly tyto křeče jako první příznak záchvatu rytmické?",
              "startHyperkineticSymptoms": "Hyperkinetické příznaky",
              "startHyperkineticSymptomsDescription": "Byl prvním příznakem záchvatu nástup hlavních hyperkinetických příznaků: šlapání, vícesměrné tahy pánve a podobně?",
              "startAtony": "Atonie nebo astatický záchvat celého těla nebo jeho části",
              "startAtonyDescription": "Byla prvním příznakem záchvatu svalová atonie nebo astatický záchvat zahrnující celé tělo nebo jeho část (např. pouze hlavu)?",
              "startOtherAutomatisms": "Jiné automatismy",
              "startOtherAutomatismsDescription": "Byly prvním příznakem záchvatu jiné automatismy: celé tělo, končetiny, vokální perseverace, sexuální automatismy, jiné komplexní automatismy?",
              "startHeartSymptoms": "Srdeční autonomní příznaky: palpitace/tachy/brady/asystolie",
              "startHeartSymptomsDescription": "Byly prvním příznakem záchvatu kardiovaskulární autonomní příznaky: palpitace, tachykardie nebo bradykardie nebo asystolie?",
              "startAutonomicSymptoms": "Další autonomní příznaky: bledost, návaly horka, respirační tíseň, abnormální pohyb zornic, nutkání na moč/fekál",
              "startAutonomicSymptomsDescription": "Byl prvním příznakem záchvatu další autonomní příznaky: obličej, návaly horka, dýchací potíže, poruchy pohybu zornic, nutkání na močení/výkaly?",
              "startLanguageIssues": "Dysfázie/afázie, dyslexie/Alexie a/nebo nedostatečné porozumění řeči/jazyku",
              "startLanguageIssuesDescription": "Byly první příznaky záchvatových poruch řeči jako dysfázie/afázie, dyslexie/alexie a/nebo nedostatečné porozumění řeči/jazyku?",
              "startMemoryDisorders": "Poruchy paměti během záchvatu (jiné smysly v normě)",
              "startMemoryDisordersDescription": "Byly prvním příznakem záchvatové poruchy paměti během záchvatu při plném zachování ostatních smyslů?",
              "startSomatosensorySymptoms": "Fokální somatosenzorické příznaky, nejčastěji parestézie s brněním a/nebo necitlivostí, píchání, lechtání, plazení nebo pocity elektrického šoku.",
              "startSomatosensorySymptomsDescription": "Byly prvními příznaky záchvatu fokální somatosenzorické příznaky, nejčastěji parestézie s brněním a/nebo necitlivostí, píchání, lechtání, plazení nebo pocit elektrického šoku?",
              "startBodyDistortion": "Zkreslený obraz těla s pocitem pohybu (např. plovoucí) nebo změněné držení těla (např. kroucení) ve stacionární končetině",
              "startBodyDistortionDescription": "Byly prvními příznaky záchvatu zkreslený obraz těla s pocitem pohybu (např. plovoucí) nebo změněné držení těla (např. kroucení) ve stacionární končetině?"
            },
            "next": {
              "nextFaint": "Došlo nebo je během záchvatu ztráta vědomí (pokud nějaké bylo na začátku)?",
              "nextFaintDescription": "Bezvědomí se může připojit nebo pokračovat",
              "nextLossConsciousness": "Došlo nebo je během záchvatu ke ztrátě vědomí (pokud k němu došlo nejprve)?",
              "nextLossConsciousnessDescription": "Ztráta vědomí se může připojit nebo pokračovat",
              "nextBodyConvulsions": "Staly se záchvaty nyní nebo byly od okamžiku, kdy začaly na začátku?",
              "nextBodyConvulsionsDescription": "Záchvaty se mohly objevit brzy a stále přetrvávat, nebo se objevily až nyní.",
              "nextBodyPartConvulsions": "Začaly ohniskové záchvaty nyní nebo pokračují tak, jak začaly?",
              "nextBodyPartConvulsionsDescription": "Fokální záchvaty mohly nastat brzy a stále přetrvávají, nebo se objevily až nyní.",
              "nextFaceConvulsions": "Došlo k záškubu obličejových svalů nyní nebo pokračuje, jak začalo?",
              "nextFaceConvulsionsDescription": "Křeče v obličeji se mohly objevit brzy a stále přetrvávají, nebo se dosud neobjevily.",
              "nextBodyStiffness": "Pokračuje ztuhnutí celého těla nebo nyní ztuhlo?",
              "nextBodyStiffnessDescription": "Stejně jako křeče se tonická ztuhlost mohla spojit nebo pokračovat, pokud ano.",
              "nextEyeMovementDisorders": "Zda se patologické pohyby očí spojily nebo stále pokračují",
              "nextEyeMovementDisordersDescription": "Stejně jako ostatní výše uvedené příznaky se mohou objevit nebo trvat.",
              "nextEyeTurnPernament": "Došlo / je vrácení knoflíků na jednu ze 4 stran?",
              "nextEyeTurnPernamentDescription": "Dosud příznaky: mohou se připojit zde nebo trvají, jak již byly.",
              "nextBodyTurn": "Došlo / probíhá jednosměrné otočení těla?",
              "nextBodyTurnDescription": "Stejně jako ostatní výše uvedené příznaky: Objevil se tento příznak nebo probíhá?",
              "nextGroundFall": "Došlo k pádu, a pokud to bylo na začátku, nemocní stále lžou?",
              "nextGroundFallDescription": "Pád - pokud byl na začátku - mohl skončit tím, že pacient vstal nebo stále ležel.",
              "nextBitTongue": "Kousli jste se během záchvatu do jazyka nebo do částí úst?",
              "nextBitTongueDescription": "Pokud se tak nestalo na začátku záchvatu! Jak to bylo na začátku, ne teď!",
              "nextUrinating": "Močili jste během záchvatu moč?",
              "nextUrinatingDescription": "Pacient může močit nevědomky během záchvatu nebo po něm.",
              "nextDefecation": "Měli jste stolici nebo pocit naléhavosti?",
              "nextDefecationDescription": "Stejně jako moč - pacient může nevědomky vylučovat fekálie nebo se cítit naléhavě nutný k vylučování fekálií.",
              "nextShout": "Ozve se během záchvatu křik?",
              "nextShoutDescription": "Pokud to nebyl symptom na začátku záchvatu, může se objevit nyní.",
              "nextSounds": "Vyskytly se nebo existují (pokud existují) vokalizace nebo verbální zvuky během záchvatu?",
              "nextSoundsDescription": "Pacient může i nadále vydávat různé zvuky, nebo se mohou objevit hned.",
              "nextAggression": "Vyvíjí se během záchvatu agresivita?",
              "nextAggressionDescription": "Objevuje se nyní nějaké agresivní chování nebo gesta pacienta?",
              "nextMotorAutomatisms": "Existují (pokud existují) nebo došlo během záchvatu k automatizaci?",
              "nextMotorAutomatismsDescription": "Někdy může tento příznak trvat dlouho.",
              "nextImmobility": "Nastalo ticho nebo ticho nyní nebo pokračuje od začátku?",
              "nextImmobilityDescription": "Stejně jako ostatní příznaky",
              "nextLaugh": "Je během záchvatu smích?",
              "nextLaughDescription": "Pokud tam nebyl na začátku záchvatu - může se objevit nyní.",
              "nextChuck": "Dochází během záchvatu / záchvatů k cinkotu, plácnutí nebo slinám?",
              "nextChuckDescription": "Pokud dříve nebo pokračují, pokud ne, dorazily nyní?",
              "nextCry": "Přidal se nebo pláče?",
              "nextCryDescription": "Stejně jako ostatní příznaky, může to být úplně znovu nebo se může objevit nyní.",
              "nextEpigastricSymptoms": "Vyskytují se během záchvatu / záchvatů epigastrické příznaky?",
              "nextEpigastricSymptomsDescription": "Pokud ano, vydrží, pokud ne - dorazili?",
              "nextDejaVu": "Vyskytují se během záchvatu / záchvatů časové bludy (deja vu a deja vecu)?",
              "nextDejaVuDescription": "Jak se další příznaky mohly objevit od nuly nebo se nyní připojily.",
              "nextVisualHallucinations": "Máte během záchvatu (záchvatů) vizuální halucinace?",
              "nextVisualHallucinationsDescription": "Probíhají nebo se přidali?",
              "nextAuditoryHallucinations": "Máte během záchvatu (záchvatů) sluchové halucinace?",
              "nextAuditoryHallucinationsDescription": "Probíhají nebo se přidali?",
              "nextEyelidsMyoclonus": "Vyvíjí se myoklonus víčka během záchvatu (záchvatů)?",
              "nextEyelidsMyoclonusDescription": "Probíhají nebo se přidali?",
              "nextLossConsciousnessPartially": "Částečná ztráta vědomí",
              "nextLossConsciousnessPartiallyDescription": "Byla částečná ztráta vědomí dalším příznakem záchvatu? Narušil jakoukoli oblast vědomí",
              "nextRhythmicConvulsions": "Rytmické křeče",
              "nextRhythmicConvulsionsDescription": "Byly tyto křeče jako další příznak záchvatu rytmické?",
              "nextHyperkineticSymptoms": "Hyperkinetické příznaky",
              "nextHyperkineticSymptomsDescription": "Byl dalším příznakem záchvatu nástup hlavních hyperkinetických příznaků: šlapání, vícesměrné tahy pánve a podobně?",
              "nextOtherAutomatisms": "Jiné automatismy",
              "nextOtherAutomatismsDescription": "Byly dalším příznakem záchvatu jiné automatismy: celé tělo, končetiny, vokální perseverace, sexuální automatismy, jiné komplexní automatismy?",
              "nextHeartSymptoms": "Srdeční autonomní příznaky: palpitace/tachy/brady/asystolie",
              "nextHeartSymptomsDescription": "Byly dalším příznakem záchvatu kardiovaskulární autonomní příznaky: palpitace, tachykardie nebo bradykardie nebo asystolie?",
              "nextAutonomicSymptoms": "Další autonomní příznaky: bledost, návaly horka, respirační tíseň, abnormální pohyb zornic, nutkání na moč/fekál",
              "nextAutonomicSymptomsDescription": "Byl dalším příznakem záchvatu další autonomní symptomy: obličej, návaly horka, respirační tíseň, porucha pohybu zornic, nutkání na močení/výkaly?",
              "nextLanguageIssues": "Dysfázie/afázie, dyslexie/Alexie a/nebo nedostatečné porozumění řeči/jazyku",
              "nextLanguageIssuesDescription": "Byly další příznaky záchvatových poruch řeči, jako je dysfázie/afázie, dyslexie/alexie a/nebo nedostatečné porozumění řeči/jazyku?",
              "nextMemoryDisorders": "Poruchy paměti během záchvatu (jiné smysly v normě)",
              "nextMemoryDisordersDescription": "Byly dalším příznakem záchvatu poruchy paměti během záchvatu s plným zachováním ostatních smyslů?",
              "nextSomatosensorySymptoms": "Fokální somatosenzorické příznaky, nejčastěji parestézie s brněním a/nebo necitlivostí, píchání, lechtání, plazení nebo pocity elektrického šoku.",
              "nextSomatosensorySymptomsDescription": "Byly další příznaky záchvatu fokální somatosenzorické příznaky, nejčastěji parestézie s brněním a/nebo necitlivostí, píchání, lechtání, plazení nebo pocit elektrického šoku?",
              "nextBodyDistortion": "Zkreslený obraz těla s pocitem pohybu (např. plovoucí) nebo změněné držení těla (např. kroucení) ve stacionární končetině",
              "nextBodyDistortionDescription": "Byly další příznaky záchvatu zkreslený obraz těla s pocitem pohybu (např. plovoucí) nebo změněné držení těla (např. kroucení) ve stacionární končetině?"
            },
            "end": {
              "endAnotherSeizure": "Může se tento záchvat změnit v další záchvat?",
              "endAnotherSeizureDescription": "Záchvat lze izolovat pouze podle popisu nebo změnit s koncem na jiný.",
              "endBodyConvulsions": "Končí záchvat generalizovanými záchvaty nebo stále probíhají?",
              "endBodyConvulsionsDescription": "Pokud byli před (na začátku a během nebo se připojili během) nebo na konci záchvatu.",
              "endBodyPartConvulsions": "Probíhají fokální záchvaty nebo se objevují na konci?",
              "endBodyPartConvulsionsDescription": "Záchvaty se mohly objevit brzy a stále přetrvávat, nebo se objevily až nyní.",
              "endFaceConvulsions": "Jsou křeče v obličejových svalech trvalé nebo se objevují na konci?",
              "endFaceConvulsionsDescription": "Jako generalizované a fokální záchvaty.",
              "endEyeMovementDisorders": "Končí záchvat abnormálními pohyby číselníku?",
              "endEyeMovementDisordersDescription": "Nebo pokračují jako dřív?",
              "endEyeTurnPernament": "Končí záchvat tím, že otočíte knoflíky na jednu ze 4 stran?",
              "endEyeTurnPernamentDescription": "Nebo to pokračuje tak, jak to bylo?",
              "endBodyTurn": "Končí záchvat otočením těla na jednu stranu?",
              "endBodyTurnDescription": "Nebo to pokračuje tak, jak to bylo?",
              "endGroundFall": "Spadne na konci záchvatu?",
              "endGroundFallDescription": "Pokud předtím nedošlo k žádnému pádu, může k tomu dojít nyní? Nebo nemocný stále leží, jako by spadl na začátku nebo uprostřed?",
              "endUrinating": "Prošel pacient na konci záchvatu nějakou močí?",
              "endUrinatingDescription": "Vyskytuje se na konci záchvatu inkontinence svěrače?",
              "endDefecation": "Ukončil pacient na konci záchvatu nevědomky výkaly nebo měl stolici?",
              "endDefecationDescription": "Vyskytuje se na konci záchvatu inkontinence svěrače?",
              "endSounds": "Došlo k nějaké verbalizaci na konci záchvatu?",
              "endSoundsDescription": "Objevují se vokalizace nebo přetrvávají (pokud existují) na konci?",
              "endAggression": "Byla na konci záchvatu agresivita?",
              "endAggressionDescription": "Mohla by se na konci záchvatu objevit agresivita, nebo probíhá tak, jak byla?",
              "endMotorAutomatisms": "Došlo k chování / automatickým pohybům na konci záchvatu?",
              "endMotorAutomatismsDescription": "Probíhají takové automatismy nebo se objevily nyní?",
              "endImmobility": "Zastavil se záchvat?",
              "endImmobilityDescription": "Ticho jako symptom konce záchvatu.",
              "endLaugh": "Směje se pacient na konci záchvatu?",
              "endLaughDescription": "Stejně jako ostatní příznaky.",
              "endChuck": "Dochází na konci záchvatu / záchvatů k cinkotu, plácnutí nebo slinám?",
              "endChuckDescription": "Stejně jako ostatní příznaky.",
              "endCry": "Pláče pacient na konci záchvatu?",
              "endCryDescription": "Pláč jako konečný příznak.",
              "endVomit": "Zvrací pacient na konci záchvatu?",
              "endVomitDescription": "Zvracení může být obsahem žaludku nebo může být nutkání na zvracení."
            },
            "after": {
              "afterEyeMovementDisorders": "Je patrný patologický pohyb očí po záchvatu?",
              "afterEyeMovementDisordersDescription": "Mohou nastat případy, kdy tento příznak může pokračovat, přestože záchvat skončil.",
              "afterEyeTurnPernament": "Dochází po záchvatu k návratu očních bulv?",
              "afterEyeTurnPernamentDescription": "Mohou nastat případy, kdy tento symptom může pokračovat, přestože záchvat skončil.",
              "afterParesisOrParalysis": "Posturální paréza/obrna",
              "afterparesisOrParalysisDescription": "Označuje se jako Todds, který se vyskytuje po záchvatech, obvykle postihuje 1 nebo více končetin, někdy je polovičatý a zmizí do 24 hodin.",
              "afterUrinating": "Může pacient po záchvatu projít / močit?",
              "afterUrinatingDescription": "Po záchvatu může dojít k močení.",
              "afterDefecation": "Může pacient po záchvatu projít / vyprázdnit se?",
              "afterDefecationDescription": "Po záchvatu se může velmi zřídka objevit faecation.",
              "afterConfusion": "Vyskytují se OOPP po záchvatu?",
              "afterConfusionDescription": "Tento termín se používá k popisu poruchy - obvykle různě rychle odeznívajícího - vědomí nebo jeho úplné absence. Někteří pacienti jsou však po záchvatu okamžitě informováni a plně v kontaktu.",
              "afterSleep": "Následuje záchvat a PPD?",
              "afterSleepDescription": "Po závažnějších záchvatech následuje tento druh spánku, který obvykle trvá 1/2 hodiny.",
              "afterSalivaOrBloodySalivaFoam": "Existuje pěna v ústech po záchvatu: ze slin nebo krvavě zbarvená krví?",
              "afterSalivaOrBloodySalivaFoamDescription": "To je příznak „slisovaných“ slin (pěnění v důsledku hyperventilace).",
              "afterHyperventilation": "Dochází k hyperventilaci po záchvatu?",
              "afterHyperventilationDescription": "Hyperventilace je obvykle důsledkem kyslíkového dluhu způsobeného zadržováním dechu během tonické fáze.",
              "afterAggression": "Existuje po záchvatu agrese?",
              "afterAggressionDescription": "Agresivita může být příznakem OOP.",
              "afterMotorAutomatisms": "Mohou po záchvatu dojít k automatismu?",
              "afterMotorAutomatismsDescription": "Podobně jako agresivita by to mohl být symptom OOP.",
              "afterVomit": "Zvrací pacient po záchvatu?",
              "afterVomitDescription": "Po záchvatu může dojít ke zvracení nebo ke zvracení.",
              "afterHighTemperature": "Zvyšuje se po záchvatu tělesná teplota?",
              "afterHighTemperatureDescription": "Může se to stát, může to být symptomatická hypotermie.",
              "afterParesisOrParalysisDescription": "Byla po záchvatu přítomna postiktální paréza nebo paralýza Toddova typu?"
            },
            "feature": {
              "featureMany": "Mohou se takové záchvaty vyskytovat sériově / ve skupinách?",
              "featureManyDescription": "Pro situace s více záchvaty! Záchvaty bývají sériové jeden po druhém nebo během krátkého časového období, někdy noční nebo denní série.",
              "featureDayOccurrence": "Vyskytl se záchvat / záchvaty během dne?",
              "featureDayOccurrenceDescription": "Bdělé záchvaty jsou záchvaty během dne, předpokládáme, že pacient v noci spí.",
              "featureNightOccurrence": "Došlo k záchvatu / záchvaty se objevily v noci?",
              "featureNightOccurrenceDescription": "Předpokládáme, že noční záchvaty se vyskytují během spánku.",
              "featureAwakeningOccurrence": "Vyskytl se záchvat / záchvaty těsně před probuzením, během probuzení nebo těsně po probuzení?",
              "featureAwakeningOccurrenceDescription": "Některé typy se vyskytují pouze v případě.",
              "featureMorningOccurrence": "Stal se záchvat / záchvaty / se dějí ráno?",
              "featureMorningOccurrenceDescription": "Jsou chvíle, kdy je prvních pár hodin ráno jedinou záchvatovou sezónou.",
              "featureDuration": "Jak dlouho záchvat trval (jak dlouho obvykle záchvaty trvaly)?",
              "featureDurationDescription": "Vyberte čas podle intervalu",
              "beforeSpecificStimuli": "Záchvat vyvolaný specifickým podnětem",
              "beforeSpecificStimuliDescription": "Může být záchvat vyvolán konkrétním podnětem nebo stavem (FS, zvuky, nedostatek spánku, emoce, stres atd.)?",
              "formChanges": "Variabilní a polymorfní záchvat",
              "formChangesDescription": "Mění se tento záchvat ve formě a délce trvání? Nezaměňovat s jinými typy záchvatů, pokud jsou současné!"
            },
            "fixCriticals": {
              "title": "Zlepšení vektoru pro záchvat",
              "subtitle": "Potvrzený záchvat",
              "description1": "Reakce pacienta, které se výrazně liší od vybraného záchvatového referenčního vektoru, jsou zobrazeny níže.",
              "description2": "Zeptejte se pacienta pečlivě na odpověď, pokud nesouhlasí s odpovědí z průzkumu 0 opravte ji.",
              "oldAnswer": "Anketa 0 odpověď",
              "newAnswer": "Nová odpověď",
              "saveConfirm": "Opravdu chcete přepsat tuto část průzkumu 0?",
              "saveSuccess": "Změny byly úspěšně uloženy",
              "vectorChangedBy": "Vektor změnil"
            },
            "premonition": {
              "trailer": "Předtucha záchvatu",
              "trailerDescription": "Předchází záchvatu předtucha – pocit blížícího se záchvatu v minutách/hodinách před záchvatem? Pocit/zvěstování vědomé nebo nevědomé."
            }
          },
          "Comorbidities": {
            "introTitle": "Souběžnosti",
            "introSubtitle1": "Zde chceme zjistit, zda máte komorbidity.",
            "introSubtitle2": "Pamatujte, že nemoci mohou ovlivnit průběh epilepsie.",
            "dataSavedMsg": "Informace o komorbiditách byly uloženy",
            "hasHeadaches": "Bolesti hlavy",
            "answerNo": "Žádný",
            "answerYes": "Ano jsou",
            "answerYesInt": "Ano",
            "disease": "Choroba",
            "typeDisease": "Zadejte kód nebo jméno nemoci",
            "hasPregnancies": "Těhotenství",
            "pregnancies": "Počet těhotenství",
            "hasChildbirths": "Porody",
            "childbirths": "Počet porodů",
            "hasNeurologicalDiseases": "Neurologická onemocnění",
            "hasInfectiousDiseases": "Zánětlivá a infekční onemocnění",
            "hasCancerDiseases": "Onkologická onemocnění",
            "hasImmuneDiseases": "Krevní a imunitní onemocnění",
            "hasEndocrineDiseases": "Endokrinní a metabolická onemocnění",
            "hasEyeDiseases": "Oční choroby",
            "hasLaryngologicalDiseases": "Laryngologické choroby",
            "hasHeartDiseases": "Nemoci srdce a oběhového systému",
            "hasRespiratoryDiseases": "Respiračních onemocnění",
            "hasDigestiveDiseases": "Nemoci trávicího systému",
            "hasSkinDiseases": "Kožní choroby",
            "hasBoneDiseases": "Kostní choroby",
            "hasUrogenitalDiseases": "Genitourinární choroby",
            "hasDevelopmentalDefects": "Vývojové, vrozené vady a chromozomální aberace",
            "hasInjuriesOrPoisoning": "Zranění a otravy",
            "somaticDiseases": "Pacient má onemocnění, která ovlivňují léčbu epilepsie",
            "hasPsychiatricDiseases": "Psychiatrická onemocnění",
            "headaches": "Pacient má bolesti hlavy"
          },
          "DrugsHistory": {
            "introTitle": "Léčba",
            "introSubtitle1": "Toto je historie vaší léčby dodnes.",
            "introSubtitle2": "Zadejte léky, které jste v minulosti užívali, a jaký byl účinek. Každý léčivý přípravek má název účinné látky v plné a zkrácené formě. Pokud si to nepamatujete, nedělejte si starosti.",
            "introSubtitle3": "Je důležité, abyste vybrali správný název léku.",
            "drug": "Lék",
            "drugEffects": "Účinky užívání drogy",
            "addDrug": "Přidejte lék",
            "removeDrug": "Odstraňte lék",
            "typeDrug": "Zadejte název léku",
            "dataSavedMsg": "Historie léčby byla uložena"
          }
        },
        "visitSurvey": {
          "stages": {
            "patientState": "Stav pacienta",
            "patientOpinion": "Názor pacienta",
            "doctorOpinion": "Názor lékaře"
          },
          "patientState": {
            "lastSeizureDate": "Datum posledního záchvatu před touto návštěvou",
            "wellBeing": "Celková pohoda od poslední návštěvy",
            "allSeizureRegistered": "Od mé poslední návštěvy jsem zadal/nezadal své záchvaty do Kalendáře událostí",
            "additionalIncidents": "Od poslední návštěvy se udály následující události - kromě událostí uvedených v Kalendáři akcí"
          },
          "patientOpinion": {
            "seizureCountByPatient": "Subjektivně, byl počet záchvatů stejný?",
            "seizureEvaluationByPatient": "Subjektivně, jak špatné byly záchvaty",
            "currentTreatment": "Moje současná léčba je",
            "sideEffects": "Nežádoucí účinky současné léčby"
          },
          "doctorOpinion": {
            "seizureCountByDoctor": "Počet záchvatů dle lékaře",
            "seizureEvaluationByDoctor": "Kvalita záchvatů podle názoru lékaře",
            "treatmentEfficiency": "Účinnost léčby podle názoru lékaře",
            "treatmentSafety": "Bezpečnost léčby podle názoru lékaře"
          },
          "date": "datum",
          "fromDate": "Z",
          "toDate": "Na",
          "seizure": "Záchvat",
          "save": "Uložit",
          "addSeizure": "Přidat záchvat",
          "noEpisodeAdded": "Nebyl registrován žádný záchvat",
          "episodeTypeLbl": "Typy záchvatů",
          "incidentDescription": "Popis záchvatu",
          "noSeizure": "Žádný záchvat",
          "next": "další",
          "howManySeizures": "Kolik záchvatů od poslední návštěvy",
          "countableLbl": "Byly tam",
          "unCountableLbl": "Bylo jich tolik, že není možné uvést přesný počet",
          "seizuresMandatoryError": "Přidejte data zabavení",
          "add": "Přidat",
          "close": "Zavřít",
          "seizuresNotAddedSummary": "Záchvaty nebyly přidány od poslední návštěvy",
          "seizuresDates": "Termíny záchvatů",
          "seizuresSinceLastVisit": "Počet záchvatů od poslední návštěvy",
          "per": "za",
          "addNewSeizureType": "Přidat nový typ záchvatu",
          "addSeizureTypeDialog": {
            "title": "Přidání nového typu záchvatu",
            "name": "Název záchvatu",
            "success": "Byl přidán nový typ záchvatu"
          },
          "seizurePeriod": "Zadejte období",
          "addPeriodicSeizure": "Přidat období",
          "deletePeriodicSeizure": "Odebrat období",
          "until": "Až do",
          "seizuresInPeriod": "Epizody se počítají"
        },
        "visitCertificates": {
          "create": "Vytvořte certifikát",
          "doNotCreate": "Nevytvářejte certifikát",
          "certificateType": "Typ certifikátu",
          "additionalRemarks": "Další poznámky",
          "additionalRemarksPlaceholder": "Zadejte další poznámky",
          "generate": "Vytvořit",
          "certificateContent": "Obsah certifikátu:",
          "pickDate": "Vyber datum",
          "threeMonths": "3 měsíce",
          "sixMonths": "6 měsíců",
          "oneYear": "1 rok",
          "date": "datum",
          "enterProcedureName": "Zadejte název procedury",
          "enterActivityName": "Zadejte název aktivity",
          "validationError": "Povinné pole",
          "addNext": "Přidat další certifikát",
          "otherDescriptionLbl": "Zadejte popis certifikátu",
          "endOfYear": "Konec roku",
          "addCertificate": "Přidat certifikát",
          "noCertificates": "Seznam certifikátů je prázdný"
        },
        "sickLeaves": {
          "title": "Nemocné listy",
          "number": "Číslo",
          "dateFrom": "Z",
          "dateTo": "Na",
          "type": "Typ",
          "status": "Postavení",
          "add": "Přidejte nemocenskou",
          "addPopup": {
            "title": "Vystavení nové nemocenské",
            "addBtn": "Vystavit nemocenskou",
            "codes": {
              "a": "pracovní neschopnost nastala po přestávce nepřesahující 60 dnů - způsobená stejnou nemocí, která způsobila pracovní neschopnost před přestávkou",
              "b": "pracovní neschopnost spadající v těhotenství",
              "c": "pracovní neschopnost v důsledku požívání alkoholu",
              "d": "pracovní neschopnost v důsledku tuberkulózy",
              "e": "pracovní neschopnost způsobená nemocí, která vznikla nejpozději do 3 měsíců ode dne zániku nemocenského pojištění - jde-li o infekční onemocnění, jehož inkubační doba přesahuje 14 dnů, nebo jiné onemocnění, jehož příznaky se projeví po době delší než 14 dnů ode dne vzniku onemocnění"
            },
            "insuredPersonTitle": "Údaje pojištěnce, pro kterého je ZUS ZLA vystaven",
            "type": "Důvod neschopnosti",
            "pesel": "Pesel",
            "fetchData": "Získejte data",
            "insuredIn": "Místo pojištění",
            "change": "Změna",
            "payersTitle": "Výběr plátců, pro které budou certifikáty vytvořeny",
            "sickLeavesTitle": "Seznam předchozích ZUS ZLA pojištěnce",
            "detailsTitle": "Informace o pracovní neschopnosti",
            "inabilityFrom": "Neschopnost od",
            "inabilityTo": "Neschopnost",
            "daysNumber": "Počet dní",
            "reason": "Důvod",
            "careForData": "Údaje o osobě, o kterou se pečuje",
            "relationship": "Příbuzenství",
            "relationship1": "dítě",
            "relationship2": "manžel, rodiče, nevlastní otec, rodič dítěte, prarodiče, tchánové, vnoučata, nevlastní matka",
            "relationship3": "jiná osoba",
            "letterCode": "Písmenný kód",
            "indication": "Lékařská indikace",
            "addressesTitle": "Adresa pobytu pojištěnce v době pracovní neschopnosti",
            "payerName": "název",
            "payerNip": "NIP",
            "hasPue": "Má on/on profil PUE?",
            "address": "Adresa",
            "inabilityRange": "Období neschopnosti",
            "disease": "Choroba",
            "number": "Série a číslo",
            "status": "Postavení",
            "successToast": "Byla vydána nemocenská",
            "addPayerTitle": "Přidávání nového plátce",
            "search": "Vyhledávání",
            "payerNotFound": "Plátce nenalezen",
            "addNewPayer": "Přidat nového plátce",
            "confirmIssueSickLeave": "Opravdu chcete vystavit nemocenskou?",
            "addNewAddress": "přidat novou adresu",
            "workPlace": "Pracovní místo",
            "icd10Note": "Poznámka! Hlavní kód icd 10 bude předán ZUS"
          },
          "cancelPopup": {
            "title": "Zrušení nemocenské",
            "reason": "Důvod zrušení",
            "confirmBtn": "Zrušit nemocenskou",
            "confirmCancelSickLeave": "Opravdu chcete zrušit tuto nemocenskou?",
            "successToast": "Nemocenská byla zrušena"
          }
        }
      },
      "patientVisits": {
        "nextVisit": {
          "title": "Návštěvy",
          "nextTitle": "Následné návštěvy",
          "changeDate": "Změnit datum",
          "join": "Zahajte návštěvu",
          "forego": "zrušení",
          "payFor": "Zaplatit za návštěvu"
        },
        "lastRecommendation": {
          "title": "Doporučení z předchozí návštěvy",
          "pdf": "Souhrn ke stažení",
          "drug": "Lék",
          "dosages": "Dávkování",
          "careLbl": "Opatruj se",
          "from": "z",
          "to": "na"
        },
        "visitList": {
          "title": "Archiv návštěv",
          "subtitle": "minulé návštěvy",
          "pdf": "Stáhnout PDF",
          "date": "datum",
          "type": "Typ",
          "category": "Kategorie",
          "doctor": "Doktor",
          "summary": "Doporučení",
          "invoice": "Faktura"
        }
      },
      "password": {
        "reset": {
          "title": "Obnova hesla",
          "subtitle1": "Jít zpět k",
          "subtitle2": "přihlašovací obrazovka",
          "form": {
            "email": "Emailová adresa",
            "send": "Potvrdit"
          },
          "response1": "Na uvedenou e-mailovou adresu byla odeslána zpráva s odkazem pro potvrzení resetování.",
          "response2": "Zavřete prosím toto okno prohlížeče a klikněte na odeslaný odkaz.",
          "resendMessage": "Odkaz na změnu hesla byl znovu odeslán"
        },
        "resetConfirm": {
          "title": "Potvrďte změnu hesla",
          "form": {
            "password": "Nové heslo",
            "passwordConfirm": "Potvrďte heslo",
            "send": "Potvrdit"
          },
          "response": "Heslo změněno.",
          "loginBtnLbl": "Přihlásit se"
        }
      },
      "patient": {
        "account": {
          "register": {
            "confirm": {
              "outcomeSuccesMsg": "Registrace byla úspěšně dokončena",
              "outcomeSuccesDetMsg": "Kliknutím na tlačítko níže přejdete na přihlašovací stránku",
              "outcomeFailureMsg": "Při pokusu o potvrzení registrace došlo k problému",
              "outcomeFailureDetMsg": "Zkuste to prosím znovu kliknutím na odkaz zaslaný v e -mailu nebo kontaktujte tým neuroterminálu",
              "saveBtn": "Přihlásit se"
            }
          },
          "settings": {
            "title": "Nastavení",
            "saveLbl": "Uložit",
            "succesMsg": "Vaše nastavení bylo uloženo"
          }
        },
        "therapy": {
          "prescriptions": {
            "newLbl": "nové předpisy",
            "archiveLbl": "archiv receptů",
            "dateFromLbl": "Vydáno od",
            "dateToLbl": "Vydáno na",
            "noDataLbl": "Žádné předpisy",
            "previewLbl": "Náhled",
            "prescriptionLbl": "Předpis",
            "headers": {
              "dateLbl": "datum",
              "doctorLbl": "Doktor",
              "numberLbl": "Předpisový kód",
              "actionsLbl": "Předpis"
            },
            "dialog": {
              "downloadLbl": "Stažení",
              "closeLbl": "Zavřít"
            }
          }
        }
      },
      "doctor": {
        "account": {
          "activation": {
            "title": "Aktivace účtu v systému",
            "alreadySent": "Dokumenty již byly odeslány",
            "alreadyFinished": "Aktivace účtu je hotová",
            "fillInFormMsg": "Vyplňte prosím níže uvedené údaje a klikněte na 'Odeslat'",
            "formAgreementScanLbl": "Sken dohody",
            "formInsuranceScanLbl": "Skenování pojištění",
            "acceptTerms1": "přijímám",
            "acceptTerms2": "podmínky použití",
            "acceptPolicy1": "přijímám",
            "acceptPolicy2": "Zásady ochrany osobních údajů",
            "sendLbl": "Poslat",
            "submitMsg1": "Děkujeme, ověřujeme vaše údaje.",
            "submitMsg2": "Potvrzení zašleme e -mailem."
          },
          "settings": {
            "title": "Nastavení",
            "saveLbl": "Uložit",
            "successMsg": "Nastavení bylo úspěšně uloženo"
          }
        }
      },
      "prescriptions": {
        "home": {
          "titleLbl": "E-recepty",
          "text1Lbl": "Žádost o recept",
          "text2Lbl": "Žádné požadavky na předpisy"
        },
        "list": {
          "currentLbl": "Proud",
          "archiveLbl": "Archivováno",
          "showLbl": "Ukázat",
          "sortNewestLbl": "Nejnovější",
          "sortDeadlingLbl": "Nejnaléhavější"
        },
        "details": {
          "medHistLbl": "Zdravotní historie",
          "pleadLbl": "žádá o recept",
          "peselLbl": "Pesel",
          "copyPeselLbl": "Kopírovat pesel",
          "checkEwusLbl": "Přihlaste se do EWUŚ",
          "addressLbl": "Adresa",
          "phoneLbl": "Telefonní číslo",
          "issueDateLbl": "Datum posledního vydání předpisu",
          "viewLbl": "Náhled na předpis",
          "drugsLbl": "Požadované léky",
          "commentLbl": "Komentář pro lékaře",
          "openLbl": "Otevřený tvůrce elektronických receptů",
          "denyInfoLbl": "Pokud se rozhodnete, že je tento požadavek neplatný, můžete vydání e-receptu odmítnout",
          "denyLbl": "Odmítnout",
          "prescriptionLbl": "Předpis",
          "confirmInfo1Lbl": "Odmítnout žádost o předpis",
          "confirmInfo2Lbl": "Opravdu chcete odmítnout vydání tohoto receptu?",
          "confirmYesLl": "Ano, popřít",
          "confirmNoLbl": "Ne",
          "rejectInfoLbl": "Žádost o předpis zamítnuta",
          "forwardInfoLbl": "Informace byla odeslána adminovi",
          "rejectBtn": "Odmítnout žádost",
          "rejectDlgTitle": "Proč chcete žádost odmítnout?",
          "rejectReasonPlaceholder": "Důvod odmítnutí",
          "forwardPrescription": "Pokud nemůžete pacientovi vystavit e-recept a chcete, aby věc vyřídila recepce",
          "forwardBtn": "Odmítněte e-recept",
          "forwardDlgTitle": "Zadejte poznámku, která bude odeslána na recepci (admin)",
          "rejectCommentDisclaimer": "Důvod odmítnutí bude zaslán pacientovi",
          "characterCount": "Počet postav:",
          "infoForAdmin": "Lékař odmítl vystavit e-recept:"
        },
        "creator": {
          "typeLbl": "Vydejte e-recept prostřednictvím",
          "returnLbl": "Vrátit se",
          "peselLbl": "Pesel",
          "copyPeselLbl": "Kopírovat pesel",
          "checkEwusLbl": "Přihlaste se do EWUŚ",
          "issueDateLbl": "Datum posledního vydání předpisu",
          "viewLbl": "Náhled na předpis",
          "drugsLbl": "Požadované léky",
          "commentLbl": "Komentář pro lékaře",
          "addProductLbl": "Přidejte další produkt",
          "govText1Lb": "Jdi na stránku",
          "govText2Lb": "a vystavit recept",
          "pinLbl": "Zadejte 4místný předpisový kolík",
          "fileLbl": "Soubor PDF s předpisem (volitelný)",
          "fileChooseLbl": "Výběrem vyberte soubor",
          "issueLbl": "Vydat e-recept",
          "prescriptionLbl": "Předpis",
          "notValidMsg": "Obsah formuláře předpisu je neplatný",
          "successMsg": "Byl vydán recept",
          "prescriptionList": "Vydaný předpis má následující kódy"
        },
        "product": {
          "drugLbl": "Lék",
          "removeLbl": "Odstranit",
          "packageLbl": "Balík",
          "refundLbl": "Vrácení peněz",
          "packNumLbl": "Počet balíků",
          "dosageLbl": "Aktuální dávkování",
          "refundCauseLbl": "Způsob platby",
          "recieptType": "Typ účtenky",
          "permissions": "Další oprávnění",
          "description": "Vlastní popis dávkování",
          "dosage": "Dávkování"
        },
        "ewus": {
          "checkingLbl": "Kontrola pesel v EWUŚ",
          "notFoundLbl": "Nemohl jsem najít pacienta v EWUŚ",
          "insuredLbl": "Pacient je pojištěn",
          "notInsuredLbl": "Pacient není pojištěn",
          "closeLbl": "Zavřít"
        },
        "doctorPass": {
          "titleLbl": "Heslo certifikátu ZUS",
          "cancelLbl": "zrušení",
          "confirmLbl": "Potvrdit"
        }
      },
      "login": {
        "footer": {
          "z1": "Organizační řád",
          "z2": "Předpisy pro poskytování elektronických služeb",
          "z3": "Zásady ochrany osobních údajů společnosti Neurosphera",
          "z4": "Zásady ochrany osobních údajů společnosti Neuroterminal"
        },
        "login": {
          "loginTitle": "Přihlaste se a domluvte si schůzku",
          "registerLinkMsg": "Registrovat",
          "loginBtnLbl": "Přihlaste se do Neurosphera",
          "resetPassMsg": "Zapomenuté heslo?",
          "passwordFieldLabel": "Heslo",
          "loginEmailLabel": "E-mailem",
          "registerMsg": "Ještě nemáte účet?"
        },
        "reset": {
          "title": "Zapomenuté heslo?",
          "subTitle": "Na níže zadanou adresu zašleme e-mail s odkazem pro nastavení nového hesla",
          "content1": "Odkaz pro změnu hesla jsme zaslali na adresu:",
          "content2": "Zkontrolujte svou e-mailovou schránku a klikněte na odkaz pro získání šance na heslo",
          "form": {
            "email": "E-mailem",
            "send": "Potvrdit"
          },
          "changePassword": "Změnit heslo",
          "recoverPassword": "Obnovit heslo",
          "response1": "Na zadanou e-mailovou adresu byla odeslána zpráva s odkazem na potvrzení resetování.",
          "response2": "Zavřete prosím toto okno prohlížeče a klikněte na odkaz přijatý e-mailem.",
          "sendAgainPrefix": "Neobdrželi jste e-mail:",
          "sendAgain": "Poslat znovu!",
          "contactReception": "nebo kontaktujte recepci:",
          "resendBlocked": "V případě, že jste neobdrželi e-mail s odkazem na změnu hesla, kontaktujte zákaznický servis."
        },
        "resetConfirm": {
          "title": "Potvrďte změnu hesla",
          "form": {
            "password": "Nové heslo",
            "passwordConfirm": "Potvrďte heslo",
            "send": "Potvrdit"
          },
          "response": "Heslo změněno.",
          "loginBtnLbl": "Přihlásit se"
        },
        "register": {
          "title": "Vítejte v Neuroterminálu",
          "subtitle1": "Vytvořte si účet a domluvte si schůzku",
          "firstName": "Jméno",
          "lastName": "Příjmení",
          "email": "E-mailem",
          "password": "Heslo",
          "passwordConfirm": "Potvrďte heslo",
          "selectAll": "Vybrat vše",
          "isApprove1": "Přečetl jsem si pravidla pro zpracování mých osobních údajů uvedená v",
          "isApprove2": "Neurosphera sp. z o.o. Zásady ochrany osobních údajů",
          "isApprove3": "a",
          "isApprove4": "Zásady ochrany osobních údajů společnosti Neuroterminal S.A",
          "maptitle": "Jak Neuroterminal funguje?",
          "mapSubtitle": "Je to velmi jednoduché",
          "cards": [
            "Fill out the registration form to create account in Neurotermianl aplication.",
            "Receive an activation email to confirm your credentials",
            "Log into Neuroterminal and fill out the information in the patient profile.",
            "Choose the doctor and date of the first visit (visit ZERO)",
            "You will pay for it with ease using PayU (bank transfer, BLIK or credit card)",
            "For your convenience we will remind you about your visits by sending you SMS",
            "Each subsequent visit, no matter if it's remote or stationary, you will do it directly from Neuroterminal."
          ],
          "passwordRequirement": {
            "length": "8 znamení",
            "upperCase": "velká písmena",
            "lowerCase": "malá písmena",
            "digit": "číslice",
            "sign": "speciální znak ( ! @ $ \\ - _ . * )"
          },
          "resendMessage": "Aktivační odkaz byl znovu odeslán"
        },
        "confirm": {
          "registerSuccessMsg": "Registrace byla úspěšně dokončena",
          "passwordChangeSuccessMsg": "heslo bylo úspěšně změněno",
          "goToLoginMsg": "Kliknutím na tlačítko níže se přihlaste do Neuroterminálu, portálu pro pacienty s Neurosphera",
          "outcomeFailureMsg": "Vyskytl se problém s potvrzením registrace",
          "outcomeFailureDetMsg": "Zkuste to znovu kliknutím na odkaz v e-mailu nebo kontaktujte tým Neuroterminálu",
          "gotTologinBtn": "Přejděte na přihlašovací stránku",
          "passwordChangeFailedMsg": "Platnost odkazu pro nastavení hesla vypršela"
        }
      },
      "leadDoctor": {
        "noDoctor": "Pacient nemá vedoucího lékaře",
        "change": "Změna",
        "choose": "Vyberte si lékaře",
        "dialogTitle": "Vyberte pro pacienta vedoucího lékaře",
        "genericError": "Došlo k chybě",
        "assign": "Přiřadit lékaře",
        "patientsNumber": "Počet pacientů",
        "assignNoteTitle": "Vybrali jste {XXXXXX} jako vedoucího lékaře pro pacienta. Vybraný lékař obdrží následující poznámku",
        "assignNoteContent": "Doktor {XXXXXX} přidělil pacienta do vaší péče. Zkontrolujte pacientovu anamnézu.",
        "assignNoteComment": "Pokud chcete, můžete přidat další zprávu. Pokud ji přidáte, bude poznámka sdílena také s administrátorem",
        "assignNoteDialogTitle": "Poznámka pro vybraného lékaře"
      },
      "eReferrals": {
        "creator": {
          "title": "Nové elektronické doporučení",
          "type": "Typ doporučení",
          "destination": "Klinika / laboratoř / oddělení nemocnice",
          "icd10": "Diagnóza",
          "selectedIcd9": "Vybrané léčebné procedury",
          "selectIcd9": "Vyberte alespoň jeden lékařský postup",
          "icd9": "Lékařská procedura",
          "comment": "Komentář lékaře",
          "issueReferral": "Vydání elektronického doporučení",
          "icdNoResults": "Žádné výsledky vyhledávání",
          "successMsg": "Elektronické doporučení bylo úspěšně vydáno",
          "issueDate": "Datum vydání"
        }
      },
      "header": {
        "labelP1": "Pacientský",
        "labelP2": "Portál"
      },
      "epicrisis": {
        "treatmentStart": "Od té doby je pacient v péči Neurosphera",
        "ictalDiagnosis": "ICTAL diagnóza",
        "mainDiagnosis": "Diagnóza MKN10",
        "additionalDiagnosis": "Další diagnostika ICD10",
        "note0Header": "Poznámka 0",
        "note0Label": "Počáteční pozorování pacientů – dokončeno v Průzkumu 0 nebo při první návštěvě pacienta v Neurosphera",
        "epicrisisEntryHeader": "Vstupy do epikrize",
        "epicrisisEntryLabel": "Jedná se o místo s důležitými informacemi o onemocnění pacienta",
        "addEntryLabel": "Přidat záznam",
        "entrySavedMsg": "Záznam byl uložen",
        "createdBy": "Vytvořil",
        "addedToObservation": "Přidáno k epikrizi",
        "migration": "migrace"
      }
    },
    "component": {
      "consultation": {
        "diagnosisSelection": {
          "searchIcd10": "Vyhledejte diagnózu",
          "searchIcd10NoResult": "Nebyly nalezeny žádné výsledky",
          "empty": "Diagnostický seznam pacientů je prázdný",
          "main": "Hlavní",
          "markAsMain": "Označit jako hlavní",
          "icd10": "ICD 10"
        },
        "seizureDiagnosisSelection": {
          "first": "Záchvaty prvního typu",
          "second": "Záchvaty druhého typu",
          "third": "Zachycuje třetí typ"
        },
        "drugDosageDialog": {
          "standardTimes": {
            "morning": "Ráno",
            "noon": "Poledne",
            "evening": "Večer"
          },
          "standardDose": {
            "morning": "Ranní dávkování",
            "noon": "Polední dávkování",
            "evening": "Večerní dávkování"
          },
          "addCustomDose": "Přidejte dávku v určený čas",
          "cancel": "zrušení",
          "save": "Uložit"
        },
        "drugDosageEdition": {
          "drugs": "Léky",
          "stage": "Krok",
          "searchDrug": "Přidejte léky",
          "searchDrugNoResult": "Nebyly nalezeny žádné výsledky",
          "dosage": "Zvolte dávkování",
          "cancelTherapy": "zrušení",
          "saveTherapy": "zachránit terapii",
          "addTherapy": "přidat další krok",
          "previousEndDateError": "Pro předchozí krok není definováno žádné datum ukončení",
          "previousEndDateBeforeCurrentError": "Datum ukončení terapie musí být větší než aktuální",
          "activeSubstanceSearch": "Vyhledávání léků s účinnou látkou",
          "removeManyTitle": "Automaticky odstranit lék z následných terapií?",
          "addManyTitle": "Automaticky přidat lék do následných terapií?",
          "yesSameDosage": "Ano, se stejným dávkováním",
          "yesDifferentDosage": "Ano, ale se stejným dávkováním",
          "refreshOrder": "Obnovit objednávku"
        },
        "drugTherapySummary": {
          "current": "Současná drogová terapie",
          "future": "Budoucí léková terapie",
          "past": "Minulá léková terapie",
          "from": "Z",
          "to": "na"
        },
        "medicalHistory": {
          "chart": {
            "loadingLbl": "Načítání dat",
            "seizuresLbl": "Záchvaty",
            "drugsLbl": "Léky",
            "resultsLbl": "Výsledek",
            "visitsLbl": "Návštěvy",
            "eventsLbl": "Události",
            "consultationLbl": "Konzultace",
            "seizuresNumberLbl": "Záchvaty",
            "seizuresDurationLbl": "Doba trvání",
            "trendBtn": "Trendová linie",
            "disabledLbl": "Nyní nepřístupné",
            "trendBetterLbl": "Trend lépe",
            "trendWorseLbl": "Trend horší",
            "rangeLbl": "Rozsah dat"
          }
        },
        "patientSummary": {
          "personalInfo": {
            "personalData": "Osobní data"
          }
        },
        "drugTherapyHistory": {
          "title": "Úprava historie lékových terapií",
          "changeHistory": "Minulé terapie",
          "noAccess": "Nemáte přístup k úpravě historie lékových terapií tohoto pacienta",
          "updateSuccess": "Historie lékových terapií byla úspěšně aktualizována"
        }
      },
      "doctor": {
        "registration": {
          "form": {
            "firstName": "název",
            "lastName": "Příjmení",
            "pesel": "Pesel",
            "pwz": "Číslo PWZ",
            "email": "Emailová adresa",
            "password": "Heslo",
            "passwordConfirm": "Potvrďte heslo",
            "phone": "Telefon",
            "diploma": "Přidejte vyděšený diplom",
            "isSpecialization": "Mám specializaci na neurologii",
            "isPractise": "Provozuji soukromou lékařskou praxi",
            "practiceInfoName": "Cvičné jméno",
            "practiceInfoNipNr": "NIP",
            "practiceInfoRpwdlNr": "Číslo RPWDL",
            "practiceInfoRegonNr": "REGON",
            "practiceAddressStreet": "ulice",
            "practiceAddressHouseAndApartmentNr": "Dům / byt č",
            "practiceAddressHouseNr": "Dům č.p.",
            "practiceAddressApartmentNr": "Byt č",
            "practiceAddressZipcode": "PSČ",
            "practiceAddressCity": "Město",
            "practiceAddressCountry": "Země",
            "isApproveDataProcessing": "Souhlasím se zpracováním mých údajů",
            "send": "Poslat žádost",
            "response1": "Děkujeme vám za registraci",
            "response2": "Budeme vás kontaktovat prostřednictvím e-mailu",
            "gotAccountMsg1": "Už máte účet?",
            "gotAccountMsg2": "Přihlásit se"
          }
        },
        "database": {
          "doctor": {
            "list": {
              "noMedicalHistoryMsg": "Formulář anamnézy ještě nebyl vyplněn. Před výběrem vedoucího lékaře prosím vyplňte co nejvíce informací.",
              "gotoMedicalHistoryLbl": "Přejít na formulář",
              "titleLbl": "Doktorská databáze",
              "titleDescLbl": "Pokud chcete změnit vedoucího lékaře, klikněte na tlačítko „Vybrat“ v profilu zvoleného lékaře a poté vyplňte krátký dotazník.",
              "subtitleLbl": "Lékaři",
              "patientTypeLbL": "Vedení pacientů",
              "sortTypeLbL": "Seřazeno podle",
              "noResultsLbL": "Nebyl nalezen žádný lékař odpovídající parametrům vyhledávání",
              "sortTypes": {
                "patientsAsc": "Vedl pacienty vzestupně",
                "patientsDesc": "Vedl pacienty sestupně",
                "alphabeticAsc": "Abecedně vzestupně",
                "alphabeticDesc": "Abecedně sestupně",
                "remotePriceAsc": "Vzdálená cena návštěvy vzestupně",
                "remotePriceDesc": "Vzdálená cena sestupně",
                "stationaryPriceAsc": "Stacionární cena návštěvy vzestupně",
                "stationaryPriceDesc": "Cena stacionární návštěvy klesá"
              },
              "content": {
                "juniorExpLbl": "Junior expert",
                "standardExpLbl": "Expert",
                "seniorExpLbl": "Senior expert",
                "leadLbL": "vedoucí lékař",
                "bioLbl": "BIO",
                "pricesLbl": "CENÍK",
                "servicesLbl": "ROZSAH SLUŽEB",
                "remoteLbl": "Vzdálená návštěva",
                "stationaryLbl": "Stacionární návštěva",
                "messageLbl": "Zpráva",
                "prescriptionLbl": "Předpis"
              }
            },
            "selection": {
              "request": {
                "alert": {
                  "youHaveTimeTo": "Musíte odpovědět do",
                  "toReplyTo": "na žádost o péči o pacienta",
                  "detailsLbl": "Podrobnosti",
                  "closeLbl": "Zavřít"
                }
              }
            }
          },
          "lead": {
            "titleLbl": "Vedoucí lékař",
            "patientsLbl": "Pacienti",
            "visitsLbl": "Návštěvy",
            "firstVisitLbl": "První",
            "subsequentVisitLbl": "Následující",
            "bioLbl": "Bio",
            "bookVisitLbl": "Zarezervujte si návštěvu",
            "doctor": {
              "change": {
                "request": {
                  "dialog": {
                    "title1": "Potvrďte výběr vedoucího lékaře",
                    "title2": "Jste si jisti, že chcete změnit svého vedoucího dcotora?",
                    "selectionConfirmMsg": "Opravdu si chcete vybrat tohoto lékaře?",
                    "historyCommentLbl": "Komentář týkající se vaší anamnézy",
                    "reasonLbl": "Proč jste se rozhodli změnit svého lékaře?",
                    "reasonCommentLbl": "Změnit důvod",
                    "evaluationLbl": "Jak byste ohodnotil svého současného lékaře?",
                    "overallCommentLbl": "Připomínky ke spolupráci se současným lékařem",
                    "overallCommentInfo": "To nám umožní kontrolovat kvalitu poskytovaných služeb a odebrat podvýkonné lékaře ze systému poté, co ověříme důvody.",
                    "confirmLbl": "Potvrďte změnu vedoucího lékaře",
                    "cancelLbl": "zrušení",
                    "reasons": {
                      "punctuality": "Doktor není přesný",
                      "result": "Žádné zlepšení",
                      "communication": "Komunikační problémy",
                      "other": "jiný"
                    },
                    "evaluations": {
                      "vgood": "velmi dobře",
                      "good": "dobrý",
                      "average": "průměrný",
                      "bad": "špatný"
                    },
                    "confirmationLbl": "Lékaři byla odeslána zpráva",
                    "closeLbL": "Zavřít"
                  }
                },
                "response": {
                  "dialog": {
                    "title": "Souhlasíte, že se stanete vedoucím lékařem tohoto pacienta?",
                    "firstSeizureLbl": "Datum první návštěvy",
                    "epilepsyDescLbl": "Typ epilepsie/záchvat",
                    "drugsUsedLbl": "Dosud používané léky",
                    "patientCommentLbl": "Komentář pacienta",
                    "acceptLbl": "souhlasím",
                    "rejectLbl": "nesouhlasím",
                    "rejectReasonLbl": "Důvod odmítnutí",
                    "confirmLbl": "Potvrdit",
                    "cancelLbl": "zrušení",
                    "rejection": {
                      "reasons": {
                        "punctuality": "Doktor není přesný",
                        "result": "Žádné zlepšení",
                        "communication": "Komunikační problémy",
                        "full": "Žádná volná místa",
                        "seizure": "Typ záchvatu nevyhovující specializaci",
                        "age": "Nevyhovující věkový profil",
                        "other": "jiný"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "patient": {
        "registration": {
          "form": {
            "email": "Emailová adresa",
            "password": "Heslo",
            "passwordConfirm": "Potvrďte heslo",
            "isAgreeTerms1": "Souhlasím s",
            "isAgreeTerms2": "podmínky použití",
            "isAgreeTerms3": "",
            "isApprove1": "Přečetl jsem si pravidla pro zpracování mých osobních údajů stanovená v",
            "isApprove2": "Neurosphera sp. z o.o. Zásady ochrany osobních údajů",
            "isApprove3": "a",
            "isApprove4": "Zásady ochrany osobních údajů společnosti Neuroterminal S.A.",
            "marketing1": "Souhlasím se zasíláním marketingových sdělení (včetně obchodních informací), jejichž účelem je propagace služeb a zboží, včetně komunikace prováděné pomocí mých osobních údajů pomocí telekomunikačních zařízení v oblasti e-mailové adresy (přijímání e-mailových zpráv) .",
            "marketing2": "Souhlasím se zasíláním marketingové komunikace (včetně obchodních informací), jejímž účelem je propagace služeb a zboží, včetně komunikace prováděné pomocí mých osobních údajů pomocí telekomunikačních zařízení v oblasti telefonního čísla (přijímání textových zpráv a příchozích hovorů).",
            "marketing3": "Chci dostávat zpravodaj od společnosti Neuroterminal S.A. jménem společnosti Neurosphera sp. z o.o.",
            "send": "Vytvořit účet",
            "response1": "Děkujeme vám za registraci",
            "response2": "Potvrzení registrace zašleme e -mailem",
            "gotoLoginLbl": "Přihlaste se do systému",
            "gotAccountMsg1": "Už máte účet?",
            "gotAccountMsg2": "Přihlásit se",
            "mandatoryDescription": "Pole označená * jsou povinná",
            "marketing1and2": "Souhlasím se zasíláním marketingových sdělení (včetně obchodních informací), jejichž účelem je propagace služeb a zboží, včetně komunikace prováděné s využitím mých osobních údajů s využitím e-mailové adresy (příjem e-mailových zpráv) a telefonního čísla (příjem textových zpráv). a příchozí hovory).",
            "consentsTitle": "Přijímání předpisů a marketingových souhlasů",
            "consentsAccept": "Přijmout vybrané"
          },
          "confirmation": {
            "header": "Potvrďte registraci!",
            "content1": "Aktivační odkaz jsme zaslali na adresu:",
            "content2": "Zkontrolujte svůj e-mail a klikněte na aktivační odkaz",
            "sendAgainPrefix": "Neobdrželi jste e-mail:",
            "sendAgain": "Poslat znovu!",
            "contactReception": "nebo kontaktujte recepci:",
            "resendBlocked": "V případě, že jste neobdrželi email s registračním odkazem, kontaktujte prosím zákaznický servis."
          }
        },
        "documentation": {
          "document": {
            "add": {
              "title": "Přidat dokument",
              "nameLbL": "název",
              "typeLbl": "Typ",
              "resultDateLbl": "Datum výsledku",
              "confirmLbl": "Připojit",
              "filesToLargeLbl": "Některé soubory byly příliš velké na přidání",
              "filesEmptyContentLbl": "Některé soubory jsou poškozené nebo nemají žádný obsah",
              "wrongFileType": "Špatný typ souboru. Povoleno pouze pdf, obrázky nebo video.",
              "unsavedFormWarningMsg1": "Ve formuláři jsou neuložená data",
              "unsavedFormWarningMsg2": "Chcete data nahrát před opuštěním obrazovky",
              "yesLbl": "Ano",
              "noLbl": "Ne",
              "uploadingMsg1": "Probíhá nahrávání souboru ..",
              "uploadingMsg2": "V případě větších souborů to může chvíli trvat",
              "uploadingMsg1Templated": "Probíhá nahrávání souboru {XXXXXX}"
            },
            "confirm": {
              "title": "Odstranit dokument?",
              "cancelLbl": "zrušení",
              "confirmLbl": "Potvrdit",
              "successMsg": "Dokument odstraněn"
            },
            "edit": {
              "title": "Upravit dokument",
              "nameLbL": "název",
              "typeLbl": "Typ",
              "resultDateLbl": "Datum výsledku",
              "fileNameLbL": "Název souboru",
              "cancelLbl": "zrušení",
              "confirmLbl": "Potvrdit",
              "changedMsg": "Dokument byl úspěšně změněn"
            },
            "viewer": {
              "closeLbl": "Zavřít",
              "typeNotSupported": "Tento typ souboru není v prohlížeči podporován"
            },
            "files": {
              "selection": {
                "title1": "Přetáhněte",
                "title2": "soubory, které zde chcete přidat",
                "title3": "nebo",
                "title4": "vyberte soubory",
                "title5": "z počítače"
              }
            },
            "list": {
              "title1": "Lékařská dokumentace",
              "title2": "Zde si můžete uložit svou mediální dokumentaci",
              "title3": "Přidáním výsledků testů můžete lékaři pomoci stanovit přesnou diagnózu a zvýšit šanci na léčbu.",
              "noDataLbl": "Žádná data",
              "noResultsLbl": "Žádné rande",
              "loadingLbl": "Načítání dokumentace",
              "downloadLbl": "Stažení",
              "showLbl": "Ukázat",
              "editLbl": "Upravit",
              "removeLbl": "Odstranit",
              "headers": {
                "type": "Typ",
                "name": "název",
                "fileName": "Soubor",
                "resultDate": "Vydáno",
                "additionDate": "Přidal"
              },
              "filters": {
                "documentTypeLbl": "Ukázat",
                "documentTypeAllLbl": "Ukázat vše",
                "resultDateFromLbl": "Datum výsledku od",
                "resultDateToLbl": "Výsledek datum do"
              },
              "markCompleted": "Označit jako dokončené",
              "markCompletedDescription": "Pokud popis označíte jako dokončený, odešle jej recepce pacientovi. Pokud chcete, můžete přidat volitelný text pro recepci",
              "isCompleted": "Dokončeno"
            },
            "disk": {
              "space": {
                "filledLbl": "Vyplněno {filled} GB z {available} GB"
              }
            }
          }
        },
        "nearest": {
          "visits": {
            "forthcomingLbl": "nadcházející návštěvy",
            "forthcomingConsultLbl": "(konzultace)",
            "noVisitMsg": "Žádné nadcházející návštěvy.",
            "noVisitZeroMsg": "Žádné nadcházející návštěvy. Zarezervujte si prosím nulovou návštěvu.",
            "noVisitZeroDisclaimerMsg": "Během návštěvy nula lékař analyzuje vaši anamnézu a stanoví váš zdravotní plán",
            "sosVisitLbl": "SOS",
            "bookVisitLbl": "Návštěva knihy",
            "cannotBookVisitMsg": "Návštěvu si nemůžete rezervovat, protože nebyla vyplněna všechna pole v sekci Moje údaje",
            "editLbl": "Upravit",
            "cancelLbl": "zrušení",
            "payLbl": "Platit",
            "dialog": {
              "paymentPending": "Čekáme na potvrzení platby",
              "paymentError": "Platba se nezdařila",
              "payTill1": "Musíte platit do",
              "payTill2": "v opačném případě bude váš požadavek zrušen.",
              "pay": "Zaplatit za návštěvu"
            },
            "absentConsultation": "Nepřítomná konzultace – bez účasti pacienta"
          }
        },
        "treatment": {
          "plan": {
            "titleLbl": "zdravotní plán",
            "inaccessibleLbl": "nepřístupný",
            "noVisitZeroDisclaimerMsg": "Při návštěvě nuly si vyberete zdravotní plán."
          },
          "prescription": {
            "request": {
              "stockLbl": "Sklad na {days} dny",
              "otherQuestLbl": "Potřebujete další drogu",
              "otherDrugLbl": "Do komentáře napište název léku. Nezapomeňte uvést přesný název léku.",
              "confirmTitleLbl": "potvrzení",
              "confirmTextLbl": "Budete přesměrováni na stránku elektronických plateb. Pokračovat?",
              "cancelBtn": "zrušení",
              "confirmBtn": "Potvrdit",
              "chooseDoctor": "Vyberte lékaře, kterého chcete požádat o předpis",
              "askForPrescriptionNotAvailable": "Žádost o recept budete moci odeslat po návštěvě lékaře, který recepty vydává",
              "noLeadDoctorAvailable": "Váš vedoucí lékař je momentálně nedostupný. Žádost o recept můžete poslat službukonajícímu lékaři."
            }
          }
        },
        "lead": {
          "doctor": {
            "titleLbl": "vedoucí lékař",
            "noVisitZeroDisclaimerMsg1": "Vyberete si svého vedoucího lékaře",
            "noVisitZeroDisclaimerMsg2": "při návštěvě nula",
            "changeLbl": "Změna",
            "prescriptLbl": "Požádejte o recept",
            "messageLbl": "Napište zprávu",
            "servicesLbl": "Poskytnuté služby",
            "services": {
              "interview": "rozhovor",
              "therapy": "nastavovací terapie",
              "commission": "emisní provize",
              "prescription": "vystavit recept",
              "documents": "vystavit doporučení, potvrzení, nemocenskou"
            },
            "scope": {
              "bullet1": "První lékařský kontakt pacienta",
              "bullet2": "Komplexní pečovatel o pacienty objednávající další testy nebo případně doporučující konzultanty specialistům",
              "bullet3": "Poradce, který je přímou podporou pacienta a dohlíží na terapeutický plán",
              "bullet4": "Podporováno celým týmem, robotem iCtal® a systémem lékařského dohledu a automatizace"
            }
          }
        },
        "hashtags": {
          "title": "Hashtagy",
          "subtitle": "Je to místo pro označení pacienta předdefinovaným hashtagem",
          "label": "Hledejte hashtagy"
        }
      },
      "finances": {
        "payment": {
          "history": {
            "title": "Historie osídlení",
            "headers": {
              "month": "Měsíc",
              "sum": "Celkový"
            }
          }
        },
        "invoice": {
          "summary": {
            "text1": "Finanční shrnutí",
            "text2": "Najdete tam údaje potřebné pro fakturaci",
            "text3": "Máte dotazy k fakturám?",
            "text4": "Kontaktujte naše finanční oddělení",
            "btn_lbl": "Souhrn ke stažení"
          }
        },
        "general": {
          "summary": {
            "title": "Finance",
            "return": "Zadní",
            "table": {
              "headers": {
                "label": "Typ návštěvy",
                "price": "Cena",
                "amount": "Množství",
                "sum": "Celkový"
              },
              "summary": "Celkový"
            }
          }
        }
      },
      "genericDialog": {
        "approve": "Ano",
        "cancel": "Ne",
        "close": "Zavřít"
      },
      "videochat": {
        "waitingForPatient": "Čekání na připojení pacienta",
        "waitingForDoctor": "Čekání na připojení lékaře",
        "connectionError": "Došlo k chybě připojení",
        "visitEnded": "Telekonference byla ukončena. Relace byla ukončena.",
        "accessDenied": "Udělte prosím přístup k mikrofonu a kameře v prohlížeči a v případě potřeby stránku obnovte",
        "accessDeniedAlert": "Žádný přístup k mikrofonu a kameře",
        "missingAudioInput": "Mikrofonní zařízení chybí",
        "missingVideoInput": "Fotoaparát chybí",
        "tooltip": {
          "disableAudio": "Zakázat zvuk",
          "enableAudio": "Povolit zvuk",
          "disableVideo": "Zakázat video",
          "enableVideo": "Povolit video",
          "terminate": "Ukončete online návštěvu"
        },
        "confirm": {
          "warningMsg1": "Návštěva stále probíhá",
          "warningMsg2": "Chcete ukončit telekonferenční hovor pro probíhající návštěvu?"
        },
        "deviceLoadError": "Chyba při načítání zařízení",
        "accessGrantedBut": "Byla však získána oprávnění:",
        "cannotConnectCamera": "Nelze se připojit ke kameře",
        "cannotConnectMicrophone": "Nelze se připojit k mikrofonu",
        "deviceAlreadyInUse": "Ujistěte se, že se zařízení již nepoužívá, a zkuste to znovu",
        "chooseDeviceDialog": {
          "chooseDevices": "Vyberte zařízení",
          "visitStartMsg1": "Návštěva začne v hod",
          "visitStartMsg2": "Chcete-li mít schůzku hladkou, vyberte zvuková a obrazová zařízení, která chcete použít",
          "camera": "Fotoaparát",
          "microphone": "Mikrofon",
          "speakers": "Řečníci"
        },
        "dialog": {
          "confirm": "Potvrdit"
        }
      },
      "addMedicalEventDialog": {
        "title": "Přidat událost",
        "descriptionPlaceholder": "Zadejte krátký popis",
        "patientSeizureTypePlaceholder": "Typ záchvatu",
        "from": "Z",
        "to": "Na",
        "duration": "Doba trvání",
        "cancel": "zrušení",
        "approve": "Uložit",
        "addNext": "Přidej další",
        "delete": "Vymazat",
        "addSuccessMsg": "Událost byla přidána",
        "noSeizureTypes": "Chcete -li přidat nový záchvat, musíte definovat typy záchvatů ve zdravotním průzkumu",
        "goToHealthSurvey": "Přejít na průzkum zdraví",
        "serialSeizures": "Sériové záchvaty",
        "seizuresCount": "Záchvaty se počítají"
      },
      "user": {
        "settings": {
          "sms": {
            "title": "SMS upozornění"
          },
          "other": {
            "title": "jiný"
          }
        }
      },
      "visitChat": {
        "title": "Navštivte chat",
        "loading": "Probíhá načítání zpráv ...",
        "emptyList": "Seznam zpráv je prázdný",
        "inputLabel": "Napište zprávu"
      },
      "payu": {
        "banner": {
          "installmentPaymentAvailable": "Platba na splátky je k dispozici zde",
          "startsFrom": "z",
          "decisionIn": "rozhodnutí v",
          "decisionInSeconds": "{val} sekund",
          "checkConditions": "Zkontrolujte podmínky"
        }
      },
      "updateMedicalEventDialog": {
        "title": "Upravit událost",
        "changesSaved": "Změny byly úspěšně uloženy"
      },
      "maintenance": {
        "newAddress": "<b>Pozor!</b> Měníme adresu pobočky ve Varšavě. Naše nová adresa je <b>ul. Taneczna 30, 02-829 Varšava (Ursynów)</b>"
      }
    },
    "ui": {
      "help": "Pomoc",
      "policy": "Zásady ochrany osobních údajů",
      "logout": "Odhlásit se",
      "navigation": {
        "home": "Domovská obrazovka",
        "account": {
          "name": "O mně",
          "personalData": "Můj účet",
          "medicalDocuments": "Lékařské dokumenty",
          "medicalHistory": "Průzkum zdraví"
        },
        "messages": "Zprávy",
        "therapy": "Terapie",
        "visits": {
          "name": "Návštěvy",
          "archive": "Archiv návštěv",
          "doctorDatabase": "Doktorská databáze"
        },
        "myVisits": "Mé návštěvy",
        "patients": "Pacienti",
        "ediagnosis": "IctalLab",
        "calendar": "Kalendář",
        "workplan": "Pracovní plán",
        "visitsAndDuties": "Návštěvy a povinnosti",
        "finances": "Finance",
        "ehealth": {
          "name": "E-zdraví",
          "prescriptions": "E-recepty",
          "referrals": "E-doporučení"
        },
        "settings": {
          "name": "Nastavení",
          "notifications": "Oznámení",
          "regulations": "Předpisy",
          "payments": "Platby"
        },
        "documentation": "Dokumentace",
        "examinations": "Zkoušky"
      },
      "closeDialog": "Zavřít",
      "visitSummary": "visit_summary",
      "invoice": "faktura",
      "correctionInvoice": "opravná_faktura",
      "ieAlert": "Litujeme, Internet Explorer již nepodporujeme. Použijte prosím jiný internetový prohlížeč."
    },
    "components": {
      "workPlan": {
        "header": {
          "TIMETABLE": "Plán",
          "TIMETABLE_CHANGES": "Změní kalendář"
        },
        "editDialog": {
          "workPosition": "Upravit pozici pracovního plánu",
          "unsavedWorkPosition": "Nová pozice pracovního plánu",
          "vacation": "Upravit dovolenou",
          "unsavedVacation": "Nová dovolená",
          "dayOfWeekLabel": "Den v týdnu",
          "officeLabel": "Kancelář",
          "visitTypeLabel": "Typ návštěvy",
          "startTimeLabel": "Doba spuštění",
          "endTimeLabel": "Čas ukončení",
          "description": "Popis",
          "cancel": "zrušení",
          "approve": "Schvalovat",
          "visitType": "Typ návštěvy",
          "visitPlace": "Navštivte místo",
          "stationaryAndRemote": "Pozornost! Tento typ návštěvy umožňuje pacientům jmenovat stacionární i vzdálené návštěvy",
          "workPlaceMissing": "Chcete -li povolit stacionární návštěvy, musíte na stránce nastavení nastavit své pracovní místo"
        },
        "deleteDialog": {
          "text": "Opravdu chcete tuto pozici smazat?",
          "cancel": "zrušení",
          "confirm": "Vymazat"
        },
        "visitType": {
          "unavailable": "Povinnost",
          "any": "Jakýkoliv typ",
          "stationaryOrRemore": "Stacionární / dálkový"
        },
        "event": {
          "duty": {
            "yourDutyToChange": "Oznaczony jako do zmiany",
            "state": {
              "AVAILABLE": "K dispozici clo",
              "NOT_AVAILABLE": "Povinnost není k dispozici",
              "TO_CHANGE": "Žádost o převzetí povinnosti",
              "PLANNED": "Vaše plánovaná povinnost"
            }
          }
        },
        "description": "Naplánujte si práci - vyberte, kdy budete k dispozici pro návštěvy",
        "selectedWorkPlan": "Vybraný pracovní plán:",
        "newWorkPlan": "Nový pracovní plán:",
        "untilFurtherNotice": "až na další oznámení",
        "couldNotDeleteActiveWorkPlan": "Aktivní pracovní plán nelze odebrat",
        "validFrom": "Platnost od",
        "validTo": "Platný do",
        "resetChanges": "Resetovat",
        "saveChanges": "Uložit",
        "unsavedChanges": "V kalendáři jsou neuložené změny",
        "visits": "Návštěvy",
        "newPlan": "Nový plán",
        "messages": {
          "deleteSuccess": "Pracovní plán byl smazán",
          "addSuccess": "Byl přidán pracovní plán",
          "updateSuccess": "Pracovní plán byl aktualizován"
        }
      },
      "doctor": {
        "calendar": {
          "todayBtn": "Dnes"
        },
        "messages": {
          "title": "Skryté zprávy",
          "show": "Ukázat",
          "item": {
            "systemLbl": "Zpráva systému",
            "types": {
              "prescribeLbl": "Žádost o vystavení receptu od: {sender}",
              "selectionLbl": "Žádost o vedení lékaře z: {sender}",
              "noteLbl": "Poznámka pro pacienta: {patient} bylo přidáno",
              "prescribeLblMovedFrom": "místo {movedFrom}"
            }
          },
          "new": "Nový"
        },
        "visits": {
          "title": "Denní plán",
          "show": "Ukázat",
          "types": "Typ návštěvy",
          "categories": "Navštivte kategorii",
          "type": {
            "remote": "Wizyty online",
            "stationary": "Stacjonarne"
          },
          "item": {
            "statusPayed": "zaplaceno za",
            "statusNotPayed": "nezaplaceno",
            "statusDone": "Hotovo",
            "statusToMove": "pohybovat se",
            "visitBtnLbl": "návštěva",
            "visitStartBtnLbl": "zahájit návštěvu",
            "historyBtnLbl": "zdravotní historie",
            "numberLbl": "Počet návštěv",
            "causeLbl": "Navštivte příčinu",
            "lastVisitLbl": "Poslední návštěva:",
            "diagnosisLbl": "Diagnóza",
            "diagnosisNoEpilepsyLbl": "Není epileptický",
            "diagnosisNoDataLbl": "Žádná diagnóza",
            "drugsLbl": "Současné drogy",
            "drugsNoneLbl": "Žádný",
            "frequencyLbl": "Frekvence záchvatů",
            "moodLbl": "Nálada",
            "summaryLbl": "Shrnutí návštěvy",
            "visitDuration": "Doba trvání:"
          }
        },
        "form": {
          "pricelist": {
            "titleInfo": "Nová ceníková položka",
            "cancelBtn": "zrušení",
            "saveBtn": "Potvrdit"
          },
          "avatar": {
            "titleInfo": "Vyberte obrázek",
            "addImageBtn": "Obrázek ze systému souborů",
            "cancelBtn": "zrušení",
            "saveBtn": "Potvrdit"
          },
          "address": {
            "streetLbl": "ulice",
            "homeBuildingLbl": "Č. Budovy / byt",
            "apartmentLbl": "Byt č",
            "buildingLbl": "Budova č.p.",
            "cityLbl": "Město",
            "zipcodeLbl": "PSČ",
            "countryLbl": "Země"
          }
        }
      },
      "message": {
        "history": {
          "title": "Zprávy",
          "newMessage": "Nová zpráva",
          "new": "Nový",
          "search": "Vyhledávání",
          "filters": {
            "current": "Proud",
            "archive": "Archiv",
            "contact": "Vyhledávání"
          },
          "noMessages": "Žádné zprávy",
          "born": "narozený",
          "historyBtnLbl": "Zdravotní historie",
          "return": "Vrátit se",
          "send": "Poslat",
          "sendAndClose": "Pošlete a zavřete návštěvu",
          "hoursToAnswer": "h",
          "timeIsUp": "Končíme",
          "hoursToAnswerRich": "Na zodpovězení máte {hours} hodin",
          "timeIsUpRich": "Deklarovaný čas na odpověď již uplynul. Dejte to co nejdříve!",
          "summary": "souhrn",
          "medicalHistory": "Zdravotní historie",
          "sort": {
            "label": "Ukázat",
            "newest": "Nejnovější",
            "alpha": "Abecedně",
            "deadline": "Nejnaléhavější"
          },
          "visitEnded": "skončilo",
          "noTherapies": "Žádné terapie",
          "list": "Seznam zpráv",
          "closeNoAnswer": "Zpráva nevyžaduje odpověď",
          "noAnswerContent": "Díky za vaši zprávu. pozdravy",
          "confirmNoAnswer": "Opravdu chcete ukončit návštěvu bez odpovědi?",
          "daysToAnswer": "dní",
          "daysToAnswerRich": "Na odpověď máte {days} dnů",
          "sendAndCloseDialog": {
            "header": "Opravdu chcete vlákno ukončit?",
            "title": "Pokud návštěvu uzavřete, pacient nebude moci na vaši zprávu odpovědět",
            "subtitle": "Pokud chcete, aby pacient mohl odpovědět na vaši zprávu, klikněte na „Odeslat“"
          },
          "newMessageOnDuty": "Nová zpráva pro lékaře ve službě",
          "unidirectional": {
            "send": "Odeslat jednosměrnou zprávu",
            "sendTitle": "Jednosměrná zpráva na",
            "sendSubtitle": "Pacient nemůže na tuto zprávu odpovědět",
            "sendSuccess": "Jednosměrná zpráva pacientovi byla odeslána",
            "infoForPatient": "Toto je zpráva - informace od lékaře. V této zprávě vám lékař poskytl další informace o vaší nemoci. Na zprávu nemůžete odpovědět, pokud chcete další informace, napište novou zprávu - navštivte svého lékaře"
          },
          "addToEpicrisis": "Přidejte k epikrizi",
          "textAddedToEpicrisis": "Vstup přidán k epikrizi"
        },
        "respondeDeadlineAlert": {
          "youHaveTimeTo": "Musíte odpovědět do",
          "toReplyTo": "na zprávu od",
          "reply": "Odpověď"
        },
        "patientAlerts": {
          "healthSurveyContent": "Je třeba dokončit váš zdravotní průzkum",
          "healthSurvey": "Přejít na průzkum"
        },
        "newMessageVisit": {
          "noDoctorTitle": "Nemáte přiřazeného vedoucího lékaře",
          "title": "Poraďte se se svým lékařem - pošlete zprávu",
          "descriptionLbl": "Popis",
          "description": "Návštěva zprávy je krátká otázka zaslaná lékaři. Váš lékař vám odpoví. Chcete-li použít tento způsob kontaktu, zaplaťte za svou návštěvu zprávu kliknutím na tlačítko „Nová zpráva“. Pokud máte obavy o své zdraví nebo potřebujete delší konzultaci - domluvte si PRAVIDELNOU schůzku se svým lékařem.",
          "messageVisitsDisabled": "Je nám líto, ale váš lékař tento typ služby neposkytuje.",
          "noLeadDoctorAvailable": "Váš vedoucí lékař je momentálně nedostupný. Můžete napsat zprávu lékaři ve službě."
        },
        "drugStock": {
          "lowMsg": "U následujících léků dochází zásoby:",
          "notInitialized": "Inicializujte své zásoby léků pro:",
          "daysLbl": "dny"
        },
        "toast": {
          "text": "Nová příchozí zpráva od"
        },
        "newPaidMessageAlert": {
          "content": "Zaplatili jste za zprávu lékaři",
          "reply": "Napište zprávu"
        },
        "unidirectionalMessageAlert": {
          "content": "Obdrželi jste zprávu - informaci od lékaře",
          "reply": "Zobrazit zprávu"
        },
        "personalDataAcceptanceAlert": {
          "title": "Potvrzení osobních údajů",
          "subtitle": "Vaše osobní údaje v systému Neuroterminál jsou důležité – ujistěte se, že jsou aktuální! Díky tomu můžete naplno využít našich služeb.",
          "changeButton": "Změňte své osobní údaje",
          "acceptButton": "Potvrďte mé osobní údaje"
        }
      },
      "utility": {
        "multipleSelect": {
          "all": "Všechno"
        },
        "confirmationDialog": {
          "title": "Jsi si jistá?",
          "text": "Jste si jistý, že chcete pokračovat?"
        },
        "youAreNotAllowedDialog": {
          "title": "Vyžadována oprávnění",
          "text": "Nemáte povolení. Pro více informací nás kontaktujte.",
          "goBackToMainPage": "Zpět na hlavní stránku"
        },
        "phoneInput": {
          "phoneNumberLabel": "Telefonní číslo",
          "legalGuardPhoneNumberLabel": "Telefon rodiče nebo zákonného zástupce"
        }
      },
      "health": {
        "state": {
          "noData": "Žádná data",
          "stateNoChange": "Žádná změna",
          "stateBetter": "Lepší",
          "stateWorse": "Horší"
        }
      },
      "calendar": {
        "title": "Návštěvy a povinnost",
        "description": "Vaše návštěvy pro aktuální týden. Kliknutím na návštěvu zobrazíte její podrobnosti.",
        "visitCancelled": "Návštěva byla zrušena",
        "visitDateChanged": "Datum návštěvy bylo změněno",
        "legend": {
          "duty": "SOS povinnost",
          "vacation": "Dovolená"
        },
        "filters": {
          "showVisits": "Zobrazit návštěvy",
          "showDuties": "Ukázat povinnosti"
        },
        "visitDetailsDialog": {
          "title": "Navštivte podrobnosti",
          "onDay": "na",
          "fromHour": "z",
          "toHour": "na",
          "close": "Zavřít"
        },
        "dutyDetailsDialog": {
          "title": "Podrobnosti o povinnosti",
          "fromHour": "z",
          "toHour": "na",
          "close": "Zavřít",
          "signUpForDuty": "Zaregistrujte se do služby",
          "markDutyToChange": "Žádost o převod cla",
          "toChangeRequestText": "Požádá o převodník služeb. Tomuto lékaři můžete pomoci tím, že se za něj přihlásíte do této povinnosti.",
          "cantMarkDutyAsToChange": "Bohužel nemůžete požádat o převod této povinnosti.",
          "dutyNotAvailable": "Tato povinnost je již přijata. Zvolte jiné datum služby.",
          "cancelToChange": "Zrušte žádost o převod cla"
        },
        "confirmVisitCancellationDialog": {
          "title": "Zrušení návštěvy",
          "sureToCancelVisit": "Opravdu chcete zrušit návštěvu pacienta",
          "fromDate": ", který se měl konat od",
          "from": "a pokračujte do",
          "to": "na",
          "description": "Pacient bude požádán, aby si vybral jiný termín, nebo ho služba kontaktuje.",
          "confirm": "Zrušit návštěvu",
          "cancel": "zrušení",
          "visitAlert": "Rušíte návštěvu, která má začít za méně než 48 hodin! Takové postupy nepodporujeme. Další informace naleznete v našem",
          "termsAndConditions": "pravidla a podmínky",
          "fromDatePassed": ", který se konal od",
          "fromPassed": "a pokračoval až do",
          "visitError": "Tato návštěva již proběhla. Ujistěte se, že tuto návštěvu opravdu chcete zrušit"
        },
        "visits": {
          "title": "Návštěvy lékařů",
          "description": "Termíny vybraných lékařů na daný den. Kliknutím na pole v kalendáři zobrazíte jeho podrobnosti.",
          "dateSelectPrefix": "Vybraný den",
          "noVisits": "Žádné návštěvy",
          "doctors": "Lékaři"
        }
      },
      "duties": {
        "applyDutyDialog": {
          "title": "Vydání povinností",
          "subtitle": "Ujistěte se, že jsou vaše změny správné.",
          "addedDuties": "Přidané povinnosti:",
          "cancelledDuties": "Zrušená cla:",
          "inHours": "na",
          "back": "Zpět k úpravám",
          "approve": "Uložit",
          "acceptTermsAndConditions": "Přijímám obchodní podmínky"
        },
        "alert": {
          "text": "Máte povinnost na:"
        },
        "description": "Zaregistrujte se do služby SOS. Vyberte si vhodné datum kliknutím na něj v kalendáři"
      },
      "payment": {
        "history": {
          "title": "Historie plateb",
          "filters": {
            "typeAll": "Všechno",
            "advFiltLbl": "Pokročilé vyhledávání",
            "advanced": {
              "from": "Počáteční datum",
              "till": "až do",
              "to": "Datum ukončení",
              "clearLbl": "Průhledná",
              "searchLbl": "Vyhledávání"
            }
          },
          "noDataLbl": "Žádná data",
          "noResultsLbl": "Nebyly nalezeny žádné výsledky vyhledávání",
          "headers": {
            "date": "datum",
            "type": "Typ",
            "amount": "Množství",
            "invoice": "Faktura",
            "service": "Název služby"
          },
          "pdfLbl": "Stažení",
          "invoice": "Faktura",
          "correctionInvoice": "Oprava"
        }
      },
      "dialog": {
        "generic": {
          "confirmation": {
            "dialog": {
              "defaultCancelLbl": "zrušení",
              "defaultConfirmLbl": "Potvrdit"
            }
          },
          "attention": "Uwaga"
        }
      },
      "patient": {
        "visits": {
          "next": {
            "visit": {
              "info": {}
            }
          },
          "operations": {
            "cancel": {
              "title": "Potvrzujete zrušení návštěvy?",
              "warning": "Podle podmínek použití návštěvy zrušené do 48 hodin do data návštěvy podléhají správnímu poplatku ve výši 50% nákladů na návštěvu. Zbývající částka bude vrácena (způsob vrácení bude projednán).",
              "reason": "Důvod zrušení návštěvy (nepovinné)",
              "confirmLbl": "Ano",
              "cancelLbl": "Ne",
              "warningCustom": "Podle Podmínek použití podléhají návštěvy zrušené do {days} dnů do data návštěvy administrativnímu poplatku ve výši {percentage} % z ceny návštěvy. Zbývající částka bude vrácena (způsob vrácení bude projednán)."
            },
            "modify": {
              "title": "Vyberte nové datum návštěvy",
              "confirmMsg": "Potvrzujete změnu data návštěvy?",
              "cancelLbl": "zrušení",
              "yesLbl": "Ano",
              "noLbl": "Ne",
              "modifyDisabled": "Tuto návštěvu nelze přesunout",
              "dateBefore": "Předchozí datum",
              "dateCurrent": "Dnešní datum",
              "change": "Změnit datum",
              "changeWarning": "Pozor! Systém umožňuje pacientovi změnit termín / lékaře pro schůzku pouze jednou"
            }
          }
        }
      },
      "surveyZeroNote": {
        "addButton": "Poznámka 0",
        "title": "Pozorování z průzkumu 0",
        "addDialog": {
          "visitPurpose": "Účel návštěvy 1",
          "visitPurposeDescription": "Zeptejte se pacienta, k čemu je schůzka 1 určena. V ideálním případě by mělo být zadáno prohlášení pacienta.",
          "surveyCompleted": "Byl průzkum 0 dokončen?",
          "surveyCompletedDescription": "Pokud průzkum 0 nebyl dokončen nebo pokud byly některé části průzkumu dokončeny, uveďte důvod.",
          "seizures": "Popište záchvaty slovy",
          "seizuresEmpty": "Nejsou definovány žádné typy záchvatů",
          "chronicDiseases": "Má pacient jiná chronická onemocnění – vedoucí? (např. autismus, mrtvice)",
          "chronicDiseasesDescription": "Chronická onemocnění",
          "epilepsy": "Má pacient v rodinné anamnéze epilepsii nebo jiná neurologická onemocnění?",
          "epilepsyDescription": "Stručně popište, kdo má v rodinné anamnéze epilepsii nebo jiná neurologická onemocnění",
          "eegCompleted": "Podstoupil pacient vyšetření EEG?",
          "mriCompleted": "Podstoupil pacient vyšetření magnetickou rezonancí?",
          "ctCompleted": "Podstoupil pacient CT vyšetření?",
          "geneticResearch": "Podstoupil pacient genetické vyšetření?",
          "relevantExaminations": "Absolvoval pacient nějaké další relevantní studie?",
          "otherMedicines": "Užívá pacient v současné době léky kromě AED zahrnutých v dotazníku 0?",
          "otherMedicinesDescription": "Popište slovy, jaké léky/látky pacient užívá",
          "allergies": "Má pacient alergie na léky, včetně antibiotik?",
          "allergiesDescription": "Popište slovy, na jaké léky / látky je pacient alergický",
          "notes": "Další poznámky",
          "examinationDescription": "Popište případné výsledky testů",
          "additionalDescription": "Slovní popis",
          "treatment": "Léčba zatím",
          "treatmentDescription": "Slovní popis",
          "specialDiet": "Existuje nějaká speciální dieta?",
          "specialDietDescription": "Slovní popis",
          "perinatalInterviewUnimportant": "Důležité informace",
          "birthDate": "Datum porodu (narození)",
          "birthType": "Typ porodu",
          "pregnancyComplications": "Komplikace během těhotenství",
          "birthWeight": "Váha při narození",
          "birthLength": "Délka při narození",
          "apgar": "Apgar body (0–10)",
          "apgarFirst": "1 min",
          "apgarNext": "Další měření",
          "birthAdditionalProcedures": "Byly perinatálně provedeny nějaké další výkony?",
          "birthPrematureAgeCorrected": "Byl upraven věk u předčasně narozených dětí?",
          "hasSiblings": "Má pacient nějaké sourozence?",
          "sibling": "Osoba",
          "siblingBirthDate": "Datum narození",
          "siblingSameParents": "Jsou to stejní rodiče?",
          "siblingHealthy": "Je zdravý?",
          "addSibling": "Přidejte další osobu",
          "geneticPredisposition": "Existuje environmentální a genetická/dědičná predispozice a rodinná anamnéza?",
          "developmentCorrect": "Je současný vývoj správný?",
          "retardationDetermined": "Bylo zjištěno, do jaké míry je retardovaný ve vztahu ke svému věku?",
          "retardationExamined": "Bylo v tomto směru odborné vyšetření?",
          "nystagmus": "Má pacient nystagmus?",
          "vision": "Pohled",
          "hearing": "Sluch",
          "tuxedo": "Kouření cigaret",
          "alcohol": "Alkohol",
          "narcotics": "Drogy",
          "leadingDisease": "Jaká je hlavní nemoc pacienta?",
          "leadingDiseaseDominantSymptoms": "Dominantní příznaky onemocnění",
          "leadingDiseaseLasting": "Jak dlouho nemoc trvá?",
          "accompanyingSymptoms": "Doprovodné příznaky: chtěl by pacient něco přidat?",
          "next": "Další",
          "previous": "Zadní",
          "saveAndClose": "Uložit a zavřít",
          "sections": {
            "surveyZero": "Průzkum 0",
            "perinatalInterview": "Perinatální rozhovor",
            "development": "Rozvoj",
            "familyInterview": "Rodinný rozhovor",
            "treatment": "Zacházení",
            "comorbidities": "Komorbidity",
            "medicalExams": "Lékařské prohlídky",
            "others": "Ostatní"
          }
        },
        "preview": {
          "userCreated": "Vytvořil",
          "seizures": "Popis zadaných záchvatů",
          "chronicDiseases": "Má pacient další závažná onemocnění?",
          "epilepsy": "Rodinný rozhovor",
          "eegCompleted": "Test EEG",
          "mriCompleted": "MRI vyšetření",
          "ctCompleted": "CT vyšetření",
          "geneticResearch": "Genetické testování",
          "relevantExaminations": "Jiný výzkum",
          "otherMedicines": "Léky kromě LPP léků",
          "allergies": "Alergie na léky",
          "addToObservations": "Přidat k postřehům",
          "addFragmentToObservations": "Přidejte vybraný fragment a proveďte pozorování",
          "addWholeToObservations": "Přidejte k pozorování celou poznámku"
        }
      },
      "medicalHistoryPrintout": {
        "download": "Stáhněte si anamnézu",
        "withObservations": "Verze s postřehy",
        "withoutObservations": "Verze bez postřehů",
        "downloadHistory": "Zobrazit historii stahování",
        "history": {
          "title": "Historie stahování anamnézy pacienta",
          "timeDownload": "Datum stažení",
          "userDownload": "Downloader",
          "observations": "Obsahuje postřehy"
        },
        "parametersPopup": {
          "title": "Stáhněte si anamnézu",
          "subtitle": "Vyberte období, ze kterého chcete stáhnout anamnézu",
          "allHistory": "celá historie z neuroterminálního systému",
          "selectedRange": "historie z konkrétní doby",
          "download": "Stáhnout",
          "cancel": "Zrušit"
        }
      },
      "patientExaminations": {
        "chooseExaminationLbl": "Vyberte vyšetření, u kterého chcete doplnit popis",
        "eeg": "EEG vyšetření",
        "addEegDescription": "Přidejte popis EEG vyšetření",
        "eegDescriptionHeader": "Popis EEG vyšetření",
        "describingDoctor": "Tvůrce popisu",
        "months": "měsíce",
        "removeEegTitle": "Odstraňte popis vyšetření EEG",
        "removeEegContent": "Opravdu chcete tento popis odstranit?"
      }
    },
    "common": {
      "sex": {
        "man": "Muži",
        "woman": "Žena",
        "nondefined": "Není definovaný"
      },
      "role": {
        "patientLbl": "trpěliví",
        "doctorLbl": "doktor"
      },
      "years": " | rok | {n} let | {n} let",
      "visit": {
        "paid": "zaplaceno",
        "additional": "další",
        "toMove": "pohybovat se",
        "confirmed": "potvrzeno",
        "done": "Hotovo",
        "cancelled": "zrušeno",
        "notPaid": "nezaplaceno",
        "messagePaid": "probíhá"
      },
      "bornAbbr": "narozený",
      "months": " | 1 měsíc | {XXXXXX} měsíců | {XXXXXX} měsíců",
      "days": " | 1 den | {XXXXXX} dnů | {XXXXXX} dnů",
      "confirm": "Potvrdit",
      "cancel": "Zrušit",
      "select": "Vybrat"
    },
    "views": {
      "profile": {
        "welcome": "VÍTEJTE !",
        "choose": "Přihlaste se jako:",
        "doctor": "doktor",
        "patient": "trpěliví"
      },
      "login": {
        "loginFldLbl": "E-mailem",
        "passwordFldLbl": "Heslo",
        "loginBtnLbl": "Přihlásit se",
        "loggingAs": "Jste přihlášeni jako",
        "wantToLoginAs": "Chci se přihlásit jako",
        "registerMsg": "Ještě nemáte účet?",
        "registerLinkMsg": "Vytvořit účet",
        "resetMsg": "Nepamatujete si heslo?",
        "resetLinkMsg": "Obnovit heslo",
        "return": "vrátit se"
      },
      "register": {
        "patientLbl": "Trpěliví",
        "doctorLbl": "Doktor",
        "loginFldLbl": "Přihlásit se",
        "newPassFldLbl": "Heslo",
        "newPassConfFldLbl": "Potvrďte heslo",
        "firstNameFldLbl": "Jméno",
        "secNameFldLbl": "Druhé jméno",
        "lastNameFldLbl": "Příjmení",
        "emailFldLbl": "E-mailem",
        "workCertNrFldLbl": "Certifikát č",
        "confirmBtnLbl": "Potvrdit",
        "backToLoginPageLbl": "Zpět na přihlašovací stránku"
      },
      "reset": {
        "loginFldLbl": "Uživatelské jméno",
        "newPasswordLbl": "Nové heslo",
        "newPasswordConfirmLbl": "Potvrďte nové heslo",
        "confirmBtnLbl": "Potvrdit",
        "backToLoginPageLbl": "Zpět na přihlašovací stránku"
      },
      "dashboard": {
        "welcomeMessage": "Přihlášený uživatel: {userName}",
        "enableEditable": "Potvrdit zobrazení",
        "disableEditable": "Spravovat zobrazení"
      },
      "visits": {
        "titleLbl": "Mé návštěvy",
        "noDataLbl": "Žádné návštěvy",
        "noResultsLbl": "Nebyly nalezeny žádné návštěvy",
        "filters": {
          "visitTime": {
            "all": "Všechno",
            "future": "Budoucnost",
            "past": "Minulý"
          },
          "searchPatientLbl": "Hledat pacienta",
          "advancedFiltersLbl": "Pokročilé vyhledávání",
          "advancedFilters": {
            "from": "Datum od",
            "betweenDatesLbl": "dělat",
            "till": "Datum do",
            "type": "Typ návštěvy",
            "category": "Navštivte kategorii",
            "all": "Všechno",
            "clearBtn": "Průhledná",
            "searchBtn": "Vyhledávání"
          }
        }
      },
      "account": {
        "common": {
          "requiredMsg": "Povinná pole jsou označena hvězdičkou",
          "clearBtn": "Průhledná",
          "editBtn": "Upravit",
          "saveBtn": "Potvrdit",
          "yes": "Ano",
          "no": "Ne",
          "woman": "žena",
          "man": "muž",
          "nondef": "není definovaný"
        },
        "home": {
          "myInfoLbl": "Moje info",
          "generalInfoLbl": "Obecné informace",
          "accountInfoLbl": "Procvičte si informace",
          "pricelistInfoLbl": "Finanční data",
          "incompleteFormWarningMsg1": "Nebyla vyplněna všechna pole",
          "incompleteFormWarningMsg2": "Opravdu chcete uložit neúplný formulář?"
        },
        "general": {
          "basicInfoLbl": "Základní informace",
          "avatarLbl": "Avatar",
          "nameLbl": "název",
          "lastNameLbl": "Příjmení",
          "sexLbl": "Sex",
          "emailLbl": "E-mailem",
          "phoneLbl": "Telefonní číslo",
          "pwzLbl": "Číslo PWZ",
          "dataSavedMsg": "Obecné informace uloženy"
        },
        "practise": {
          "basicInfoLbl": "Povolení, poskytované služby, vědecký nosič",
          "type": "Úroveň",
          "consultationType": "Typ konzultace",
          "academicTitle": "Akademický titul",
          "specialization": "Specializace",
          "workPlace": "Pracovní místo",
          "epileptologyCert": "Certifikace epileptologie",
          "nfzContract": "Smlouva NFZ",
          "testResDescriptions": "Popisy výsledků testů",
          "consultations": "Konzultace",
          "scientificWork": "Vědecká práce",
          "scientificSocieties": "Vědecké společnosti",
          "www": "WWW stránka",
          "bio": "Bio",
          "certificate": "Certifikát ZUS",
          "chooseFile": "Vyberte soubor",
          "patientsInfoLbl": "Pacienti",
          "newPatients": {
            "label": "Noví pacienti",
            "accept": "Přiznává",
            "noaccept": "Nepřiznává"
          },
          "acceptedPatients": "Přijatí pacienti",
          "leadPractiseInfoLbl": "Informace o vedoucí praxi",
          "workplaceName": "Název pracoviště",
          "workplacePosition": "Pozice",
          "communicators": "Komunikátory",
          "usernameMessanger": "Uživatelské jméno Messengeru",
          "usernameWhatsup": "Uživatelské jméno Whatsapp",
          "usernameSkype": "Uživatelské jméno Skype",
          "usernameHangouts": "Uživatelské jméno Hangouts",
          "dataSavedMsg": "Informace o cvičení byly uloženy",
          "neuroscreenDiagnoses": "Propagované nemoci",
          "partner": "Partner NSPH"
        },
        "pricelist": {
          "services": "Služby",
          "visitsLbl": "Návštěvy",
          "othersLbl": "Ostatní",
          "systemItemsLbl": "Systémové položky",
          "systemItemsDescription": "Tyto služby globálně nastavuje společnost Neuroterminal",
          "descriptionsLbl": "Popisy výsledků testů",
          "table": {
            "statusLbl": "Postavení",
            "activeLbl": "Aktivní",
            "inactiveLbl": "Neaktivní",
            "nameLbl": "Název služby",
            "durationLbl": "Doba trvání",
            "priceLbl": "Cena",
            "paymentLbl": "Vaše platba",
            "perHourLbl": "za hodinu",
            "dutyLbl": "Povinnost"
          },
          "bankAccount": "bankovní účet",
          "practiceName": "Název soukromé praxe",
          "nip": "Nip",
          "regon": "Regon",
          "dataSavedMsg": "Informace o ceníku uloženy",
          "visitsDescription": "Nastavte sazby pro konkrétní typy návštěv.",
          "othersDescription": "Nastavte sazby pro další služby, které nejsou seskupeny podle typů pacientů, jako jsou zprávy nebo lékařské dokumenty",
          "choosePatientTypes": "Pro definování ceníku návštěv musíte vybrat alespoň jeden typ pacienta",
          "widgetVisibility": "Viditelné ve widgetu pro jmenování"
        }
      },
      "patientDashboard": {
        "aboutMe": "O mně",
        "myData": "moje data",
        "myDataEdit": "Upravit",
        "myDataFill": "Vyplnit",
        "myDataShow": "Ukázat",
        "medicalDocuments": "zdravotní dokumentaci",
        "healthQuestionnaire": "zdravotní dotazník",
        "visitsLbl": "Návštěvy",
        "eventCalendar": "Kalendář akcí",
        "addEventLbl": "Přidat",
        "seizure": "Záchvat",
        "infection": "Infekce",
        "fever": "Horečka",
        "insomnia": "Nespavost",
        "menstruation": "Menstruace",
        "pregnancyStart": "Začne těhotenství nebo období před těhotenstvím",
        "pregnancyEnd": "Těhotenství nebo období před těhotenstvím končí",
        "chooseLeadDoctor": "Vyberte vedoucího lékaře",
        "alert": {
          "yourVisitWithDoctor": "Vaše návštěva u lékaře",
          "startsAt": "začíná v",
          "lastsFrom": "trvá od",
          "join": "Připojit se",
          "visitToMove": {
            "first": "Vaše návštěva v",
            "second": "byla zrušena",
            "makeNewAppointment": "Vyberte nové datum"
          },
          "myData": {
            "label": "Chcete -li použít účet pacienta, vyplňte formulář osobních údajů.",
            "action": "Přejít na formulář"
          },
          "prescriptionsToRead": {
            "label": "Lékař vydal recept. Chcete -li zobrazit PIN kód, přejděte na kartu Terapie.",
            "action": "Zobrazit předpisy"
          },
          "selectLeadDoctor": {
            "label": "Vyberte svého vedoucího lékaře.",
            "action": "Vyberte lékaře"
          },
          "doctorRejectedRequest": "{doctorName} nesouhlasil s tím, že se stane vaším vedoucím lékařem. Důvod: {reason}"
        },
        "activation": {
          "welcomeLbl": "Vítejte v Neuroterminalu",
          "init1Lbl": "Jsem rád, že jsi s námi.",
          "init2Lbl": "Před první návštěvou vyplňte informace o svém zdravotním stavu.",
          "init3Lbl": "moje data",
          "init4Lbl": "zdravotní dokumentaci",
          "init5Lbl": "Pak rezervujte návštěvu nula.",
          "nextLbl": "další"
        },
        "therapy": "současná terapie",
        "noCurrentTherapies": "Žádná současná terapie",
        "drugStock": "zásoba drog",
        "drugStockDescription": "Kliknutím na „Aktualizovat“ poskytnete aktuální zásobu léků.",
        "supplyFor": "zásoby na {days} dny",
        "amountUndefined": "částka není definována",
        "supplyEndsIn": "končí za {days} dnů",
        "medicineLbl": "Lék",
        "runningOutStocks": "Vaše zásoby docházejí. Požádejte svého vedoucího lékaře o vystavení receptu.",
        "update": "Aktualizace",
        "save": "Uložit",
        "askForPrescription": "Požádejte o recept",
        "updateDrugStockLbl": "Aktualizace zásob drog",
        "updateDrugStockLblDesc": "Spočítejte si aktuální zásoby léků a vložte hodnotu do níže uvedeného pole.",
        "drugStockUpdateHelpLbl": "Zadejte zásoby drog od začátku dneška. Bude aktualizováno automaticky každé ráno s dávkami z vaší aktuální protidrogové terapie.",
        "drugStockUpdated": "Zásoby drog pro {products} byly úspěšně aktualizovány",
        "drugStockRecalculationFailed": "Automatická aktualizace zásob léků se nezdařila. Zásobu léků můžete aktualizovat ručně.",
        "referrals": {
          "title": "Doporučení od lékaře",
          "appoint": "Jmenovat"
        },
        "nonepileptic": {
          "infoMsg": "Všechny konzultace jsou jednorázové návštěvy prováděné nejlepšími specialisty. Podrobné podmínky služby se seznamem dostupných lékařů jsou k dispozici v rámci procesu rezervace návštěvy.",
          "consultationsLbl": "konzultace",
          "specialistsLbl": "specialisté",
          "epileptLbl": "epileptolog",
          "neurosurLbl": "neurochirurg",
          "neurologLbl": "neurolog",
          "psychLbl": "psychiatr"
        },
        "dataNotFilled": {
          "title": "Vítejte v aplikaci Neuroterminál!",
          "footer": "Vyberte, kdo bude pacientem Neurosphera",
          "patient": "Jsem pacient",
          "patientDescription": "Pro pacienty starší 18 let",
          "guardian": "Pacientem je dítě",
          "guardianDescription": "Pro pacienty, kteří jsou mladší 18 let nebo mají zákonného zástupce"
        },
        "yourLeadDoctor": "Váš vedoucí lékař",
        "addEvent": "Přidat událost",
        "showCalendar": "Zobrazit kalendář",
        "filesAdded": "Soubory přidány",
        "addFiles": "Přidat soubory",
        "showAllFiles": "Zobrazit všechny soubory",
        "surveyZeroDesc": "Průzkum vyplní lékař při návštěvě Průzkum 0",
        "showSurveyZero": "Ukaž průzkum",
        "confirmDeletionTitle": "Smazání události",
        "confirmDeletionText": "Chcete tuto událost smazat?",
        "noLeadDoctor": "Vedoucího lékaře si vyberete po návštěvě nula",
        "notAvailableNow": "Není dostupný",
        "carePlan": {
          "title": "Plán péče",
          "date": "datum",
          "incomingVisitsAndReferrals": "Příchozí návštěvy a doporučení",
          "incomingVisitsAndReferralsDesc": "Zde se zobrazí vaše příchozí schůzky a doporučení lékaře",
          "bookFirstVisit": "Zarezervujte si první návštěvu",
          "archiveVisits": "Archiv návštěv",
          "archiveVisitsDesc": "Zde uvidíte již uskutečněné návštěvy",
          "scheduledDate": "navrhované datum",
          "getSummaryPdf": "získat doporučení z návštěvy",
          "cancelReferral": "odstranit doporučení",
          "changeDate": "změnit datum",
          "cancelVisit": "zrušit návštěvu",
          "payForVisit": "zaplatit za návštěvu",
          "startingAt": "Začátek v",
          "goToVisit": "Zadejte návštěvu",
          "showMoreArchiveVisits": "Zobrazit všechny návštěvy archivu",
          "cancelReferralConfirmation": "Opravdu chcete toto doporučení odstranit?",
          "cancelReferralSuccess": "Doporučení bylo odstraněno",
          "chooseDate": "Vyberte datum",
          "change": "Změňte plán péče",
          "reassignVisit": "změnit lékaře",
          "chooseDateFreeReferral": "Vyberte si termín návštěvy zdarma",
          "suggestedDate": "navrhované datum",
          "referralRemoved": "Odstraněné doporučení",
          "downloadSummary": "Stáhnout souhrn",
          "description": "Popis",
          "filters": {
            "canceled": "Představení zrušeno",
            "visits": "Zobrazit návštěvy",
            "messages": "Zobrazit zprávy",
            "referrals": "Zobrazit doporučení"
          }
        },
        "doctorCare": "Zdravotní péče",
        "neuroscreen": "Neuroscreen",
        "doctorOnDuty": "Doktor ve službě"
      },
      "patientAccount": {
        "info": {
          "general": "Údaje o pacientech",
          "infoLbl": "Peronální data",
          "avatarLbl": "Avatar",
          "nameLbl": "název",
          "lastNameLbl": "Příjmení",
          "sexLbl": "Sex",
          "emailLbl": "E-mailem",
          "phoneLbl": "Telefonní číslo",
          "peselLbl": "Pesel",
          "otherDocumentLbl": "Jiný dokument",
          "documentTypeLbl": "Typ dokumentu",
          "documentNumberLbl": "Číslo dokumentu",
          "birthDate": "Datum narození",
          "repLbl": "Oficiální reprezentativní údaje",
          "repLblShort": "Zákonný zástupce",
          "teleAddrLbl": "Data tel. Adresy",
          "bioMedLbl": "Biomeická data",
          "bloodTypeLbl": "Krevní skupina",
          "rhTypeLbl": "RH",
          "heightLbl": "výška",
          "weightLbl": "Hmotnost",
          "iceLbl": "ICE (V případě nouze - v bezprostředních situacích)",
          "iceShortLbl": "LED",
          "icePers1Lbl": "Osoba, která má zavolat 1",
          "icePers1PhoneLbl": "Osoba 1 telefon č. 1",
          "icePers2Lbl": "Osoba, která má zavolat 2",
          "icePers2PhoneLbl": "Osoba 2 telefon č",
          "clearBtn": "Průhledná",
          "saveBtn": "Potvrdit",
          "dataSavedMsg": "Data uložena",
          "entitledStatementMsg": "Prohlašuji, že mám plná zákonná práva",
          "legalGuardianMsg": "Prohlašuji, že jsem zákonné zastoupení oprávněné zastupovat pacienta jako:",
          "invalidPeselWarnMsg": "Pokud je PESEL nesprávný, pohlaví a datum narození nebudou uloženy",
          "fromNsph": "Byl pacient léčen v CTP Neurosphera?",
          "hasEpilepsy": "Má pacient epilepsii?",
          "incompleteFormWarningMsg1": "Nebyla vyplněna všechna pole",
          "incompleteFormWarningMsg2": "Opravdu chcete uložit neúplný formulář? Nezapomeňte, že pokud nevyplníte všechna povinná pole, nebudete si moci rezervovat návštěvu nula.",
          "yesLbl": "Ano",
          "noLbl": "Ne",
          "invoiceBuyer": {
            "title": "Fakturační údaje",
            "checkboxLbl": "Chci pro fakturu nastavit různá data",
            "firm": "Firma",
            "person": "Soukromá osoba",
            "firmLbl": "Jméno firmy",
            "nipLbl": "Nip",
            "firstNameLbl": "Jméno",
            "lastNameLbl": "Příjmení",
            "streetLbl": "ulice",
            "buildingLbl": "Budova",
            "zipcodeLbl": "Poštovní směrovací číslo",
            "cityLbl": "Město",
            "comment": "Komentář"
          },
          "generalDesc": "Potřebujeme nějaké vaše podrobnosti. Toto je formální požadavek. Pamatujte, že jsou právně a technicky chráněny!",
          "generalDescLegal": "Potřebujeme také podrobnosti o našem pacientovi. Jedná se o formální požadavek, ale také o schopnost správně vystavit recept, osvědčení nebo doporučení.",
          "nameLblRich": "<b>jméno</b> pacienta",
          "lastNameLblRich": "<b>příjmení</b> pacienta",
          "peselLblRich": "<b>Pesel</b> pacienta",
          "parentLbl": "Rodičovská data",
          "repLblDesc": "Potřebujeme o vás, milý rodiči, více informací, abychom se o vás mohli postarat v souladu s požadavky zákona. Pamatujte, že vaše data jsou právně a technicky chráněna!",
          "repNameLbl": "<b>Jméno</b> rodiče nebo zákonného zástupce",
          "repLastNameLbl": "<b>Příjmení</b> rodiče nebo zákonného zástupce",
          "repPhoneLbl": "<b>Telefon</b> rodiče nebo zákonného zástupce",
          "repPeselLbl": "<b>PESEL</b> rodiče nebo zákonného zástupce",
          "teleAddrLblDesc": "Správné kontaktní údaje jsou nezbytné, aby lékař mohl vystavit recept, potvrzení nebo doporučení.",
          "bioMedLblDesc": "Následující informace nejsou povinné. Můžete je doplnit později. Pamatujte, že poskytnutí biometrických údajů pomáhá lékaři vybrat správnou dávku léků.",
          "others": "Jiní",
          "othersDesc": "Následující údaje nejsou povinné, pokud nám chcete zanechat informace o tom, koho máme informovat v případě nouze.",
          "addIceContact": "Přidejte další osobu",
          "nsphSourceQuestion": "Jak jste se dozvěděli o Neurosphera?",
          "nsphSourceDoctorDescription": "Uveďte prosím jméno lékaře",
          "incompleteFormConfirmBtn": "Doplňte údaje",
          "address": {
            "streetLbl": "Pacientova <b>ulice</b>",
            "cityLbl": "<b>město</b> pacienta",
            "countryLbl": "<b>země</b> pacienta"
          },
          "leaveDialog": {
            "title": "Nedokončili jste zadávání údajů, opravdu se chcete odhlásit?",
            "subtitle": "Při příštím přihlášení je budete muset zadat znovu",
            "footer": "V případě jakýchkoliv dalších dotazů nebo v případě problémů s vyplněním přihlášky nás kontaktujte:"
          },
          "contactEmailLbl": "Další e-mailová adresa pro příjem systémových zpráv",
          "contactEmailDesc": "Uvedená adresa bude k dispozici pro recepci kliniky. Chcete-li také dostávat upozornění ze systému na danou adresu, označte příslušné body:",
          "contactVisitNotification": "Návštěvy - platba, potvrzení",
          "contactEmailPlaceholder": "Emailová adresa",
          "contactDescriptionPlaceholder": "Popis adresy",
          "contactReferralNotification": "Doporučení - plán péče",
          "addAdditionalContactEmail": "Přidejte další e-mailovou adresu",
          "documentCountry": "Země",
          "partner": "Partner NSPH"
        },
        "bioMed": {
          "saveSuccess": "Zmiany zostały zapisane",
          "fullInfo": "Zobrazit všechna data"
        },
        "bioMedEditDialog": {
          "title": "Editace biomedicínských dat pacienta",
          "subtitle": "Při změně hodnoty se data automaticky uloží"
        }
      },
      "visitReservation": {
        "title": "Návštěva rezervace",
        "consultationTitle": "Rezervace konzultace",
        "specializationChoice": "Volba specializace",
        "doctorChoice": "Volba lékaře",
        "slotChoice": "Výběr slotu",
        "summaryAndPayment": "Shrnutí a platba",
        "noServicesAvailable": "Nové návštěvy epilepsie budete moci jmenovat po návštěvě, kterou jste již jmenovali",
        "goToVisitsList": "Přejít na seznam návštěv",
        "appointConsultation": "Domluvte si konzultaci / EEG",
        "leadDoctor": "vedoucí lékař",
        "next": "další",
        "choose": "Vybrat",
        "back": "Zadní",
        "doctor": "Doktor",
        "service": "Servis",
        "price": "Cena",
        "payment": "Platební formulář",
        "date": "datum",
        "pay": "Platit",
        "noSlotsAvailable": "Nejsou k dispozici žádné sloty",
        "doctors": "Lékaři",
        "availableSlots": "Dostupné sloty",
        "summary": "souhrn",
        "serviceDescription": "Popis",
        "eegDescription": "V systému Neuroterminal se přihlásíte ke standardnímu testu EEG trvajícímu 30 minut. Test se provádí v centru pro léčbu epilepsie Neurosphera. Pokud se chcete přihlásit k nestandardnímu testu EEG nebo k úpravě EEG (VEEG, DEEG, PSG nebo jiné), domluvte si schůzku telefonicky se sekretariátem centra pro epilepsii Neurosphera na čísle +48 609 463 191",
        "serviceTypeSelection": {
          "label": "Typ návštěvy",
          "epilepsy": "Návštěva epilepsie",
          "consultation": "Konzultace / EEG"
        },
        "sorting": {
          "label": "Seřadit",
          "alpha": "Abecedně",
          "teleconferencePriceAsc": "Cena telekonference: vzestupně",
          "teleconferencePriceDesc": "Cena telekonference: sestupně",
          "stationaryPriceAsc": "Cena stacionární návštěvy: vzestupně",
          "stationaryPriceDesc": "Cena stacionární návštěvy: sestupně",
          "bestMatch": "Nejlepší zápas"
        },
        "serviceDescriptions": {
          "neurosurgeon": {
            "title": "Kdo je neurochirurg?",
            "description": "Chirurg, který se specializuje na diagnostiku a chirurgickou léčbu některých onemocnění nervového systému (mozek, mícha, periferní nervy), u nichž farmakologická léčba nezabrala, by měl být proveden chirurgický zákrok.",
            "bulletsTitle": "Kdy se poradit s neurochirurgem?",
            "bullet1": "Výskyt neurologických problémů, u nichž farmakologická léčba nefungovala, např. diskopatie, chronické bolesti a poranění zad, mozkové nádory a neoplastické léze páteře, hemangiomy atd.",
            "bullet2": "Silné a dlouhotrvající bolesti hlavy a závratě",
            "bullet3": "Neznámá příčina necitlivosti nebo brnění končetin",
            "bullet4": "Výskyt časté synkopy",
            "bullet5": "Přítomnost poškození neznámé příčiny, vize, sluchu",
            "bullet6": "Prevalence parézy končetin"
          },
          "neuropsychologist": {
            "title": "Kdo je neuropsycholog?",
            "description": "Specialista na klinickou psychologii, zabývající se vztahem mezi fungováním mozku a lidským chováním. Činnost neuropsychologa je zaměřena především na osoby s poškozením mozku způsobeným různými nemocemi, úrazy nebo úrazy.",
            "bulletsTitle": "Kdy se obrátit na neuropsychologa?",
            "bullet1": "Když je pacientovi diagnostikováno problémy s normálním fungováním, jako jsou poranění hlavy, mozkové nádory, nemoci, které ničí nervovou tkáň. např. Alzheimerova nebo Parkinsonova nemoc",
            "bullet2": "Dochází k poškození paměti",
            "bullet3": "Problémy se soustředěním",
            "bullet4": "Výskyt poruch řeči",
            "bullet5": "Obtížnost s orientací, hledání cesty",
            "bullet6": "Navzdory normálním očním výsledkům dochází k poruchám zraku",
            "bullet7": "Objevují se změny nálady, úzkost, bludy",
            "bullet8": "V návykových stavech"
          },
          "neurologopedist": {
            "title": "Kdo je neurolog?",
            "description": "Specialista na diagnostiku a léčbu poruch řeči a komunikace neurologického původu. Neurolog může pracovat jak s dětmi, které mají opožděný vývoj řeči nebo poruchy řeči na různém pozadí, tak s dospělými pacienty.",
            "bulletsTitle": "Kdy se obrátit na neurologa?",
            "bullet1": "Když je dítěti diagnostikována neurologická porucha, genetický syndrom nebo porucha autistického spektra",
            "bullet2": "Když rodič zpozoruje u svého dítěte psychomotorickou retardaci",
            "bullet3": "Když má dítě potíže s řečí-dvouleté nebo starší dítě vůbec nemluví, mluví málo nebo má řečovou vadu",
            "bullet4": "Když dítě nadměrně slintá, má problémy s jídlem, nezavírá pusu ani dýchá nosem",
            "bullet5": "Když má dospělý poruchu řeči po mrtvici nebo nehodě",
            "bullet6": "Když má dospělý poruchy řeči v důsledku stařecké demence, Parkinsonovy choroby, Alzheimerovy choroby nebo roztroušené sklerózy"
          },
          "psychiatrist": {
            "title": "Kdo je psychiatr?",
            "description": "Lékař zabývající se problémy duševního zdraví - duševními poruchami a nemocemi, a to jak z hlediska diagnostiky, tak další terapie. Psychiatr může provádět farmakologickou léčbu a doporučit další konzultace jiným specializacím.",
            "bulletsTitle": "Kdy se obrátit na psychiatra?",
            "bullet1": "Když jsou dlouho otravní: smutek, utrpení, apatie, nedostatek energie, bezradnost, pesimistická vize budoucnosti",
            "bullet2": "Nástup sebevražedných myšlenek",
            "bullet3": "Při problémech a poruchách spánku",
            "bullet4": "Pro úzkostné poruchy",
            "bullet5": "Nástup hyperaktivity, nepřirozeně zvýšená nálada a zmatenost",
            "bullet6": "Výskyt problémů s koncentrací a zhoršení paměti",
            "bullet7": "Vyhýbání se kontaktu a odchodu do důchodu",
            "bullet8": "V případě bludů ovlivňujících vnímání reality a halucinace"
          },
          "epileptologist": {
            "title": "Kdo je epileptolog?",
            "description": "Neurolog specializující se na diagnostiku a léčbu epilepsie. Epileptolog se může specializovat na diagnostiku a léčbu epilepsie u dětí nebo dospělých. Lékař kromě výběru terapie koordinuje terapeutický proces a odkazuje pacienta také na lékaře jiných specializací.",
            "bulletsTitle": "Kdy se obrátit na epileptologa?",
            "bullet1": "Výskyt náhlého záchvatu, při kterém pacient ztratí vědomí a upadne a svaly se napnou. Objevují se křeče a pacient může přestat dýchat",
            "bullet2": "Dojde k náhlé ztrátě kontaktu s okolím a pacient nehybně zmrzne",
            "bullet3": "Svalové křeče se objevují bez vědomí",
            "bullet4": "Svalová relaxace, bezvědomí, pacientský pád"
          },
          "eeg": {
            "title": "Kdo provádí test EEG?",
            "description": "Test EEG provádí technik specializující se na tento obor na žádost epileptologa, neurologa nebo psychiatra. Odpovědný za plánování a provádění výzkumu všech modalit u dospělých a dětí. Výzkum se provádí v režimu probuzení, usínání a spánku. Mohou být také provedeny u pacienta doma. Test trvá přibližně 30 minut.",
            "bulletsTitle": "Kdy je test EEG nezbytný?",
            "bullet1": "Test EEG je základní test v diagnostice epilepsie",
            "bullet2": "EEG se často používá k označení příčin poruch spánku",
            "bullet3": "Může být uveden do provozu pro poranění hlavy",
            "bullet4": "Mezi další indikace patří zhoršení zraku, koktání, opakující se bolesti hlavy, mdloby nebo ztráta vědomí"
          },
          "visit0": {
            "bulletsTitle": "Co je průzkum 0?",
            "bullet1": "<b>Předchází a připravuje</b> 1 návštěvu u lékaře specialisty na neurosféru",
            "bullet2": "Je to <b>podrobný rozhovor s vaší anamnézou</b>",
            "bullet3": "Provozuje ho<b> Clinical Resident </b>, kterého pro vás individuálně vybereme",
            "bullet4": "<b>Připravte se na to.</b> Připomeňte si, jaké léky jste užili, jak často se záchvaty objevují, jak probíhají a jaká je jejich povaha",
            "bullet5": "Lékař sestaví digitální dokumentaci vašeho případu formou strukturovaného rozhovoru. Digitální data organizovaná tímto způsobem může náš robot Ictal analyzovat a navrhnout diagnózu a terapii během návštěvy 1",
            "bullet6": "Délka <b>až 60 minut</b>",
            "bullet7": "Můžete si vybrat typ návštěvy - <b>online nebo stacionární</b>"
          },
          "visit1": {
            "bulletsTitle": "Co je návštěva 1?",
            "bullet1": "Je to návštěva klinického specialisty na neurosféru dle vašeho výběru",
            "bullet2": "Lékař uvidí výsledky průzkumu 0, výsledky analýzy našeho robota Ictal a vámi přiložené materiály, které mu umožní efektivně potvrdit diagnózu a terapii",
            "bullet3": "Lékař vám v případě potřeby nabídne další testy",
            "bullet4": "Lékař vám nabídne komplexní plán péče",
            "bullet5": "Délka <b>až 30 minut</b>",
            "bullet6": "Můžete si vybrat typ návštěvy - <b> online nebo stacionární</b>"
          },
          "vns": {
            "title": "Co je návštěva VNS?",
            "description": "Při návštěvě VNS váš lékař zkontroluje vaše aktuální nastavení. Na základě historie zaznamenaných záchvatů vybere vhodnou terapii VNS. Lékař při návštěvě mění nastavení stimulace pomocí počítače, konfiguračního softwaru a programovacího dálkového ovladače. Lékař může snížit nebo zvýšit proud a zvýšit nebo snížit dobu nečinnosti zařízení. Prohlídka trvá 15 minut.",
            "bulletsTitle": "Kdy rezervovat schůzku VNS?",
            "bullet1": "Když terapie VNS zahrnuje postupnou změnu aktuálních parametrů",
            "bullet2": "Když pacient zaznamená významný nárůst počtu záchvatů",
            "bullet3": "Když pacient zaznamená výrazné zhoršení zdraví a pohody",
            "bullet4": "Když si pacient všimne, že implantovaný stimulátor již nefunguje, tj. již nevysílá pulzy",
            "bullet5": "Před absolvováním jakýchkoli lékařských testů, které mohou ovlivnit nebo být ovlivněny zařízením VNS, jako je zobrazování magnetickou rezonancí (MRI)"
          },
          "dietician": {
            "title": "Kdo je dietolog?",
            "description": "Specialista, který vystudoval vyšší vzdělání se zdravotnickým nebo nutričním (zemědělským) profilem. Má dostatečné znalosti pro provádění diet u různých skupin pacientů a při vydávání doporučení zohledňuje mimo jiné pohlaví, věk, váhu a výšku, nemoci včetně alergií a preference pacientů. Pro každého pacienta tedy vytváří individuální dietní plán a dohlíží na něj. V závislosti na výsledcích testů nebo informací získaných od pacienta zavádí potřebné úpravy. Úzká spolupráce s pacientem a jeho lékařem je proto nesmírně důležitá.",
            "bulletsTitle": "Kdy se poradit s dietologem?",
            "bullet1": "Když lékař Neurosphery navrhl léčbu ketogenní dietou",
            "bullet2": "Pokud si myslíte, že ketogenní dieta může být ve vašem případě možností léčby (POZNÁMKA - návštěva lékaře je také nutná, pokud jste pacientem s Neurospherou, zeptejte se svého lékaře na tuto možnost léčby, pokud již pacientem s neurosférou nejste , domluvte si návštěvu ZERO)",
            "bullet3": "Když držíte ketogenní dietu a chcete si ověřit vaši současnou léčbu (POZNÁMKA - nutná je i návštěva lékaře)",
            "bullet4": "Když držíte ketogenní dietu – kontrolní návštěva (dietolog vás během návštěvy bude informovat o nejvhodnější době se přihlásit)",
            "bullet5": "Když si chcete ověřit svůj aktuální jídelníček"
          },
          "neurologist": {
            "title": "Kdo je neurolog?",
            "description": "Specialista, který sleduje reakce pacientova těla, vyšetřuje funkce nervové soustavy, kontroluje i reakce lidského těla na podněty. Zabývá se nemocemi periferního a centrálního nervového systému. Neurolog se zaměřuje především na nemoci, které jsou způsobeny procesy, které ničí nervový systém člověka. Vyšetří podklady vznikajících neurologických onemocnění, stanoví diagnózu a vhodnou léčbu. Zabývá se léčbou nemocí jako je ischias, degenerace páteře, epilepsie, Parkinsonova choroba, Alzheimerova choroba, Huntingtonova chorea, cévní mozkové příhody a stavy po cévních mozkových příhodách, migrény a další bolesti hlavy.",
            "bulletsTitle": "Kdy se obrátit na neurologa?",
            "bullet1": "Při mdlobách a ztrátě vědomí",
            "bullet2": "Když se prudce zhoršuje paměť a jsou problémy s koncentrací",
            "bullet3": "Když máte problémy se spánkem",
            "bullet4": "Když jsou problémy s rovnováhou a koordinací",
            "bullet5": "Pokud se objeví brnění v končetinách spolu s narušeným pocitem",
            "bullet6": "Pokud je chronická bolest spojená se závratí"
          },
          "geneticist": {
            "title": "Kdo je genetik?",
            "description": "Klinický genetik je lékař, jehož úkolem je určit, zda je onemocnění dědičné a jaký mechanismus vedl k dědičnosti abnormality. Genetik také zjišťuje pravděpodobnost výskytu stejných vad nebo onemocnění v následujících generacích. Jeho účelem je také identifikovat rodinné příslušníky nemocného, u kterých se může projevit dědičné onemocnění. Úlohou genetiky je také informovat rodinu o možnostech prevence a léčby onemocnění a poskytovat jim genetické poradenství. Důležitým prvkem je také provádění odborné léčby dědičného onemocnění.",
            "bulletsTitle": "Kdy se obrátit na genetika?",
            "bullet1": "Když je ve vaší rodině nemoc se známými nebo suspektními genetickými příčinami",
            "bullet2": "Kdy chcete provést test WES",
            "bullet3": "Když chcete vyloučit vrozené onemocnění, které může být příčinou epilepsie",
            "bullet4": "Když plánujete těhotenství a chcete zjistit, zda vaše dítě nemůže mít také epilepsii"
          },
          "absent": {
            "title": "Co je absence konzultace?",
            "description": "Absence konzultace je typ lékařské návštěvy, kterou vybraný specialista na Neurosphera provádí bez přítomnosti pacienta. Absenční konzultace je zaměřena na rozbor zdravotní dokumentace (propouštěcí karty, výsledky genetických testů a další) nebo doplňkové vyšetření (EEG, MRI a další). Specialista na neurosféru provede analýzu dokumentace, provede příslušnou poznámku a doporučení pro pacienta. Skutečnost, že se konala, a její výsledek budou uloženy v archivu návštěv pacienta. POZNÁMKA: nepřítomná konzultace trvá 30 minut a probíhá bez účasti pacienta.",
            "bulletsTitle": "Kdy se mohu přihlásit ke konzultaci v nepřítomnosti?",
            "bullet1": "Budete přesměrováni, abyste se přihlásili na nepřítomnou konzultaci během návštěvy 1 nebo později - pokud lékař takovou konzultaci uzná za vhodnou. Jeho účelem je provedení dodatečného klinického rozboru, který přesahuje časový rámec nebo vyžaduje konzultaci s jiným lékařem (příklad: ukáže se například, že je potřeba konzultovat metabolický test s vybraným specialistou nebo několik MRI vyšetření v průběhu je třeba analyzovat období několika let).",
            "bullet2": "Obvykle má klinický specialista během 30minutové konzultace možnost vidět dva podpůrné testy s popisy."
          },
          "metabolic": {
            "title": "Co je to metabolická konzultace?",
            "description": "Metabolické poradenství se týká vzácných onemocnění - vrozených poruch metabolismu, což jsou genetická onemocnění. Diagnostika metabolických onemocnění zahrnuje především četná vyšetření a laboratorní vyšetření, často podpořená odbornými vyšetřeními.",
            "bulletsTitle": "Kdy se poradit?",
            "bullet1": "Na metabolickou konzultaci se vyplatí přijít, pokud je podezření na onemocnění v genetických testech, nebo pokud jsou testy: tandemová krevní hmotnostní spektrometrie (tandemová RS, RS / RS) nebo profil organických kyselin v moči (GC-MS test) nesprávný.",
            "bullet2": "Některá onemocnění spojená s epilepsií mohou mít metabolický základ, např. deficit pyruvátdehydrogenázy (PDHD), deficit transportéru glukózy do mozku (GLUT-1 DS) nebo vybraná mitochondriální onemocnění."
          },
          "veegOnStandby": {
            "title": "Co je vyšetření VEEG v bdělém stavu?",
            "description": "VEEG je záznam synchronizovaného EEG a videa v reálném čase. Pro správnou diagnózu potřebuje lékař vědět, co se s pacientem během záchvatů děje. Důležité jsou všechny detaily: jakým směrem se otáčí hlava, kterým směrem \"ubíhají\" oční bulvy, kde dochází k záchvatům atd. Videometrické vyšetření umožňuje lékaři vidět pacienta v tento pro diagnózu onemocnění zásadní okamžik. Vyšetření se provádí ve speciálně připravené laboratoři. Vyšetření v bdělém stavu se provádí během dne. Pacient podle pokynů lékaře rozhodne, jak dlouho má vyšetření trvat. Pacient má k dispozici křeslo a postel ve vyšetřovně. Pacient může během vyšetření používat toaletu připoutaný k EEG přístroji. Po vyšetření pacient obdrží CD s nahraným záznamem. Popis vyšetření je zahrnut v ceně.",
            "bulletsTitle": "Jak se připravit na vyšetření?",
            "bullet1": "Pacient by měl mít čistou hlavu",
            "bullet2": "Před vyšetřením by se na vlasy neměl aplikovat žádný gel ani lak na vlasy",
            "bullet3": "Užívejte léky podle pokynů svého lékaře",
            "bullet4": "Spánkovou deprivaci neprovádějte bez domluvy s lékařem – pacient přichází ve spánku"
          },
          "veegDuringSleep": {
            "title": "Co je vyšetření VEEG během spánku?",
            "description": "VEEG je záznam synchronizovaného EEG a videa v reálném čase. Pro správnou diagnózu potřebuje lékař vědět, co se s pacientem během záchvatů děje. Důležité jsou všechny detaily: jakým směrem se otáčí hlava, kterým směrem \"ubíhají\" oční bulvy, kde dochází k záchvatům atd. Videometrické vyšetření umožňuje lékaři vidět pacienta v tento pro diagnózu onemocnění zásadní okamžik. Vyšetření se provádí ve speciálně připravené laboratoři. Vyšetření spánku se provádí večer nebo v noci. Jak dlouho má vyšetření trvat, rozhoduje pacient podle pokynů lékaře. Pacient má k dispozici křeslo a postel ve vyšetřovně. Pacient může během vyšetření používat toaletu připoutaný k EEG přístroji. Velké děti a dospělí leží sami na posteli, malé děti s doprovodem, čekárna je k dispozici obsluze. Po vyšetření pacient obdrží CD s nahraným záznamem. Popis vyšetření je zahrnut v ceně.",
            "bulletsTitle": "Jak se připravit na vyšetření?",
            "bullet1": "Pacient by měl mít čistou hlavu",
            "bullet2": "Před vyšetřením by se na vlasy neměl aplikovat žádný gel ani lak na vlasy",
            "bullet3": "Užívejte léky podle pokynů svého lékaře",
            "bullet4": "Spánkovou deprivaci neprovádějte bez domluvy s lékařem – pacient přichází ve spánku",
            "bullet5": "Není na škodu vzít si s sebou z domova oblíbený polštář"
          },
          "veegNight": {
            "title": "Co je VEEG test?",
            "description": "VEEG je záznam synchronizovaného EEG a videa v reálném čase. Pro správnou diagnózu potřebuje lékař vědět, co se s pacientem během záchvatů děje. Důležité jsou všechny detaily: jakým směrem se otáčí hlava, kterým směrem \"ubíhají\" oční bulvy, kde dochází k záchvatům atd. Videometrické vyšetření umožňuje lékaři vidět pacienta v tento pro diagnózu onemocnění zásadní okamžik. Vyšetření se provádí ve speciálně připravené laboratoři. Pacient má v ordinaci k dispozici křeslo a postel. Vyšetření začínáme od okamžiku, kdy pacient usne, dále procházíme jednotlivými fázemi a nakonec zaznamenáváme okamžik probuzení. Pacient má možnost během vyšetření použít toaletu a přitom zůstat připojen k EEG přístroji. Velké děti a dospělí leží sami na posteli, malé děti s pečovatelkou, k dispozici je čekárna. Po vyšetření pacient obdrží CD s nahraným záznamem. Popis vyšetření je zahrnut v ceně.",
            "bulletsTitle": "Jak se připravit na vyšetření?",
            "bullet1": "Pacient by měl mít čistou hlavu",
            "bullet2": "Před vyšetřením by se na vlasy neměl aplikovat žádný gel ani lak na vlasy",
            "bullet3": "Užívejte léky podle pokynů svého lékaře",
            "bullet4": "Spánkovou deprivaci neprovádějte bez domluvy s lékařem – pacient přichází ve spánku",
            "bullet5": "Není na škodu vzít si s sebou z domova oblíbený polštář"
          },
          "veegDay": {
            "title": "Co je to 24hodinový test VEEG?",
            "description": "Test VEEG je záznam synchronizované studie EEG a videa v reálném čase. Pro správnou diagnózu potřebuje lékař vědět, co se s pacientem během záchvatů děje. Důležité jsou všechny detaily: jakým směrem se otáčí hlava, kterým směrem \"ubíhají\" oční bulvy, kde dochází k záchvatům atd. Videometrické vyšetření umožňuje lékaři vidět pacienta v tento pro diagnózu onemocnění zásadní okamžik. Vyšetření se provádí ve speciálně připravené laboratoři. Pacient má v ordinaci k dispozici křeslo a postel. Běžně vyšetření začíná nočním vyšetřením a poté pacienta sledujeme přes den. Pacient má možnost během vyšetření použít toaletu a přitom zůstat připojený k EEG přístroji. Stravování je v ceně vyšetření. Velké děti a dospělí leží sami na posteli, malé děti s doprovodem, k dispozici čekárna. Po vyšetření pacient obdrží CD s nahraným záznamem. Popis vyšetření je zahrnut v ceně.",
            "bulletsTitle": "Jak se připravit na vyšetření?",
            "bullet1": "Pacient by měl mít čistou hlavu",
            "bullet2": "Před vyšetřením by se na vlasy neměl aplikovat žádný gel ani lak na vlasy",
            "bullet3": "Užívejte léky podle pokynů svého lékaře",
            "bullet4": "Spánkovou deprivaci neprovádějte bez domluvy s lékařem – pacient přichází ve spánku",
            "bullet5": "Není na škodu vzít si s sebou z domova oblíbený polštář",
            "bullet6": "Další podrobnosti lze domluvit s technikem na telefonním čísle recepce ordinace +48 609 463 191"
          },
          "eegConsultation": {
            "title": "Co je to EEG vyšetření?",
            "description": "Standardní EEG vyšetření se provádí, když je pacient vzhůru. Technik vybere vhodnou čepici a umístí elektrody na hlavu pacienta. Pro kvalitu vyšetření je důležité, aby přiléhaly co nejtěsněji k pokožce hlavy. Samotné vyšetření trvá cca 20 minut. Ihned po vyšetření se pacient může vrátit k normální činnosti. Po vyšetření pacient obdrží CD s nahraným záznamem. Popis vyšetření je zahrnut v ceně.",
            "bulletsTitle": "Jak se připravit na vyšetření?",
            "bullet1": "Pacient by měl mít čistou hlavu",
            "bullet2": "Před vyšetřením by se na vlasy neměl aplikovat žádný gel ani lak na vlasy",
            "bullet3": "Užívejte léky podle pokynů svého lékaře",
            "bullet4": "Spánkovou deprivaci neprovádějte bez domluvy s lékařem – pacient přichází ve spánku"
          },
          "sleepDisturbance": {
            "title": "Co je konzultace týkající se poruchy spánku?",
            "description": "Poruchy spánku mohou být primární (vyplývající z abnormalit v samotném spánku) i příznakem onemocnění z jiných systémů. Někdy je indikována psychiatrická konzultace. Během návštěvy bude proveden podrobný rozhovor, který bude zahrnovat průběh noci a harmonogram dne. Pro takovou návštěvu se vyplatí připravit si spánkový deník, pokrývající minimálně 5 dní a zaznamenat případné rušivé epizody vyskytující se v noci pomocí domácích metod. Po konzultaci se posoudí, zda je problém fyziologický či nikoliv. Pokud je diagnostikována porucha spánku, bude vám nabídnuta léčba – léčba nebo doporučení ke specialistovi, který se zabývá základním problémem.",
            "bulletsTitle": "Kdy se poradit?",
            "bullet1": "Pokud jste během dne ospalí nebo podráždění",
            "bullet2": "Pokud trpíte nespavostí nebo nadměrnou ospalostí způsobenou emočními faktory",
            "bullet3": "Pokud pozorujete poruchy cirkadiánního rytmu spánku a probouzení",
            "bullet4": "Pokud chrápete, když spíte"
          },
          "regularVisitEegPackage": {
            "title": "Jaká je běžná návštěva + EEG balíček?",
            "description": "EEG test – je standardní test k posouzení bioelektrické aktivity mozku, na základě kterého se posuzuje správnost jeho činnosti. Vyšetření trvá cca 20 minut. Po vyšetření jde pacient na vizitu. PRAVIDELNÁ stacionární návštěva - každá další návštěva. Vydrží až 30 minut. Během návštěvy lékař provede analýzu EEG testu, stanoví konečnou diagnózu a analyzuje data shromážděná v systému. Pacient obdrží CD s nahraným EEG testem a doporučeními z pravidelné návštěvy.",
            "bulletsTitle": "Kdy se přihlásit k odběru balíčku?",
            "bullet1": "EEG test je základním vyšetřením v diagnostice epilepsie",
            "bullet2": "Pacient trpící epilepsií by měl pravidelně každý rok provádět EEG vyšetření a pravidelně navštěvovat epileptologa",
            "bullet3": "Pokud plánujete stacionární návštěvu lékaře, vyplatí se provést EEG k posouzení bioelektrické aktivity mozku",
            "bullet4": "Necítíte žádné zlepšení nebo zhoršení svého zdravotního stavu, chcete se se svým lékařem poradit o změně léčby"
          },
          "adhdPackage": {
            "title": "Jak se ADHD diagnostikuje?",
            "description": "Diagnostika ADHD se skládá ze 2 setkání s neuropsychologem, každé v délce 1,5 hodiny, během kterých je veden klinický rozhovor a neuropsychologické testy. Po schůzkách psycholog podá posudek a doporučí, za kým by měl pacient jít, zda k neurologovi (30 minut) nebo psychiatrovi (45 minut). Při příslušné návštěvě pacient obdrží diagnózu a doporučení pro další léčbu (farmakologickou léčbu nebo psychologickou terapii).",
            "bulletsTitle": "Kdy se přihlásit k odběru ADHD balíčku?",
            "bullet1": "Pokud jsou potíže s organizací času a úkolů. Je pro vás obtížné začít a dokončit projekty, plánovat a udržovat pořádek",
            "bullet2": "Pokud se vyskytnou emocionální potíže, máte sklony ke změnám nálad, frustraci a máte potíže s ovládáním svých emocí. Zažíváte silné reakce na stresové situace",
            "bullet3": "Když na sobě zpozorujete mezilidské obtíže. Jste neklidní, impulzivní a máte potíže brát v úvahu pohledy ostatních lidí",
            "bullet4": "Pokud je pro vás obtížné odhadnout čas potřebný k dokončení úkolu"
          },
          "standardVisit": {
            "title": "Co je to běžná návštěva?",
            "description": "PRAVIDELNÁ návštěva – každá další návštěva. Vydrží až 30 minut. Během návštěvy lékař shromažďuje rozhovor a analyzuje kalendář událostí pacienta. Lékař přezkoumá zdravotnickou dokumentaci pacienta a může navrhnout další vyšetření. Po každé návštěvě dostává pacient doporučení z návštěvy, plán terapie a plán další péče. Pacient si může vybrat typ návštěvy – online nebo stacionární.",
            "bulletsTitle": "Jak vypadá běžná návštěva?",
            "bullet1": "Diskutujte o svém aktuálním zdravotním stavu - zapamatujte si datum posledního útoku a svou pohodu - doplňte kalendář akcí",
            "bullet2": "Seznámení s výsledky nedávných testů - pokud jste v nedávné době prováděli testy, ujistěte se, že všechny výsledky jsou přiloženy ke zdravotnické dokumentaci",
            "bullet3": "Diskuse o další terapii",
            "bullet4": "Sestavení komplexního plánu péče",
            "bullet5": "Vystavení receptu, doporučení, nemocenská, potvrzení"
          },
          "standardVisit15": {
            "title": "Co je to běžná návštěva?",
            "description": "PRAVIDELNÁ návštěva – každá další návštěva. Vydrží až 30 minut. Během návštěvy lékař shromažďuje rozhovor a analyzuje kalendář událostí pacienta. Lékař přezkoumá zdravotnickou dokumentaci pacienta a může navrhnout další vyšetření. Po každé návštěvě dostává pacient doporučení z návštěvy, plán terapie a plán další péče. Pacient si může vybrat typ návštěvy – online nebo stacionární.",
            "bulletsTitle": "Jak vypadá běžná návštěva?",
            "bullet1": "Diskutujte o svém aktuálním zdravotním stavu - zapamatujte si datum posledního útoku a svou pohodu - doplňte kalendář akcí",
            "bullet2": "Seznámení s výsledky nedávných testů - pokud jste v nedávné době prováděli testy, ujistěte se, že všechny výsledky jsou přiloženy ke zdravotnické dokumentaci",
            "bullet3": "Diskuse o další terapii",
            "bullet4": "Sestavení komplexního plánu péče",
            "bullet5": "Vystavení receptu, doporučení, nemocenská, potvrzení"
          },
          "caseConference": {
            "title": "Co je případová konference?",
            "description": "Konference o lékařském případu mezi lékařskými specialisty a lékařskými partnery Neurosphery je kolektivní setkání s cílem prodiskutovat klinický případ pacienta a stanovit optimální plán léčby. Konzultace je organizována v situacích, kdy je k dosažení nejlepších výsledků léčby nutná pomoc nebo další odborné znalosti. Jde o typ mezioborové lékařské konference, při které dochází k výměně poznatků a názorů mezi odborníky."
          },
          "mmpi2Package": {
            "title": "Co je to osobnostní test MMPI-2?",
            "description": "Umožňujeme diagnostikovat poruchy osobnosti pomocí nejrozsáhlejšího a univerzálního nástroje klinického hodnocení dostupného v Polsku – Minnesota Multidimensional Personality Inventory (úplná adaptace MMPI-2 byla vydána v roce 2012). Test obsahuje deset kontrolních škál, kterými lze popsat spolehlivost získaného výsledkového profilu a zdůvodnit jejich klinickou interpretaci. Kontrolní škály mohou také informovat diagnostika o motivaci subjektu, míře adaptace a možných obranných mechanismech.",
            "bulletsTitle": "Co je to osobnostní test MMPI-2?",
            "bullet1": "Samotný inventář může být použit při klinickém popisu osobnostních problémů, ale může být také užitečný při identifikaci osob, které nejsou vhodné pro výkon konkrétní profesní funkce. Lze jej použít k diagnostice dospělých, kteří dosáhli alespoň 6 let formálního vzdělání. Test musí být proveden pod dohledem psychologa. Na provedení testu a jeho dokončení byste si měli vyhradit přibližně 90 minut. Při dokončování testu se musíte 567 odkázat na příkazy výběrem TRUE/FALSE. Samotnému vyplnění testu by měla předcházet zhruba 50minutová schůzka, na které se shromáždí data z rozhovoru a test se předloží testované osobě. Do týdne, po absolvování návštěvy a vyplnění testu, obdrží vyšetřovaná osoba zprávu se shrnutím získaných výsledků."
          },
          "cbtPackage": {
            "title": "Co je CBT terapie pro nespavost?",
            "description": "Kognitivně behaviorální terapie (CBT) pro nespavost je považována za léčbu první linie pro lidi bojující s nespavostí, doporučovaná úřady, jako jsou National Institutes of Health (NIH), National Institute for Health and Care Excellence (NICE) a American College. lékařů. CBT-I je účinnou alternativou léků na spaní, která nabízí trvalá řešení změnou spánkových návyků a myšlení. Celý proces CBT-I zahrnuje pět sezení rozložených do šesti týdnů, ale zlepšení lze obvykle zaznamenat dříve. Relace mohou probíhat stacionárně nebo online. Asi 75 % lidí s chronickou nespavostí pociťuje výrazné zlepšení spánku a 90 % omezuje nebo úplně přestává užívat léky na spaní.",
            "bulletsTitle": "CBT-I terapie se doporučuje lidem, kteří:",
            "bullet1": "Trpí chronickou nespavostí: Problémy se spánkem se objevují nejméně třikrát týdně po dobu nejméně tří měsíců.",
            "bullet2": "Mají potíže s usínáním: V noci se snaží usnout více než 30 minut.",
            "bullet3": "Noční probuzení: Pravidelně se probouzejí uprostřed noci a mají potíže s usínáním po dobu delší než 30 minut.",
            "bullet4": "Díky tomu se během dne cítí unavení a podráždění: Nespavost ovlivňuje jejich každodenní pohodu a kvalitu života.",
            "bullet5": "Hledají alternativu k práškům na spaní: Chtějí se vyhnout závislosti na farmakoterapii a hledají dlouhodobá přírodní řešení."
          },
          adhdConsultation: {
              title: 'Proč to stojí za to?',
              description: 'Individuální koučování je intenzivní hodinové setkání se zkušeným ADHD koučem zaměřené na vaše aktuální výzvy a cíle. Toto nejsou teoretické rozhovory – sezení je naplánováno tak, aby vám poskytlo konkrétní praktické nástroje, které můžete okamžitě implementovat. Získáte hodinu plného zaměření na vaše potřeby, bez obecných rad a „jednoho řešení pro všechny“. Tato podpora je přizpůsobena specifičnosti ADHD a vašim individuálním cílům – abyste okamžitě pocítili rozdíl ve svém životě.',
              bulletsTitle: 'Co získáte během jednoho sezení?',
              bullet1: '<b>Analýza aktuálních výzev</b> – kouč vám pomůže pochopit, které obtíže přímo vyplývají z ADHD a jak je efektivně překonat.',
              bullet2: '<b>Praktické strategie</b> – naučíte se konkrétní techniky, které můžete okamžitě začít používat ke zlepšení koncentrace, organizace a time managementu.',
              bullet3: '<b>Individuální akční plán</b> – vytvoříte si plán kroků, které musíte podniknout před dalším sezením, což vám pomůže zůstat motivovaní a vidět rychlé výsledky.',
          },
          psychotherapy: {
              title: 'Co je to psychoterapeutické sezení?',
              description: 'Psychoterapeutické sezení je setkání pacienta (klienta) s psychoterapeutem, jehož cílem je společné řešení emočních, psychických či behaviorálních problémů. Cílem sezení je pomoci vám porozumět, přijmout a transformovat vaše myšlenky, emoce nebo chování. Může zahrnovat práci na traumatech, úzkostech, depresích, stresu nebo problémech ve vztazích.',
              bulletsTitle: 'Kdy se přihlásit na relaci?',
              bullet1: 'Psychoterapie se doporučuje v mnoha situacích, kdy někdo zažívá emocionální, duševní nebo sociální potíže, které ovlivňují každodenní fungování a kvalitu života. Může také pomoci při rozvoji sebeuvědomění a zlepšení vztahů s ostatními.',
          }
        },
        "visitTypeChoice": "Typ návštěvy",
        "selectPreviousSlots": "Volná místa budou prezentována po výběru předchozího termínu",
        "allDoctors": "Všichni lékaři",
        "paymentLinkSent": "Platební odkaz byl odeslán pacientovi",
        "openPaymentLink": "Otevřete platební odkaz",
        "linkCopied": "Odkaz byl zkopírován do schránky",
        "visitPackageDescriptions": {
          "startPackage1": "Jako nový pacient s Neurosphera nebo rodič pacienta musíte mít schůzku 1 s klinickým specialistou. Bude mu předcházet Průzkum 0, který nám umožní shromáždit informace pro účinnou diagnostiku a terapii. To vše za jednu dostupnou cenu.",
          "startPackage2": "Vyberte lékaře specialistu, který uvidí návštěvu 1 a zda bude online nebo stacionární. Pozor na rozdíly v cenách a umístění stacionární návštěvy.",
          "startPackage3": "Průzkum 0 předchází a připravuje se na návštěvu 1. Bude se konat s klinickým rezidentem neurosféry dle našeho výběru. Vyberte si typ návštěvy, který vám vyhovuje (online nebo stacionárně) a její termín. Pamatujte, že průzkum 0 trvá až 60 minut.",
          "startPackage4": "Provedení platby nám umožňuje rezervovat si schůzku u daného lékaře za vás. Nezapomeňte, že datum můžete kdykoli změnit zdarma!"
        },
        "slotChoiceDescriptions": {
          "VISIT_1": "Návštěva 1 se uskuteční po průzkumu 0. Klinický specialista na neurosféru a typ návštěvy, který jste již vybrali v předchozím kroku. Lékař stanoví diagnózu a navrhne vhodnou terapii. Zpracuje také individuální plán péče. Pamatujte, že návštěva 1 trvá až 30 minut."
        },
        "calculateInstallments": "Platit na splátky",
        "priceFrom": "Cena od",
        "payments": {
          "directPayment": "S {provider} můžete platit bezpečně:",
          "creditCard": "Kreditní karta",
          "blik": "Blik",
          "onlineTransfer": "Online převod",
          "onlineApplication": "Online aplikace",
          "mobilePayments": "Mobilní platby",
          "payLater": "Zaplaťte později"
        },
        "expand": "Rozšířit",
        "approve": "Potvrdit",
        "cancel": "zrušení",
        "fold": "Složit",
        "cancelAndChangeDate": "Zrušit / změnit datum",
        "visitDuration": "Délka návštěvy",
        "durationSelectDesc1": "Vyšetření může trvat od",
        "durationSelectDesc2": "na",
        "durationSelectDesc3": "hodin",
        "durationSelectDesc4": "Chcete-li pokračovat ve schůzce, vyberte počet hodin",
        "durationTime": "Čas vyšetření",
        "serviceGroupDescriptions": {
          "eeg_consultation": {
            "groupSelectionTitle": "Dostupné vyšetření EEG/VEEG",
            "title": "Kdo provádí vyšetření EEG?",
            "description": "Vyšetření EEG provádí technik se specializací v tomto oboru na žádost epileptologa, neurologa nebo psychiatra. Zodpovědný za plánování a provádění výzkumu všech modalit u dospělých a dětí. Výzkum se provádí v režimu probuzení, usínání a spánku. Mohou být také prováděny u pacienta doma. Test trvá přibližně 30 minut.",
            "bulletsTitle": "Kdy je nutné vyšetření EEG?",
            "bullet1": "EEG test je základním vyšetřením v diagnostice epilepsie",
            "bullet2": "EEG se často používá k indikaci příčin poruch spánku",
            "bullet3": "Může být uveden do provozu pro poranění hlavy",
            "bullet4": "Mezi další indikace patří zrakové postižení, koktání, opakované bolesti hlavy, mdloby nebo ztráta vědomí"
          }
        },
        "allWorkplaces": "Všechny lokality",
        "allTechniciansIntro": "Vyšetření provede jeden z našich zkušených techniků",
        "markedAsPaid": "Návštěva byla jmenována a označena jako placená",
        "markAsPaid": "Označit jako zaplacené",
        "stage": "Etapa",
        "sum": "Součet",
        "freeAppointmentPrice": "Volný, uvolnit",
        "appointmentSuccess": "Wizyta została umówiona",
        "sameDateAsPrevious": "Návštěva by měla být ve stejný den jako předchozí návštěva balíčku",
        "refundRestrictions": "Zásady zrušení návštěvy",
        "refundRestrictionsAccept": "Přijímám storno podmínky",
        "supervisorDoctor": "návštěva supervizora",
        "supervisorDoctorAbbr": "Vedoucí",
        "doctorPackageDescription": "Balíček Lékaři je návštěva za účasti 2 a více lékařů. Lékař provádějící vizitu vykonává veškeré činnosti související s návštěvou. Pozorující lékař návštěvu pozoruje a může se zapojit do klinické diskuse s ošetřujícím lékařem a pacientem.",
        "specialist": "Specialista v"
      },
      "paymentOutcome": {
        "title": "Potvrzení platby",
        "loading": "Načítání platebních údajů",
        "status": {
          "title": "Postavení",
          "types": {
            "pending": {
              "title": "Probíhá zpracování platby",
              "subtitle": "Platba zatím není potvrzena",
              "comment": "Chvíli počkejte, než se platba dokončí"
            },
            "success": {
              "title": "Služba byla zaplacena",
              "subtitle": "Platba byla potvrzena",
              "comment": "Děkuji za platbu"
            },
            "error": {
              "title": "Služba nebyla zaplacena",
              "subtitle": "Platba byla zrušena",
              "comment": "Opakujte prosím proces platby"
            }
          }
        },
        "details": {
          "title": "Podrobnosti",
          "date": "datum",
          "name": "Název služby",
          "doctor": "Doktor"
        }
      },
      "visitPaymentOutcome": {
        "title": "Návštěva rezervace",
        "subtitle": "potvrzení",
        "details": "podrobnosti",
        "loading": "Načítají se data návštěv ...",
        "card": {
          "successTitle": "Vaše návštěva byla zaplacena",
          "successLabel1": "Děkujeme za platbu",
          "successLabel2": "O návštěvě budete informováni 24 hodin předem",
          "failTitle": "Vaše návštěva nebyla zaplacena",
          "failLabel1": "V procesu platby došlo k chybě",
          "retryPayment": "Zopakovat platbu",
          "toGoMessageView": "Přejít na zobrazení zpráv",
          "paymentPending": "Vaše platba zatím nebyla potvrzena. Může to trvat až 1 minutu",
          "paymentConfirmed": "Platba byla potvrzena!",
          "successLabel1Package": "Děkuji! Je o vás dobře postaráno!"
        },
        "goHome": "Přejít na hlavní stránku",
        "visitScope": "Rozsah návštěvy",
        "scopeBullets": {
          "visit0Bullet1": "Doba trvání cca. 60 minut",
          "visit0Bullet2": "Vedená klinickým lékařem",
          "visit0Bullet3": "Rozhovor s lékařem o zdravotních, biometrických a osobních údajích pacienta",
          "visit0Bullet4": "Analýza epilepsie a komorbidní historie",
          "visit0Bullet5": "Analýza terapeutické historie",
          "visit0Bullet6": "Na základě shromážděných údajů bude možné zpočátku stanovit funkční diagnózu a určit rozsah zdravotního problému pacienta",
          "migrationVisitBullet1": "Určeno pro pacienty, kteří byli nebo jsou v naší péči před rokem 2021",
          "migrationVisitBullet2": "Ověření zdravotních, biometrických a osobních údajů pacienta",
          "migrationVisitBullet3": "Přechod na nový systém péče",
          "visit1Bullet1": "Trvání od 30 do 60 minut",
          "visit1Bullet2": "Analýza ICtal® a počáteční rozpoznávání počítače",
          "visit1Bullet3": "Počáteční diagnostika a výběr ošetřujícího lékaře",
          "visit1Bullet4": "Vypracování plánu počáteční léčby s plánem vyšetření a dalších aktivit",
          "visit1Bullet5": "Vývoj plánu klinického počítačového pozorování ICtal Move® a počátečního plánu počítačové terapie ICtal®",
          "visit1Bullet6": "Diskuse o předběžných jednáních",
          "standardVisitBullet1": "Trvání od 30 do 45 minut",
          "standardVisitBullet2": "Stanovení konečné diagnózy",
          "standardVisitBullet3": "Neustálá analýza dat shromážděných v systému ICtal®",
          "standardVisitBullet4": "Schopnost zasáhnout v případě, že nedojde ke zlepšení nebo zhoršení - rady ICtal® nebo rady",
          "standardVisit15Bullet1": "Trvání 15 minut",
          "standardVisit15Bullet2": "Stanovení konečné diagnózy",
          "standardVisit15Bullet3": "Neustálá analýza dat shromážděných v systému ICtal®",
          "standardVisit15Bullet4": "Schopnost zasáhnout v případě, že nedojde ke zlepšení nebo zhoršení - rady ICtal® nebo rady",
          "consultationBullet1": "Trvání od 45 do 60 minut",
          "consultationBullet2": "Návštěva lékaře jiného zaměření",
          "consultationBullet3": "Zdravotní historie",
          "consultationBullet4": "Analýza historie epilepsie a komorbidit",
          "consultationBullet5": "Poskytněte lékařskou pomoc ve specializaci konzultantského lékaře",
          "messageBullet1": "Pamatujte, že zpráva je krátká textová otázka, na kterou lékař odpoví co nejdříve během pracovní doby.",
          "messageBullet2": "V rámci poplatku můžete zvolit odeslání pouze jedné zprávy - otázky. Když lékař odpoví, máte možnost položit další doplňující otázku, na kterou lékař znovu odpoví a vlákno automaticky skončí.",
          "messageBullet3": "Pozornost! Lékař má právo odmítnout vám poradit jako součást zprávy, pokud se domnívá, že váš dotaz je způsobilý pro pravidelnou návštěvu. Zpráva není určena k pomoci v nouzových situacích nebo život ohrožujících situacích!",
          "visit0Bullet7": "Můžete si vybrat typ návštěvy - <b>online nebo stacionární</b>"
        }
      },
      "awaitingPaymentNotAvailable": {
        "title": "Rezervace návštěvy se nezdařila!",
        "subtitle": "Omluvám se za nepříjemnost",
        "ageDescription": "Vámi vybraný lékař pacienty tohoto věku nepřijímá",
        "changeDescription": "Vyberte jiného lékaře a poté se objednejte",
        "action": "Vyberte lékaře a termín návštěvy"
      },
      "neuroscreen": {
        "main": {
          "title": "Klinika Neurosphera vytváří novou kvalitu v léčbě pacientů!",
          "subtitle1": "Efektivně spojujeme znalosti a dlouholeté zkušenosti s nutností neustálého vývoje a zdokonalování našich léčebných metod!",
          "subtitle2": "Připravili jsme pro vás anketu, která vám na klinice Neurosphera pomůže vybrat správného lékaře a správný plán péče.",
          "subtitle3": "Vyplněním průzkumu si jej lékař před návštěvou přečte a bude vědět, na jaký problém se hlásíte.",
          "subtitle4": "Vyplnění dotazníku trvá přibližně 5 minut.",
          "fillNeuroscreen": "Vyplňte průzkum",
          "fillNeuroscreenDescription": "Chci dokončit průzkum a zadat plán péče o pacienta",
          "goToReservation": "Vyberte lékaře",
          "goToReservationDescription": "Nechci vyplňovat anketu, chci si hned vybrat lékaře"
        },
        "survey": {
          "title": "Průzkum pacientů Neuroscreen - Neurosphera",
          "subtitle1": "Po vyplnění dotazníku vám sdělíme, k jaké návštěvě a lékaři byste se měli objednat.",
          "subtitle2": "Informace, které zde poskytnete, budou sdíleny s vaším lékařem a pomohou vám správně diagnostikovat a léčit vás.",
          "next": "Další",
          "prev": "Zadní",
          "save": "Kompletní průzkum",
          "form": {
            "section": {
              "HEADACHES": "Bolesti hlavy",
              "MOVEMENT_ISSUES": "Poruchy pohybu",
              "MEMORY_ISSUES": "Poruchy paměti",
              "EATING_ISSUES": "Poruchy příjmu potravy",
              "CONSCIOUSNESS_ISSUES": "Poruchy vědomí nebo vědomí",
              "PSYCHOMOTOR_DEVELOPMENT_ISSUES": "Poruchy psychomotorického vývoje",
              "EPILEPSY": "Epilepsie a záchvaty",
              "SPINE_ISSUES": "Problémy s páteří",
              "MUTIPLE_SCLEROSIS": "Roztroušená skleróza",
              "DIZZINESS": "Závrať",
              "AUTISM": "Autistické spektrum",
              "OTHER_SYMPTOMS": "Jiné příznaky"
            },
            "adult": {
              "field": {
                "headachesGeneral": "Pociťujete bolesti hlavy?",
                "headaches1": "Máte neustálou tupou bolest hlavy?",
                "headaches2": "Pociťujete pulzující bolest, obvykle na jedné straně hlavy?",
                "headaches3": "Jsou vaše bolesti hlavy doprovázeny citlivostí na světlo, zvuk, nevolností nebo zvracením?",
                "headaches4": "Pociťujete poruchy zraku, jako jsou záblesky světla nebo skvrny před očima, než začne bolest hlavy?",
                "headaches5": "Když vás bolí hlava, pociťujete zarudnutí očí, slzení nebo otok víčka na straně bolesti nebo ucpaný nos?",
                "movementGeneral": "Zaznamenali jste třes končetin, svalovou ztuhlost nebo potíže s pohybem?",
                "movement1": "Pociťujete třes rukou nebo jiných částí těla?",
                "movement2": "Zhoršuje se třes v klidu?",
                "movement3": "Cítíte ztuhlost svalů, která ztěžuje pohyb?",
                "movement4": "Všimli jste si zpomalení ve svých každodenních činnostech?",
                "movement5": "Zaznamenali jste nějaké problémy s chůzí, jako jsou malé krůčky nebo nečekané pády?",
                "memoryIssuesGeneral": "Máte potíže se zapamatováním nových informací, hledáním slov nebo prostorovou orientací?",
                "memoryIssues1": "Máte problém si vzpomenout na nedávné události?",
                "memoryIssues2": "Zapomínáte někdy jména, data nebo máte problém najít správná slova při mluvení?",
                "memoryIssues3": "Potřebujete stále více pomoci s každodenními činnostmi jako je obsluha domácích spotřebičů, správa financí, plánování?",
                "memoryIssues4": "Máte potíže s orientací v místech, která jste dříve znali?",
                "memoryIssues5": "Pozorujete změny ve svém chování, jako je sociální stažení, apatie, impulzivita nebo nedostatek taktu?",
                "epilepsyGeneral": "Zažili jste někdy ztrátu vědomí, nekontrolovatelné křeče nebo krátké okamžiky pozastavení?",
                "epilepsy1": "Zažíváte náhlé, nevysvětlitelné výpadky proudu?",
                "epilepsy2": "Když ztratíte vědomí, cítíte se zmatení nebo máte potíže s okamžitým návratem k běžným činnostem?",
                "epilepsy3": "Jsou výpadky vědomí doprovázeny křečemi?",
                "epilepsy4": "Zažíváte před ztrátou vědomí/křeče nějaké specifické pocity nebo signály (např. „aura“)?",
                "epilepsy5": "Jsou chvíle, kdy náhle přestanete reagovat a na krátkou dobu „zamrznete“?",
                "spineIssuesGeneral": "Trpíte chronickými nebo akutními bolestmi zad nebo krku?",
                "spineIssues1": "Trpíte chronickými bolestmi zad nebo krku?",
                "spineIssues2": "Pociťujete necitlivost paží nebo nohou?",
                "spineIssues3": "Všimli jste si ztráty síly v rukou nebo nohou?",
                "spineIssues4": "Máte potíže s ovládáním močového měchýře nebo střev?",
                "spineIssues5": "Vyzařuje bolest z páteře do jiných částí těla?",
                "mutipleSclerosisGeneral": "Všimli jste si pocitu mravenčení, necitlivosti nebo jiných změn smyslových vjemů, které se mohou vyskytovat v různých částech těla?",
                "mutipleSclerosis1": "Zažíváte nečekaná období slabosti končetin nebo potíže s chůzí?",
                "mutipleSclerosis2": "Máte problémy s rovnováhou nebo koordinací?",
                "mutipleSclerosis3": "Zažíváte náhlé epizody zrakových poruch, jako je dvojité vidění nebo rozmazané vidění?",
                "mutipleSclerosis4": "Pociťujete neobvyklou únavu, která nesouvisí s cvičením?",
                "mutipleSclerosis5": "Pociťujete bolesti svalů a křeče?",
                "dizzinessGeneral": "Máte závratě nebo problémy s rovnováhou?",
                "dizziness1": "Objevuje se závratě náhle a bez varování?",
                "dizziness2": "Cítíte také nevolnost nebo zvracení, když se vám točí hlava?",
                "dizziness3": "Stává se závrať vážnější, když změníte polohu těla, jako je vstávání nebo záklon hlavy?",
                "dizziness4": "Souvisí výskyt závratí s bolestmi uší nebo změnami sluchu?",
                "dizziness5": "Je závratě doprovázena tinnitem nebo pocitem plnosti v uších?",
                "otherSymptomsGeneral": "Máte nějaké další příznaky, které nespadají do výše uvedených kategorií?",
                "otherSymptoms1": "Máte potíže s polykáním nebo se při jídle často dusíte?",
                "otherSymptoms2": "Máte problémy se spánkem, jako je nespavost nebo nadměrná denní ospalost?",
                "otherSymptoms3": "Máte periodické potíže se soustředěním nebo „mozkovou mlhu“, která vám brání soustředit se na úkoly?",
                "otherSymptoms4": "Všimli jste si, že vaše chrápání začíná nebo se zhoršuje během spánku?",
                "otherSymptoms5": "Cítíte někdy náhlou slabost v jedné části těla nebo na jedné straně těla?"
              }
            },
            "child": {
              "field": {
                "headachesGeneral": "Bolí vaše dítě hlava?",
                "headaches1": "Má vaše dítě neustále tupou bolest hlavy?",
                "headaches2": "Je to pulzující bolest, obvykle jednostranná?",
                "headaches3": "Je bolest doprovázena přecitlivělostí na světlo a zvuk, nevolností a zvracením?",
                "headaches4": "Má dítě před začátkem bolesti hlavy poruchy zraku, jako jsou záblesky světla nebo skvrny před očima?",
                "headaches5": "Když vás bolí hlava, pociťujete zarudnutí očí, slzení nebo otok víčka na straně bolesti nebo ucpaný nos?",
                "movementGeneral": "Má vaše dítě nějaké pohybové poruchy: třes, ztuhlost, parézy?",
                "movement1": "Má vaše dítě třes?",
                "movement2": "Vyskytuje se tento třes v klidu?",
                "movement3": "Dochází k tomuto chvění při pohybu?",
                "movement4": "Má vaše dítě ztuhlost končetin, která se zhoršuje?",
                "movement5": "Pohybový vývoj vašeho dítěte nepostupuje nebo neustupuje?",
                "epilepsyGeneral": "Má vaše dítě záchvaty?",
                "epilepsy1": "Opakují se záchvaty?",
                "epilepsy2": "Jsou záchvaty různé nebo podobné?",
                "epilepsy3": "Dochází k záchvatům během bdění?",
                "epilepsy4": "Vyskytují se záchvaty během spánku (v noci nebo ve dne)?",
                "epilepsy5": "Jsou záchvaty generalizované?",
                "epilepsy6": "Jsou záchvaty fokální, tedy ne celého těla, např. jedna nebo dvě končetiny nebo polovina těla?",
                "epilepsy7": "Jsou záchvaty rytmické?",
                "consciousnessIssuesGeneral": "Trpí dítě poruchami vědomí a/nebo ztrátou vědomí?",
                "consciousnessIssues1": "Trpí dítě poruchou vědomí?",
                "consciousnessIssues2": "Zažívá dítě úplnou ztrátu vědomí s pádem?",
                "consciousnessIssues3": "Zažívá vaše dítě zavěšování nebo myšlenky?",
                "consciousnessIssues4": "Trvají stavy poruchy vědomí několik sekund?",
                "consciousnessIssues5": "Trvají stavy poruchy vědomí déle než 30 sekund?",
                "eatingIssuesGeneral": "Má vaše dítě poruchu příjmu potravy?",
                "eatingIssues1": "Má vaše dítě patologický nedostatek chuti k jídlu?",
                "eatingIssues2": "Má vaše dítě patologickou nadměrnou chuť k jídlu?",
                "autismGeneral": "Má dítě rysy nebo diagnózu poruchy autistického spektra?",
                "autism1": "Má dítě poruchu autistického spektra?",
                "autism2": "Má dítě podezření na autismus?",
                "psychomotorDevelopmentIssuesGeneral": "Má dítě poruchy psychomotorického vývoje?",
                "psychomotorDevelopmentIssues1": "Je porucha psychomotorického vývoje diagnostikována a potvrzena lékařem/lékařským střediskem?",
                "psychomotorDevelopmentIssues2": "Je porucha psychomotorického vývoje pouze suspektní a nepotvrzená?"
              }
            }
          }
        },
        "rating": {
          "title": "Děkujeme za vyplnění průzkumu!",
          "subtitle": "Neuroterminální systém vám řekne správného lékaře pro vaši nemoc",
          "showMore": "Zobrazit více",
          "diagnosis": {
            "HEADACHES": "<b>Bolesti hlavy jsou komplexní neurologický problém</b>, který může mít desítky různých podob a intenzit. <b>Tyto neduhy mohou být příznakem mnoha nemocí</b> nejen v oblasti neurologie, ale i v jiných oblastech medicíny. Je klíčové, aby se diagnostikou a léčbou bolestí hlavy zabýval neurolog – specialista na cefalologii, vědu věnující se této problematice. <br/><b>Centrum Neurosphera přistupuje k diagnostice a léčbě bolestí hlavy individuálně i jako tým.</b>",
            "MOVEMENT_ISSUES": "<b>Poruchy hybnosti jsou skupinou neurologických poruch, které se projevují různými problémy s pohybem.</b> Může se jednat o svalovou slabost a parézu, ale i třes, ztuhlost nebo spasticitu. Tato široká škála příznaků je důsledkem poškození nebo poruch ve fungování nervového systému. <b>Pokud zaznamenáte nějaké znepokojivé příznaky, je důležité navštívit specialistu,</b> protože včasná diagnóza a vhodná léčba jsou klíčem ke zlepšení kvality života pacienta. <br/><b>Neurosphera je schopna přesně diagnostikovat příčinu potíží a zvolit vhodné léčebné metody.</b>",
            "MEMORY_ISSUES": "<b>Poruchy paměti jsou často spojeny s demencí, kde jsou jedním z hlavních příznaků.</b> Problémy s pamětí se však mohou objevit v každém věku a být signálem různých onemocnění, od lehkých až po vážné. Proto je tak důležité <b>v případě jakýchkoli znepokojivých příznaků navštívit specialistu,</b> který komplexně posoudí fungování paměti a diagnostikuje možné příčiny. <br/><b>V centru Neurosphera má pacient možnost vybrat si specialistu na neuropsychologii, který provede příslušná vyšetření a diagnostikuje příčinu onemocnění, a neurologa, který pacientovi vybere vhodnou terapii.</ b>",
            "EATING_ISSUES": "<b>Poruchy příjmu potravy jsou komplexní zdravotní problémy, které vyžadují komplexní přístup.</b> Na jejich léčbě se podílejí různí odborníci, jako jsou dietologové, neurologové a psychiatři. Přestože jsou poruchy příjmu potravy často příznakem hlubších psychických nebo fyzických problémů, <b>vhodně zvolená strava a nutriční terapie mohou být důležitým prvkem léčby</b> ovlivňujícím fyzické i duševní zdraví pacienta. <br/><b>Centrum Neurosphera nabízí možnost diagnostiky problému poruch příjmu potravy s neurologem nebo dietologem, který pacientovi připraví jídelníček na míru.</b>",
            "CONSCIOUSNESS_ISSUES": "<b>Poruchy vědomí a uvědomění jsou stavy, které by měly vždy vzbuzovat obavy a vybízet k naléhavé konzultaci s neurologem.</b> Často jsou prvním příznakem vážného neurologického onemocnění nebo jiné vážné zdravotní poruchy. K přesnému určení příčiny těchto onemocnění <b>je nutné provést další testy, jako je EEG (elektroencefalografie) a MRI (zobrazování magnetickou rezonancí).</b> Tyto diagnostické metody umožňují posouzení elektrické aktivity mozku a detailní vizualizaci jeho struktur, která je klíčová pro stanovení správné diagnózy a nasazení vhodné léčby. <br/><b>Centrum Neurosphera přistupuje k diagnostice a terapii individuálně i jako tým. Klinika nabízí možnost provedení EEG testů.</b>",
            "PSYCHOMOTOR_DEVELOPMENT_ISSUES": "<b>Poruchy psychomotorického vývoje u dětí jsou komplexním klinickým problémem, který vyžaduje odborný a komplexní přístup.</b> Vzhledem k rozmanitosti symptomů a potenciálních příčin by diagnostiku a terapii měl provádět multidisciplinární tým specialistů. <br/><b>Centrum Neurosphera nabízí komplexní péči, která umožňuje širokou škálu testů a konzultací s různými odborníky.</b> Umožňuje přesné určení příčin poruch a zavedení vhodného terapeutického programu. Pro optimální vývoj dítěte je rozhodující včasná diagnostika a terapie.",
            "EPILEPSY": "<b>Léčba epilepsie v centru Neurosphera je naší specializací.</b> Máme 25 let zkušeností s diagnostikou a terapií tohoto onemocnění, což z nás dělá jedno z předních center v Polsku. <b>Náš tým složený z 20 specialistů využívá nejnovější diagnostické nástroje</b> včetně pokročilých systémů založených na umělé inteligenci, aby pacientům poskytoval komplexní péči. Díky tomu dokážeme efektivně diagnostikovat a léčit i ty nejsložitější případy epilepsie a nabídnout našim pacientům nejvyšší standard lékařské péče.",
            "SPINE_ISSUES": "Bolest páteře a zad je problém, který postihuje mnoho lidí a může mít různé příčiny. <b>Jde o stav, který vyžaduje komplexní přístup, spojující znalosti a zkušenosti neurologů a neurochirurgů.</b> <br/><b>V centru Neurosphera máme specializovaný tým, který se zabývá diagnostikou a léčba tohoto typu onemocnění.</b> Díky rozsáhlým znalostem a zkušenostem našich specialistů a dostupnosti moderních diagnostických metod jsme schopni přesně určit příčinu bolesti a navrhnout optimální léčbu. <b>Náš multidisciplinární přístup umožňuje komplexní posouzení pacienta a vypracování individuálního terapeutického plánu.</b>",
            "MUTIPLE_SCLEROSIS": "<b>Roztroušená skleróza je chronické neurologické onemocnění, jehož průběh je velmi individuální a může se mezi pacienty výrazně lišit.</b> Diagnostika RS je často náročná kvůli rozmanitosti příznaků, které mohou napodobovat jiná onemocnění. <b>Účinná léčba roztroušené sklerózy vyžaduje specializovaný přístup a spolupráci mnoha specialistů.</b> Terapie by měla být přizpůsobena individuálním potřebám každého pacienta a zahrnovat léčbu farmakologickou i nefarmakologickou. <b>Tým zkušených neurologů je nezbytný pro správnou diagnostiku a komplexní terapii u lidí s RS.</b>",
            "DIZZINESS": "<b>Závrať je stav, který je nejčastěji příznakem, nikoli samostatnou nemocí.</b> Může být způsoben různými faktory, jak laryngologickými (např. poruchy rovnováhy), tak neurologickými (např. poškozením centrálního nervového systému). <br/><b>Pacienti se závratěmi se často hlásí neurologovi a my v Centru Neurosphera jsme dokonale připraveni takové pacienty přijmout.</b> Nabízíme komplexní diagnostiku, která umožňuje přesné určení příčiny příznaků. <b>V některých případech může být nutná konzultace s neurochirurgem, zvláště když závratě souvisí s problémy s páteří.</b> Díky našemu multidisciplinárnímu přístupu jsme schopni našim pacientům poskytnout účinnou pomoc.",
            "AUTISM": "<b>Porucha autistického spektra je komplexní neurovývojová porucha, která zahrnuje potíže se sociální komunikací, sociální interakce a omezené a opakující se vzorce chování.</b> Autismus může mít mnoho podob, od mírné po těžkou, a často se vyskytuje společně s jiné poruchy. <b>Typický i atypický dětský autismus vyžaduje odbornou diagnostiku a komplexní terapeutický přístup.</b> <br/><b>V centru Neurosphera máme zkušený tým specialistů, kteří se zabývají diagnostikou a léčbou autismus a jeho komplikace.</b> Nabízíme širokou škálu služeb, přizpůsobených individuálním potřebám každého pacienta.",
            "OTHER_SYMPTOMS": "<b>Neurologické symptomy mohou být extrémně různorodé a často nejednoznačné.</b> Kromě charakteristických symptomů, jako je otupělost nebo poruchy rovnováhy, pacienti uvádějí také slabost, bolest, problémy se spánkem, podrážděnost a potíže se soustředěním. <b>Tato široká škála příznaků může ztížit přesnou diagnózu, protože mnohé z nich mohou indikovat různé neurologické stavy a někdy i poruchy s jinou příčinou.</b> <br/><b>Specializované centrum Neurosphera vybavení a zkušený tým,</b>díky kterému jsme schopni provést přesnou diagnostiku a indikovat příčinu i těch nejnezřetelnějších neurologických příznaků."
          }
        }
      }
    },
    "error": {
      "LOGIN_CRED_MISSING": "Přihlášení a heslo jsou povinné",
      "LOGIN_INVALID_CRED": "Neplatné uživatelské jméno či heslo",
      "PASS_AND_CONF_NOT_EQUAL": "Heslo a potvrzení se liší",
      "AUTHORIZATION": "neoprávněný přístup ke zdroji, směrování na přihlašovací stránku",
      "WRONG_PROFILE": "Přihlášení se nezdařilo, protože byl vybrán špatný profil"
    },
    "validation": {
      "required": "Políčko je vyžadováno",
      "alphanumeric": "Musí obsahovat pouze písmena a číslice",
      "email": "Neplatná emailová adresa",
      "invalidRange": "Neplatný rozsah",
      "nip": "Neplatné číslo NIP",
      "regon": "Neplatné číslo REGON",
      "pesel": "Neplatné číslo PESEL",
      "max": "Povoleno až {length} znaků",
      "min": "Je vyžadováno alespoň {length} znaků",
      "passNonMatching": "Hesla se neshodují",
      "maxFileSize": "Soubor by měl mít méně než {size} MB",
      "pwz": "Neplatné číslo PWZ",
      "zusCertificate": "Povolená přípona souboru je .pfx",
      "password": {
        "lowercase": "Je vyžadováno malé písmeno",
        "uppercase": "Je vyžadováno velké písmeno",
        "minimumChar": "Minimálně {length} znaků",
        "numericChar": "Je vyžadováno jedno číslo",
        "specialChar": "Je vyžadován speciální znak, dostupné znaky jsou!@$ \\ -_.*"
      }
    },
    "enums": Enums.cs,
    "serverErrors": ServerErrors.cs,
    "office": {
      "phone": "+48 609 463 191",
      "phoneHref": "tel:+48609463191",
      "mail": "biuro@neurosphera.pl",
      "mailHref": "mailto:biuro@neurosphera.pl"
    },
    "appointWidget": {
      "appoint": {
        "title": "Naplánujte si návštěvu",
        "visitTypeLabel": "Typ návštěvy",
        "visitLocationLabel": "Umístění",
        "doctorListDisclaimer": "Konečná cena závisí na typu návštěvy",
        "visitTypeListDisclaimer": "Konečná cena závisí na typu návštěvy",
        "doctorListHeader": "Vyberte si lékaře",
        "visitTypeListHeader": "Vyberte typ návštěvy",
        "visitLocationListHeader": "Vyberte místo návštěvy",
        "doctorLabel": "Doktor",
        "startingPricePrefix": "z",
        "appointment": {
          "selectHeader": {
            "START_PACKAGE": "Naplánujte návštěvu 0 a návštěvu 1",
            "MIGRATION_VISIT": "Naplánujte si migrační návštěvu",
            "STANDARD_VISIT": "Naplánujte si pravidelnou návštěvu",
            "NEUROSURGEON_CONSULTATION": "Naplánujte si neurochirurgickou konzultaci",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Domluvte si neuropsychologickou konzultaci",
            "NEUROLOGOPEDIST_CONSULTATION": "Domluvte si konzultaci logopeda (neurologického).",
            "PSYCHIATRIST_CONSULTATION": "Naplánujte si psychiatrickou konzultaci",
            "GENETICIST_CONSULTATION": "Naplánujte si konzultaci s genetikem",
            "EPILEPTOLOGIST_CONSULTATION": "Naplánujte si konzultaci s epileptologem",
            "EEG_CONSULTATION": "Naplánujte si EEG vyšetření",
            "NEUROLOGIST_CONSULTATION": "Domluvte si neurologickou konzultaci",
            "DIETICIAN_CONSULTATION": "Naplánujte si konzultaci s dietologem",
            "VNS_CONSULTATION": "Naplánujte si návštěvu VNS",
            "ABSENT_CONSULTATION": "Naplánujte si konzultace v nepřítomnosti",
            "METABOLIC_CONSULTATION": "Domluvte si metabolickou konzultaci",
            "SLEEP_DISTURBANCE_CONSULTATION": "Naplánujte si konzultaci ohledně poruchy spánku",
            "ADHD_PACKAGE": "Naplánujte si návštěvy balíčku ADHD",
            "REGULAR_VISIT_EEG_PACKAGE": "Naplánujte si pravidelnou návštěvu epilepsie a EEG",
            ADHD_CONSULTATION: 'Koučovací sezení ADHD',
            PSYCHOTHERAPY_CONSULTATION: 'Psychoterapeutické sezení'
          },
          "description": {
            "START_PACKAGE": "Návštěva 0 je bezplatná návštěva za účelem získání podrobného rozhovoru o pacientově lékařské anamnéze, předcházející návštěvě 1. Během návštěvy 1 bude provedena diagnóza, terapie a komplexní plán péče na příští rok. Návštěva 0 je cca. 1 hodina, návštěva 1 - 30 minut; obojí může probíhat na místě nebo online.",
            "MIGRATION_VISIT": "Migrační návštěva",
            "STANDARD_VISIT": "Pravidelná epileptologická návštěva je určena pro současné pacienty s Neurosphera, kteří již absolvovali Návštěvu 1 nebo Migrační návštěvu.",
            "NEUROSURGEON_CONSULTATION": "Konzultace se doporučuje v případě chronických neurologických problémů, jako jsou:",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Uvedeno v případě:",
            "NEUROLOGOPEDIST_CONSULTATION": "Konzultace logopeda (neurologická).",
            "PSYCHIATRIST_CONSULTATION": "Návštěva pro lidi, kteří se potýkají s problémy duševního zdraví, včetně:",
            "GENETICIST_CONSULTATION": "Uvedeno v případě:",
            "EPILEPTOLOGIST_CONSULTATION": "Konzultace - epileptolog",
            "EEG_CONSULTATION": "Základní vyšetření v diagnostice epilepsie, dále:",
            "NEUROLOGIST_CONSULTATION": "Uvedeno v případě:",
            "DIETICIAN_CONSULTATION": "Návštěva pro lidi:",
            "VNS_CONSULTATION": "návštěva VNS",
            "ABSENT_CONSULTATION": "Konzultace v nepřítomnosti",
            "METABOLIC_CONSULTATION": "Indikováno v případě vrozených poruch metabolismu. Některá metabolická onemocnění mohou přispět ke vzniku epilepsie. Vyplatí se zhodnotit, zda existuje podezření na onemocnění v genetických testech.",
            "SLEEP_DISTURBANCE_CONSULTATION": "Uvedeno v případě:",
            "ADHD_PACKAGE": "Balíček obsahuje 2 návštěvy psychologa (každá v délce 1,5 hodiny) a návštěvu neurologa nebo návštěvu psychiatra. Balíček je určen lidem, kteří si všimnou:",
            "REGULAR_VISIT_EEG_PACKAGE": "Balíček obsahuje EEG test a pravidelnou stacionární návštěvu. Balíček je určen současným pacientům s neurosférou, kteří již absolvovali návštěvu 1 nebo migrační návštěvu. Přihlaste se k odběru balíčku, pokud:",
            "MMPI2_PACKAGE": "Umožňujeme diagnostikovat poruchy osobnosti pomocí nejrozsáhlejšího a univerzálního nástroje klinického hodnocení dostupného v Polsku – Minnesota Multidimensional Personality Inventory (úplná adaptace MMPI-2 byla vydána v roce 2012).",
            "CBT_PACKAGE": "Kognitivně behaviorální terapie (CBT) pro nespavost je považována za léčbu první linie pro lidi bojující s nespavostí, doporučovaná úřady, jako jsou National Institutes of Health (NIH), National Institute for Health and Care Excellence (NICE) a American College. lékaře.",
            ADHD_CONSULTATION: 'Individuální koučování je intenzivní hodinové setkání se zkušeným ADHD koučem zaměřené na vaše aktuální výzvy a cíle.',
            PSYCHOTHERAPY_CONSULTATION: 'Psychoterapeutické sezení je setkání pacienta (klienta) s psychoterapeutem, jehož cílem je společné řešení emočních, psychických či behaviorálních problémů.'
          },
          "bulletPoints": {
            "DIETICIAN_CONSULTATION": [
              "for whom the ketogenic diet is indicated by physician or is of interest",
              "who are using a ketogenic diet and want to review their current treatment",
              "they want to verify their current diet"
            ],
            "EEG_CONSULTATION": [
              "to indicate the causes of sleep disorders",
              "in head injuries and recurring headaches",
              "fainting or loss of consciousness"
            ],
            "NEUROLOGIST_CONSULTATION": [
              "fainting and loss of consciousness, problems with balance and coordination",
              "chronic headaches combined with dizziness",
              "deterioration of memory and trouble concentrating",
              "tingling in the extremities with a disturbed sensation"
            ],
            "PSYCHIATRIST_CONSULTATION": [
              "depression",
              "anxiety disorders",
              "sleep problems",
              "unnaturally elevated mood and hyperactivity"
            ],
            "GENETICIST_CONSULTATION": [
              "family history of diseases with genetic causes",
              "the exclusion of congenital disease as a cause of epilepsy",
              "assessment of the risk of a child having epilepsy in the event of a planned pregnancy",
              "before performing the WES test"
            ],
            "NEUROSURGEON_CONSULTATION": [
              "chronic pain and injuries of the spine",
              "brain tumors and neoplastic changes in the spine",
              "numbness, tingling and paresis of the limbs",
              "severe headaches and dizziness, visual and hearing disturbances",
              "consultation of magnetic resonance imaging of the head"
            ],
            "NEUROPSYCHOLOGIST_CONSULTATION": [
              "problems in normal functioning that have arisen as a result of, for example, head injuries, brain tumors, diseases that destroy nervous tissue",
              "impairment of memory or concentration",
              "speech, orientation or wayfinding difficulties",
              "visual disturbances despite normal ophthalmic results"
            ],
            "SLEEP_DISTURBANCE_CONSULTATION": [
              "daytime sleepiness or irritability",
              "sleepiness or insomnia caused by emotional factors",
              "disruption of the daily rhythm of sleep and waking up",
              "snoring while sleeping"
            ],
            "ADHD_PACKAGE": [
              "Difficulty Organizing",
              "Underestimating time",
              "interpersonal difficulties",
              "Emotional difficulties"
            ],
            "REGULAR_VISIT_EEG_PACKAGE": [
              "You haven't had an EEG done in the last year",
              "You are planning a regular stationary visit with an Egyptologist",
              "You feel a lack of improvement or deterioration of your health"
            ]
          },
          "detailsHeader": {
            "VISIT_0": "Navštivte 0",
            "MIGRATION_VISIT": "Migrační návštěva",
            "VISIT_1": "Návštěva 1",
            "START_PACKAGE": "Návštěva 0 a návštěva 1",
            "STANDARD_VISIT": "Pravidelná návštěva",
            "NEUROSURGEON_CONSULTATION": "Konzultace - neurochirurg",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Konzultace - neuropsycholog",
            "NEUROLOGOPEDIST_CONSULTATION": "Konzultace - logoped (neurolog)",
            "PSYCHIATRIST_CONSULTATION": "Konzultace - psychiatr",
            "GENETICIST_CONSULTATION": "Konzultace - genetik",
            "EPILEPTOLOGIST_CONSULTATION": "Konzultace - epileptolog",
            "EEG_CONSULTATION": "Naplánujte si vyšetření EEG",
            "NEUROLOGIST_CONSULTATION": "Konzultace - neurolog",
            "DIETICIAN_CONSULTATION": "Konzultace - dietolog",
            "VNS_CONSULTATION": "návštěva VNS",
            "ABSENT_CONSULTATION": "Konzultace v nepřítomnosti",
            "METABOLIC_CONSULTATION": "Metabolické poradenství",
            "SLEEP_DISTURBANCE_CONSULTATION": "Poradenství při poruchách spánku",
            "ADHD_PACKAGE": "ADHD balíček",
            "REGULAR_VISIT_EEG_PACKAGE": "Pravidelné návštěvy epilepsie a EEG",
            ADHD_CONSULTATION: 'Koučovací sezení ADHD',
            PSYCHOTHERAPY_CONSULTATION: 'Psychoterapeutické sezení'
          },
          "details": {
            "VISIT_0": "Návštěva 0 je přípravná návštěva, během níž je veden hloubkový, velmi podrobný rozhovor. Prohlídka trvá cca. 1 hodina. Návštěva 0 musí být provedena před návštěvou 1.",
            "MIGRATION_VISIT": "",
            "VISIT_1": "Na návštěvě 1 je potvrzena diagnóza a je stanoven komplexní plán péče. Prohlídka trvá cca. 30 minut.",
            "STANDARD_VISIT": "Délka prohlídky je cca 30 minut.",
            "NEUROSURGEON_CONSULTATION": "Délka konzultace je cca. 45 minut.",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Délka konzultace je cca 1 hodina.",
            "NEUROLOGOPEDIST_CONSULTATION": "Konzultace - logoped (neurolog)",
            "PSYCHIATRIST_CONSULTATION": "Délka konzultace je cca. 45 minut.",
            "GENETICIST_CONSULTATION": "Délka konzultace je cca. 45 minut.",
            "EPILEPTOLOGIST_CONSULTATION": "Konzultace - epileptolog",
            "EEG_CONSULTATION": "Naplánujte si vyšetření EEG",
            "NEUROLOGIST_CONSULTATION": "Délka konzultace je cca. 30 minut.",
            "DIETICIAN_CONSULTATION": "Délka konzultace je cca 1 hodina.",
            "VNS_CONSULTATION": "návštěva VNS",
            "ABSENT_CONSULTATION": "Konzultace v nepřítomnosti",
            "METABOLIC_CONSULTATION": "Délka konzultace je cca. 30 minut.",
            "SLEEP_DISTURBANCE_CONSULTATION": "Délka konzultace je cca. 45 minut.",
            ADHD_CONSULTATION: 'Délka sezení cca 1 hodina.',
            PSYCHOTHERAPY_CONSULTATION: 'Délka sezení cca 1 hodina.'
          }
        },
        "remoteVisitDescription": "Vzdálená návštěva přes Neuroterminál. Vyžaduje se zařízení s kamerou a mikrofonem a připojení k internetu",
        "details": "Podrobnosti",
        "visitHeader": {
          "VISIT_0": "Navštivte 0",
          "MIGRATION_VISIT": "Migrační návštěva",
          "VISIT_1": "Návštěva 1",
          "START_PACKAGE": "Návštěva 0 a návštěva 1",
          "STANDARD_VISIT": "Pravidelná návštěva",
          "NEUROSURGEON_CONSULTATION": "Konzultace - neurochirurg",
          "NEUROPSYCHOLOGIST_CONSULTATION": "Konzultace - neuropsycholog",
          "NEUROLOGOPEDIST_CONSULTATION": "Konzultace - logoped (neurolog)",
          "PSYCHIATRIST_CONSULTATION": "Konzultace - psychiatr",
          "GENETICIST_CONSULTATION": "Konzultace - genetik",
          "EPILEPTOLOGIST_CONSULTATION": "Konzultace - epileptolog",
          "EEG_CONSULTATION": "Naplánujte si vyšetření EEG",
          "NEUROLOGIST_CONSULTATION": "Konzultace - neurolog",
          "DIETICIAN_CONSULTATION": "Konzultace - dietolog",
          "VNS_CONSULTATION": "návštěva VNS",
          "ABSENT_CONSULTATION": "Konzultace v nepřítomnosti",
          "METABOLIC_CONSULTATION": "Metabolické poradenství",
          "SLEEP_DISTURBANCE_CONSULTATION": "Poradenství při poruchách spánku",
          "ADHD_PACKAGE": "ADHD balíček",
          "REGULAR_VISIT_EEG_PACKAGE": "Pravidelné návštěvy epilepsie a EEG"
        },
        "instruction": {
          "START_PACKAGE": "Pokud nevidíte termín, který je pro Vás vhodný - zkuste zvolit jiného lékaře, typ návštěvy nebo dřívější termín návštěvy 0"
        },
        "visitPriceLabel": "Cena návštěvy",
        "showAllSlots": "zobrazit více hodin",
        "hideSlots": "kolaps",
        "nextLabel": "další",
        "validationErrorHeader": "Chcete-li přejít na souhrn, vyberte:",
        "ok": "OK",
        "validationError": {
          "slot": "datum",
          "location": "Umístění",
          "doctor": "Doktor",
          "service": "Typ návštěvy"
        },
        "chooseLabel": "Vybrat",
        "doctorMissingLabel": "Doktor",
        "locationMissingLabel": "Typ návštěvy",
        "andLabel": "a",
        "unavailableDoctors": "Lékaři ve zvolený termín, typ a/nebo typ návštěvy nedostupní",
        "ADULT": "Dospělý",
        "CHILD": "Dítě",
        "visitNotAvailable": "Je nám líto, ale vámi vybrané datum <b>{visitName}</b> není k dispozici. Vraťte se a vyberte jiné datum",
        "visitNotSaved": "Návštěva zatím nebyla zaregistrována. Vraťte se na souhrn a dokončete proces rezervace schůzky.",
        "clearSelection": "Jasný výběr",
        "visitOrder": "Je to vaše první návštěva tohoto specialisty?",
        "visitOrderFirst": "Ano",
        "visitOrderNext": "Ne"
      },
      "exitPoll": {
        "title": "proč chceš odejít?",
        "description": "Vaše odpověď je pro nás velmi důležitá! Díky ní budeme moci zlepšovat naše služby.",
        "SERVICE": "Nenašel jsem službu, která mě zajímá",
        "DATE": "Žádné vhodné termíny návštěvy",
        "PRICE": "Nelíbí se mi cena služby",
        "OTHER": "jiný",
        "sendResponse": "Odeslat odpověď",
        "back": "Vraťte se na stránku",
        "whatService": "Jakou službu jste hledali?",
        "enterMessage": "Zadejte prosím svou zprávu",
        "reasonMissing": "Uveďte prosím důvod své rezignace"
      },
      "main": {
        "exitPopup": {
          "description": "Opravdu chcete ukončit proces jmenování? Váš výběr nebude uložen.",
          "confirm": "Ano, chci odejít",
          "decline": "Ne, vraťte se k domluvě"
        }
      },
      "summary": {
        "title": "Shrnutí",
        "grossPrice": "Celková částka",
        "availableOnlinePayments": "Přijímáme online platby",
        "transfer": "Bankovní převod",
        "installments": "Splátky 0 %.",
        "payment": "Platba",
        "paymentDescription": "Chcete-li pokračovat v platbě, zadejte svou e-mailovou adresu. Zašleme vám shrnutí a odkaz k platbě.",
        "descriptionExistingAccount": "Pokud máte účet v Neuroterminálu, uveďte prosím e-mailovou adresu, kterou používáte pro přihlášení.",
        "makeReservations": "Rezervujte si schůzky",
        "makeReservation": "Rezervovat schůzku",
        "unexpectedError": "Při pokusu o naplánování schůzky došlo k neočekávané chybě. Zkuste to prosím znovu."
      },
      "thanku": {
        "title": "Děkuju!",
        "emailHasBeenSent": "Na zadanou e-mailovou adresu jsme odeslali zprávu se shrnutím a odkazem na platbu:",
        "finalizeAppointment": "Přejděte na svůj e-mail a kliknutím na odeslaný platební odkaz <b>dokončete proces plánování schůzky</b>. Na dokončení procesu plánování schůzky máte čas do <b>{deadline}</b>.",
        "resendEmail": "Neobdrželi jste e-mail? Zkontrolujte složku Spam popř",
        "resendEmailLink": "Znovu odeslat e-mail",
        "resendEmailDone": "E-mail byl znovu odeslán",
        "backToNeurosphera": "Zpět na neurosphera.pl"
      }
    },
    "server": {
      "episode": {
        "description": "Epizoda záchvatu přidána během návštěvy"
      },
      "generatedepisode": {
        "description": "Epizoda záchvatu přidána během návštěvy"
      }
    },
    "dates": {
      "today": "dnes",
      "tomorrow": "zítra"
    }
  },
  "formats": {
    "currency": {
      "style": "měna",
      "currency": "americký dolar"
    }
  }
}