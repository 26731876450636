import Enums from "./enums/enums";
import ServerErrors from "./errors/server-errors";

export default {
  "translations": {
    "$vuetify": {
      "noDataText": "Немає даних",
      "dataFooter": {
        "itemsPerPageAll": "Покажи все",
        "pageText": "Навігація:"
      },
      "dataTable": {
        "sortBy": "Сортувати за",
        "ariaLabel": {
          "sortNone": "sortNone"
        },
        "itemsPerPageText": "На сторінку:"
      },
      "timepicker": {
        "am": "am",
        "pm": "вечора"
      },
      "dataIterator": {
        "loadingText": "Завантаження даних"
      },
      "badge": "значок",
      "datePicker": {
        "prevMonthAriaLabel": "Попередній місяць",
        "nextMonthAriaLabel": "Наступного місяця"
      }
    },
    "view": {
      "admin": {
        "settings": {
          "ewusPasswordChanged": "Пароль EWUŚ змінено",
          "save": "Зберегти",
          "ewusPasswordTitle": "Зміна пароля EWUŚ",
          "ewusPasswordLabel": "Новий пароль",
          "removeAccount": "Видалити обліковий запис",
          "registrationDate": "Дата Реєстрації",
          "registrationSource": "Зареєстровано",
          "registeredBy": {
            "PATIENT": "пацієнт",
            "DOCPLANNER": "Znany lekarz",
            "APPOINTING_WIDGET": "Віджет"
          },
          "deleteAccount": "Видалити обліковий запис",
          "accountNotForDeletion": "Цей обліковий запис не можна видалити",
          "accountDeletionConfirmation": "Ви впевнені, що хочете видалити цей обліковий запис?",
          "yes": "Так",
          "no": "Немає",
          "accountRemovedMsg": "Обліковий запис пацієнта видалено"
        },
        "notes": {
          "title": "Примітки",
          "current": "Поточний",
          "archive": "Архів",
          "mine": "Шахта"
        },
        "noteDetails": {
          "added": "Час створення",
          "patient": "Пацієнт",
          "pesel": "Песель",
          "address": "Адреса",
          "phone": "Телефон",
          "content": "Зміст",
          "sharedBy": "Поділився",
          "moveToArchive": "Перемістити в архів",
          "save": "Зберегти",
          "reply": "Відповісти",
          "initialNote": "Довідка лікаря",
          "yes": "Так",
          "no": "Немає",
          "addedFiles": "Додані файли"
        },
        "reassignment": {
          "noReplacementAvailable": "Немає альтернативного лікаря за тією ж ціною",
          "backToVisitToMoveTable": "Повернутися до списку призначень для заміни",
          "visitNotFoundRedirection": "Nie znaleziono wizyty, powrócisz do strony głównej",
          "reassignmentSummary": "Резюме",
          "visitNotToMove": "Відвідування не можна перенести, оскільки його статус уже оновлено.",
          "title": "Зміна лікаря",
          "currentVisitDate": "Дата відвідування",
          "currentVisitHeader": "Зміна для відвідування",
          "additionalInfo": "Додаткова інформація",
          "upcomingVisit": "Майбутнє відвідування пацієнта",
          "patient": "Пацієнт",
          "doctor": "лікар",
          "currentVisitFromPackageHeader": "Зміна для візиту з пакету",
          "cannotReassignDueToRelatedVisit": "Візит не може бути призначено повторно через зв’язок з іншим візитом, який уже було зроблено"
        },
        "patients": {
          "selectPatient": "Виберіть пацієнта",
          "name": "Ім'я",
          "email": "Електронна пошта",
          "pesel": "Песель"
        },
        "certificates": {
          "issuingDoctor": "Лікар видає довідку",
          "changeLbl": "Зміна",
          "selectDoctorLbl": "Виберіть лікаря",
          "hide": "Сховати"
        }
      },
      "visits": {
        "DoctorVisits": {
          "titleLbl": "Мої візити",
          "returnLbl": "повернення",
          "noDataLbl": "Відвідувань немає",
          "noResultsLbl": "Відвідувань не знайдено",
          "filters": {
            "visitTime": {
              "all": "Усі",
              "future": "Майбутнє",
              "past": "Минуле"
            },
            "searchPatientLbl": "Шукати пацієнта",
            "advancedFiltersLbl": "Розширений пошук",
            "advancedFilters": {
              "from": "Дата від",
              "betweenDatesLbl": "робити",
              "till": "Дата до",
              "type": "Тип відвідування",
              "typeAll": "Усі",
              "clearBtn": "ясно",
              "searchBtn": "пошук"
            }
          },
          "table": {
            "headers": {
              "state": "Держава",
              "lastName": "Прізвище",
              "firstName": "Ім'я",
              "pesel": "ПЕСЕЛЬ",
              "age": "Вік",
              "visitType": "Тип відвідування",
              "visitCategory": "Категорія",
              "visitStatus": "Статус",
              "dateFrom": "Дата відвідування",
              "sinceLast": "З минулого"
            },
            "actions": {
              "summary": "Резюме відвідування",
              "start": "Відвідайте"
            }
          }
        }
      },
      "patients": {
        "DoctorPatients": {
          "titleLbl": "Пацієнти",
          "returnLbl": "повернення",
          "noDataLbl": "Пацієнтів немає",
          "noResultsLbl": "Відвідувань не знайдено",
          "filters": {
            "scope": {
              "all": "Усі пацієнти",
              "my": "Мої пацієнти"
            },
            "search": "Пошук",
            "advancedFiltersLbl": "Розширений пошук",
            "from": "від",
            "birthDate": "Дата народження",
            "till": "до",
            "clearBtn": "ясно",
            "searchBtn": "пошук",
            "tabs": {
              "personal": "Персональні дані",
              "address": "Деталі адреси",
              "biomedic": "Біомедичні дані",
              "medic": "Медичні дані"
            }
          },
          "table": {
            "headers": {
              "lastName": "Прізвище",
              "firstName": "Ім'я",
              "medicalHistory": "Медична історія",
              "pesel": "ПЕСЕЛЬ",
              "age": "Вік",
              "sex": "Секс",
              "leadDoctor": "Провідний лікар",
              "personalData": "Персональні дані",
              "email": "Електронна пошта",
              "phone": "Номер телефону",
              "city": "Місто",
              "zipCode": "Поштовий індекс",
              "country": "Країна",
              "bloodType": "Група крові",
              "rh": "Rh",
              "weight": "вага",
              "height": "Висота",
              "hasEpilepsy": "Має епілепсію?",
              "mainIcd10": "Основний діагноз",
              "comorbidities": "Супутні захворювання",
              "vns": "Імплантований VNS",
              "activeSubstance": "Діюча речовина",
              "product": "Ліки",
              "therapyType": "Тип терапії"
            }
          }
        }
      },
      "visit": {
        "Visit": {
          "titleLbl": "Відвідайте",
          "returnLbl": "повернення",
          "printLbl": "друк",
          "survey": "Опитування 0",
          "documentation": "Переглянути документацію",
          "visitDocumentation": "Документи з візиту",
          "calendar": "Календар подій",
          "eDiagnosisBtn": "е-діагностика",
          "eTherapyBtn": "електронна терапія",
          "medHistoryBtn": "медична історія",
          "messHistoryBtn": "історія повідомлень",
          "notesBtn": "нотатки",
          "observations": "спостережень",
          "diagnosis": "діагностика (супутні захворювання)",
          "seizureDiagnosis": "діагноз (судоми)",
          "medication": "ліки",
          "recommendations": "рекомендації",
          "prescriptionBtn": "рецепт",
          "certificateBtn": "сертифікат",
          "referralBtn": "направлення",
          "changeTherapyBtn": "змінити терапію",
          "cancel": "скасувати",
          "save": "зберегти",
          "end": "закінчити",
          "visitLeaveConfirmMsg": "Ви впевнені, що хочете покинути візит?",
          "visitSaveConfirmMsg": "Ви впевнені, що хочете заощадити і залишити візит?",
          "visitEndConfirmMsg": "Хочете закінчити візит? Після його припинення будь -які подальші зміни до нього будуть неможливі",
          "dataSavedMsg": "Збережені дані відвідування",
          "visitEndedMsg": "Візит закінчився",
          "dataSaveErrorMsg": "Помилка під час збереження даних про відвідування",
          "messageHistoryEmpty": "З цим пацієнтом немає повідомлень",
          "drugsImported": "Лікарська терапія була ініціалізована",
          "drugTherapiesInEditionMode": "Медикаментозна терапія знаходиться в режимі видання. Відвідування не можна зберегти.",
          "emptyRecommendations": "Рекомендації не надаються. Візит не можна закінчити.",
          "referrals": {
            "title": "рефералів",
            "removeDialogTitle": "Ви впевнені, що хочете видалити це пересилання?",
            "addReferral": "Направлення",
            "addDialogTitle": "Новий реферал",
            "noAvailableServices": "Для цього пацієнта немає доступних послуг",
            "consultationService": "Тип консультації",
            "doctor": "Лікар",
            "description": "Опис",
            "cancel": "Скасувати",
            "empty": "Направлення на цей візит не видається",
            "scheduled1Month": "1 місяць",
            "scheduled2Months": "2 місяці",
            "scheduled3Months": "3 місяці",
            "scheduled6Months": "6 місяців",
            "scheduled1Year": "1 рік",
            "scheduled1month": "1 місяць",
            "scheduled2month": "2 місяці",
            "scheduled3month": "3 місяці",
            "scheduled6month": "6 місяців",
            "scheduled12month": "1 рік",
            "scheduledDate": "Запланована дата",
            "availableFree": {
              "title": "Ви не оформили направлення на безкоштовне відвідування",
              "subtitle": "Після цього візиту пацієнт має право на 1 безкоштовне відвідування однієї з послуг",
              "subtitle2": "Поверніться до свого плану лікування та оформіть відповідне направлення!",
              "back": "Поверніться до візиту, щоб отримати направлення",
              "endWithoutReferral": "Закінчити без направлення",
              "visitDescription": "Після цього візиту пацієнт має право на безкоштовне відвідування наступних служб",
              "visitAddTitle": "направлення на безкоштовне відвідування"
            },
            "doctorPackage": "Пакет лікаря",
            "doctorPackageQuestion": "Направлення на пакет лікаря?"
          },
          "prescriptions": {
            "title": "рецепти",
            "addPrescription": "Рецепт",
            "empty": "На цей візит рецепт не видається"
          },
          "certificates": {
            "title": "сертифікати",
            "addCertificate": "Сертифікат",
            "empty": "На цей візит не видано жодного сертифіката"
          },
          "ended": {
            "titleLbl": "Телеконференція завершилася",
            "questionMsg": "Чи хотіли б Ви закінчити цей візит і повернутися на головний екран?",
            "confirmationMsg": "Дякуємо, що відвідали візит"
          },
          "saving": {
            "warningMsg": "Відвідування автоматично збережеться через {no} хв",
            "autoSaveMsg": "Відвідування було автоматично збережено. Будь ласка, негайно припиніть візит"
          },
          "icd10": {
            "title": "діагноз"
          },
          "leaveDialog": {
            "leave": "Залиште без збереження",
            "saveAndLeave": "Збережіть і залиште",
            "endAndLeave": "Закінчити і піти"
          },
          "absentConsultationInfo": "Відсутна консультація проходить без участі пацієнта",
          "wrongDataDialog": {
            "title": "Ваші дані не можуть бути збережені на даний момент.",
            "description": "Будь ласка, зв’яжіться з клієнтським офісом Центру терапії епілепсії Neurosphere."
          },
          "additionalObservations": "Додаткові спостереження",
          "notMandatoryLbl": "необов'язкове поле",
          "observationsPlaceholder": "Спостереження заповнюються автоматично при заповненні розділу відвідування",
          "additionalObservationsPlaceholder": "Заповніть додаткові спостереження",
          "addObservationLbl": "Зберегти спостереження",
          "leadDoctorHeader": "Провідний лікар",
          "documentationTabs": {
            "visitSurvey": "Відвідайте опитування",
            "therapyArrangement": "Організація терапії",
            "healthCare": "План догляду",
            "recommendations": "Рекомендації",
            "certificates": "Сертифікати",
            "appoint": "призначити",
            "skip": "не призначають",
            "referralsSaved": "Рефералів збережено",
            "carePlan": {
              "addNext": "Додати далі",
              "remove": "видалити",
              "loading": "Завантаження даних...",
              "referralListEmpty": "Список рекомендацій порожній",
              "epilepsy": {
                "name": "Відвідування хворих на епілепсію",
                "title": "наступний візит епілептика - запис на прийом",
                "date": "Дата візиту"
              },
              "eeg": {
                "name": "ЕЕГ дослідження",
                "title": "ЕЕГ дослідження - запис на прийом",
                "date": "Дата ЕЕГ",
                "service": "Тип ЕЕГ"
              },
              "consultation": {
                "name": "Консультація",
                "title": "Консультація НСПЗ - запис на прийом",
                "date": "Дата консультації",
                "service": "Тип консультації"
              },
              "other": {
                "name": "Інший",
                "title": "Зовнішні реферали",
                "emptyList": "Список виданих зовнішніх направлень для цього візиту порожній",
                "addNextEReferral": "Додати е-реферал",
                "isEReferral": "Електронне направлення",
                "referralCode": "Промо-Код",
                "noDataLbl": "Список рекомендацій порожній"
              },
              "message": {
                "name": "Повідомлення до лікаря",
                "title": "повідомлення візит - запис на прийом",
                "date": "Дата візиту"
              }
            }
          },
          "recommendationTemplate": {
            "title": "шаблон рекомендації",
            "examinationNames": "Назви іспитів",
            "template": {
              "message": "Прохання написати повідомлення до лікуючого лікаря про стан здоров'я",
              "therapy": "Препарати за схемою в медикаментозній терапії та на обліку пацієнта в Нейротермінальній системі у вкладці Терапія",
              "urgentContact": "У разі термінового звернення до лікаря або непередбачених обставин, будь ласка, телефонуйте на реєстратурі +48 609 463 191",
              "noTherapy": "Без фармакологічного лікування",
              "calendar": "Будь ласка, ведіть календар подій на порталі пацієнтів у системі Нейротермінал",
              "headMr": "Будь ласка, зробіть МРТ голови",
              "medicalExaminations": "Будь ласка, проведіть додаткові дослідження та додайте результати до медичної документації в системі «Нейротермінал»."
            }
          },
          "epicrisis": "Епікриз",
          "noteZeroObservations": "Примітка 0 спостережень",
          "editNoteZeroObservations": "Редагувати зауваження 0",
          "saveObservationLbl": "Зберегти примітку 0 спостережень",
          "downloadTestRecommendations": "Завантажити пробні рекомендації",
          "mainDoctor": "Головний лікар",
          "supervisors": "Наглядачі"
        },
        "ictal": {
          "diagnosis": {
            "title": "Шановна пані, шановний пане",
            "fillSurveyMsg": "Судом ще не визначено. Заповніть опитування 0",
            "ictalDiagnose": "Діагностувати за допомогою Ictall",
            "ictalDiagnoseOneMoreTime": "Поставте діагноз ще раз",
            "patientSeizureType": "Вибраний тип нападу (визначається пацієнтом)",
            "suggestedDiagnoseMsg": "Потенційний діагноз для",
            "subtitle1": "Пам'ятайте, що наш",
            "subtitle2": "ШІ-робот – Ictal®",
            "subtitle3": "- це інноваційний інструмент, який допоможе вам і буде постійно вчитися допомагати вам ще краще, але",
            "subtitle4": "це не медичний пристрій і не може використовуватися для оцінки реального клінічного стану ваших пацієнтів.",
            "subtitle5": "Усю бібліотеку онтології епілепсії було розроблено для точного розпізнавання типу епілептичного нападу згідно з останньою класифікацією MLPP, тому цей інструмент можна використовувати для оцінки гіпотетичних клінічних станів у освітніх або навчальних цілях."
          },
          "outcome": {
            "saveLbl": "Зберегти діагноз",
            "reasonLbl": "Причина вибору цього діагнозу",
            "selectDiagnosisMsg": "Виберіть діагноз",
            "calcDiagnosisMsg": "Постановка діагнозу ..",
            "otherDiagnosis1Lbl": "Інший",
            "otherDiagnosis2Lbl": "діагноз",
            "approveLbl": "я згоден",
            "rejectLbl": "я не згоден",
            "fixCritical": "Вирішіть критичні питання",
            "updatedByLbl": "За даними діагнозу"
          },
          "therapy": {
            "title": "Шановна пані, шановний пане",
            "ictalCheck": "Перевірити",
            "seizureType": "Тип нападу",
            "type": {
              "1": "I Монотерапія",
              "2": "II Монотерапія",
              "3": "III Монотерапія",
              "4": "I Політерапія",
              "5": "II Політерапія",
              "6": "III Політерапія",
              "0": "Пропозиція ICTAL",
              "7": "Подальша політерапія:"
            },
            "drug": {
              "description": {
                "lastUsed": "Востаннє використано:",
                "color": {
                  "undefined": "Препарат раніше не застосовувався.",
                  "orange": "Препарат застосовувався раніше: відсутність ефекту / слабкий ефект.",
                  "red": "Препарат застосовувався раніше: негативна дія, стан пацієнта погіршився.",
                  "green": "Препарат застосовувався раніше: задовільний ефект."
                }
              }
            },
            "filter": {
              "availableInPL": "Препарат доступний у Польщі",
              "refundedInPL": "Повернули ліки в Польщі",
              "reproductiveAgeOptional": "Репродуктивний вік"
            },
            "notFound": "ICTAL не зміг адаптувати лікування для пацієнта.",
            "apply": "Застосовувати терапію",
            "proposal": "Можливі варіанти терапії",
            "subtitle1": "Пам'ятайте, що наш",
            "subtitle2": "ШІ-робот – Ictal®",
            "subtitle3": "- це інноваційний інструмент, який допоможе вам і буде постійно вчитися допомагати вам ще краще, але",
            "subtitle4": "це не медичний пристрій і не може використовуватися для оцінки реального клінічного стану ваших пацієнтів.",
            "subtitle5": "Увесь процес лікування епілепсії розроблено командою експертів і базується на рекомендаціях провідних медичних наукових товариств, які займаються проблемами епілепсії, тому цей інструмент можна використовувати для оцінки гіпотетичних клінічних станів у освітніх або навчальних цілях."
          }
        },
        "doctorNotes": {
          "title": "Примітки",
          "subtitle": "Це місце, де ви можете зберігати особисті нотатки про цього пацієнта",
          "addNote": "Додати коментар",
          "save": "Зберегти зміни",
          "saveSuccess": "Нотатку збережено",
          "modified": "Остання зміна",
          "editCancel": "Скасувати зміни",
          "edit": "Редагувати примітку",
          "delete": "Видалити нотатку",
          "askDelete": "Ви впевнені, що хочете видалити цю нотатку?",
          "deleteSuccess": "Примітку видалено",
          "publicVisible": "Видно всім лікарям",
          "sharedBy": "Надіслано",
          "limitedShare": "Видно для обраних лікарів",
          "doctor": "лікар",
          "forAdmin": "Видно для адміністратора",
          "repliedTo": "W odpowiedzi na",
          "sharedByPatient": "Надав пацієнт для заочної консультації",
          "absentConsultation": "Заочна консультація",
          "addFiles": "Додати файл"
        },
        "diagnosis": {
          "confirmTitle": "Зміна діагнозу",
          "confirmText": "Ви впевнені, що не хочете змінювати діагноз?",
          "updatedMsg": "Оновлена діагностика цього типу нападів"
        },
        "patientMessageHistory": {
          "title": "Історія повідомлень пацієнта",
          "emptyList": "Історія повідомлень пацієнта порожня"
        }
      },
      "landing": {
        "common": {
          "menu": {
            "observe": "Спостерігайте за нами",
            "link": {
              "database": "NT - База даних",
              "doctors": "Для лікарів",
              "contact": "Контакт",
              "login": "Увійдіть",
              "register": "Зареєструватись"
            },
            "terms": {
              "title": "Умови використання",
              "z1": "Організаційні регламенти",
              "z2": "Положення про надання електронних послуг",
              "z3": "Політика конфіденційності Neurosphera",
              "z4": "Політика конфіденційності нейротерміналів",
              "payuInstallments": "Розстрочка PayU – поширені запитання"
            }
          },
          "contact": {
            "title": "Зв'яжіться з нами",
            "media": {
              "visit": "Відвідайте нас"
            },
            "dataTitle": "Зв'яжіться з нами",
            "formTitle": "Контактний формуляр",
            "form": {
              "email": "Надішліть електронний лист",
              "phone": "Зателефонуйте нам",
              "message": "повідомлення",
              "info": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud вправа ullamco Laboris nisi ut aliquip ex ea commodo result. Duis aute irure dolor в",
              "send": "Відправити повідомлення",
              "ApproveDataProcessing": "Я добровільно погоджуюся обробляти мої персональні дані компанією Neuroterminal S.A. для обслуговування моєї заявки. Детальну інформацію про принципи захисту персональних даних та ваші права можна знайти в",
              "policy": "Політика конфіденційності",
              "feedback": {
                "header": "Дякуємо, що надіслали нам своє повідомлення!",
                "content": "Ми зв'яжемося з вами найближчим часом\nпо телефону або електронною поштою"
              }
            }
          },
          "help": {
            "title": "Маєте запитання?"
          }
        },
        "main": {
          "title1": "Сучасна епілепсія",
          "title2": "система лікування",
          "bullet1": "Зручні телемедичні консультації\nвід 99 злотих",
          "bullet2": "Доступ до найкращих\nепілептологи",
          "bullet3": "Комплексна медична допомога\nне виходячи з дому",
          "arrangeConsultation": "Призначати зустріч",
          "features": {
            "consultations": {
              "title": "Телемедицина\nконсультації",
              "description": "Проводити онлайн -відвідування\nза допомогою відеоконференцій"
            },
            "emergency": {
              "title": "Надзвичайна ситуація\nсправи",
              "description": "24 -годинний дзвінок SOS\nз лікарями"
            },
            "panel": {
              "title": "Пацієнт\nпанелі",
              "description": "Медична історія,\nмедичні документи\nта рекомендації\nв одному місці"
            },
            "edocuments": {
              "title": "Електронні рецепти,\nлікарняні листки",
              "description": "та рефералів\nне виходячи з дому"
            },
            "doctors": {
              "title": "\nЛікарі",
              "description": "Доступ до найкращих епілептологів\nбез черг"
            },
            "quickAccess": {
              "title": "Швидко\nдоступ",
              "description": "Консультації з лікарями\nбез черг"
            }
          },
          "subHeaderBullet1": "Залишайтеся на зв'язку зі своїм лікарем,\nхто використовує модуль штучного інтелекту\nдля контролю ефективності вашого лікування.",
          "subHeaderBullet2": "Отримайте доступ до найкращих невропатологів Польщі\nне виходячи з дому.",
          "subHeaderBullet3": "Приєднуйтесь до Neuroterminal безкоштовно\nі почніть використовувати інноваційні рішення\nдля лікування епілепсії!",
          "howItWorks": "Як це працює?",
          "steps": {
            "choose": {
              "title": "Вибір пацієнта\nлікар",
              "description": "з бази даних спеціалістів"
            },
            "appoint": {
              "title": "Робить\nзустріч",
              "description": "на телемедичний візит\nу зручний час"
            },
            "diagnose": {
              "title": "Лікар ставить діагноз",
              "description": "і обирає терапію.\nСистема штучного інтелекту iCtal\nдопомагає йому в цьому"
            },
            "documents": {
              "title": "Додає медичне\nдокументи",
              "description": ""
            },
            "contact": {
              "title": "Лікар залишається\nна звязку",
              "description": "з пацієнтом,\nрегулярно відстежує прогрес\nреалізованого лікування"
            }
          },
          "opinions": "Думки користувачів",
          "register": "Створіть обліковий запис, щоб записатися на зустріч"
        },
        "database": {
          "epilepsy": {
            "title": "Що таке епілепсія?",
            "subheader1": "Епілепсія - це медичний стан\nхарактеризується періодичними судомами.",
            "subheader2": "Результат - приступ\nтимчасово зміненого\nфункція мозку\nспричинений\nаномальні або надмірні\nелектричні розряди\nвсередині клітин мозку."
          },
          "seizure": {
            "title": "Як відбувається судоми\nвиглядає як?",
            "subheader": "Симптоми судом можуть бути різними.",
            "bullet1": "Деякі люди з епілепсією\nпросто \"втратити контакт\"\nпротягом декількох секунд під час нападу",
            "bullet2": "Інші люди падають на підлогу\nз судомами кінцівок",
            "footer": "Класифікація типу нападу дуже важлива\nі допомагає лікарю спланувати необхідне лікування.\nОдноразовий напад не означає, що у вас епілепсія."
          },
          "factors": {
            "title": "Провокуючі фактори",
            "subheader": "Також можуть бути викликані судоми\nза відомими та оборотними факторами,\nяк от",
            "bullet1": "Не спати",
            "bullet2": "Алкоголь\nзняття",
            "bullet3": "Низький вміст цукру",
            "seizureRecognition": "Щонайменше два неспровокованих нападу\nзазвичай необхідні для діагностики епілепсії.",
            "seizuresRelatedTo": "Судоми можуть бути пов'язані з",
            "relatedToBullet1": "Пошкодження головного мозку",
            "relatedToBullet2": "Сімейна схильність",
            "relatedToBullet3": "Часто їх причина\nабсолютно невідомо"
          },
          "whoCanSick": {
            "title": "Хто може захворіти?",
            "subheader": "Епілепсія може вразити будь -кого,\nнезалежно від статі та віку.",
            "bullet1": "Найчастіше судоми виникають у дітей\nвіком до 1 року,\nтоді як літні люди зазвичай мають збільшення\nзахворюваності після 65 років.",
            "bullet2": "За оцінками, до 1% людей\nстраждають на епілепсію (близько 400 000 осіб у Польщі)."
          },
          "treatment": {
            "title": "Як лікується епілепсія?",
            "bullet1": "Наркотики можуть ефективно контролювати напади\nприблизно в 70%\nпацієнтів з епілепсією",
            "bullet1Header": "Препарати",
            "bullet2": "Для пацієнтів з неконтрольованою епілепсією,\nнейрохірургія може \"вилікувати\"\nшляхом усунення джерела\nсудом та епілепсії.",
            "bullet2Header": "Хірургія",
            "footer": "Медикаментозне лікування або іноді хірургічне втручання\nможе контролювати напади у більшості людей з епілепсією.\nДеякі люди потребують довічного лікування, щоб контролювати напади,\nале інші врешті -решт припиняться. Деякі діти з епілепсією\nз віком може вирости з судом."
          },
          "impact": {
            "title": "Як впливає епілепсія\nповсякденне життя?",
            "subheader": "Судоми та епілепсія можуть вплинути",
            "bullet1": "Безпека",
            "bullet2": "Відносини",
            "bullet3": "Вакансія",
            "bullet4": "За кермом автомобіля",
            "bullet5": "І багато -багато іншого",
            "footer": "Громадське сприйняття та лікування людей з епілепсією\nчасто є більшими проблемами, ніж фактичні напади."
          },
          "help": {
            "title": "Нейротермінал буде\nдопоможе вам вилікувати епілепсію",
            "bullet1Header": "Комфорт",
            "bullet1": "Ви влаштуєте\nконсультація лікаря\nне виходячи з дому.",
            "bullet2Header": "Інновації",
            "bullet2": "Ми використовуємо штучні\nрозвідувальних систем\nта сучасні аналітичні засоби\nдля лікування епілепсії.",
            "bullet3Header": "Безпека",
            "bullet3": "Ви отримаєте доступ\nна 24-годинну неврологічну зміну\nяка надасть вам\nнегайну допомогу.",
            "makeAnAppointment": "Призначати зустріч"
          }
        },
        "doctors": {
          "title1": "Стань лікарем",
          "titleIn": "в",
          "title2": "НЕЙРОТЕРМІНАЛ",
          "subtitle": "Проконсультуйтеся з пацієнтами онлайн\nвикористовуючи найновіші\nтехнологій.",
          "register": "Зареєструйтесь",
          "features": {
            "consultations": {
              "title": "Телемедицина\nконсультації",
              "description": "Здійснюйте візити онлайн\nза допомогою відеоконференцій\nабо поспілкуватися"
            },
            "digitalOffice": {
              "title": "Цифровий\nофіс",
              "description": "Усі відвідування та медичні дані\nваших пацієнтів\nв одному місці"
            },
            "eDocuments": {
              "title": "\nЕлектронні документи",
              "description": "Створюйте рецепти,\nлікарняні та направлення\nонлайн"
            },
            "flexibleSchedule": {
              "title": "Гнучкий\nрозклад",
              "description": "Заплануйте консультації\nв зручні для вас дати\n7 днів на тиждень"
            },
            "system": {
              "title": "Система\nНейротермінал",
              "description": "Використовуйте інноваційний\nсистема штучного інтелекту\nдля діагностики пацієнтів"
            },
            "patients": {
              "title": "\nПацієнти",
              "description": "Ведіть своїх пацієнтів\nі швидко здобувати нові"
            }
          },
          "steps": {
            "register": {
              "title": "Зареєструйтесь",
              "description": ""
            },
            "training": {
              "title": "Пройдіть навчання",
              "description": "як користуватися нейротерміналом\nплатформи та поведінки\nтелемедичні консультації"
            },
            "schedule": {
              "title": "Призначте консультації\nрозклад",
              "description": "вільно планувати\nваша доступність"
            },
            "pricing": {
              "title": "Вкажіть прайс -лист",
              "description": "ваша зарплата\nдля консультації"
            },
            "consultations": {
              "title": "Проконсультуйтесь",
              "description": "телемедицина онлайн через\nнейротермінальну платформу\nчерез відео чи чат"
            },
            "monitor": {
              "title": "Монітор\nпацієнтів",
              "description": "та прогрес їх лікування"
            }
          },
          "firstSteps": "Перші кроки"
        },
        "patients": {},
        "removePatient": {
          "title": "Підтвердити видалення облікового запису",
          "subTitle1": "Обліковий запис зареєстровано в",
          "subTitle2": "буде видалено",
          "why": "Чому ви хочете видалити обліковий запис",
          "whyPrompt": "Ваша відповідь дуже важлива для нас. Це допоможе нам покращити наш сервіс.",
          "removeBtn": "Видаліть свій обліковий запис з Neuroterminal",
          "accountRemoved": "Ваш обліковий запис видалено",
          "noAccount": "Обліковий запис не існує або його неможливо видалити",
          "accountRemovedDescription": "Пам’ятайте, що ви можете зареєструватися в Neuroterminal і забронювати візит до Neurosphera в будь-який час."
        }
      },
      "ictal": {
        "lab": {
          "title": "IctalLab",
          "subtitle": "Введіть вектор або завантажте пацієнта, щоб розпочати автоматичну діагностику.",
          "diagnose": "Діагноз",
          "clear": "Ясно",
          "inputVector": "Вхідний вектор",
          "loadPatient": "Виберіть пацієнта",
          "changePatient": "Змінити пацієнта",
          "selectedPatient": "Обраний пацієнт",
          "survey": "Опитування",
          "diagnosis": "Діагностика",
          "factorsConfiguration": "Конфігурація факторів",
          "sum": "Сума",
          "therapy": "Терапія",
          "check": "Перевірити",
          "selectTherapy": "Виберіть тип терапії",
          "selectedTherapy": "Обраний вид терапії",
          "selectSeizureType": "Виберіть типи нападів",
          "selectedSeizureType": "Вибрані типи нападів",
          "drugAvailableInPl": "Препарат доступний у Польщі",
          "drugRefunded": "Ліки повернено",
          "patientIsPregnant": "Пацієнтка вагітна",
          "patientWithSomaticDiseases": "Пацієнт із соматичними захворюваннями",
          "patientInOlderAge": "Пацієнт у старшому віці",
          "patientOfChildbearingAge": "Пацієнт дітородного віку",
          "patientAge": "Вік пацієнта",
          "recommendedTherapy": "Рекомендована терапія",
          "errors": {
            "factorsError": "Сума коефіцієнтів повинна бути 100",
            "vectorLengthError": "Неправильна довжина вхідного вектора",
            "vectorInvalidError": "Неправильний вектор введення"
          },
          "factors": {
            "nn": "НН",
            "knn": "KNN",
            "jacc": "Жаккард",
            "jaro": "Джаро",
            "sodi": "Соренсен – Кості",
            "over": "Перекриття",
            "tver": "Тверський: α = 1, β = 1"
          },
          "surveyHeaders": {
            "name": "Питання",
            "always": "Завжди",
            "veryOften": "Дуже часто",
            "often": "Часто",
            "sometimes": "Іноді",
            "rarely": "Рідко",
            "veryRarely": "Дуже рідко",
            "never": "Ніколи",
            "veryLong": "Дуже довго",
            "long": "Довго",
            "bitLong": "Трохи довгий",
            "variable": "Змінна",
            "short": "Короткий",
            "veryShort": "Дуже короткий",
            "ultraShort": "Ультракороткий"
          },
          "seizuresTableHeaders": {
            "seizureType": "Тип нападу",
            "probability": "Ймовірність [ %]"
          },
          "selectUserDialog": {
            "title": "Виберіть пацієнта",
            "noPatientsFound": "Пацієнтів не знайдено."
          },
          "configuration": {
            "subtitle": "Конфігурація",
            "drugCatalog": {
              "title": "Каталог препаратів",
              "add": "Додати новий препарат"
            },
            "factors": {
              "save": "Збережіть фактори",
              "coefModifiedMsg": "Коефіцієнти збережено"
            }
          },
          "drugs": {
            "deleteConfirmationDialogText": "Видалення ліків також видалить дані навчання двигуна Ictal. Усі дані, пов’язані з цим препаратом, будуть видалені. Бажаєте продовжити?",
            "updateDrugDialogTitle": "Оновлення препарату",
            "createDrugDialogTitle": "Додайте ліки",
            "save": "Зберегти",
            "cancel": "Скасувати",
            "validation": {
              "minAgeRequired": "Необхідний мінімальний вік",
              "minAgeRange": "Мінімальний вік повинен бути числом у діапазоні 0 - 120",
              "weightRequired": "Потрібна вага",
              "weightRange": "Вага має бути числом у діапазоні -3 - 3"
            },
            "legend": {
              "title": "Наркотики - легенда",
              "column": "Колонка",
              "value": "Цінність",
              "thrAbove": "Досягнуто вікового порогу; вік вище встановленого порогу",
              "thrBelow": "Віковий поріг не досягнутий; вік нижче встановленого порогу",
              "drugAvailable": "Препарат доступний у PL",
              "drugNotAvailable": "Препарат недоступний у PL",
              "drugRefunded": "Ліки повернено в PL",
              "drugNotRefunded": "Препарат не повертається в PL",
              "usageForbidden": "Заборонено",
              "usageVeryRare": "Дуже рідко використовується",
              "usageRare": "Рідко використовується",
              "usageAmbivalent": "Амбівалентні",
              "usageSometimes": "Іноді використовується",
              "usageOften": "Часто використовується",
              "usageRecommended": "Рекомендується"
            }
          },
          "usedDrugs": "Препарати, які підлягають відфільтруванню (мається на увазі - надзвичайне погіршення стану)",
          "drugProposal": "Пропозиція про ліки",
          "wageSum": "Сума заробітної плати (вище - краще)",
          "patientSex": {
            "label": "Секс",
            "type": {
              "MALE": "Чоловічий",
              "FEMALE": "Жіночий"
            }
          },
          "experts": {
            "titleLbl": "Фахівці лікарі",
            "addExpertMsg": "Додайте експерта",
            "editExpertMsg": "Експерт редагування",
            "deleteExpertTitleMsg": "Вилучити експерта",
            "deleteExpertDescMsg": "Видалення експерта призведе до видалення всіх даних навчання двигуна Ictal, пов'язаних з цим експертом. Ви хочете продовжити?",
            "saveMsg": "Дані експертів змінено",
            "save": "Зберегти",
            "cancel": "Скасувати",
            "validation": {
              "weightRange": "Вага має бути числом в діапазоні 0-1"
            }
          },
          "recommendations": {
            "titleLbl": "Препарати, рекомендовані фахівцями",
            "addRecommendationLbl": "Додайте рекомендацію",
            "saveMsg": "Вектор рекомендації щодо наркотиків збережено",
            "save": "Зберегти",
            "cancel": "Скасувати",
            "dialog": {
              "titleLbl": "Рекомендовані препарати",
              "expertLbl": "Експерт",
              "therapyLbl": "Терапія"
            }
          },
          "epilepsies": {
            "titleLbl": "Види епілепсії",
            "addMsg": "Додайте тип епілепсії",
            "editMsg": "Змінити тип епілепсії",
            "deleteTitleMsg": "Видалити тип епілепсії",
            "deleteDescMsg": "Видалення типу епілепсії призведе до видалення всіх даних навчання двигуна Ictal, пов'язаних з цим типом епілепсії. Ви хочете продовжити?",
            "saveMsg": "Змінено дані про типи епілепсії",
            "save": "Зберегти",
            "cancel": "Скасувати"
          },
          "ediagnosis": {
            "titleLbl": "Електронна діагностика",
            "headers": {
              "eventsTrailer": "Задовго до нападу (години, дні)",
              "eventsBefore": "Безпосередньо перед нападом (секунди)",
              "eventsStart": "Початок судом - перший симптом",
              "eventsNext": "Наступні симптоми судом",
              "eventsEnd": "Закінчення нападу",
              "eventsAfter": "Після нападу",
              "eventsFeature": "Загальна характеристика судом"
            },
            "dialog": {
              "titleLbl": "Вектор електронної діагностики",
              "epilepsyLbl": "Тип епілепсії",
              "save": "Зберегти",
              "cancel": "Скасувати",
              "saveMsg": "Вектор електронної діагностики змінений"
            },
            "events": {
              "trailerPrecise": "Точне прогнозування (завершення)",
              "trailerUnspecified": "Прогноз не визначений (кінцевий)",
              "beforeAura": "Аура (раніше)",
              "beforeSpecificStimuli": "Специфічні подразники (раніше)",
              "beforeNystagmus": "Ністагм (раніше)",
              "beforeImmobility": "Нерухомість (раніше)",
              "startFaint": "Непритомність (початок)",
              "startLossConsciousness": "Втрата свідомості (початок)",
              "startBodyConvulsions": "Судоми тіла (початок)",
              "startBodyPartConvulsions": "Судоми частини тіла (початок)",
              "startFaceConvulsions": "Судоми обличчя (початок)",
              "startBodyStiffness": "Жорсткість тіла (початок)",
              "startLimbEjection": "Викидання кінцівок (початок)",
              "startEyeMovementDisorders": "Порушення руху очей (початок)",
              "startEyeTurnPernament": "Поворот ока (початок)",
              "startBodyTurn": "Поворот кузова (початок)",
              "startGroundFall": "Падіння землі (початок)",
              "startBitTongue": "Прикус язика (початок)",
              "startShout": "Крик (початок)",
              "startSounds": "Звуки (початок)",
              "startMotorAutomatisms": "Автоматизм двигуна (запуск)",
              "startImmobility": "Нерухомість (початок)",
              "startLaugh": "Сміх (початок)",
              "startChuck": "Затиск (початок)",
              "startCry": "Плач (початок)",
              "startEpigastricSymptoms": "Епігастральні симптоми (початок)",
              "startDejaVu": "Дежавю (початок)",
              "startVisualHallucinations": "Зорові галюцинації (початок)",
              "startAuditoryHallucinations": "Слухові галюцинації (початок)",
              "startEyelidsMyoclonus": "Міоклонус повік (початок)",
              "startMyoclonus": "Міоклонус (початок)",
              "nextFaint": "Непритомність (далі)",
              "nextLossConsciousness": "Втрата свідомості (далі)",
              "nextBodyConvulsions": "Судоми тіла (далі)",
              "nextBodyPartConvulsions": "Судоми частини тіла (далі)",
              "nextFaceConvulsions": "Судоми обличчя (далі)",
              "nextBodyStiffness": "Жорсткість тіла (далі)",
              "nextEyeMovementDisorders": "Порушення руху очей (далі)",
              "nextEyeTurnPernament": "Око стає постійним (далі)",
              "nextBodyTurn": "Поворот кузова (наступний)",
              "nextGroundFall": "Падіння землі (далі)",
              "nextBitTongue": "Прикус язика (далі)",
              "nextUrinating": "Сечовипускання (далі)",
              "nextDefecation": "Дефекація (далі)",
              "nextShout": "Кричати (далі)",
              "nextSounds": "Звуки (далі)",
              "nextAggression": "Агресія (далі)",
              "nextMotorAutomatisms": "Моторний автоматизм (далі)",
              "nextImmobility": "Нерухомість (далі)",
              "nextLaugh": "Сміється (далі)",
              "nextChuck": "Патрон (далі)",
              "nextCry": "Плаче (далі)",
              "nextEpigastricSymptoms": "Епігастральні симптоми (далі)",
              "nextDejaVu": "Дежавю (далі)",
              "nextVisualHallucinations": "Зорові галюцинації (далі)",
              "nextAuditoryHallucinations": "Слухові галюцинації (далі)",
              "nextEyelidsMyoclonus": "Міоклонус повік (далі)",
              "endAnotherSeizure": "Ще один приступ (кінець)",
              "endBodyConvulsions": "Судоми тіла (кінець)",
              "endBodyPartConvulsions": "Судоми частини тіла (кінець)",
              "endFaceConvulsions": "Судоми обличчя (кінець)",
              "endEyeMovementDisorders": "Порушення руху очей (кінець)",
              "endEyeTurnPernament": "Поворот ока (кінець)",
              "endBodyTurn": "Поворот кузова (кінець)",
              "endGroundFall": "Падіння землі (кінець)",
              "endUrinating": "Сечовипускання (кінець)",
              "endDefecation": "Дефекація (кінець)",
              "endSounds": "Звуки (кінець)",
              "endAggression": "Агресія (кінець)",
              "endMotorAutomatisms": "Моторний автоматизм (кінець)",
              "endImmobility": "Нерухомість (кінець)",
              "endLaugh": "Сміх (кінець)",
              "endChuck": "Затиск (кінець)",
              "endCry": "Плач (кінець)",
              "endVomit": "Блювота (кінець)",
              "afterEyeMovementDisorders": "Порушення руху очей (після)",
              "afterEyeTurnPernament": "Постійне повернення очей (після)",
              "afterUrinating": "Сечовипускання (після)",
              "afterDefecation": "Дефекація (після)",
              "afterConfusion": "Плутанина (після)",
              "afterSleep": "Сон (після)",
              "afterAggression": "Агресія (після)",
              "afterMotorAutomatisms": "Моторний автоматизм (після)",
              "afterVomit": "Блювота (після)",
              "afterHighTemperature": "Висока температура (після)",
              "featureMany": "Багато (функція)",
              "featureDayOccurrence": "Поява дня (функція)",
              "featureNightOccurrence": "Нічна подія (функція)",
              "featureAwakeningOccurrence": "Пробудження (особливість)",
              "featureMorningOccurrence": "Ранкова подія (функція)",
              "featureDuration": "Тривалість (функція)"
            }
          },
          "model": {
            "search": "Пошук",
            "manage": "Керувати",
            "drug": {
              "general": "Загальні дані",
              "weights": "Ваги",
              "shortName": "Коротке ім'я",
              "name": "Назва",
              "minAge": "Мінімальний вік",
              "plAvailability": "Доступно в PL",
              "plRefunded": "Повернено в PL",
              "reproductiveAgeWeight": "Репродуктивний вік",
              "pregnantWeight": "Вагітна",
              "oldAgeWeight": "Похилий вік",
              "somaticDiseaseWeight": "Соматична хвороба"
            },
            "expert": {
              "description": "Експерт",
              "factor": "Фактор"
            },
            "epilepsy": {
              "name": "Назва"
            }
          },
          "algorithmSteps": {
            "filterName": "Фільтр",
            "order": "Замовлення",
            "usedFilters": "Вживані фільтри",
            "filters": {
              "SeizureDrugFilter": "Вибір Дурґа за судомами",
              "UsedDrugFilter": "Видалення вживаних наркотиків",
              "TherapyDrugFilter": "Замовлення ліків за рекомендаціями експертів (вага)",
              "WeightDrugFilter": "Розрахунок ваги"
            },
            "tableModel": {
              "drugName": "Назва",
              "drugShortName": "Коротке ім'я",
              "minAge": "Мінімальний вік",
              "oldAgeWeight": "Старший вік",
              "pregnantWeight": "Вагітність",
              "reproductiveAgeWeight": "Репродуктивний вік",
              "somaticDiseaseWeight": "Соматичні захворювання",
              "weightSum": "Сума ваги"
            }
          },
          "furtherPoliOffset": "Додатковий номер політерапії (починається з 0)"
        }
      },
      "duties": {
        "Duties": {
          "duty": "Планове чергування",
          "availableDuty": "Доступне мито",
          "notAvailableDuty": "Обов’язок недоступний",
          "selectedDuty": "Вибрано",
          "blockedDuty": "Недоступний",
          "cancelledDuty": "Вибрано для скасування",
          "dutyToChange": "Прохання взяти на себе обов'язки",
          "changes": {
            "approve": {
              "text": "Зберегти зміни",
              "tooltipInstruction": "Виберіть обов'язки з наявних і збережіть, натиснувши цю кнопку"
            },
            "discard": {
              "text": "Скасувати зміни"
            }
          }
        },
        "confirmationDialog": {
          "text": "Ваші зміни не зберігаються. Ви хочете їх відкинути?"
        },
        "youAreNotAllowedDialog": {
          "title": "Потрібні дозволи",
          "text": "Ви не маєте дозволу реєструвати мита. Зв'яжіться з нами для отримання додаткової інформації."
        },
        "obligatoryDutyDialog": {
          "title": "Операція не допускається",
          "subtitle": "Відповідно до правил та умов, вибране мито не може бути скасовано.",
          "text": "Для отримання додаткової інформації перегляньте наш",
          "termsAndConditions": "Обов’язки та положення",
          "close": "Закрити"
        },
        "dutiesLimitAchievedDialog": {
          "title": "Операція не допускається",
          "subtitle": "Ви досягли ліміту на цей тиждень.",
          "text": "Для отримання додаткової інформації перегляньте наш",
          "termsAndConditions": "Обов’язки та положення",
          "close": "Закрити"
        }
      },
      "medicalHistory": {
        "MedicalHistory": {
          "title": "Медична історія",
          "return": "Повернення",
          "show": "Показати",
          "psychomotorDevelopment": "Психомоторний розвиток",
          "comorbidities": "Супутні захворювання",
          "notDiagnosedLbl": "Не діагностовано",
          "bloodType": "Група крові",
          "height": "Висота",
          "weight": "Вага",
          "bmi": "ІМТ",
          "tabs": {
            "psychomotorDevelopment": "Психомоторний розвиток",
            "seizuresHistory": "Історія нападів",
            "seizuresCharacteristics": "Характеристика судом",
            "comorbidities": "Супутні захворювання",
            "treatment": "Лікування"
          },
          "treatment": {
            "title": "Історія лікування та відвідування",
            "currentTherapy": "Сучасна терапія",
            "biomedicalData": "Біомедичні дані",
            "comorbiditiesData": "Дані про супутні захворювання",
            "previousTherapies": "Історія терапії",
            "number": "Ні",
            "medicine": "Ліки",
            "dosage": "Дозування",
            "effect": "Ефект",
            "noCurrentTherapy": "Поточної терапії немає",
            "start": "Почати",
            "end": "Кінець",
            "noPreviousTherapies": "Історія терапії порожня",
            "treat": "лікування",
            "visits": "відвідувань"
          },
          "survey": {
            "title": "Опитування 0"
          },
          "calendar": {
            "title": "Календар подій",
            "visitCalendarLabel": "Календар відвідувань"
          },
          "prescriptions": {
            "title": "Електронні рецепти"
          },
          "consultations": {
            "title": "Відвідування",
            "noConsultations": "Ніяких консультацій",
            "observations": "Спостереження",
            "recomendation": "Рекомендація",
            "doctor": "Лікар",
            "date": "Дата",
            "type": "Тип",
            "category": "Категорія",
            "none": "Жодного"
          },
          "icd10": {
            "title": "Діагностика",
            "current": "Поточний діагноз",
            "history": "Історія",
            "from": "Від",
            "added": "Додано",
            "removed": "Вилучено",
            "noCurrentIcd10": "Поточного діагнозу немає",
            "noIcd10History": "Історія діагнозу порожня"
          },
          "bioMedInfo": {
            "edit": "Редагувати"
          },
          "visitCalendar": {
            "title": "Думка лікаря і пацієнта",
            "opinionTypeHeader": "Тип думки",
            "seizureEvaluation": "Тяжкість судом",
            "wellBeing": "благополуччя",
            "treatmentRating": "Ефективність лікування",
            "sideEffects": "Побічні ефекти",
            "treatmentSafety": "Безпека лікування",
            "patientOpinion": "Думка пацієнта",
            "doctorOpinion": "Думка лікаря",
            "noData": "Немає даних опитувань",
            "noVisits": "Без візитів"
          },
          "epicrisis": {
            "title": "Епікриз"
          }
        },
        "healthSurvey": {
          "title": "Опитування 0",
          "readOnlyAccess": "Попередній перегляд лише для читання. Анкету може заповнити лікар під час першого відвідування",
          "noAnswer": "Немає відповіді",
          "allDisabled": "Опитування 0 не можна змінювати після першого відвідування",
          "partCompleted": "Ця частина опитування завершена",
          "currentTabDisabled": "Ця частина медичного обстеження завершена і не може бути змінена після першого відвідування",
          "emptyFilterSet": "У списку є лише питання без відповіді.",
          "surveyUpdatedByDoctor": "Лікар щойно оновив опитування у частині \"{sectionName}\"",
          "filters": {
            "show": "Показати",
            "all": "Усі",
            "empty": "Порожній"
          },
          "hintPopup": {
            "title": "Не знаєте, як відповісти?",
            "content": "Не турбуйтесь! Залиште складне питання без відповіді. Лікар допоможе вам заповнити пропущені частини опитування під час першої телефонної розмови."
          },
          "SeizureHistory": {
            "introTitle": "Ми хотіли б дізнатися, як довго і які у вас судоми.",
            "introSubtitle": "Це дуже важливо. Зверніть увагу: це ваша історія хвороби, яку можна визначити лише таким чином, немає іншого способу її описати, тому спробуйте отримати точні факти та дати!",
            "next": "Далі",
            "edit": "Редагувати",
            "back": "Попередній",
            "historyCanBeModified": "Ваша історія нападів уже ініціалізована. Ви можете змінити його перед першим відвідуванням.",
            "historyCannotBeModified": "Вашу історію нападів не можна змінити після першого відвідування.",
            "return": "Повернення",
            "outroTitle": "Вітаємо, ви відповіли на всі запитання про історію нападів.",
            "outroSubtitle": "Дякую!",
            "save": "Зберегти",
            "generatingInProgress": "Триває формування історії нападів. Будь ласка, зачекайте ...",
            "saveSuccess": "Історію вилучення успішно збережено",
            "generateSuccess": "Історію конфіскацій створено успішно",
            "stages": {
              "firstSeizure": "Перший приступ",
              "quantityAndRegularity": "Кількість і регулярність",
              "frequency": "Частота",
              "worsePeriods": "Гірші періоди",
              "betterPeriods": "Кращі періоди",
              "breaks": "Перерви"
            },
            "seizureTypesForm": {
              "title": "Визначте кількість типів судом і назвіть їх (максимум 3)",
              "modifyWarning": "Увага! Після зміни типів судом слід переосмислити історію нападів та характеристики нападів",
              "modifyDisabled": "Типи судом не можна змінювати, тому що судоми",
              "typeName": "Назва типу нападу",
              "addType": "Додайте тип нападу"
            },
            "firstSeizureForm": {
              "title": "Введіть дату першого вилучення",
              "subtitle1": "Поверніться і пригадайте будь -який перший приступ.",
              "subtitle2": "Визначте дату вашого першого нападу якомога точніше.",
              "subtitle3": "Ми називаємо це нападом {name} (ми визначимо його тип пізніше разом з вашим провідним лікарем).",
              "sickSince": "Гаразд, тому ви хворієте на:"
            },
            "quantityAndRegularity": {
              "title": "Напади, подібні до першого, тривають донині, свідчать про те, що:",
              "quantityYes": "З цього першого захоплення і до сьогодні вони з’явились",
              "quantityNo": "Напад, як і перший, не повторився",
              "quantityUndefined": "Їх було так багато, що неможливо назвати точну кількість",
              "seizuresQuantity": "Кількість нападів",
              "title2": "Чи відбувалися вони регулярно, тобто приблизно через рівні проміжки часу",
              "yes": "Так",
              "no": "Ні",
              "knowsAllDates": "Ви знаєте дати всіх нападів?",
              "regularOccurrence": "Епізоди трапляються регулярно?"
            },
            "frequency": {
              "title": "Укажіть частоту - введіть, скільки нападів зазвичай відбувається за певний період часу:",
              "in": "В"
            },
            "worsePeriods": {
              "title": "Чи були гірші періоди, коли судоми частішали?",
              "subtitle": "Визначте гірші періоди, коли судоми виникали частіше, ніж зазвичай",
              "date": "Дата",
              "frequency": "Частота нападів",
              "to": "до",
              "remove": "Видалити",
              "addPeriod": "Додати крапку"
            },
            "betterPeriods": {
              "title": "Чи були кращі періоди, коли напади були рідше?",
              "subtitle": "Визначте кращі періоди, коли судоми відбувалися рідше, ніж зазвичай"
            },
            "breaks": {
              "title": "Чи були перерви, коли нападів не було?",
              "subtitle": "Визначте перерви, коли нападів не відбулося",
              "date": "Дата",
              "to": "до",
              "remove": "Видалити",
              "add": "Додайте крапку"
            },
            "knowsAllDates": {
              "subtitle": "Заповніть дати наступних нападів",
              "date": "Дата",
              "remove": "Видалити",
              "add": "Додати дату"
            }
          },
          "Intro": {
            "title1": "Інформація, яку ви надаєте тут, буде передана вашому лікарю. Вони допоможуть правильно поставити діагноз і вилікувати вас.",
            "fillSurvey": "Заповніть опитування здоров'я",
            "inVisit": {
              "title1": "Триває телеконсультація.",
              "title2": "Настав час заповнити анкету разом з лікарем.",
              "title3": "Ваш лікар допоможе вам відповісти на незрозумілі питання.",
              "next": "Далі"
            }
          },
          "PsychomotorDevelopment": {
            "introTitle": "Психомоторний розвиток",
            "introSubtitle1": "На цьому етапі ми хочемо визначити, який у вас загальний психофізичний стан.",
            "introSubtitle2": "Завдяки вашим відповідям лікар підбере оптимальну терапію.",
            "introSubtitle3": "Пам’ятайте, що якщо якесь питання є незрозумілим, ви можете відповісти на нього під час першої телефонної розмови з лікарем.",
            "stages": {
              "psychoPhysicalState": "Фізичний і психічний стан",
              "senses": "Почуття",
              "drugs": "Наркотики"
            },
            "psychoPhysicalState": {
              "overall": "Визначте психомоторний розвиток",
              "retardation": "Визначити психомоторну відсталість (інвалідність)",
              "iq": "Визначити IQ пацієнта",
              "notTested": "Не перевірено",
              "tested": "Перевірено",
              "iqValue": "Введіть значення IQ",
              "speech": "Активне та пасивне мовлення. (Мовлення та розуміння промови)",
              "mentalDisorders": "Історія психічних захворювань",
              "neurologicalSyndrome": "Підтверджений неврологічний синдром",
              "walk": "Прогулянка",
              "sex": "Секс"
            },
            "senses": {
              "nystagmus": "У пацієнта ністагм?",
              "vision": "Бачення",
              "hearing": "Слух"
            },
            "drugs": {
              "smoking": "Куріння",
              "alcohol": "Алкоголь",
              "narcotics": "Наркотики"
            },
            "mentalDisorders": {
              "psychosis": "Психоз в даний час або в минулому",
              "depression": "Депресія в даний час або в минулому",
              "autism": "Діагностований аутизм",
              "adhd": "СДУГ тощо",
              "mentalDisordersNotKnown": "Відсутні або невідомі"
            },
            "neurologicalSyndrome": {
              "piramidalDeficit": "Пірамідний дефіцит",
              "brainDeficit": "Мозок",
              "sensoryDisturbance": "Сенсорні порушення",
              "peripharalParesis": "Перифаральний парез",
              "noNeurologicalSyndrome": "Невідомо чи невідомо"
            }
          },
          "SeizuresCharacteristics": {
            "introTitle": "Характеристика судом",
            "introSubtitle1": "Пам’ятайте, що ви описуєте типові напади або напади. Описуючи один напад, ви можете точно сказати, що сталося. У разі множинних нападів деякі з них можуть дещо відрізнятися один від одного, тому ви перераховуєте їх випадкові характеристики. Тут ви опишете стани перед захопленням: трейлери, безпосередні події та стадії захоплення (початок, продовження, кінець та подія після захоплення). Ви також надасте загальну інформацію про припадки.",
            "introSubtitle2": "Кожна інформація є важливою і може вплинути на заміну лікаря діагнозом, постарайтеся бути максимально точним і точним.",
            "question": "Питання",
            "seizure": "Тип нападу",
            "emptySeizureTypesInfo": "Ви повинні спочатку визначити типи нападів у вкладці «Історія нападів»",
            "stages": {
              "trailers": "Напад\nпророцтва",
              "before": "Негайний попередній напад\nрозлад",
              "start": "Перший симптом\nнападу",
              "next": "Інший\nсимптом нападу",
              "end": "Симптоми\nзакінчення нападу",
              "after": "Після\nнапад",
              "feature": "Напад\nособливості",
              "premonition": "Передчуття"
            },
            "trailers": {
              "trailerPrecise": "Чи існують (або був лише 1 вилучення) причепи конкретних гаваней?",
              "trailerPreciseDescription": "Передбачення - це більш -менш точне передчуття нападу. Під явним трейлером ми маємо на увазі конкретне почуття, яке можна описати: тривогу, смуток, хвилювання тощо. Іноді трейлер невизначений, пацієнту важко його описати. Це тривале явище, воно виникає за кілька годин (рідше за дні) до нападу, що відрізняє його від аури, яка безпосередньо передує нападу. У деяких пацієнтів здатність відчувати судоми з’являється лише через деякий час.",
              "trailerUnspecified": "Чи існують (або був лише 1 випадок) неуточнені причепи для вилучення?",
              "trailerUnspecifiedDescription": ""
            },
            "before": {
              "beforeAura": "Чи є аура до нападу (нападів)?",
              "beforeAuraDescription": "Передчуття нападу (називається аурою) - це суб’єктивний стан, що відчувається по -різному, що сигналізує про те, що напад має відбутися (одразу або на невизначений час). Буває, що судом після аури не буде. Пацієнт може спочатку не знати, що у нього пароксизмальна аура, зазвичай це усвідомлюється після наступних нападів аури.",
              "beforeSpecificStimuli": "Чи можуть судоми / судоми бути викликані специфічними подразниками?",
              "beforeSpecificStimuliDescription": "Напр. раптовий або специфічний звук, удар або біль, переривчасте світло, спалах чи інше явище. Іноді напад є рефлекторним у відповідь на щось.",
              "beforeNystagmus": "Чи є якісь патологічні рухи очей та / або ністагм до нападу (нападів)?",
              "beforeNystagmusDescription": "Ністагм - це швидке переміщення ручок, зазвичай у протилежних напрямках. Інші рухи - це швидке повертання ручок або їх обертання.",
              "beforeImmobility": "Чи є / є спокій, раптове знерухомлення або зупинка руху до нападу (нападів)?",
              "beforeImmobilityDescription": "Бувають випадки, коли ми помічаємо, що пацієнт припиняє рух і стає нерухомим. Це може бути симптомом перед нападом."
            },
            "start": {
              "startFaint": "Чи почався / почався напад із затемненням?",
              "startFaintDescription": "Втрата свідомості - це найглибший тип втрати контакту, контролю та сприйняття. Це означає, що мозок якимось чином \"вимикається\", залишаючи тільки серцебиття і дихання. Це завжди супроводжується падінням, відсутністю реактивності та повною відсутністю контакту. Тривалість варіюється.",
              "startLossConsciousness": "Напади почалися / почалися із втратою або частковою втратою свідомості?",
              "startLossConsciousnessDescription": "При втраті свідомості відбувається автоматична втрата свідомості. Коли відбувається лише втрата свідомості, порушується контакт із оточенням та свідоме мислення, контакт та реакція. Пацієнт може стояти, іноді виконувати різні рухові дії, навіть говорити, але логічний і свідомий контакт та реакції порушуються / втрачаються.",
              "startBodyConvulsions": "Чи судоми почалися / почалися з судом всього тіла?",
              "startBodyConvulsionsDescription": "Судоми - це швидкі, ритмічні рухи м’язів, які скорочуються і ритмічно розслабляються. Це не тремтячі, ламаються або мляві рухи; зазвичай безглузді без напрямку руху.",
              "startBodyPartConvulsions": "Чи почалися судоми (напади) або почалися з судом частини тіла? (одна або кілька кінцівок, тулуб, геміплегія, але НЕ все тіло)",
              "startBodyPartConvulsionsDescription": "Судоми - це швидкі, ритмічні рухи м’язів під час їх ритмічного скорочення та розслаблення. Це не тремтячі, ламаються або мляві рухи; зазвичай безглузді без напрямку руху. Часткові (вогнищеві) судоми можуть «блукати», тобто рухатися (наприклад, від нижньої кінцівки до верхньої)",
              "startFaceConvulsions": "Чи почалися напади (напади) із судом обличчя?",
              "startFaceConvulsionsDescription": "Напади - це швидкі, ритмічні рухи м’язів, які скорочуються і ритмічно розслабляються. Це не тремтячі, ламаються або мляві рухи; зазвичай безглузді без напрямку руху. Часткові (вогнищеві) судоми можуть «блукати», тобто зміщуватися, так само - судоми обличчя можуть вражати все обличчя або його частину або бік.",
              "startBodyStiffness": "Судоми починаються / починаються із загального застигання тіла?",
              "startBodyStiffnessDescription": "Ця скутість називається фазово -тонічним нападом, який виражається у тетанічному скороченні та надзвичайному напруженні всіх м’язів тіла.",
              "startLimbEjection": "Судоми почалися / почалися з викидом 4 кінцівок або раптовим згинанням?",
              "startLimbEjectionDescription": "Це відбувається на початку деяких судом, переважно у дітей, і може бути симптомом так званих судомних зсувів.",
              "startEyeMovementDisorders": "Чи є порушення руху очей (наприклад, згадані вище) початковим симптомом нападу (нападів)?",
              "startEyeMovementDisordersDescription": "Ністагм - це швидке переміщення ручок, зазвичай у протилежних напрямках. Інші рухи - це швидке повертання ручок або їх обертання.",
              "startEyeTurnPernament": "Чи можуть очні яблука повернутися на одну сторону на початку нападу (нападів)?",
              "startEyeTurnPernamentDescription": "Цей поворот можна вліво, вправо, вгору і вниз. Це може бути швидко і повільно, зазвичай для того, щоб ручки повернулися в проміжне положення, потрібно деякий час.",
              "startBodyTurn": "Чи може початок судом / судом проявитися як повільне або швидке повернення тіла в одну (праву чи ліву) сторону?",
              "startBodyTurnDescription": "Ця фраза часто асоціюється з поворотом очного яблука, про який говорили у попередньому питанні.",
              "startGroundFall": "Чи він падає на землю на початку нападу / нападу?",
              "startGroundFallDescription": "Падіння визначається як більш -менш раптове перекидання пацієнта, яке може бути: «жорстким», «млявим», швидким і повільним, ковзанням по предметах, частково контрольованим або неконтрольованим.",
              "startBitTongue": "Ви кусаєте язик або якусь частину рота на початку нападу (нападів)?",
              "startBitTongueDescription": "Зазвичай - якщо такий укус має місце - це зазвичай відбувається на початку нападу.",
              "startShout": "Чи кричить пацієнт на початку нападу (нападів)?",
              "startShoutDescription": "Йдеться про насильницький і зазвичай гучний крик, більш -менш сформульований; це може бути звук, це можуть бути окремі слова",
              "startSounds": "Чи видає пацієнт якісь звуки або голос на початку нападу (нападів)?",
              "startSoundsDescription": "Цього разу мова йде про тихі, довші звуки, висловлювання та шуми, складені або абсолютно безглузді.",
              "startSpeechStop": "Чи припиняється мова на початку нападу?",
              "startSpeechStopDescription": "Це ситуація, коли пацієнт не може говорити або не відповідає на запитання. Часто неможливо визначити причину.",
              "startMotorAutomatisms": "Чи виконує пацієнт простий або складний руховий автоматизм на початку нападу (нападів)?",
              "startMotorAutomatismsDescription": "Пацієнт може несвідомо робити деякі повторювані рухи або жести, це називається автоматизмом.",
              "startImmobility": "Чи є нерухомість або повна нерухомість на початку нападу (нападів)?",
              "startImmobilityDescription": "Пацієнт може «померти» при русі, що є частиною початку нападу.",
              "startLaugh": "Чи може хвора людина сміятися на початку нападу / нападів?",
              "startLaughDescription": "Це рідкісний симптом, сміх може бути бурхливим, раптовим або повільним.",
              "startChuck": "Чи є якийсь дзвін, пощеплення або слиновиділення на початку нападу / нападів?",
              "startChuckDescription": "Це називається вегетативними симптомами, які зазвичай не усвідомлюються.",
              "startCry": "Чи є плач на початку нападу (нападів)?",
              "startCryDescription": "Цей крик можна назвати емоційним симптомом при фокальних припадках.",
              "startEpigastricSymptoms": "Чи виникають у вас епігастральні симптоми на початку судом / судом?",
              "startEpigastricSymptomsDescription": "Такі симптоми: відчуття «блукання шлунка», «переповнення кишечника» або відчуття наповнення шлунка вмістом.",
              "startDejaVu": "Чи відчуваєте ви марення у часі (дежавю та дежа веку) на початку нападу / нападів?",
              "startDejaVuDescription": "Це симптоми так званого дежавю та дежа веку, тобто відчуття, що «щось сталося», «я сказав це раніше» або «ця ситуація вже сталася»; можна з часом стати нереальним і відчути дезорієнтацію.",
              "startVisualHallucinations": "Чи відчуваєте ви візуальні галюцинації на початку нападу (нападів)?",
              "startVisualHallucinationsDescription": "Такі галюцинації (галюцинації) можуть бути простими і складнішими: людина може побачити те, чого немає, об’єкти можуть зменшуватися і збільшуватися, з’являтися зигзаг різних форм і кольорів тощо.",
              "startAuditoryHallucinations": "Чи відчуваєте ви слухові галюцинації на початку нападу / нападів?",
              "startAuditoryHallucinationsDescription": "Ці галюцинації (галюцинації) можуть бути простими і складнішими: ви можете почути те, чого немає, це можуть бути звуки, слова, свистки тощо.",
              "startFeelingFearOrAnxiety": "Судом починається з почуття страху чи тривоги?",
              "startFeelingFearOrAnxietyDescription": "Це почуття може виникнути без видимих причин, зазвичай раптово.",
              "startEyelidsMyoclonus": "Міоклонус повік на початку нападу (нападів)?",
              "startEyelidsMyoclonusDescription": "Міоклонус повік - це швидке і швидке розривання повік, які \"стрибають\" зазвичай на кілька секунд, що може бути, а може і не бути пов'язано з ненормальними рухами очей.",
              "startMyoclonus": "Чи є міоклонус на початку нападу / судом?",
              "startMyoclonusDescription": "Міоклонус - це раптовий і бурхливий ривок (більш сильний і зазвичай більший за судоми) загальних м’язів або лише окремих частин тіла або груп м’язів.",
              "startLossConsciousnessPartially": "Часткова втрата свідомості",
              "startLossConsciousnessPartiallyDescription": "Чи була часткова втрата свідомості першою ознакою нападу? Порушується будь-яка сфера свідомості",
              "startRhythmicConvulsions": "Ритмічні судоми",
              "startRhythmicConvulsionsDescription": "Чи були ці конвульсії як перший симптом нападу ритмічними?",
              "startHyperkineticSymptoms": "Гіперкінетичні симптоми",
              "startHyperkineticSymptomsDescription": "Чи був першим симптомом нападу поява основних гіперкінетичних симптомів: педалювання, різноспрямовані поштовхи тазом тощо?",
              "startAtony": "Атонія або астатичні судоми всього або частини тіла",
              "startAtonyDescription": "Чи був першим симптомом нападу м’язова атонія чи астатичний напад, що охоплював усе тіло чи його частину (наприклад, лише голову)?",
              "startOtherAutomatisms": "Інші автоматизми",
              "startOtherAutomatismsDescription": "Чи були першим симптомом нападу інші автоматизми: все тіло, кінцівки, голосові персеверації, статеві автоматизми, інші комплексні автоматизми?",
              "startHeartSymptoms": "Серцево-вегетативні симптоми: серцебиття/тахія/браді/асистолія",
              "startHeartSymptomsDescription": "Чи були першим симптомом нападу серцево-судинні вегетативні симптоми: серцебиття, тахікардія чи брадикардія, чи асистолія?",
              "startAutonomicSymptoms": "Додаткові вегетативні симптоми: блідість, почервоніння, респіраторний дистрес, ненормальний рух зіниць, позиви до сечі/калу",
              "startAutonomicSymptomsDescription": "Чи були першим симптомом нападу додаткові вегетативні симптоми: обличчя, почервоніння, респіраторний дистрес, порушення рухів зіниць, позиви до сечовипускання/калу?",
              "startLanguageIssues": "Дисфазія/афазія, дислексія/алексія та/або відсутність розуміння мовлення/мови",
              "startLanguageIssuesDescription": "Чи були перші симптоми нападу розлади мовлення, такі як дисфазія/афазія, дислексія/алексія та/або відсутність розуміння мовлення/мови?",
              "startMemoryDisorders": "Порушення пам'яті під час нападу (інші органи чуття нормальні)",
              "startMemoryDisordersDescription": "Чи були першим симптомом нападу порушення пам'яті під час нападу з повним збереженням інших органів чуття?",
              "startSomatosensorySymptoms": "Вогнищеві соматосенсорні симптоми, найчастіше парестезії з поколюванням і/або онімінням, поколюванням, лоскотом, повзанням або відчуттям електричного удару.",
              "startSomatosensorySymptomsDescription": "Чи були перші симптоми нападу вогнищеві соматосенсорні симптоми, найчастіше парестезії з поколюванням та/або онімінням, поколюванням, лоскотанням, повзанням або відчуттям удару електричним струмом?",
              "startBodyDistortion": "Спотворене зображення тіла з відчуттям руху (наприклад, ширяння) або зміненої пози (наприклад, поворот) у нерухомій кінцівці",
              "startBodyDistortionDescription": "Чи були перші симптоми нападу спотворене зображення тіла з відчуттям руху (наприклад, ширяння) або зміна пози (наприклад, поворот) у нерухомій кінцівці?"
            },
            "next": {
              "nextFaint": "Чи була або втрата свідомості під час нападу (якщо вона була на початку)?",
              "nextFaintDescription": "Несвідомість може приєднатися або продовжуватися",
              "nextLossConsciousness": "Чи була або є втрата свідомості під час нападу (якщо він був спочатку)?",
              "nextLossConsciousnessDescription": "Втрата свідомості може приєднатися або тривати",
              "nextBodyConvulsions": "Напади сталися зараз або вони були з моменту їх початку на початку?",
              "nextBodyConvulsionsDescription": "Судоми могли статися рано і продовжуються, а можуть з’явитися лише зараз.",
              "nextBodyPartConvulsions": "Чи почалися зараз вогнищеві напади або вони продовжуються так, як почалися?",
              "nextBodyPartConvulsionsDescription": "Вогнищеві напади могли виникнути рано і продовжувати зберігатися, а можуть з’явитися лише зараз.",
              "nextFaceConvulsions": "Поторгування лицьових м’язів сталося зараз чи триває так, як почалося?",
              "nextFaceConvulsionsDescription": "Судоми на обличчі могли виникнути рано і продовжувати зберігатися, а можуть і не виникнути досі.",
              "nextBodyStiffness": "Жорсткість всього тіла ще триває або тепер вона стала жорсткою?",
              "nextBodyStiffnessDescription": "Як і судоми, тонічна скутість може приєднатися або тривати, якби це було так.",
              "nextEyeMovementDisorders": "Чи приєдналися патологічні рухи очей, чи ще тривають",
              "nextEyeMovementDisordersDescription": "Як і інші вищеописані симптоми, вони можуть з’являтися або тривати.",
              "nextEyeTurnPernament": "Чи відбулося / є повернення ручок на одну з 4 сторін?",
              "nextEyeTurnPernamentDescription": "Як симптоми досі: може приєднатися тут або триватиме так, як це вже було.",
              "nextBodyTurn": "Чи відбувся / відбувається однобічний поворот кузова?",
              "nextBodyTurnDescription": "Як і інші вищезазначені симптоми: цей симптом з’явився або триває?",
              "nextGroundFall": "Чи відбулося падіння, і якщо це було на початку, чи хворі все ще лежать?",
              "nextGroundFallDescription": "Падіння - якщо воно було на початку - могло закінчитися тим, що пацієнт вставав або все ще лежав.",
              "nextBitTongue": "Ви кусали язик або частини рота під час нападу?",
              "nextBitTongueDescription": "Якби це не сталося на початку нападу! Як це було на початку, а не зараз!",
              "nextUrinating": "Ви виділяли сечу під час нападу?",
              "nextUrinatingDescription": "Пацієнт може несвідомо виділяти сечу під час або після нападу.",
              "nextDefecation": "У вас було випорожнення або відчуття терміновості?",
              "nextDefecationDescription": "Так само, як і сеча - пацієнт може несвідомо видати фекалії або відчути необхідність видалити кал.",
              "nextShout": "Чи є крик під час нападу?",
              "nextShoutDescription": "Якщо це не було симптомом на початку нападу, це може з’явитися зараз.",
              "nextSounds": "Чи були або є (якщо такі є) вокалізація або словесні звуки під час нападу?",
              "nextSoundsDescription": "Пацієнт може продовжувати видавати різні звуки, або вони можуть з’явитися зараз.",
              "nextAggression": "Чи розвивається агресія під час нападу?",
              "nextAggressionDescription": "Чи проявляється зараз якась агресивна поведінка чи жести пацієнта?",
              "nextMotorAutomatisms": "Чи є (якщо такі є) чи відбулися автоматизми під час нападу?",
              "nextMotorAutomatismsDescription": "Іноді цей симптом може тривати тривалий час.",
              "nextImmobility": "Чи спокій чи спокій стався зараз чи триває від початку?",
              "nextImmobilityDescription": "Як і інші симптоми",
              "nextLaugh": "Чи є сміх під час нападу?",
              "nextLaughDescription": "Якщо його не було на початку нападу - це може з’явитися зараз.",
              "nextChuck": "Чи є під час нападів / нападів якийсь дзвін, тряска або виділення слини?",
              "nextChuckDescription": "Якщо колишні чи діючі, то ні, чи прибули вони зараз?",
              "nextCry": "Він приєднався чи продовжує плакати?",
              "nextCryDescription": "Як і інші симптоми, це могло бути знову, а може з’явитися зараз.",
              "nextEpigastricSymptoms": "Чи виникають епігастральні симптоми під час нападів / нападів?",
              "nextEpigastricSymptomsDescription": "Якщо вони були, то чи тривали вони, якщо ні - чи прибули?",
              "nextDejaVu": "Чи виникають через час судоми / судоми марення, пов’язані з часом (дежавю та дежа веку)?",
              "nextDejaVuDescription": "Як інші симптоми могли бути з нуля або прикріплені зараз.",
              "nextVisualHallucinations": "Чи відчуваєте ви візуальні галюцинації під час нападу (нападів)?",
              "nextVisualHallucinationsDescription": "Вони тривають або приєдналися?",
              "nextAuditoryHallucinations": "Чи відчуваєте ви слухові галюцинації під час нападу (нападів)?",
              "nextAuditoryHallucinationsDescription": "Вони тривають або приєдналися?",
              "nextEyelidsMyoclonus": "Чи розвивається міоклонус повік під час нападу (нападів)?",
              "nextEyelidsMyoclonusDescription": "Вони тривають або приєдналися?",
              "nextLossConsciousnessPartially": "Часткова втрата свідомості",
              "nextLossConsciousnessPartiallyDescription": "Чи була часткова втрата свідомості наступною ознакою нападу? Порушується будь-яка сфера свідомості",
              "nextRhythmicConvulsions": "Ритмічні судоми",
              "nextRhythmicConvulsionsDescription": "Чи були ці судоми як наступний симптом нападу ритмічними?",
              "nextHyperkineticSymptoms": "Гіперкінетичні симптоми",
              "nextHyperkineticSymptomsDescription": "Чи був наступним симптомом нападу поява основних гіперкінетичних симптомів: педалювання, різноспрямовані поштовхи тазом тощо?",
              "nextOtherAutomatisms": "Інші автоматизми",
              "nextOtherAutomatismsDescription": "Чи були наступними симптомами нападу інші автоматизми: все тіло, кінцівки, голосові персеверації, статеві автоматизми, інші комплексні автоматизми?",
              "nextHeartSymptoms": "Серцево-вегетативні симптоми: серцебиття/тахія/браді/асистолія",
              "nextHeartSymptomsDescription": "Чи були наступними симптомами нападу серцево-судинні вегетативні симптоми: серцебиття, тахікардія або брадикардія або асистолія?",
              "nextAutonomicSymptoms": "Додаткові вегетативні симптоми: блідість, почервоніння, респіраторний дистрес, ненормальний рух зіниць, позиви до сечі/калу",
              "nextAutonomicSymptomsDescription": "Чи були наступним симптомом нападу додаткові вегетативні симптоми: обличчя, почервоніння, респіраторний дистрес, порушення рухів зіниць, позиви до сечовипускання/калу?",
              "nextLanguageIssues": "Дисфазія/афазія, дислексія/алексія та/або відсутність розуміння мовлення/мови",
              "nextLanguageIssuesDescription": "Чи були наступні симптоми нападу розлади мовлення, такі як дисфазія/афазія, дислексія/алексія та/або відсутність розуміння мовлення/мови?",
              "nextMemoryDisorders": "Порушення пам'яті під час нападу (інші органи чуття нормальні)",
              "nextMemoryDisordersDescription": "Чи були наступними симптомами нападу порушення пам'яті під час нападу з повним збереженням інших органів чуття?",
              "nextSomatosensorySymptoms": "Вогнищеві соматосенсорні симптоми, найчастіше парестезії з поколюванням і/або онімінням, поколюванням, лоскотом, повзанням або відчуттям електричного удару.",
              "nextSomatosensorySymptomsDescription": "Чи були наступні симптоми нападу вогнищеві соматосенсорні симптоми, найчастіше парестезії з поколюванням та/або онімінням, поколювання, лоскотання, повзання або відчуття ураження електричним струмом?",
              "nextBodyDistortion": "Спотворене зображення тіла з відчуттям руху (наприклад, ширяння) або зміненої пози (наприклад, поворот) у нерухомій кінцівці",
              "nextBodyDistortionDescription": "Чи було наступним симптомом нападу спотворене зображення тіла з відчуттям руху (наприклад, ширяння) або зміна пози (наприклад, поворот) у нерухомій кінцівці?"
            },
            "end": {
              "endAnotherSeizure": "Чи може цей напад перетворитися на новий напад?",
              "endAnotherSeizureDescription": "Судом можна лише ізолювати, як описано, або змінити з закінченням на інший.",
              "endBodyConvulsions": "Чи судомні напади закінчуються генералізованими або вони все ще тривають?",
              "endBodyConvulsionsDescription": "Якщо вони були раніше (на початку і під час або приєдналися під час) або в кінці нападу.",
              "endBodyPartConvulsions": "Фокальні напади тривають або з’являються наприкінці?",
              "endBodyPartConvulsionsDescription": "Судоми могли статися рано і продовжуються, а можуть з’явитися лише зараз.",
              "endFaceConvulsions": "Судоми лицьових м’язів тривалі або з’являються наприкінці?",
              "endFaceConvulsionsDescription": "Як генералізовані та осередкові напади.",
              "endEyeMovementDisorders": "Чи напад закінчується ненормальними рухами набору номера?",
              "endEyeMovementDisordersDescription": "Або вони продовжують як раніше?",
              "endEyeTurnPernament": "Чи закінчується напад повертанням ручок в одну з 4 сторін?",
              "endEyeTurnPernamentDescription": "Або все відбувається так, як було?",
              "endBodyTurn": "Чи напад закінчується поворотом тіла на одну сторону?",
              "endBodyTurnDescription": "Або все відбувається так, як було?",
              "endGroundFall": "Він падає в кінці нападу?",
              "endGroundFallDescription": "Якщо раніше не було падіння, чи може це статися зараз? Або хвора людина все ще лежить так, ніби впала на початку чи посередині?",
              "endUrinating": "Чи видаляв пацієнт сечу в кінці нападу?",
              "endUrinatingDescription": "Чи виникає нетримання сечі сфінктера в кінці нападу?",
              "endDefecation": "В кінці нападу пацієнт несвідомо видав кал або у нього було випорожнення?",
              "endDefecationDescription": "Чи виникає нетримання сечі сфінктера в кінці нападу?",
              "endSounds": "Чи була якась вербалізація в кінці нападу?",
              "endSoundsDescription": "Чи з’являються вокалізації чи зберігаються (якщо такі є) наприкінці?",
              "endAggression": "Чи була агресія в кінці захоплення?",
              "endAggressionDescription": "Чи могла агресія проявитися в кінці нападу, або це відбувається так, як було?",
              "endMotorAutomatisms": "Чи відбувалися поведінка / автоматичні рухи в кінці нападу?",
              "endMotorAutomatismsDescription": "Чи існують такі автоматизми чи з'явилися зараз?",
              "endImmobility": "Чи приступ зупинився?",
              "endImmobilityDescription": "Тиша як симптом припинення судом.",
              "endLaugh": "Чи сміється пацієнт наприкінці нападу?",
              "endLaughDescription": "Як і інші симптоми.",
              "endChuck": "Чи є якісь дзвінки, цокання або виділення слини в кінці нападу / нападів?",
              "endChuckDescription": "Як і інші симптоми.",
              "endCry": "Чи плаче пацієнт в кінці нападу?",
              "endCryDescription": "Плач як кінцевий симптом.",
              "endVomit": "Чи зригує пацієнт в кінці нападу?",
              "endVomitDescription": "Блювота може бути вмістом шлунка або може бути позивом до блювоти."
            },
            "after": {
              "afterEyeMovementDisorders": "Чи проявляється патологічний рух очей після нападу?",
              "afterEyeMovementDisordersDescription": "Можуть бути випадки, коли цей симптом може продовжуватися, навіть якщо приступ закінчився.",
              "afterEyeTurnPernament": "Чи є повернення очних яблук після нападу?",
              "afterEyeTurnPernamentDescription": "Можуть бути випадки, коли цей симптом може продовжуватися, навіть якщо приступ закінчився.",
              "afterParesisOrParalysis": "Постуральний парез/параліч",
              "afterparesisOrParalysisDescription": "Позначається як Todds виникає після судом, зазвичай вражає 1 або більше кінцівок, іноді з половинкою і зникає протягом 24 годин.",
              "afterUrinating": "Чи може пацієнт виділити сечу після нападу?",
              "afterUrinatingDescription": "Сечовипускання може виникнути після нападу.",
              "afterDefecation": "Чи може пацієнт пройти / випорожнитися після нападу?",
              "afterDefecationDescription": "Дуже рідко після нападу може виникати фекалія.",
              "afterConfusion": "Чи виникають ЗІЗ після нападу?",
              "afterConfusionDescription": "Цей термін використовується для опису розладу - зазвичай по -різному швидко стихаючого - свідомості або її повної відсутності. Деякі пацієнти, однак, негайно усвідомлюють і повністю контактують після нападу.",
              "afterSleep": "Чи слідує за нападом PPD?",
              "afterSleepDescription": "Після більш важких нападів слідує такий сон, який зазвичай триває 1/2 години.",
              "afterSalivaOrBloodySalivaFoam": "Чи виникає піна в роті після нападу: від слини або кров’янистого кольору з кров’ю?",
              "afterSalivaOrBloodySalivaFoamDescription": "Це симптом «ущільненої» слини (спінювання її через гіпервентиляцію).",
              "afterHyperventilation": "Чи виникає гіпервентиляція після нападу?",
              "afterHyperventilationDescription": "Зазвичай гіпервентиляція є наслідком кисневого боргу через затримку дихання під час тонізуючої фази.",
              "afterAggression": "Чи є агресія після захоплення?",
              "afterAggressionDescription": "Агресія може бути симптомом ЗІЗ.",
              "afterMotorAutomatisms": "Чи можуть виникнути автоматизми після нападу?",
              "afterMotorAutomatismsDescription": "Подібно до агресії, це може бути симптомом ЗІЗ.",
              "afterVomit": "Пацієнт кидає після нападу?",
              "afterVomitDescription": "Зригування або накопичення під час блювоти може статися після нападу.",
              "afterHighTemperature": "Чи підвищується температура тіла після нападу?",
              "afterHighTemperatureDescription": "Це може статися, це може бути симптоматичне переохолодження.",
              "afterParesisOrParalysisDescription": "Чи був постиктальний парез або параліч типу Тодда після нападу?"
            },
            "feature": {
              "featureMany": "Чи можуть такі напади виникати послідовно / групами?",
              "featureManyDescription": "Для кількох судомних ситуацій! Судоми, як правило, мають послідовний характер один за одним або протягом короткого періоду часу, іноді нічні або денні.",
              "featureDayOccurrence": "Чи відбувалися судоми / судоми відбувалися протягом дня?",
              "featureDayOccurrenceDescription": "Припадки неспання - це судоми протягом дня, ми припускаємо, що пацієнт спить вночі.",
              "featureNightOccurrence": "Чи відбувалися судоми / судоми відбувалися вночі?",
              "featureNightOccurrenceDescription": "Ми припускаємо, що нічні судоми виникають під час сну.",
              "featureAwakeningOccurrence": "Чи стався напад / напади сталися безпосередньо перед пробудженням, під час пробудження або відразу після пробудження?",
              "featureAwakeningOccurrenceDescription": "Деякі типи зустрічаються лише в тому випадку, якщо.",
              "featureMorningOccurrence": "Чи судоми / судоми відбувалися / відбувалися вранці?",
              "featureMorningOccurrenceDescription": "Бувають випадки, коли перші кілька годин ранку є єдиним сезоном нападів.",
              "featureDuration": "Скільки тривав напад (скільки зазвичай тривають напади)?",
              "featureDurationDescription": "Виберіть час з інтервалом",
              "beforeSpecificStimuli": "Судомний напад, викликаний певним подразником",
              "beforeSpecificStimuliDescription": "Чи може напад бути спровокований певним подразником або станом (ПС, звуки, недосипання, емоції, стрес тощо)?",
              "formChanges": "Варіабельний і поліморфний напад",
              "formChangesDescription": "Чи змінюється форма та тривалість цього нападу? Не плутати з іншими видами нападів, якщо вони є одночасними!"
            },
            "fixCriticals": {
              "title": "Удосконалення вектора для нападу",
              "subtitle": "Підтверджене захоплення",
              "description1": "Відповіді пацієнтів, які сильно відрізняються від вибраного еталонного вектора нападу, відображаються нижче.",
              "description2": "Уважно запитайте пацієнта про відповідь, якщо вона не згодна з відповіддю з опитування 0 виправте її.",
              "oldAnswer": "Опитування 0 відповідей",
              "newAnswer": "Нова відповідь",
              "saveConfirm": "Ви впевнені, що бажаєте змінити цю частину опитування 0?",
              "saveSuccess": "Зміни успішно збережено",
              "vectorChangedBy": "Вектор змінено"
            },
            "premonition": {
              "trailer": "Передчуття нападу",
              "trailerDescription": "Чи передує нападу передчуття – відчуття наближення нападу за хвилини/години до нападу? Відчуття/оповіщення у свідомості або без свідомості."
            }
          },
          "Comorbidities": {
            "introTitle": "Супутні захворювання",
            "introSubtitle1": "Тут ми хочемо визначити, чи є у вас супутні захворювання.",
            "introSubtitle2": "Пам’ятайте, що захворювання можуть впливати на перебіг епілепсії.",
            "dataSavedMsg": "Інформація про супутні захворювання збережена",
            "hasHeadaches": "Головні болі",
            "answerNo": "Жодного",
            "answerYes": "Так, є",
            "answerYesInt": "Так",
            "disease": "Захворювання",
            "typeDisease": "Введіть код захворювання або назву",
            "hasPregnancies": "Вагітність",
            "pregnancies": "Кількість вагітностей",
            "hasChildbirths": "Пологи",
            "childbirths": "Кількість пологів",
            "hasNeurologicalDiseases": "Неврологічні захворювання",
            "hasInfectiousDiseases": "Запальні та інфекційні захворювання",
            "hasCancerDiseases": "Ракові захворювання",
            "hasImmuneDiseases": "Захворювання крові та імунітету",
            "hasEndocrineDiseases": "Ендокринні та метаболічні захворювання",
            "hasEyeDiseases": "Очні захворювання",
            "hasLaryngologicalDiseases": "Ларингологічні захворювання",
            "hasHeartDiseases": "Захворювання серця і кровоносної системи",
            "hasRespiratoryDiseases": "Захворювання органів дихання",
            "hasDigestiveDiseases": "Захворювання органів травлення",
            "hasSkinDiseases": "Шкірні захворювання",
            "hasBoneDiseases": "Кісткові захворювання",
            "hasUrogenitalDiseases": "Захворювання сечостатевої системи",
            "hasDevelopmentalDefects": "Вроджені вади розвитку та хромосомні аберації",
            "hasInjuriesOrPoisoning": "Травми та отруєння",
            "somaticDiseases": "Пацієнт має захворювання, які впливають на лікування епілепсії",
            "hasPsychiatricDiseases": "Психічні захворювання",
            "headaches": "У хворого головні болі"
          },
          "DrugsHistory": {
            "introTitle": "Лікування",
            "introSubtitle1": "Це історія вашого лікування до сьогодні.",
            "introSubtitle2": "Введіть ліки, які ви вживали в минулому, і який був ефект. Кожен лікарський засіб має назву активної речовини в повній та скороченій формі. Якщо ви цього не пам’ятаєте, не переживайте.",
            "introSubtitle3": "Важливо правильно вибрати назву препарату.",
            "drug": "Ліки",
            "drugEffects": "Наслідки прийому препарату",
            "addDrug": "Додайте ліки",
            "removeDrug": "Видаліть ліки",
            "typeDrug": "Введіть назву ліки",
            "dataSavedMsg": "Історія лікування збережена"
          }
        },
        "visitSurvey": {
          "stages": {
            "patientState": "Стан хворого",
            "patientOpinion": "Думка пацієнта",
            "doctorOpinion": "Думка лікаря"
          },
          "patientState": {
            "lastSeizureDate": "Дата останнього вилучення перед цим візитом",
            "wellBeing": "Загальне самопочуття після останнього візиту",
            "allSeizureRegistered": "Я вніс / не вніс свої напади в Календар подій з моменту мого останнього візиту",
            "additionalIncidents": "Наступні події відбулися з часу останнього відвідування - окрім подій, перелічених у Календарі подій"
          },
          "patientOpinion": {
            "seizureCountByPatient": "Суб'єктивно чи була однакова кількість припадків?",
            "seizureEvaluationByPatient": "Суб'єктивно, наскільки сильними були судоми",
            "currentTreatment": "Моє поточне лікування",
            "sideEffects": "Побічні ефекти поточного лікування"
          },
          "doctorOpinion": {
            "seizureCountByDoctor": "Кількість нападів за даними лікаря",
            "seizureEvaluationByDoctor": "Якість судом на думку лікаря",
            "treatmentEfficiency": "Ефективність лікування на думку лікаря",
            "treatmentSafety": "Безпека лікування на думку лікаря"
          },
          "date": "Дата",
          "fromDate": "Від",
          "toDate": "до",
          "seizure": "Напад",
          "save": "зберегти",
          "addSeizure": "Додайте захоплення",
          "noEpisodeAdded": "Вилучення не зареєстровано",
          "episodeTypeLbl": "Типи нападів",
          "incidentDescription": "Опис нападу",
          "noSeizure": "Без судом",
          "next": "Далі",
          "howManySeizures": "Скільки нападів з моменту останнього відвідування",
          "countableLbl": "Там були",
          "unCountableLbl": "Їх було так багато, що неможливо назвати точну кількість",
          "seizuresMandatoryError": "Додайте дати вилучення",
          "add": "додати",
          "close": "Закрити",
          "seizuresNotAddedSummary": "Нападів не додано з часу останнього відвідування",
          "seizuresDates": "Дати судом",
          "seizuresSinceLastVisit": "Кількість нападів з моменту останнього відвідування",
          "per": "пер",
          "addNewSeizureType": "Додати новий тип нападу",
          "addSeizureTypeDialog": {
            "title": "Додавання нового типу захоплення",
            "name": "Назва нападу",
            "success": "Додано новий тип захоплення"
          },
          "seizurePeriod": "Вкажіть період",
          "addPeriodicSeizure": "Додати період",
          "deletePeriodicSeizure": "Видалити період",
          "until": "Поки",
          "seizuresInPeriod": "Кількість епізодів"
        },
        "visitCertificates": {
          "create": "Створити сертифікат",
          "doNotCreate": "Не створювати сертифікат",
          "certificateType": "Тип сертифіката",
          "additionalRemarks": "Додаткові зауваження",
          "additionalRemarksPlaceholder": "Введіть додаткові зауваження",
          "generate": "Створити",
          "certificateContent": "Зміст сертифіката:",
          "pickDate": "Виберіть дату",
          "threeMonths": "3 місяці",
          "sixMonths": "6 місяців",
          "oneYear": "1 рік",
          "date": "Дата",
          "enterProcedureName": "Введіть назву процедури",
          "enterActivityName": "Введіть назву діяльності",
          "validationError": "Обов'язкове поле",
          "addNext": "Додайте наступний сертифікат",
          "otherDescriptionLbl": "Введіть опис сертифіката",
          "endOfYear": "Кінець року",
          "addCertificate": "Додати сертифікат",
          "noCertificates": "Список сертифікатів порожній"
        },
        "sickLeaves": {
          "title": "Лікарняні листи",
          "number": "Номер",
          "dateFrom": "Від",
          "dateTo": "до",
          "type": "Тип",
          "status": "Статус",
          "add": "Додати лікарняний",
          "addPopup": {
            "title": "Видача нового листка непрацездатності",
            "addBtn": "Оформити лікарняний",
            "codes": {
              "a": "непрацездатність, що настала після перерви не більше 60 днів, - спричинена тим самим захворюванням, яке спричинило непрацездатність до перерви",
              "b": "падіння непрацездатності під час вагітності",
              "c": "непрацездатність внаслідок зловживання алкоголем",
              "d": "непрацездатність внаслідок захворювання на туберкульоз",
              "e": "втрата працездатності внаслідок захворювання, що виникло не пізніше 3 місяців з дня припинення страхування на випадок хвороби, - у разі інфекційного захворювання, інкубаційний період якого перевищує 14 днів, або іншого захворювання, симптоми якого проявляються після закінчення терміну більше 14 днів з моменту початку захворювання"
            },
            "insuredPersonTitle": "Дані застрахованої особи, на яку видається ZUS ZLA",
            "type": "Причина нездатності",
            "pesel": "Песель",
            "fetchData": "Отримати дані",
            "insuredIn": "Місце страхування",
            "change": "Зміна",
            "payersTitle": "Вибір платників, для яких будуть створені довідки",
            "sickLeavesTitle": "Список попередніх ZUS ZLA застрахованої особи",
            "detailsTitle": "Довідка про непрацездатність",
            "inabilityFrom": "Нездатність від",
            "inabilityTo": "Нездатність до",
            "daysNumber": "Кількість днів",
            "reason": "Причина",
            "careForData": "Дані особи, яка перебуває під опікою",
            "relationship": "Спорідненість",
            "relationship1": "дитина",
            "relationship2": "дружина, батьки, вітчим, батько дитини, бабуся, дідусь, свекри, онуки, мачуха",
            "relationship3": "інша людина",
            "letterCode": "Літерний код",
            "indication": "Медичне показання",
            "addressesTitle": "Адреса перебування застрахованої особи в період непрацездатності",
            "payerName": "Ім'я",
            "payerNip": "NIP",
            "hasPue": "Чи має вона/він профіль PUE?",
            "address": "Адреса",
            "inabilityRange": "Період непрацездатності",
            "disease": "Захворювання",
            "number": "Серія і номер",
            "status": "Статус",
            "successToast": "Лікарняний лист видано",
            "addPayerTitle": "Додавання нового платника",
            "search": "Пошук",
            "payerNotFound": "Платника не знайдено",
            "addNewPayer": "Додати нового платника",
            "confirmIssueSickLeave": "Ви впевнені, що хочете оформити лікарняний?",
            "addNewAddress": "Додайте нову адресу",
            "workPlace": "робоче місце",
            "icd10Note": "Примітка! Основний код ICD 10 буде передано до ZUS"
          },
          "cancelPopup": {
            "title": "Анулювання листка непрацездатності",
            "reason": "Причина скасування",
            "confirmBtn": "Анулювати листок непрацездатності",
            "confirmCancelSickLeave": "Ви впевнені, що хочете анулювати цей лікарняний?",
            "successToast": "Лікарняний скасовано"
          }
        }
      },
      "patientVisits": {
        "nextVisit": {
          "title": "Відвідування",
          "nextTitle": "Подальші візити",
          "changeDate": "Змінити дату",
          "join": "Почніть візит",
          "forego": "Скасувати",
          "payFor": "Оплата за візит"
        },
        "lastRecommendation": {
          "title": "Рекомендації з попереднього візиту",
          "pdf": "Завантажити резюме",
          "drug": "Ліки",
          "dosages": "Дозування",
          "careLbl": "Піклуватися",
          "from": "від",
          "to": "до"
        },
        "visitList": {
          "title": "Відвідує архів",
          "subtitle": "минулі відвідування",
          "pdf": "Завантажити PDF",
          "date": "Дата",
          "type": "Тип",
          "category": "Категорія",
          "doctor": "Лікар",
          "summary": "Рекомендації",
          "invoice": "Рахунок -фактура"
        }
      },
      "password": {
        "reset": {
          "title": "Відновлення паролю",
          "subtitle1": "Поверніться до",
          "subtitle2": "екран входу",
          "form": {
            "email": "Адреса електронної пошти",
            "send": "Підтвердити"
          },
          "response1": "На вказану адресу електронної пошти надіслано повідомлення з посиланням для підтвердження скидання.",
          "response2": "Будь ласка, закрийте це вікно браузера та клацніть на надіслане посилання.",
          "resendMessage": "Посилання для зміни пароля знову надіслано"
        },
        "resetConfirm": {
          "title": "Підтвердьте зміну пароля",
          "form": {
            "password": "Новий пароль",
            "passwordConfirm": "Підтвердьте пароль",
            "send": "Підтвердити"
          },
          "response": "Пароль змінено.",
          "loginBtnLbl": "Увійдіть"
        }
      },
      "patient": {
        "account": {
          "register": {
            "confirm": {
              "outcomeSuccesMsg": "Реєстрація успішно завершена",
              "outcomeSuccesDetMsg": "Натисніть кнопку нижче, щоб перейти на сторінку входу",
              "outcomeFailureMsg": "Під час спроби підтвердити реєстрацію виникла проблема",
              "outcomeFailureDetMsg": "Спробуйте ще раз, натиснувши посилання, надіслане електронним листом, або зв’яжіться з командою Neuroterminal",
              "saveBtn": "Увійдіть"
            }
          },
          "settings": {
            "title": "Налаштування",
            "saveLbl": "Зберегти",
            "succesMsg": "Ваші налаштування збережено"
          }
        },
        "therapy": {
          "prescriptions": {
            "newLbl": "нові рецепти",
            "archiveLbl": "рецептурний архів",
            "dateFromLbl": "Видано з",
            "dateToLbl": "Видано до",
            "noDataLbl": "Ніяких рецептів",
            "previewLbl": "Попередній перегляд",
            "prescriptionLbl": "Рецепт",
            "headers": {
              "dateLbl": "Дата",
              "doctorLbl": "Лікар",
              "numberLbl": "Код рецепта",
              "actionsLbl": "Рецепт"
            },
            "dialog": {
              "downloadLbl": "Завантажити",
              "closeLbl": "Закрити"
            }
          }
        }
      },
      "doctor": {
        "account": {
          "activation": {
            "title": "Активація облікового запису в системі",
            "alreadySent": "Документи вже надіслані",
            "alreadyFinished": "Активація облікового запису здійснюється",
            "fillInFormMsg": "Будь ласка, заповніть дані нижче та натисніть \"Надіслати\"",
            "formAgreementScanLbl": "Сканування угоди",
            "formInsuranceScanLbl": "Страхове сканування",
            "acceptTerms1": "я приймаю",
            "acceptTerms2": "умови використання",
            "acceptPolicy1": "я приймаю",
            "acceptPolicy2": "політика конфіденційності",
            "sendLbl": "Надіслати",
            "submitMsg1": "Дякуємо, ми перевіряємо ваші дані.",
            "submitMsg2": "Ми надішлемо підтвердження електронною поштою."
          },
          "settings": {
            "title": "Налаштування",
            "saveLbl": "Зберегти",
            "successMsg": "Налаштування збережено"
          }
        }
      },
      "prescriptions": {
        "home": {
          "titleLbl": "Електронні рецепти",
          "text1Lbl": "Запит на рецепт",
          "text2Lbl": "Немає запитів на рецепти"
        },
        "list": {
          "currentLbl": "Поточний",
          "archiveLbl": "В архіві",
          "showLbl": "Показати",
          "sortNewestLbl": "Найновіші",
          "sortDeadlingLbl": "Найбільш терміново"
        },
        "details": {
          "medHistLbl": "Медична історія",
          "pleadLbl": "просить рецепт",
          "peselLbl": "Песел",
          "copyPeselLbl": "Копіювати pesel",
          "checkEwusLbl": "Перевірте в EWUŚ",
          "addressLbl": "Адреса",
          "phoneLbl": "Телефонний номер",
          "issueDateLbl": "Дата останнього видачі рецепта",
          "viewLbl": "Попередній перегляд рецепта",
          "drugsLbl": "Запитані ліки",
          "commentLbl": "Коментар для лікаря",
          "openLbl": "Відкриті програми для створення електронних рецептів",
          "denyInfoLbl": "Якщо ви вирішите, що цей запит є недійсним, ви можете відмовити у видачі електронного рецепта",
          "denyLbl": "Заперечити",
          "prescriptionLbl": "Рецепт",
          "confirmInfo1Lbl": "Відхилити запит на рецепт",
          "confirmInfo2Lbl": "Ви впевнені, що хочете відмовити у видачі цього рецепта?",
          "confirmYesLl": "Так, відмовити",
          "confirmNoLbl": "Ні",
          "rejectInfoLbl": "Запит на рецепт відхилено",
          "forwardInfoLbl": "Інформацію надіслано адміну",
          "rejectBtn": "Відхилити запит",
          "rejectDlgTitle": "Чому ви хочете відхилити запит?",
          "rejectReasonPlaceholder": "Причина відмови",
          "forwardPrescription": "Якщо ви не можете виписати пацієнту електронний рецепт і хочете, щоб цим займалася реєстратура",
          "forwardBtn": "Відмовитися від електронного рецепта",
          "forwardDlgTitle": "Введіть примітку, яка буде відправлена на рецепцію (адмін)",
          "rejectCommentDisclaimer": "Причина відмови буде надіслана пацієнту",
          "characterCount": "Кількість символів:",
          "infoForAdmin": "Лікар відмовився виписати електронний рецепт:"
        },
        "creator": {
          "typeLbl": "Видайте електронний рецепт через",
          "returnLbl": "Повернення",
          "peselLbl": "Песел",
          "copyPeselLbl": "Копіювати pesel",
          "checkEwusLbl": "Перевірте в EWUŚ",
          "issueDateLbl": "Дата останнього видачі рецепта",
          "viewLbl": "Попередній перегляд рецепта",
          "drugsLbl": "Запитані ліки",
          "commentLbl": "Коментар для лікаря",
          "addProductLbl": "Додати інший продукт",
          "govText1Lb": "Перейти на сторінку",
          "govText2Lb": "та видати рецепт",
          "pinLbl": "Введіть 4-значний рецептурний штифт",
          "fileLbl": "PDF -файл із рецептом (необов’язково)",
          "fileChooseLbl": "Виберіть, щоб вибрати файл",
          "issueLbl": "Видайте електронний рецепт",
          "prescriptionLbl": "Рецепт",
          "notValidMsg": "Зміст рецептурної форми недійсний",
          "successMsg": "Видано рецепт",
          "prescriptionList": "Виписані рецепти мають такі коди"
        },
        "product": {
          "drugLbl": "Ліки",
          "removeLbl": "Видалити",
          "packageLbl": "Пакет",
          "refundLbl": "Повернення коштів",
          "packNumLbl": "Кількість пакетів",
          "dosageLbl": "Поточна доза",
          "refundCauseLbl": "Оплата",
          "recieptType": "Тип квитанції",
          "permissions": "Додаткові дозволи",
          "description": "Індивідуальний опис дозування",
          "dosage": "Дозування"
        },
        "ewus": {
          "checkingLbl": "Перевірка песеля в EWUŚ",
          "notFoundLbl": "Не вдалося знайти пацієнта в EWUŚ",
          "insuredLbl": "Пацієнт застрахований",
          "notInsuredLbl": "Пацієнт не застрахований",
          "closeLbl": "Закрити"
        },
        "doctorPass": {
          "titleLbl": "Пароль сертифіката ZUS",
          "cancelLbl": "Скасувати",
          "confirmLbl": "Підтвердити"
        }
      },
      "login": {
        "footer": {
          "z1": "Організаційний регламент",
          "z2": "Положення про надання електронних послуг",
          "z3": "Політика конфіденційності Neurosphere",
          "z4": "Політика конфіденційності Neuroterminal"
        },
        "login": {
          "loginTitle": "Увійдіть і призначте зустріч",
          "registerLinkMsg": "Реєстрація",
          "loginBtnLbl": "Увійдіть в Neurosphere",
          "resetPassMsg": "Забули пароль?",
          "passwordFieldLabel": "Пароль",
          "loginEmailLabel": "Електронна пошта",
          "registerMsg": "Ще не маєте облікового запису?"
        },
        "reset": {
          "title": "Забули пароль?",
          "subTitle": "На введену нижче адресу ми надішлемо електронний лист із посиланням для встановлення нового пароля",
          "content1": "Ми надіслали посилання для зміни пароля на адресу:",
          "content2": "Перевірте свою скриньку електронної пошти та натисніть посилання, щоб отримати можливість введення пароля",
          "form": {
            "email": "Електронна пошта",
            "send": "Підтвердьте"
          },
          "changePassword": "Змінити пароль",
          "recoverPassword": "Відновити пароль",
          "response1": "На вказану адресу електронної пошти надіслано повідомлення з посиланням для підтвердження скидання.",
          "response2": "Будь ласка, закрийте це вікно браузера та натисніть посилання, отримане електронною поштою.",
          "sendAgainPrefix": "Ви не отримали електронного листа:",
          "sendAgain": "Надішліть ще раз!",
          "contactReception": "або зв'яжіться зі стійкою реєстрації:",
          "resendBlocked": "Якщо ви не отримали електронний лист із посиланням для зміни пароля, зв’яжіться зі службою підтримки клієнтів."
        },
        "resetConfirm": {
          "title": "Підтвердьте зміну пароля",
          "form": {
            "password": "Новий пароль",
            "passwordConfirm": "Підтвердьте пароль",
            "send": "Підтвердьте"
          },
          "response": "Пароль змінено.",
          "loginBtnLbl": "Увійти"
        },
        "register": {
          "title": "Ласкаво просимо до Neuroterminal",
          "subtitle1": "Створіть обліковий запис і призначте зустріч",
          "firstName": "Ім'я",
          "lastName": "Прізвище",
          "email": "Електронна пошта",
          "password": "Пароль",
          "passwordConfirm": "Підтвердьте пароль",
          "selectAll": "Вибрати все",
          "isApprove1": "Я ознайомився з правилами обробки моїх персональних даних, викладеними в",
          "isApprove2": "Neurosphere sp. z o.o. Політика конфіденційності",
          "isApprove3": "і",
          "isApprove4": "Політика конфіденційності Neuroterminal S.A",
          "maptitle": "Як працює Neuroterminal?",
          "mapSubtitle": "Це дуже просто",
          "cards": [
            "Fill out the registration form to create account in Neurotermianl aplication.",
            "Receive an activation email to confirm your credentials",
            "Log into Neuroterminal and fill out the information in the patient profile.",
            "Choose the doctor and date of the first visit (visit ZERO)",
            "You will pay for it with ease using PayU (bank transfer, BLIK or credit card)",
            "For your convenience we will remind you about your visits by sending you SMS",
            "Each subsequent visit, no matter if it's remote or stationary, you will do it directly from Neuroterminal."
          ],
          "passwordRequirement": {
            "length": "8 ознак",
            "upperCase": "верхній регістр",
            "lowerCase": "нижній регістр",
            "digit": "цифра",
            "sign": "спеціальний знак ( ! @ $ \\ - _ . * )"
          },
          "resendMessage": "Посилання для активації надіслано знову"
        },
        "confirm": {
          "registerSuccessMsg": "Реєстрація успішно завершена",
          "passwordChangeSuccessMsg": "Пароль успішно змінено",
          "goToLoginMsg": "Натисніть кнопку нижче, щоб увійти на Neuroterminal, портал для пацієнтів Neurosphere",
          "outcomeFailureMsg": "Виникла проблема з підтвердженням реєстрації",
          "outcomeFailureDetMsg": "Будь ласка, спробуйте ще раз, натиснувши посилання в електронному листі, або зв’яжіться з командою Neuroterminal",
          "gotTologinBtn": "Перейдіть на сторінку входу",
          "passwordChangeFailedMsg": "Термін дії посилання для встановлення пароля закінчився"
        }
      },
      "leadDoctor": {
        "noDoctor": "У пацієнта немає головного лікаря",
        "change": "Зміна",
        "choose": "Виберіть лікаря",
        "dialogTitle": "Виберіть провідного лікаря для пацієнта",
        "genericError": "Сталася помилка",
        "assign": "Призначити лікаря",
        "patientsNumber": "Кількість пацієнтів",
        "assignNoteTitle": "Ви вибрали {name} провідним лікарем для пацієнта. Вибраний лікар отримає наступну довідку",
        "assignNoteContent": "Лікар {name} призначив пацієнта до вас. Перегляньте історію хвороби пацієнта.",
        "assignNoteComment": "Якщо ви хочете, ви можете додати додаткове повідомлення. Якщо ви додасте його, до нотатки також поділиться адміністратор",
        "assignNoteDialogTitle": "Пам'ятка для обраного лікаря"
      },
      "eReferrals": {
        "creator": {
          "title": "Нове електронне направлення",
          "type": "Тип направлення",
          "destination": "Клініка/лабораторія/лікарняна палата",
          "icd10": "Діагностика",
          "selectedIcd9": "Вибрані лікувальні процедури",
          "selectIcd9": "Виберіть хоча б одну лікувальну процедуру",
          "icd9": "Медична процедура",
          "comment": "Коментар лікаря",
          "issueReferral": "Оформити електронне направлення",
          "icdNoResults": "Немає результатів пошуку",
          "successMsg": "Електронне направлення успішно оформлено",
          "issueDate": "Дата випуску"
        }
      },
      "header": {
        "labelP1": "портал для",
        "labelP2": "пацієнтів"
      },
      "epicrisis": {
        "treatmentStart": "З тих пір пацієнт перебуває під наглядом Нейросфери",
        "ictalDiagnosis": "ICTAL діагностика",
        "mainDiagnosis": "Діагностика МКБ10",
        "additionalDiagnosis": "Додаткова діагностика МКБ10",
        "note0Header": "Примітка 0",
        "note0Label": "Початкове спостереження за пацієнтом - завершено під час опитування 0 або під час першого візиту пацієнта до Neurosphera",
        "epicrisisEntryHeader": "Записи в епікризі",
        "epicrisisEntryLabel": "Це місце з важливою інформацією про хворобу пацієнта",
        "addEntryLabel": "Додати запис",
        "entrySavedMsg": "Запис збережено",
        "createdBy": "Створено",
        "addedToObservation": "Додано до епікризу",
        "migration": "міграція"
      }
    },
    "component": {
      "consultation": {
        "diagnosisSelection": {
          "searchIcd10": "Пошук діагнозу",
          "searchIcd10NoResult": "Нічого не знайдено",
          "empty": "Список діагностики пацієнта порожній",
          "main": "Головна",
          "markAsMain": "Позначити як основне",
          "icd10": "МКБ 10"
        },
        "seizureDiagnosisSelection": {
          "first": "Судоми першого типу",
          "second": "Судоми другого типу",
          "third": "Судоми третього типу"
        },
        "drugDosageDialog": {
          "standardTimes": {
            "morning": "Ранок",
            "noon": "Полудень",
            "evening": "Вечір"
          },
          "standardDose": {
            "morning": "Ранкова доза",
            "noon": "Добова доза",
            "evening": "Вечірнє дозування"
          },
          "addCustomDose": "Додайте дозування у зазначений час",
          "cancel": "Скасувати",
          "save": "Зберегти"
        },
        "drugDosageEdition": {
          "drugs": "Ліки",
          "stage": "Крок",
          "searchDrug": "Додайте ліки",
          "searchDrugNoResult": "Нічого не знайдено",
          "dosage": "Виберіть дозування",
          "cancelTherapy": "скасувати",
          "saveTherapy": "зберегти терапію",
          "addTherapy": "додати наступний крок",
          "previousEndDateError": "Для попереднього кроку не визначено дати завершення",
          "previousEndDateBeforeCurrentError": "Дата завершення терапії повинна бути більшою за поточну",
          "activeSubstanceSearch": "Пошук ліків з діючою речовиною",
          "removeManyTitle": "Автоматично вилучати препарат із наступних терапій?",
          "addManyTitle": "Автоматично додавати препарат до наступних терапій?",
          "yesSameDosage": "Так, з таким же дозуванням",
          "yesDifferentDosage": "Так, але з таким же дозуванням",
          "refreshOrder": "Оновити порядок"
        },
        "drugTherapySummary": {
          "current": "Сучасна медикаментозна терапія",
          "future": "Майбутня медикаментозна терапія",
          "past": "Попередня медикаментозна терапія",
          "from": "Від",
          "to": "до"
        },
        "medicalHistory": {
          "chart": {
            "loadingLbl": "Завантаження даних",
            "seizuresLbl": "Судоми",
            "drugsLbl": "Ліки",
            "resultsLbl": "Результати",
            "visitsLbl": "Відвідування",
            "eventsLbl": "Події",
            "consultationLbl": "Консультація",
            "seizuresNumberLbl": "Судоми",
            "seizuresDurationLbl": "Тривалість",
            "trendBtn": "Лінія тренду",
            "disabledLbl": "Тепер недоступний",
            "trendBetterLbl": "Тренд краще",
            "trendWorseLbl": "Тенденція гірша",
            "rangeLbl": "Діапазон даних"
          }
        },
        "patientSummary": {
          "personalInfo": {
            "personalData": "Персональні дані"
          }
        },
        "drugTherapyHistory": {
          "title": "Редагування історії медикаментозної терапії",
          "changeHistory": "Минулі терапії",
          "noAccess": "Ви не маєте доступу до зміни історії медикаментозної терапії для цього пацієнта",
          "updateSuccess": "Історія медикаментозної терапії успішно оновлена"
        }
      },
      "doctor": {
        "registration": {
          "form": {
            "firstName": "Назва",
            "lastName": "Прізвище",
            "pesel": "Песел",
            "pwz": "Номер PWZ",
            "email": "Адреса електронної пошти",
            "password": "Пароль",
            "passwordConfirm": "Підтвердьте пароль",
            "phone": "Телефон",
            "diploma": "Додайте сканований диплом",
            "isSpecialization": "Маю спеціалізацію з неврології",
            "isPractise": "Я веду приватну медичну практику",
            "practiceInfoName": "Назва практики",
            "practiceInfoNipNr": "NIP",
            "practiceInfoRpwdlNr": "Номер RPWDL",
            "practiceInfoRegonNr": "REGON",
            "practiceAddressStreet": "Вулиця",
            "practiceAddressHouseAndApartmentNr": "Будинок / квартира №",
            "practiceAddressHouseNr": "Будинок №",
            "practiceAddressApartmentNr": "Квартира №",
            "practiceAddressZipcode": "ЗІП код",
            "practiceAddressCity": "Місто",
            "practiceAddressCountry": "Країна",
            "isApproveDataProcessing": "Я даю згоду на обробку моїх даних",
            "send": "Відправляти запит",
            "response1": "Дякуємо за реєстрацію",
            "response2": "Ми зв'яжемося з вами електронною поштою",
            "gotAccountMsg1": "Уже є обліковий запис?",
            "gotAccountMsg2": "Увійдіть"
          }
        },
        "database": {
          "doctor": {
            "list": {
              "noMedicalHistoryMsg": "Форма історії хвороби ще не заповнена. Будь ласка, заповніть його якомога більшою кількістю інформації перед вибором провідного лікаря.",
              "gotoMedicalHistoryLbl": "Перейдіть до форми",
              "titleLbl": "База даних лікарів",
              "titleDescLbl": "Якщо ви хочете змінити свого головного лікаря, натисніть кнопку «Вибрати» у профілі обраного вами лікаря, а потім заповніть коротку анкету.",
              "subtitleLbl": "Лікарі",
              "patientTypeLbL": "Пацієнти вели",
              "sortTypeLbL": "Сортувати за",
              "noResultsLbL": "Не вдалося знайти жодного лікаря, що відповідає параметрам пошуку",
              "sortTypes": {
                "patientsAsc": "Вів пацієнтів по зростанню",
                "patientsDesc": "Вела пацієнтів, що спускалися",
                "alphabeticAsc": "Висхідний за алфавітом",
                "alphabeticDesc": "За алфавітом за спаданням",
                "remotePriceAsc": "Ціна віддаленого відвідування зростає",
                "remotePriceDesc": "Ціна на дистанційне відвідування падає",
                "stationaryPriceAsc": "Ціни на стаціонарні відвідування зростають",
                "stationaryPriceDesc": "Ціни на стаціонарні відвідування знижуються"
              },
              "content": {
                "juniorExpLbl": "Молодший експерт",
                "standardExpLbl": "Експерт",
                "seniorExpLbl": "Старший експерт",
                "leadLbL": "провідний лікар",
                "bioLbl": "BIO",
                "pricesLbl": "ПРАЙС ЛИСТ",
                "servicesLbl": "ОБЛАСТЬ ПОСЛУГ",
                "remoteLbl": "Віддалений візит",
                "stationaryLbl": "Стаціонарний візит",
                "messageLbl": "повідомлення",
                "prescriptionLbl": "Рецепт"
              }
            },
            "selection": {
              "request": {
                "alert": {
                  "youHaveTimeTo": "Потрібно відповідати до",
                  "toReplyTo": "на прохання про догляд за пацієнтом",
                  "detailsLbl": "Деталі",
                  "closeLbl": "Закрити"
                }
              }
            }
          },
          "lead": {
            "titleLbl": "Провідний лікар",
            "patientsLbl": "Пацієнти",
            "visitsLbl": "Відвідування",
            "firstVisitLbl": "Перший",
            "subsequentVisitLbl": "Подальші",
            "bioLbl": "Біо",
            "bookVisitLbl": "Замовте візит",
            "doctor": {
              "change": {
                "request": {
                  "dialog": {
                    "title1": "Підтвердіть вибір головного лікаря",
                    "title2": "Ви впевнені, що хочете змінити свого потенційного клієнта?",
                    "selectionConfirmMsg": "Ви впевнені, що хочете обрати цього лікаря?",
                    "historyCommentLbl": "Прокоментуйте історію хвороби",
                    "reasonLbl": "Чому Ви вирішили змінити лікаря?",
                    "reasonCommentLbl": "Змінити причину",
                    "evaluationLbl": "Як би ви оцінили свого теперішнього лікаря?",
                    "overallCommentLbl": "Коментарі щодо співпраці з нинішнім лікарем",
                    "overallCommentInfo": "Це дасть нам змогу контролювати якість наданих послуг та видалити непрацездатних лікарів із системи після перевірки причин.",
                    "confirmLbl": "Підтвердьте зміну головного лікаря",
                    "cancelLbl": "Скасувати",
                    "reasons": {
                      "punctuality": "Лікар не пунктуальний",
                      "result": "Поліпшення немає",
                      "communication": "Проблеми спілкування",
                      "other": "Інший"
                    },
                    "evaluations": {
                      "vgood": "дуже добре",
                      "good": "добре",
                      "average": "середній",
                      "bad": "погано"
                    },
                    "confirmationLbl": "Повідомлення надійшло до лікаря",
                    "closeLbL": "Закрити"
                  }
                },
                "response": {
                  "dialog": {
                    "title": "Чи погоджуєтесь Ви стати провідним лікарем цього пацієнта?",
                    "firstSeizureLbl": "Дата першого вилучення",
                    "epilepsyDescLbl": "Епілепсія/судомний тип",
                    "drugsUsedLbl": "Ліки, що використовуються досі",
                    "patientCommentLbl": "Коментар пацієнта",
                    "acceptLbl": "я згоден",
                    "rejectLbl": "я не погоджуюсь",
                    "rejectReasonLbl": "Причина відмови",
                    "confirmLbl": "Підтвердити",
                    "cancelLbl": "Скасувати",
                    "rejection": {
                      "reasons": {
                        "punctuality": "Лікар не пунктуальний",
                        "result": "Поліпшення немає",
                        "communication": "Проблеми спілкування",
                        "full": "Вакансій немає",
                        "seizure": "Тип нападу не відповідає спеціалізації",
                        "age": "Невідповідний віковий профіль",
                        "other": "Інший"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "patient": {
        "registration": {
          "form": {
            "email": "Адреса електронної пошти",
            "password": "Пароль",
            "passwordConfirm": "Підтвердьте пароль",
            "isAgreeTerms1": "Я згоден",
            "isAgreeTerms2": "умови використання",
            "isAgreeTerms3": "",
            "isApprove1": "Я прочитав правила обробки моїх персональних даних, викладені в",
            "isApprove2": "Neurosphera sp. z o.o. Політика конфіденційності",
            "isApprove3": "та",
            "isApprove4": "Політика конфіденційності Neuroterminal S.A",
            "marketing1": "Я погоджуюся надсилати мені маркетингові повідомлення (включаючи комерційну інформацію), мета яких-просування послуг та товарів, включаючи спілкування, що здійснюється за допомогою моїх персональних даних за допомогою телекомунікаційних пристроїв у сфері електронної адреси (отримання повідомлень електронної пошти) .",
            "marketing2": "Я погоджуюся надсилати мені маркетингові повідомлення (включаючи комерційну інформацію), мета яких - популяризувати послуги та товари, включаючи комунікацію, що здійснюється за допомогою моїх персональних даних за допомогою телекомунікаційних пристроїв у сфері телефонного номера (прийом текстових повідомлень та вхідні дзвінки).",
            "marketing3": "Я хочу отримувати інформаційний бюлетень від Neuroterminal S.A. від імені Neurosphera sp. z o.o.",
            "send": "Зареєструвати обліковий запис",
            "response1": "Дякуємо за реєстрацію",
            "response2": "Ми надішлемо підтвердження реєстрації електронним листом",
            "gotoLoginLbl": "Увійдіть у систему",
            "gotAccountMsg1": "Уже є обліковий запис?",
            "gotAccountMsg2": "Увійдіть",
            "mandatoryDescription": "Поля, позначені *, є обов'язковими для заповнення",
            "marketing1and2": "Я даю згоду на отримання маркетингових повідомлень (включаючи комерційну інформацію), метою яких є просування послуг та товарів, у тому числі спілкування, що здійснюється з використанням моїх персональних даних за допомогою адреси електронної пошти (отримання повідомлень електронної пошти) та номера телефону (отримання текстових повідомлень та вхідні дзвінки).",
            "consentsTitle": "Прийняття нормативних документів і дозволів на маркетинг",
            "consentsAccept": "Прийняти вибране"
          },
          "confirmation": {
            "header": "Підтвердьте реєстрацію!",
            "content1": "Ми надіслали посилання для активації на адресу:",
            "content2": "Перевірте свою електронну пошту та натисніть посилання для активації",
            "sendAgainPrefix": "Ви не отримали електронного листа:",
            "sendAgain": "Надішліть ще раз!",
            "contactReception": "або зв'яжіться зі стійкою реєстрації:",
            "resendBlocked": "Якщо ви не отримали електронного листа з посиланням на реєстрацію, зв’яжіться зі службою підтримки клієнтів."
          }
        },
        "documentation": {
          "document": {
            "add": {
              "title": "Додати документ",
              "nameLbL": "Назва",
              "typeLbl": "Тип",
              "resultDateLbl": "Дата результату",
              "confirmLbl": "Прикріпити",
              "filesToLargeLbl": "Деякі файли були занадто великими для додавання",
              "filesEmptyContentLbl": "Деякі файли пошкоджені або не мають вмісту",
              "wrongFileType": "Неправильний тип файлу. Дозволено лише pdf, зображення або відео.",
              "unsavedFormWarningMsg1": "У формі є незбережені дані",
              "unsavedFormWarningMsg2": "Завантажити дані перед тим, як залишити екран",
              "yesLbl": "Так",
              "noLbl": "Ні",
              "uploadingMsg1": "Триває завантаження файлу ..",
              "uploadingMsg2": "У разі збільшення файлів це може зайняти деякий час",
              "uploadingMsg1Templated": "Виконується завантаження файлу {filename}"
            },
            "confirm": {
              "title": "Вилучити документ?",
              "cancelLbl": "Скасувати",
              "confirmLbl": "Підтвердити",
              "successMsg": "Документ вилучено"
            },
            "edit": {
              "title": "Редагувати документ",
              "nameLbL": "Назва",
              "typeLbl": "Тип",
              "resultDateLbl": "Дата результату",
              "fileNameLbL": "Назва файлу",
              "cancelLbl": "Скасувати",
              "confirmLbl": "Підтвердити",
              "changedMsg": "Документ успішно змінено"
            },
            "viewer": {
              "closeLbl": "Закрити",
              "typeNotSupported": "Цей тип файлу не підтримується у засобі перегляду"
            },
            "files": {
              "selection": {
                "title1": "Перетягни та Впусти",
                "title2": "файли, які ви хочете додати сюди",
                "title3": "або",
                "title4": "вибрати файли",
                "title5": "з комп'ютера"
              }
            },
            "list": {
              "title1": "Медична документація",
              "title2": "Тут ви можете зберігати медичну документацію",
              "title3": "Додавши результати тестів, ви можете допомогти лікарю поставити точний діагноз і збільшити шанс лікування.",
              "noDataLbl": "Немає даних",
              "noResultsLbl": "Без дати",
              "loadingLbl": "Завантаження документації",
              "downloadLbl": "Завантажити",
              "showLbl": "Показати",
              "editLbl": "Редагувати",
              "removeLbl": "Видалити",
              "headers": {
                "type": "Тип",
                "name": "Назва",
                "fileName": "Файл",
                "resultDate": "Виданий",
                "additionDate": "Додано"
              },
              "filters": {
                "documentTypeLbl": "Показати",
                "documentTypeAllLbl": "Покажи все",
                "resultDateFromLbl": "Дата результату від",
                "resultDateToLbl": "Дата результату до"
              },
              "markCompleted": "Позначити як виконане",
              "markCompletedDescription": "Якщо ви позначите опис як виконаний, він буде відправлений реєстратурою пацієнту. Якщо ви хочете, ви можете додати додатковий текст для прийому",
              "isCompleted": "Виконано"
            },
            "disk": {
              "space": {
                "filledLbl": "Заповнено {filled} ГБ із {available} ГБ"
              }
            }
          }
        },
        "nearest": {
          "visits": {
            "forthcomingLbl": "майбутні візити",
            "forthcomingConsultLbl": "(консультації)",
            "noVisitMsg": "Без майбутніх візитів.",
            "noVisitZeroMsg": "Без майбутніх візитів. Будь ласка, замовте нульовий візит.",
            "noVisitZeroDisclaimerMsg": "Під час нульового відвідування лікар проаналізує вашу історію хвороби та встановить ваш план здоров’я",
            "sosVisitLbl": "SOS",
            "bookVisitLbl": "Відвідування книги",
            "cannotBookVisitMsg": "Ви не можете забронювати відвідування, оскільки не всі поля в розділі \"Мої дані\" були заповнені",
            "editLbl": "Редагувати",
            "cancelLbl": "Скасувати",
            "payLbl": "Оплата",
            "dialog": {
              "paymentPending": "Чекаємо підтвердження оплати",
              "paymentError": "Не вдалося здійснити платіж",
              "payTill1": "Ви повинні платити до",
              "payTill2": "інакше ваше бажання буде скасовано.",
              "pay": "Оплатіть відвідування"
            },
            "absentConsultation": "Відсутня консультація - без участі пацієнта"
          }
        },
        "treatment": {
          "plan": {
            "titleLbl": "план здоров’я",
            "inaccessibleLbl": "недоступний",
            "noVisitZeroDisclaimerMsg": "Під час відвідування нуля ви оберете план здоров’я."
          },
          "prescription": {
            "request": {
              "stockLbl": "Запас протягом {days} дн",
              "otherQuestLbl": "Потрібен інший препарат",
              "otherDrugLbl": "Введіть у коментарі назву препарату. Не забудьте вказати точну назву препарату.",
              "confirmTitleLbl": "Підтвердження",
              "confirmTextLbl": "Ви будете перенаправлені на сторінку електронних платежів. Продовжити?",
              "cancelBtn": "Скасувати",
              "confirmBtn": "Підтвердити",
              "chooseDoctor": "Виберіть лікаря, у якого ви хочете виписати рецепт",
              "askForPrescriptionNotAvailable": "Відправити заявку на рецепт ви зможете після відвідування лікаря, який виписує рецепти",
              "noLeadDoctorAvailable": "Ваш головний лікар наразі недоступний. Ви можете надіслати запит на виписку рецепта до чергового лікаря."
            }
          }
        },
        "lead": {
          "doctor": {
            "titleLbl": "провідний лікар",
            "noVisitZeroDisclaimerMsg1": "Ви оберете свого головного лікаря",
            "noVisitZeroDisclaimerMsg2": "під час візиту нуль",
            "changeLbl": "Змінити",
            "prescriptLbl": "Попросіть рецепт",
            "messageLbl": "Напишіть повідомлення",
            "servicesLbl": "Надані послуги",
            "services": {
              "interview": "інтерв'ю",
              "therapy": "встановлення терапії",
              "commission": "випускна комісія",
              "prescription": "видати рецепт",
              "documents": "видати направлення, довідку, лікарняний лист"
            },
            "scope": {
              "bullet1": "Перший медичний контакт пацієнта",
              "bullet2": "Комплексний догляд за пацієнтами замовляє додаткові обстеження або, можливо, направляє консультантів до фахівців",
              "bullet3": "Консультант, який є безпосередньою підтримкою пацієнта та контролює терапевтичний план",
              "bullet4": "Підтримується всією командою, роботом iCtal® та системою медичного нагляду та автоматизації"
            }
          }
        },
        "hashtags": {
          "title": "Хештеги",
          "subtitle": "Це місце для позначення пацієнта попередньо визначеним хештегом",
          "label": "Шукайте хештеги"
        }
      },
      "finances": {
        "payment": {
          "history": {
            "title": "Історія розрахунків",
            "headers": {
              "month": "Місяць",
              "sum": "Всього"
            }
          }
        },
        "invoice": {
          "summary": {
            "text1": "Фінансова інформація",
            "text2": "Ви знайдете там дані, необхідні для виставлення рахунку",
            "text3": "Питання щодо рахунків -фактур?",
            "text4": "Зверніться до нашого фінансового відділу",
            "btn_lbl": "Завантажити резюме"
          }
        },
        "general": {
          "summary": {
            "title": "Фінанси",
            "return": "Назад",
            "table": {
              "headers": {
                "label": "Тип відвідування",
                "price": "Ціна",
                "amount": "Сума",
                "sum": "Всього"
              },
              "summary": "Всього"
            }
          }
        }
      },
      "genericDialog": {
        "approve": "Так",
        "cancel": "Ні",
        "close": "Закрити"
      },
      "videochat": {
        "waitingForPatient": "Очікування підключення пацієнта",
        "waitingForDoctor": "Очікування підключення лікаря",
        "connectionError": "Сталася помилка підключення",
        "visitEnded": "Телеконференція припинена. Сесія була припинена.",
        "accessDenied": "Надайте доступ до мікрофона та камери у веб -переглядачі та при необхідності оновіть сторінку",
        "accessDeniedAlert": "Немає доступу до мікрофона та камери",
        "missingAudioInput": "Відсутній мікрофонний пристрій",
        "missingVideoInput": "Пристрій камери відсутній",
        "tooltip": {
          "disableAudio": "Вимкнути звук",
          "enableAudio": "Увімкнути звук",
          "disableVideo": "Вимкнути відео",
          "enableVideo": "Увімкнути відео",
          "terminate": "Завершіть відвідування онлайн"
        },
        "confirm": {
          "warningMsg1": "Візит ще триває",
          "warningMsg2": "Ви хочете завершити дзвінок телеконференції для поточного візиту?"
        },
        "deviceLoadError": "Помилка завантаження пристроїв",
        "accessGrantedBut": "Однак дозволи отримано:",
        "cannotConnectCamera": "Не вдається підключитися до камери",
        "cannotConnectMicrophone": "Не вдається підключитися до мікрофона",
        "deviceAlreadyInUse": "Переконайтеся, що пристрій ще не використовується, а потім повторіть спробу",
        "chooseDeviceDialog": {
          "chooseDevices": "Виберіть пристрої",
          "visitStartMsg1": "Візит розпочнеться о",
          "visitStartMsg2": "Для безперебійної зустрічі виберіть аудіо- та відеопристрої, які хочете використовувати",
          "camera": "Камера",
          "microphone": "мікрофон",
          "speakers": "динаміки"
        },
        "dialog": {
          "confirm": "Підтвердьте"
        }
      },
      "addMedicalEventDialog": {
        "title": "Додати подію",
        "descriptionPlaceholder": "Введіть короткий опис",
        "patientSeizureTypePlaceholder": "Тип нападу",
        "from": "Від",
        "to": "До",
        "duration": "Тривалість",
        "cancel": "Скасувати",
        "approve": "Зберегти",
        "addNext": "Додайте інший",
        "delete": "Видалити",
        "addSuccessMsg": "Подія додана",
        "noSeizureTypes": "Щоб додати нові напади, необхідно визначити свої типи нападів у медичному огляді",
        "goToHealthSurvey": "Перейдіть на огляд здоров'я",
        "serialSeizures": "Серійні судоми",
        "seizuresCount": "Підрахунок судом"
      },
      "user": {
        "settings": {
          "sms": {
            "title": "SMS -сповіщення"
          },
          "other": {
            "title": "Інший"
          }
        }
      },
      "visitChat": {
        "title": "Відвідайте чат",
        "loading": "Триває завантаження повідомлень ...",
        "emptyList": "Список повідомлень порожній",
        "inputLabel": "Напишіть повідомлення"
      },
      "payu": {
        "banner": {
          "installmentPaymentAvailable": "Розстрочка оплати доступна тут",
          "startsFrom": "від",
          "decisionIn": "рішення в",
          "decisionInSeconds": "{val} секунд",
          "checkConditions": "Перевірте умови"
        }
      },
      "updateMedicalEventDialog": {
        "title": "Редагувати подію",
        "changesSaved": "Зміни успішно збережено"
      },
      "maintenance": {
        "newAddress": "<b>Увага!</b> Ми змінюємо адресу філії у Варшаві. Наша нова адреса <b>вул. Taneczna 30, 02-829 Warsaw (Ursynów)</b>"
      }
    },
    "ui": {
      "help": "Довідка",
      "policy": "Політика конфіденційності",
      "logout": "Вийти",
      "navigation": {
        "home": "Домашній екран",
        "account": {
          "name": "Про мене",
          "personalData": "Мій рахунок",
          "medicalDocuments": "Медичні документи",
          "medicalHistory": "Опитування стану здоров’я"
        },
        "messages": "Повідомлення",
        "therapy": "Терапія",
        "visits": {
          "name": "Відвідування",
          "archive": "Архів візитів",
          "doctorDatabase": "База даних лікарів"
        },
        "myVisits": "Мої візити",
        "patients": "Пацієнти",
        "ediagnosis": "IctalLab",
        "calendar": "Календар",
        "workplan": "План роботи",
        "visitsAndDuties": "Відвідування та обов’язки",
        "finances": "Фінанси",
        "ehealth": {
          "name": "Електронне здоров’я",
          "prescriptions": "Електронні рецепти",
          "referrals": "Електронні реферали"
        },
        "settings": {
          "name": "Налаштування",
          "notifications": "Повідомлення",
          "regulations": "Положення",
          "payments": "Виплати"
        },
        "documentation": "Документація",
        "examinations": "Експертизи"
      },
      "closeDialog": "Закрити",
      "visitSummary": "visit_summary",
      "invoice": "рахунок -фактура",
      "correctionInvoice": "виправлення_фактури",
      "ieAlert": "На жаль, ми більше не підтримуємо Internet Explorer. Будь ласка, використовуйте інший Інтернет -браузер."
    },
    "components": {
      "workPlan": {
        "header": {
          "TIMETABLE": "План",
          "TIMETABLE_CHANGES": "Змінює календар"
        },
        "editDialog": {
          "workPosition": "Змінити позицію плану роботи",
          "unsavedWorkPosition": "Нова позиція плану роботи",
          "vacation": "Редагувати відпустку",
          "unsavedVacation": "Нова відпустка",
          "dayOfWeekLabel": "День тижня",
          "officeLabel": "Офіс",
          "visitTypeLabel": "Тип відвідування",
          "startTimeLabel": "Час початку",
          "endTimeLabel": "Час закінчення",
          "description": "Опис",
          "cancel": "Скасувати",
          "approve": "Затвердити",
          "visitType": "Тип відвідування",
          "visitPlace": "Відвідайте місце",
          "stationaryAndRemote": "Увага! Цей тип візитів дозволяє пацієнтам призначати як стаціонарні, так і віддалені відвідування",
          "workPlaceMissing": "Щоб увімкнути стаціонарні відвідування, вам потрібно встановити своє робоче місце на сторінці налаштувань"
        },
        "deleteDialog": {
          "text": "Ви впевнені, що хочете видалити цю позицію?",
          "cancel": "Скасувати",
          "confirm": "Видалити"
        },
        "visitType": {
          "unavailable": "Обов'язок",
          "any": "Будь -якого типу",
          "stationaryOrRemore": "Стаціонарні / дистанційні"
        },
        "event": {
          "duty": {
            "yourDutyToChange": "Oznaczony jako do zmiany",
            "state": {
              "AVAILABLE": "Обов’язок доступний",
              "NOT_AVAILABLE": "Обов’язок відсутній",
              "TO_CHANGE": "Прохання взяти на себе обов'язки",
              "PLANNED": "Ваш запланований обов'язок"
            }
          }
        },
        "description": "Плануйте свою роботу - виберіть, коли ви зможете відвідувати",
        "selectedWorkPlan": "Обраний план роботи:",
        "newWorkPlan": "Новий план роботи:",
        "untilFurtherNotice": "до подальшого повідомлення",
        "couldNotDeleteActiveWorkPlan": "Не вдалося видалити активний план роботи",
        "validFrom": "Діє з",
        "validTo": "Дійсно до",
        "resetChanges": "Скинути",
        "saveChanges": "Зберегти",
        "unsavedChanges": "У календарі є незбережені зміни",
        "visits": "Відвідування",
        "newPlan": "Новий план",
        "messages": {
          "deleteSuccess": "План роботи видалено",
          "addSuccess": "Додано план роботи",
          "updateSuccess": "План роботи оновлено"
        }
      },
      "doctor": {
        "calendar": {
          "todayBtn": "Сьогодні"
        },
        "messages": {
          "title": "Невідомі повідомлення",
          "show": "Показати",
          "item": {
            "systemLbl": "Системне повідомлення",
            "types": {
              "prescribeLbl": "Запит на видачу рецепта від: {sender}",
              "selectionLbl": "Запит стати головним лікарем від: {sender}",
              "noteLbl": "Примітка для пацієнта: {patient} додано",
              "prescribeLblMovedFrom": "замість {movedFrom}"
            }
          },
          "new": "новий"
        },
        "visits": {
          "title": "План дня",
          "show": "Показати",
          "types": "Тип відвідування",
          "categories": "Категорія відвідування",
          "type": {
            "remote": "Wizyty онлайн",
            "stationary": "Stacjonarne"
          },
          "item": {
            "statusPayed": "оплачено",
            "statusNotPayed": "не оплачено",
            "statusDone": "зроблено",
            "statusToMove": "рухатись",
            "visitBtnLbl": "відвідати",
            "visitStartBtnLbl": "розпочати візит",
            "historyBtnLbl": "медична історія",
            "numberLbl": "Кількість відвідувань",
            "causeLbl": "Відвідати причину",
            "lastVisitLbl": "Останній візит:",
            "diagnosisLbl": "Діагностика",
            "diagnosisNoEpilepsyLbl": "Не епілептичний",
            "diagnosisNoDataLbl": "Діагнозу немає",
            "drugsLbl": "Сучасні препарати",
            "drugsNoneLbl": "Жодного",
            "frequencyLbl": "Частота нападів",
            "moodLbl": "Настрій",
            "summaryLbl": "Резюме відвідування",
            "visitDuration": "Тривалість:"
          }
        },
        "form": {
          "pricelist": {
            "titleInfo": "Новий пункт прайс -листа",
            "cancelBtn": "Скасувати",
            "saveBtn": "Підтвердити"
          },
          "avatar": {
            "titleInfo": "Виберіть зображення",
            "addImageBtn": "Зображення з файлової системи",
            "cancelBtn": "Скасувати",
            "saveBtn": "Підтвердити"
          },
          "address": {
            "streetLbl": "Вулиця",
            "homeBuildingLbl": "Будинок № / квартира",
            "apartmentLbl": "Квартира №",
            "buildingLbl": "Будинок №",
            "cityLbl": "Місто",
            "zipcodeLbl": "ЗІП код",
            "countryLbl": "Країна"
          }
        }
      },
      "message": {
        "history": {
          "title": "Повідомлення",
          "newMessage": "Нове повідомлення",
          "new": "Новий",
          "search": "Пошук",
          "filters": {
            "current": "Поточний",
            "archive": "Архів",
            "contact": "Пошук"
          },
          "noMessages": "Немає повідомлень",
          "born": "народився",
          "historyBtnLbl": "Медична історія",
          "return": "Повернення",
          "send": "Надіслати",
          "sendAndClose": "Надішліть і закрийте візит",
          "hoursToAnswer": "h",
          "timeIsUp": "Час минув",
          "hoursToAnswerRich": "У вас є {hours} год для відповіді",
          "timeIsUpRich": "Заявлений час для відповіді вже минув. Подаруйте якомога швидше!",
          "summary": "Резюме",
          "medicalHistory": "Медична історія",
          "sort": {
            "label": "Показати",
            "newest": "Найновіші",
            "alpha": "В алфавітному порядку",
            "deadline": "Найбільш терміново"
          },
          "visitEnded": "закінчився",
          "noTherapies": "Жодної терапії",
          "list": "Список повідомлень",
          "closeNoAnswer": "Повідомлення не вимагає відповіді",
          "noAnswerContent": "Дякуємо за ваше повідомлення. З повагою",
          "confirmNoAnswer": "Ви впевнені, що бажаєте завершити візит, не відповідаючи?",
          "daysToAnswer": "днів",
          "daysToAnswerRich": "У вас є {days} днів, щоб відповісти",
          "sendAndCloseDialog": {
            "header": "Ви впевнені, що бажаєте завершити ланцюжок?",
            "title": "Якщо ви закриєте візит, пацієнт не зможе відповісти на ваше повідомлення",
            "subtitle": "Якщо ви хочете, щоб пацієнт міг відповісти на ваше повідомлення, натисніть «Надіслати»"
          },
          "newMessageOnDuty": "Нове повідомлення черговому лікарю",
          "unidirectional": {
            "send": "Надіслати однонаправлене повідомлення",
            "sendTitle": "Односпрямоване повідомлення до",
            "sendSubtitle": "Пацієнт не може відповісти на це повідомлення",
            "sendSuccess": "Пацієнту надіслано односпрямоване повідомлення",
            "infoForPatient": "Це повідомлення - інформація від лікаря. У цьому повідомленні ваш лікар надав вам додаткову інформацію про ваше захворювання. Ви не можете відповісти на повідомлення, якщо вам потрібна додаткова інформація, напишіть нове повідомлення - зверніться до лікаря"
          },
          "addToEpicrisis": "Додати до епікризу",
          "textAddedToEpicrisis": "Запис додано до епікризу"
        },
        "respondeDeadlineAlert": {
          "youHaveTimeTo": "Потрібно відповідати до",
          "toReplyTo": "на повідомлення від",
          "reply": "Відповісти"
        },
        "patientAlerts": {
          "healthSurveyContent": "Потрібно пройти обстеження здоров'я",
          "healthSurvey": "Перейдіть на опитування"
        },
        "newMessageVisit": {
          "noDoctorTitle": "Ви не призначили провідного лікаря",
          "title": "Зверніться до лікаря - надішліть повідомлення",
          "descriptionLbl": "Опис",
          "description": "Повідомлення - це коротке запитання, надіслане лікареві. Ваш лікар відповість на це питання. Якщо ви хочете скористатися цією формою контакту, оплатіть своє відвідування-повідомлення, натиснувши кнопку «Нове повідомлення». Якщо у вас є які -небудь сумніви щодо вашого здоров'я або вам потрібна більш тривала консультація - просимо РЕГУЛЬНО відвідувати лікаря.",
          "messageVisitsDisabled": "Вибачте, але ваш лікар не надає таких послуг.",
          "noLeadDoctorAvailable": "Ваш головний лікар наразі недоступний. Ви можете написати повідомлення черговому лікарю."
        },
        "drugStock": {
          "lowMsg": "Запаси на такі ліки закінчуються:",
          "notInitialized": "Ініціалізуйте свій запас ліків для:",
          "daysLbl": "днів"
        },
        "toast": {
          "text": "Нове вхідне повідомлення від"
        },
        "newPaidMessageAlert": {
          "content": "Ви заплатили за повідомлення до лікаря",
          "reply": "Написати повідомлення"
        },
        "unidirectionalMessageAlert": {
          "content": "Ви отримали повідомлення - інформацію від лікаря",
          "reply": "Переглянути повідомлення"
        },
        "personalDataAcceptanceAlert": {
          "title": "Підтвердження особистих даних",
          "subtitle": "Ваші особисті дані в системі «Нейротермінал» важливі - переконайтеся, що вони актуальні! Завдяки цьому ви можете отримати повний досвід наших послуг.",
          "changeButton": "Змінити персональні дані",
          "acceptButton": "Підтвердити мої особисті дані"
        }
      },
      "utility": {
        "multipleSelect": {
          "all": "Усі"
        },
        "confirmationDialog": {
          "title": "Ти впевнений?",
          "text": "Ви впевнені, що хочете продовжити?"
        },
        "youAreNotAllowedDialog": {
          "title": "Потрібні дозволи",
          "text": "У вас немає дозволу. Зв'яжіться з нами для отримання додаткової інформації.",
          "goBackToMainPage": "Повернення до головної сторінки"
        },
        "phoneInput": {
          "phoneNumberLabel": "Телефонний номер",
          "legalGuardPhoneNumberLabel": "Телефон одного з батьків або законного представника"
        }
      },
      "health": {
        "state": {
          "noData": "Немає даних",
          "stateNoChange": "Без змін",
          "stateBetter": "Краще",
          "stateWorse": "Гірше"
        }
      },
      "calendar": {
        "title": "Відвідування та чергування",
        "description": "Ваші відвідування за поточний тиждень. Натисніть на візит, щоб переглянути його деталі.",
        "visitCancelled": "Візит скасували",
        "visitDateChanged": "Змінено дату відвідування",
        "legend": {
          "duty": "Обов’язок SOS",
          "vacation": "Відпустка"
        },
        "filters": {
          "showVisits": "Показати відвідування",
          "showDuties": "Показувати обов’язки"
        },
        "visitDetailsDialog": {
          "title": "Відвідайте деталі",
          "onDay": "на",
          "fromHour": "від",
          "toHour": "до",
          "close": "Закрити"
        },
        "dutyDetailsDialog": {
          "title": "Деталі обов'язку",
          "fromHour": "від",
          "toHour": "до",
          "close": "Закрити",
          "signUpForDuty": "Запишіться на чергування",
          "markDutyToChange": "Заява про передачу мита",
          "toChangeRequestText": "Просить передати боргу. Ви можете допомогти цьому лікарю, підписавшись на цю службу за нього.",
          "cantMarkDutyAsToChange": "На жаль, ви не можете подати запит на перенесення цього обов’язку.",
          "dutyNotAvailable": "Цей обов’язок уже взятий. Виберіть іншу дату мита.",
          "cancelToChange": "Скасувати запит на передачу мита"
        },
        "confirmVisitCancellationDialog": {
          "title": "Скасування візиту",
          "sureToCancelVisit": "Ви впевнені, що хочете скасувати візит пацієнта",
          "fromDate": ", що мало відбутися з",
          "from": "і продовжувати до тих пір",
          "to": "до",
          "description": "Пацієнта попросять вибрати іншу дату, або служба зв’яжеться з ним.",
          "confirm": "Скасувати візит",
          "cancel": "Скасувати",
          "visitAlert": "Ви скасовуєте візит, який має розпочатися менш ніж за 48 годин! Ми не підтримуємо таку практику. Для отримання додаткової інформації, будь ласка, перегляньте наш",
          "termsAndConditions": "правила та умови",
          "fromDatePassed": ", який відбувся з",
          "fromPassed": "і продовжував до",
          "visitError": "Цей візит вже відбувся. Переконайтеся, що ви дійсно хочете скасувати цей візит"
        },
        "visits": {
          "title": "Відвідування лікарів",
          "description": "Вибрані прийоми лікарів на день. Натисніть на поле в календарі, щоб переглянути його деталі.",
          "dateSelectPrefix": "Вибраний день",
          "noVisits": "Без візитів",
          "doctors": "Лікарі"
        }
      },
      "duties": {
        "applyDutyDialog": {
          "title": "Видання обов'язків",
          "subtitle": "Переконайтеся, що ваші зміни правильні.",
          "addedDuties": "Додані мита:",
          "cancelledDuties": "Скасовані мита:",
          "inHours": "на",
          "back": "Повернутися до редагування",
          "approve": "Зберегти",
          "acceptTermsAndConditions": "Я приймаю Загальні положення та умови обов'язків"
        },
        "alert": {
          "text": "Ви маєте обов’язок:"
        },
        "description": "Запишіться на службу SOS. Виберіть зручну дату, натиснувши її в календарі"
      },
      "payment": {
        "history": {
          "title": "Історія платежів",
          "filters": {
            "typeAll": "Усі",
            "advFiltLbl": "Розширений пошук",
            "advanced": {
              "from": "Дата початку",
              "till": "до",
              "to": "Дата закінчення",
              "clearLbl": "Ясно",
              "searchLbl": "Пошук"
            }
          },
          "noDataLbl": "Немає даних",
          "noResultsLbl": "Результатів пошуку не знайдено",
          "headers": {
            "date": "Дата",
            "type": "Тип",
            "amount": "Сума",
            "invoice": "Рахунок -фактура",
            "service": "Назва служби"
          },
          "pdfLbl": "Завантажити",
          "invoice": "Рахунок -фактура",
          "correctionInvoice": "Виправлення"
        }
      },
      "dialog": {
        "generic": {
          "confirmation": {
            "dialog": {
              "defaultCancelLbl": "Скасувати",
              "defaultConfirmLbl": "Підтвердити"
            }
          },
          "attention": "Uwaga"
        }
      },
      "patient": {
        "visits": {
          "next": {
            "visit": {
              "info": {}
            }
          },
          "operations": {
            "cancel": {
              "title": "Ви підтверджуєте скасування візиту?",
              "warning": "Згідно з Умовами користування, відвідування, скасовані протягом 48 годин до дати відвідування, обкладаються адміністративним збором у розмірі 50% вартості візиту. Залишок суми буде повернуто (буде розглянуто спосіб повернення).",
              "reason": "Причина скасування відвідування (необов’язково)",
              "confirmLbl": "Так",
              "cancelLbl": "Ні",
              "warningCustom": "Згідно з Умовами використання відвідування, скасовані протягом {days} днів до дати відвідування, підлягають адміністративному збору в розмірі {percentage}% від вартості відвідування. Залишок суми повертається (спосіб повернення обговорюється)."
            },
            "modify": {
              "title": "Виберіть нову дату відвідування",
              "confirmMsg": "Ви підтверджуєте зміну дати відвідування?",
              "cancelLbl": "Скасувати",
              "yesLbl": "Так",
              "noLbl": "Ні",
              "modifyDisabled": "Неможливо перенести цей візит",
              "dateBefore": "Попередня дата",
              "dateCurrent": "Поточна дата",
              "change": "Змінити дату",
              "changeWarning": "Увага! Система дозволяє пацієнту лише один раз змінити дату / лікаря на прийом"
            }
          }
        }
      },
      "surveyZeroNote": {
        "addButton": "Примітка 0",
        "title": "Спостереження опитування 0",
        "addDialog": {
          "visitPurpose": "Мета візиту 1",
          "visitPurposeDescription": "Запитайте пацієнта, з якою метою призначено прийом 1. В ідеалі має бути внесено заяву пацієнта.",
          "surveyCompleted": "Опитування 0 завершено?",
          "surveyCompletedDescription": "Якщо опитування 0 не було завершено або якщо деякі частини опитування були завершені, вкажіть причину.",
          "seizures": "Опишіть напади словами",
          "seizuresEmpty": "Типи судом не визначені",
          "chronicDiseases": "Чи є у пацієнта інші хронічні захворювання - провідні? (наприклад, аутизм, інсульт)",
          "chronicDiseasesDescription": "Хронічні захворювання",
          "epilepsy": "Чи є в сімейному анамнезі пацієнта епілепсія або інші неврологічні захворювання?",
          "epilepsyDescription": "Коротко опишіть, в чиєму сімейному анамнезі є епілепсія чи інші неврологічні захворювання",
          "eegCompleted": "Чи проходив пацієнт ЕЕГ?",
          "mriCompleted": "Чи проходив пацієнт МРТ?",
          "ctCompleted": "Чи робили пацієнту КТ?",
          "geneticResearch": "Чи проводився пацієнт генетичний тест?",
          "relevantExaminations": "Чи проводилися пацієнту інші відповідні дослідження?",
          "otherMedicines": "Чи приймає пацієнт на даний момент ліки, окрім АЗД, включених до анкети 0?",
          "otherMedicinesDescription": "Опишіть словами, які ліки/речовини приймає пацієнт",
          "allergies": "Чи є у пацієнта алергія на ліки, в тому числі на антибіотики?",
          "allergiesDescription": "Опишіть словами, на які препарати/речовини у пацієнта алергія",
          "notes": "додаткові нотатки",
          "examinationDescription": "Опишіть результати тесту, якщо такі є",
          "additionalDescription": "Слово опис",
          "treatment": "Лікування поки що",
          "treatmentDescription": "Словесний опис",
          "specialDiet": "Чи є якась особлива дієта?",
          "specialDietDescription": "Словесний опис",
          "perinatalInterviewUnimportant": "Важлива інформація",
          "birthDate": "Дата пологів (народження)",
          "birthType": "Тип народження",
          "pregnancyComplications": "Ускладнення під час вагітності",
          "birthWeight": "Вага при народженні",
          "birthLength": "Довжина при народженні",
          "apgar": "Бали Апгар (0-10)",
          "apgarFirst": "1 хв",
          "apgarNext": "Наступне вимірювання",
          "birthAdditionalProcedures": "Чи проводились якісь додаткові процедури перинатально?",
          "birthPrematureAgeCorrected": "Чи коригували вік для недоношених дітей?",
          "hasSiblings": "Чи є у пацієнта брати і сестри?",
          "sibling": "особа",
          "siblingBirthDate": "Дата народження",
          "siblingSameParents": "Це ті самі батьки?",
          "siblingHealthy": "Він здоровий?",
          "addSibling": "Додайте іншу особу",
          "geneticPredisposition": "Чи є екологічна та генетична/спадкова схильність і сімейна історія?",
          "developmentCorrect": "Чи правильний поточний розвиток?",
          "retardationDetermined": "Чи визначено, наскільки він відстає у розвитку по відношенню до свого віку?",
          "retardationExamined": "Чи був спеціальний огляд за цим напрямком?",
          "nystagmus": "Чи є у пацієнта ністагм?",
          "vision": "Приціл",
          "hearing": "Слухання",
          "tuxedo": "Куріння сигарет",
          "alcohol": "Алкоголь",
          "narcotics": "наркотики",
          "leadingDisease": "Яке провідне захворювання у хворого?",
          "leadingDiseaseDominantSymptoms": "Домінуюча симптоматика захворювання",
          "leadingDiseaseLasting": "Як довго триває хвороба?",
          "accompanyingSymptoms": "Супутні симптоми: чи хоче пацієнт щось додати?",
          "next": "Далі",
          "previous": "Назад",
          "saveAndClose": "Зберегти та закрити",
          "sections": {
            "surveyZero": "Опитування 0",
            "perinatalInterview": "Перинатальна бесіда",
            "development": "розвиток",
            "familyInterview": "Сімейне інтерв'ю",
            "treatment": "Лікування",
            "comorbidities": "Супутні захворювання",
            "medicalExams": "Медичні огляди",
            "others": "інше"
          }
        },
        "preview": {
          "userCreated": "Створений",
          "seizures": "Опис внесених припадків",
          "chronicDiseases": "Чи є у пацієнта інші серйозні захворювання?",
          "epilepsy": "Сімейне інтерв'ю",
          "eegCompleted": "Тест ЕЕГ",
          "mriCompleted": "МРТ дослідження",
          "ctCompleted": "КТ",
          "geneticResearch": "Генетичне тестування",
          "relevantExaminations": "Інші дослідження",
          "otherMedicines": "Наркотики, крім ЛПП",
          "allergies": "Медикаментозна алергія",
          "addToObservations": "Додати до спостережень",
          "addFragmentToObservations": "Додайте виділений фрагмент, виконайте спостереження",
          "addWholeToObservations": "Додайте цілу нотатку до спостережень"
        }
      },
      "medicalHistoryPrintout": {
        "download": "Завантажити історію хвороби",
        "withObservations": "Версія із спостереженнями",
        "withoutObservations": "Версія без зауважень",
        "downloadHistory": "Показати історію завантажень",
        "history": {
          "title": "Історія завантажень історії хвороби пацієнта",
          "timeDownload": "Дата завантаження",
          "userDownload": "Завантажувач",
          "observations": "Містить спостереження"
        },
        "parametersPopup": {
          "title": "Завантажити історію хвороби",
          "subtitle": "Виберіть період, за який ви хочете завантажити історію хвороби",
          "allHistory": "вся історія з нейротермінальної системи",
          "selectedRange": "історія певного часу",
          "download": "Завантажити",
          "cancel": "Скасувати"
        }
      },
      "patientExaminations": {
        "chooseExaminationLbl": "Виберіть обстеження, для якого ви хочете заповнити опис",
        "eeg": "ЕЕГ дослідження",
        "addEegDescription": "Додати опис дослідження ЕЕГ",
        "eegDescriptionHeader": "Опис дослідження ЕЕГ",
        "describingDoctor": "Творець опису",
        "months": "місяців",
        "removeEegTitle": "Видалити опис дослідження ЕЕГ",
        "removeEegContent": "Ви впевнені, що хочете видалити цей опис?"
      }
    },
    "common": {
      "sex": {
        "man": "Чоловіки",
        "woman": "Жінка",
        "nondefined": "Не визначено"
      },
      "role": {
        "patientLbl": "пацієнта",
        "doctorLbl": "лікар"
      },
      "years": " | рік | {n} років | {n} років",
      "visit": {
        "paid": "оплачується",
        "additional": "додаткові",
        "toMove": "рухатись",
        "confirmed": "підтверджено",
        "done": "зроблено",
        "cancelled": "скасовано",
        "notPaid": "не оплачено",
        "messagePaid": "в процесі"
      },
      "bornAbbr": "народився",
      "months": " | 1 місяць | {XXXXXX} місяців | {XXXX} місяців",
      "days": " | 1 день | {XXXXXX} днів | {XXXX} днів",
      "confirm": "Підтвердити",
      "cancel": "Скасувати",
      "select": "Виберіть"
    },
    "views": {
      "profile": {
        "welcome": "Ласкаво просимо!",
        "choose": "Увійти як:",
        "doctor": "лікар",
        "patient": "пацієнта"
      },
      "login": {
        "loginFldLbl": "Електронна пошта",
        "passwordFldLbl": "Пароль",
        "loginBtnLbl": "Увійдіть",
        "loggingAs": "Ви увійшли як",
        "wantToLoginAs": "Я хочу увійти як",
        "registerMsg": "Ще не маєте облікового запису?",
        "registerLinkMsg": "Зареєструвати обліковий запис",
        "resetMsg": "Не пам’ятаєте свій пароль?",
        "resetLinkMsg": "Скинути пароль",
        "return": "повернення"
      },
      "register": {
        "patientLbl": "Пацієнт",
        "doctorLbl": "Лікар",
        "loginFldLbl": "Увійти",
        "newPassFldLbl": "Пароль",
        "newPassConfFldLbl": "Підтвердьте пароль",
        "firstNameFldLbl": "Ім'я",
        "secNameFldLbl": "Прізвище",
        "lastNameFldLbl": "Прізвище",
        "emailFldLbl": "Електронна пошта",
        "workCertNrFldLbl": "Сертифікат №",
        "confirmBtnLbl": "Підтвердити",
        "backToLoginPageLbl": "Повернутися на сторінку входу"
      },
      "reset": {
        "loginFldLbl": "Ім'я користувача",
        "newPasswordLbl": "Новий пароль",
        "newPasswordConfirmLbl": "Підтвердити новий пароль",
        "confirmBtnLbl": "Підтвердити",
        "backToLoginPageLbl": "Повернутися на сторінку входу"
      },
      "dashboard": {
        "welcomeMessage": "Увійшов користувач: {userName}",
        "enableEditable": "Підтвердити перегляд",
        "disableEditable": "Керувати поданням"
      },
      "visits": {
        "titleLbl": "Мої візити",
        "noDataLbl": "Відвідувань немає",
        "noResultsLbl": "Відвідувань не знайдено",
        "filters": {
          "visitTime": {
            "all": "Усі",
            "future": "Майбутнє",
            "past": "Минуле"
          },
          "searchPatientLbl": "Шукати пацієнта",
          "advancedFiltersLbl": "Розширений пошук",
          "advancedFilters": {
            "from": "Дата від",
            "betweenDatesLbl": "робити",
            "till": "Дата до",
            "type": "Тип відвідування",
            "category": "Категорія відвідування",
            "all": "Усі",
            "clearBtn": "ясно",
            "searchBtn": "пошук"
          }
        }
      },
      "account": {
        "common": {
          "requiredMsg": "Обов’язкові поля позначені зірочкою",
          "clearBtn": "Ясно",
          "editBtn": "Редагувати",
          "saveBtn": "Підтвердити",
          "yes": "Так",
          "no": "Ні",
          "woman": "жінка",
          "man": "людина",
          "nondef": "не визначено"
        },
        "home": {
          "myInfoLbl": "Моя інформація",
          "generalInfoLbl": "Загальна інформація",
          "accountInfoLbl": "Інформація про практику",
          "pricelistInfoLbl": "Фінансові дані",
          "incompleteFormWarningMsg1": "Не всі поля заповнені",
          "incompleteFormWarningMsg2": "Ви впевнені, що хочете зберегти неповну форму?"
        },
        "general": {
          "basicInfoLbl": "Основна інформація",
          "avatarLbl": "Аватар",
          "nameLbl": "Назва",
          "lastNameLbl": "Прізвище",
          "sexLbl": "Секс",
          "emailLbl": "Електронна пошта",
          "phoneLbl": "Телефонний номер",
          "pwzLbl": "Номер PWZ",
          "dataSavedMsg": "Загальна інформація збережена"
        },
        "practise": {
          "basicInfoLbl": "Дозволи, надані послуги, науковий носій",
          "type": "Рівень",
          "consultationType": "Тип консультації",
          "academicTitle": "Вчене звання",
          "specialization": "Спеціалізація",
          "workPlace": "Робоче місце",
          "epileptologyCert": "Сертифікат епілептології",
          "nfzContract": "Договір НФЗ",
          "testResDescriptions": "Опис результатів тесту",
          "consultations": "Консультації",
          "scientificWork": "Наукова робота",
          "scientificSocieties": "Наукові товариства",
          "www": "Сторінка WWW",
          "bio": "Біо",
          "certificate": "Сертифікат ZUS",
          "chooseFile": "Виберіть файл",
          "patientsInfoLbl": "Пацієнти",
          "newPatients": {
            "label": "Нові пацієнти",
            "accept": "Зізнається",
            "noaccept": "Не визнає"
          },
          "acceptedPatients": "Прийняті пацієнти",
          "leadPractiseInfoLbl": "Провідна інформація про практику",
          "workplaceName": "Назва робочого місця",
          "workplacePosition": "Позиція",
          "communicators": "Комунікатори",
          "usernameMessanger": "Ім'я користувача месенджера",
          "usernameWhatsup": "Ім'я користувача WhatsApp",
          "usernameSkype": "Ім'я користувача Skype",
          "usernameHangouts": "Ім'я користувача Hangouts",
          "dataSavedMsg": "Інформація про практику збережена",
          "neuroscreenDiagnoses": "Розповсюджені хвороби",
          "partner": "Партнер NSPH"
        },
        "pricelist": {
          "services": "Послуги",
          "visitsLbl": "Відвідування",
          "othersLbl": "Інші",
          "systemItemsLbl": "Елементи системи",
          "systemItemsDescription": "Ці послуги глобально встановлюються компанією Neuroterminal",
          "descriptionsLbl": "Опис результатів тесту",
          "table": {
            "statusLbl": "Статус",
            "activeLbl": "Активний",
            "inactiveLbl": "Неактивний",
            "nameLbl": "Назва послуги",
            "durationLbl": "Тривалість",
            "priceLbl": "Ціна",
            "paymentLbl": "Ваш платіж",
            "perHourLbl": "на годину",
            "dutyLbl": "обов'язок"
          },
          "bankAccount": "банківський рахунок",
          "practiceName": "Назва приватної практики",
          "nip": "Ніп",
          "regon": "Regon",
          "dataSavedMsg": "Інформація про прайс -лист збережена",
          "visitsDescription": "Встановіть тарифи для певних типів відвідувань.",
          "othersDescription": "Встановіть тарифи на інші послуги, не згруповані за типами пацієнтів, як -от повідомлення або медичні документи",
          "choosePatientTypes": "Вам потрібно вибрати принаймні один тип пацієнта, щоб визначити прайс -лист відвідувань",
          "widgetVisibility": "Відображається у віджеті призначення"
        }
      },
      "patientDashboard": {
        "aboutMe": "Про мене",
        "myData": "мої дані",
        "myDataEdit": "Редагувати",
        "myDataFill": "Заповнити",
        "myDataShow": "Показати",
        "medicalDocuments": "медична документація",
        "healthQuestionnaire": "опитувальник здоров’я",
        "visitsLbl": "Відвідування",
        "eventCalendar": "Календар подій",
        "addEventLbl": "Додати",
        "seizure": "Напад",
        "infection": "Інфекція",
        "fever": "Лихоманка",
        "insomnia": "Безсоння",
        "menstruation": "Менструації",
        "pregnancyStart": "Початок вагітності або періоду до вагітності",
        "pregnancyEnd": "Вагітність або період до вагітності закінчуються",
        "chooseLeadDoctor": "Виберіть провідного лікаря",
        "alert": {
          "yourVisitWithDoctor": "Ваш візит до лікаря",
          "startsAt": "починається о",
          "lastsFrom": "триває з",
          "join": "Приєднуйтесь",
          "visitToMove": {
            "first": "Ваш візит о",
            "second": "було скасовано",
            "makeNewAppointment": "Виберіть нову дату"
          },
          "myData": {
            "label": "Щоб використовувати обліковий запис пацієнта, заповніть форму особистих даних.",
            "action": "Перейдіть до форми"
          },
          "prescriptionsToRead": {
            "label": "Лікар видав рецепт. Щоб переглянути PIN -код, перейдіть на вкладку Терапія.",
            "action": "Покажіть рецепти"
          },
          "selectLeadDoctor": {
            "label": "Виберіть свого головного лікаря.",
            "action": "Виберіть лікаря"
          },
          "doctorRejectedRequest": "{doctorName} не погодився стати вашим головним лікарем. Причина: {doctorName}"
        },
        "activation": {
          "welcomeLbl": "Ласкаво просимо до Neuroterminal",
          "init1Lbl": "Раді, що ви з нами.",
          "init2Lbl": "Перед першим відвідуванням заповніть інформацію про стан свого здоров’я.",
          "init3Lbl": "мої дані",
          "init4Lbl": "медична документація",
          "init5Lbl": "Тоді бронюйте візит нуль.",
          "nextLbl": "Далі"
        },
        "therapy": "поточна терапія",
        "noCurrentTherapies": "Поточної терапії немає",
        "drugStock": "запас наркотиків",
        "drugStockDescription": "Натисніть «Оновити», щоб надати свій поточний запас ліків.",
        "supplyFor": "постачання протягом {days} дн",
        "amountUndefined": "сума не визначена",
        "supplyEndsIn": "закінчується через {days} дн",
        "medicineLbl": "Ліки",
        "runningOutStocks": "Ваші запаси закінчуються. Попросіть свого провідного лікаря видати рецепт.",
        "update": "Оновлення",
        "save": "Зберегти",
        "askForPrescription": "Попросіть рецепт",
        "updateDrugStockLbl": "Оновлення запасів ліків",
        "updateDrugStockLblDesc": "Порахуйте свій поточний запас ліків і додайте значення у поле нижче.",
        "drugStockUpdateHelpLbl": "Введіть запаси наркотиків з початку сьогодні. Він буде оновлюватися щоранку автоматично з дозуванням з поточної медикаментозної терапії.",
        "drugStockUpdated": "Запаси ліків для {products} успішно оновлено",
        "drugStockRecalculationFailed": "Помилка автоматичного оновлення запасів ліків. Ви можете оновити свій запас ліків вручну.",
        "referrals": {
          "title": "Рекомендація від лікаря",
          "appoint": "Призначити"
        },
        "nonepileptic": {
          "infoMsg": "Усі консультації-це одноразові візити кращих спеціалістів. Детальні умови надання послуг із переліком лікарів доступні в процесі бронювання візиту.",
          "consultationsLbl": "консультації",
          "specialistsLbl": "спеціалістів",
          "epileptLbl": "епілептолог",
          "neurosurLbl": "нейрохірург",
          "neurologLbl": "нейрологопед",
          "psychLbl": "психіатр"
        },
        "dataNotFilled": {
          "title": "Ласкаво просимо в програму Neuroterminal!",
          "footer": "Виберіть, хто буде пацієнтом Neurosphere",
          "patient": "Я пацієнт",
          "patientDescription": "Для пацієнтів старше 18 років",
          "guardian": "Пацієнт – дитина",
          "guardianDescription": "Для пацієнтів віком до 18 років або мають законного опікуна"
        },
        "yourLeadDoctor": "Ваш головний лікар",
        "addEvent": "Додати подію",
        "showCalendar": "Показати календар",
        "filesAdded": "Файли додані",
        "addFiles": "Додати файли",
        "showAllFiles": "Переглянути всі файли",
        "surveyZeroDesc": "Опитування заповнює лікар під час візиту Опитування 0",
        "showSurveyZero": "Покажіть опитування",
        "confirmDeletionTitle": "Видалення події",
        "confirmDeletionText": "Видалити цю подію?",
        "noLeadDoctor": "Ви оберете головного лікаря після нульового візиту",
        "notAvailableNow": "Недоступний",
        "carePlan": {
          "title": "План догляду",
          "date": "Дата",
          "incomingVisitsAndReferrals": "Вхідні візити та реферали",
          "incomingVisitsAndReferralsDesc": "Тут з’являться ваші вхідні зустрічі та направлення лікаря",
          "bookFirstVisit": "Забронюйте перший візит",
          "archiveVisits": "Відвідування архіву",
          "archiveVisitsDesc": "Тут ви побачите візити, які вже відбулися",
          "scheduledDate": "запропонована дата",
          "getSummaryPdf": "отримати рекомендації від відвідування",
          "cancelReferral": "видалити реферал",
          "changeDate": "змінити дату",
          "cancelVisit": "скасувати візит",
          "payForVisit": "оплатити відвідування",
          "startingAt": "Починаючи з",
          "goToVisit": "Введіть візит",
          "showMoreArchiveVisits": "Показати всі відвідування архіву",
          "cancelReferralConfirmation": "Ви впевнені, що хочете видалити цього реферала?",
          "cancelReferralSuccess": "Реферал видалено",
          "chooseDate": "Виберіть дату",
          "change": "Змінити план догляду",
          "reassignVisit": "змінити лікаря",
          "chooseDateFreeReferral": "Виберіть дату безкоштовного відвідування",
          "suggestedDate": "запропонована дата",
          "referralRemoved": "Видалено направлення",
          "downloadSummary": "Завантажити резюме",
          "description": "опис",
          "filters": {
            "canceled": "Шоу скасовано",
            "visits": "Показати візити",
            "messages": "Показати повідомлення",
            "referrals": "Показати рефералів"
          }
        },
        "doctorCare": "Медична допомога",
        "neuroscreen": "Нейроскрін",
        "doctorOnDuty": "Черговий лікар"
      },
      "patientAccount": {
        "info": {
          "general": "Дані про пацієнтів",
          "infoLbl": "Перонні дані",
          "avatarLbl": "Аватар",
          "nameLbl": "Назва",
          "lastNameLbl": "Прізвище",
          "sexLbl": "Секс",
          "emailLbl": "Електронна пошта",
          "phoneLbl": "Номер телефону",
          "peselLbl": "Песел",
          "otherDocumentLbl": "Інший документ",
          "documentTypeLbl": "Тип документа",
          "documentNumberLbl": "Номер документа",
          "birthDate": "День народження",
          "repLbl": "Офіційні представницькі дані",
          "repLblShort": "Законний представник",
          "teleAddrLbl": "Дані телеадреси",
          "bioMedLbl": "Біомедичні дані",
          "bloodTypeLbl": "Група крові",
          "rhTypeLbl": "RH",
          "heightLbl": "Хайт",
          "weightLbl": "Вага",
          "iceLbl": "ДВС (у разі надзвичайної ситуації - у негайних ситуаціях)",
          "iceShortLbl": "ICE",
          "icePers1Lbl": "Особа, якій потрібно зателефонувати 1",
          "icePers1PhoneLbl": "Особа 1 телефон №1",
          "icePers2Lbl": "Особа, якій потрібно зателефонувати 2",
          "icePers2PhoneLbl": "Номер 2 особи 2",
          "clearBtn": "Ясно",
          "saveBtn": "Підтвердити",
          "dataSavedMsg": "Дані збережено",
          "entitledStatementMsg": "Цим заявляю, що маю повні законні права",
          "legalGuardianMsg": "Цим я заявляю, що я є законним представником, який має право представляти Пацієнта як:",
          "invalidPeselWarnMsg": "За умови, що PESEL неправильний, стать та дата народження не будуть збережені",
          "fromNsph": "Чи проходив лікування пацієнт у CTP Neurosphera?",
          "hasEpilepsy": "У пацієнта епілепсія?",
          "incompleteFormWarningMsg1": "Не всі поля заповнені",
          "incompleteFormWarningMsg2": "Ви впевнені, що хочете зберегти неповну форму? Пам’ятайте, що якщо ви не заповніть усі обов’язкові поля, ви не зможете забронювати нульовий візит.",
          "yesLbl": "Так",
          "noLbl": "Ні",
          "invoiceBuyer": {
            "title": "Дані рахунків -фактур",
            "checkboxLbl": "Я хочу встановити різні дані для рахунка -фактури",
            "firm": "Фірма",
            "person": "Приватна особа",
            "firmLbl": "Назва фірми",
            "nipLbl": "Ніп",
            "firstNameLbl": "Ім'я",
            "lastNameLbl": "Прізвище",
            "streetLbl": "Вулиця",
            "buildingLbl": "Будівля",
            "zipcodeLbl": "Поштовий індекс",
            "cityLbl": "Місто",
            "comment": "Прокоментуйте"
          },
          "generalDesc": "Нам потрібні деякі ваші дані. Це формальна вимога. Пам’ятайте, вони захищені юридично та технічно!",
          "generalDescLegal": "Нам також потрібні дані нашого пацієнта. Це офіційна вимога, а також можливість правильно виписувати рецепт, довідки чи направлення.",
          "nameLblRich": "<b>ім’я</b> пацієнта",
          "lastNameLblRich": "<b>Прізвище</b> пацієнта",
          "peselLblRich": "<b>Песель</b> пацієнта",
          "parentLbl": "Батьківські дані",
          "repLblDesc": "Нам потрібна додаткова інформація про вас, шановні батьки, щоб мати можливість піклуватися про вас відповідно до вимог законодавства. Пам’ятайте, що ваші дані захищені юридично та технічно!",
          "repNameLbl": "<b>Ім’я</b> батьків або законного представника",
          "repLastNameLbl": "<b>Прізвище</b> батьків або законного представника",
          "repPhoneLbl": "<b>Телефон</b> батьків або законного представника",
          "repPeselLbl": "<b>PESEL</b> батьків або законного представника",
          "teleAddrLblDesc": "Правильні контактні дані необхідні для того, щоб лікар виписав рецепт, довідку або направлення.",
          "bioMedLblDesc": "Наведена нижче інформація не є обов’язковою. Ви можете поповнити їх пізніше. Пам’ятайте, що надання біометричних даних допомагає лікарю підібрати правильну дозу ліків.",
          "others": "інші",
          "othersDesc": "Наведені нижче дані не є обов’язковими, якщо ви хочете залишити нам інформацію про те, кого повідомити у разі надзвичайної ситуації.",
          "addIceContact": "Додайте іншу людину",
          "nsphSourceQuestion": "Як ви дізналися про Neurosphere?",
          "nsphSourceDoctorDescription": "Вкажіть, будь ласка, ім’я лікаря",
          "incompleteFormConfirmBtn": "Заповніть дані",
          "address": {
            "streetLbl": "<b>вулиця</b> пацієнта",
            "cityLbl": "<b>Місто</b> пацієнта",
            "countryLbl": "<b>Країна</b> пацієнта"
          },
          "leaveDialog": {
            "title": "Ви не завершили введення даних, ви впевнені, що хочете вийти?",
            "subtitle": "Вам доведеться повторно ввести їх під час наступного входу",
            "footer": "Якщо у вас виникли додаткові запитання або виникли проблеми із заповненням заявки - зв'яжіться з нами:"
          },
          "contactEmailLbl": "Додаткова електронна адреса для отримання системних повідомлень",
          "contactEmailDesc": "За вказаною адресою буде доступна реєстратура клініки. Якщо ви також хочете отримувати сповіщення від системи на вказану адресу, відзначте відповідні пункти:",
          "contactVisitNotification": "Виїзди - оплата, підтвердження",
          "contactEmailPlaceholder": "Адреса електронної пошти",
          "contactDescriptionPlaceholder": "Опис адреси",
          "contactReferralNotification": "Направлення - план догляду",
          "addAdditionalContactEmail": "Додайте наступну електронну адресу",
          "documentCountry": "Країна",
          "partner": "Партнер NSPH"
        },
        "bioMed": {
          "saveSuccess": "Zmiany zostały zapisane",
          "fullInfo": "Показати всі дані"
        },
        "bioMedEditDialog": {
          "title": "Редагування біомедичних даних пацієнта",
          "subtitle": "При зміні значення дані автоматично зберігаються"
        }
      },
      "visitReservation": {
        "title": "Відвідайте бронювання",
        "consultationTitle": "Замовлення консультації",
        "specializationChoice": "Вибір спеціалізації",
        "doctorChoice": "Вибір лікаря",
        "slotChoice": "Вибір слота",
        "summaryAndPayment": "Підсумок та оплата",
        "noServicesAvailable": "Ви зможете призначити нові візити до епілепсії після того, як ви вже призначили візит",
        "goToVisitsList": "Перейдіть до списку відвідувань",
        "appointConsultation": "Призначте консультацію / ЕЕГ",
        "leadDoctor": "провідний лікар",
        "next": "Далі",
        "choose": "Виберіть",
        "back": "Назад",
        "doctor": "Лікар",
        "service": "Обслуговування",
        "price": "Ціна",
        "payment": "Форма оплати",
        "date": "Дата",
        "pay": "Оплата",
        "noSlotsAvailable": "Немає слотів",
        "doctors": "Лікарі",
        "availableSlots": "Доступні слоти",
        "summary": "Резюме",
        "serviceDescription": "Опис",
        "eegDescription": "У нейротермінальній системі ви реєструєтесь на стандартне обстеження ЕЕГ тривалістю 30 хвилин. Тест проводиться в Центрі терапії епілепсії нейросфери. Якщо ви хочете записатися на нестандартне тестування ЕЕГ або модифікацію ЕЕГ (VEEG, DEEG, PSG або інше), запишіться на прийом по телефону до секретаріату Центру терапії епілепсії нейросфери за номером +48609 463 191",
        "serviceTypeSelection": {
          "label": "Тип відвідування",
          "epilepsy": "Відвідування епілепсії",
          "consultation": "Консультація / ЕЕГ"
        },
        "sorting": {
          "label": "Сортувати",
          "alpha": "В алфавітному порядку",
          "teleconferencePriceAsc": "Ціна телеконференції: зростаюча",
          "teleconferencePriceDesc": "Ціна телеконференції: низхідна",
          "stationaryPriceAsc": "Ціна відвідування стаціонарного: зростаюча",
          "stationaryPriceDesc": "Ціна відвідування нерухомості: спад",
          "bestMatch": "Найкращий матч"
        },
        "serviceDescriptions": {
          "neurosurgeon": {
            "title": "Хто такий нейрохірург?",
            "description": "Хірург, який спеціалізується на діагностиці та хірургічному лікуванні деяких захворювань нервової системи (головний, спинний мозок, периферійні нерви), для яких фармакологічне лікування не дало результатів, і слід провести операцію.",
            "bulletsTitle": "Коли звертатися до нейрохірурга?",
            "bullet1": "Виникнення неврологічних проблем, при яких фармакологічне лікування не дало результатів, напр. дископатія, хронічний біль і травми спини, пухлини головного мозку та неопластичні ураження хребта, гемангіоми тощо.",
            "bullet2": "Сильні та тривалі головні болі та запаморочення",
            "bullet3": "Невідомі причини оніміння або поколювання в кінцівках",
            "bullet4": "Виникнення частого непритомності",
            "bullet5": "Наявність невідомої причини Порушення зору, слуху",
            "bullet6": "Поширеність парезів кінцівок"
          },
          "neuropsychologist": {
            "title": "Хто такий нейропсихолог?",
            "description": "Спеціаліст з клінічної психології, що займається питаннями взаємозв’язку між функціонуванням мозку та поведінкою людини. Діяльність нейропсихолога в основному спрямована на людей з ушкодженнями мозку, викликаними різними захворюваннями, травмами або травмами.",
            "bulletsTitle": "Коли звертатися до нейропсихолога?",
            "bullet1": "Коли у пацієнта діагностуються проблеми нормального функціонування, такі як травми голови, пухлини мозку, захворювання, що руйнують нервову тканину. наприклад Хвороба Альцгеймера або Паркінсона",
            "bullet2": "Відбувається погіршення пам’яті",
            "bullet3": "Проблеми з концентрацією уваги",
            "bullet4": "Виникнення порушень мовлення",
            "bullet5": "Труднощі з орієнтацією, пошуком шляху",
            "bullet6": "Незважаючи на нормальні офтальмологічні результати, є порушення зору",
            "bullet7": "З'являються зміни настрою, тривога, марення",
            "bullet8": "У станах залежності"
          },
          "neurologopedist": {
            "title": "Хто такий нейрологопед?",
            "description": "Спеціаліст з діагностики та лікування порушень мовлення та спілкування неврологічного походження. Нейрологопед може працювати як з дітьми, у яких затримка розвитку мовлення або порушення мовлення різного походження, так і з дорослими пацієнтами.",
            "bulletsTitle": "Коли звертатися до нейрологопеда?",
            "bullet1": "Коли у дитини діагностується неврологічний розлад, генетичний синдром або розлад аутистичного спектру",
            "bullet2": "Коли батьки помічають у дитини психомоторну відсталість",
            "bullet3": "Коли у дитини є проблеми з мовленням-2-річна або старша дитина взагалі не говорить, мало говорить або має мовленнєві вади",
            "bullet4": "Коли у дитини надмірно виділяється слина, у нього виникають проблеми з харчуванням, він не закриває рот і не дихає носом",
            "bullet5": "Коли у дорослої людини спостерігається порушення мови після інсульту або нещасного випадку",
            "bullet6": "Коли у дорослої людини є порушення мови внаслідок старечої деменції, хвороби Паркінсона, хвороби Альцгеймера або розсіяного склерозу"
          },
          "psychiatrist": {
            "title": "Хто такий психіатр?",
            "description": "Лікар, який займається проблемами психічного здоров'я - психічними розладами та захворюваннями, як з точки зору діагностики, так і подальшої терапії. Психіатр може проводити фармакологічне лікування та направляти додаткові консультації до інших спеціальностей.",
            "bulletsTitle": "Коли звертатися до психіатра?",
            "bullet1": "Коли вони тривалий час дратують: смуток, страждання, апатія, брак енергії, безпорадність, песимістичне бачення майбутнього",
            "bullet2": "Поява думок про самогубство",
            "bullet3": "При проблемах і порушеннях сну",
            "bullet4": "Для тривожних розладів",
            "bullet5": "Початок гіперактивності, неприродно підвищеного настрою та розгубленості",
            "bullet6": "Виникнення проблем з концентрацією уваги та погіршення пам’яті",
            "bullet7": "Уникання контактів та вихід на пенсію",
            "bullet8": "У разі марення, що впливає на сприйняття дійсності, і галюцинацій"
          },
          "epileptologist": {
            "title": "Хто такий епілептолог?",
            "description": "Невропатолог, що спеціалізується на діагностиці та лікуванні епілепсії. Епілептолог може спеціалізуватися на діагностиці та лікуванні епілепсії у дітей або дорослих. Окрім вибору терапії, лікар координує терапевтичний процес, направляючи пацієнта також до лікарів інших спеціальностей.",
            "bulletsTitle": "Коли звертатися до епілептолога?",
            "bullet1": "Виникнення раптового нападу, при якому пацієнт втрачає свідомість і падає, а м’язи напружуються. Виникають судоми, і пацієнт може перестати дихати",
            "bullet2": "Відбувається раптова втрата контакту з навколишнім середовищем, і пацієнт нерухомо застигає",
            "bullet3": "М’язові судоми виникають без втрати свідомості",
            "bullet4": "Розслаблення м’язів, втрата свідомості, падіння пацієнта"
          },
          "eeg": {
            "title": "Хто проводить тест ЕЕГ?",
            "description": "Тест на ЕЕГ проводить спеціаліст у цій галузі на прохання епілептолога, невропатолога або психіатра. Відповідає за планування та проведення всіх видів досліджень у дорослих та дітей. Дослідження проводяться в режимі неспання-засинання-сну. Їх також можна проводити вдома у пацієнта. Тест триває приблизно 30 хвилин.",
            "bulletsTitle": "Коли необхідне дослідження ЕЕГ?",
            "bullet1": "ЕЕГ -тест є основним для діагностики епілепсії",
            "bullet2": "ЕЕГ часто використовується для визначення причин порушення сну",
            "bullet3": "Може бути призначений для травм голови",
            "bullet4": "Інші ознаки включають порушення зору, заїкання, періодичні головні болі, непритомність або втрату свідомості"
          },
          "visit0": {
            "bulletsTitle": "Що таке опитування 0?",
            "bullet1": "<b>Передує та готує</b> 1 візит до лікаря-спеціаліста з нейросфери",
            "bullet2": "Це <b>детальне інтерв’ю з вашої історії хвороби</b>",
            "bullet3": "Його керує<b> клінічний ординатор </b>, якого ми підберемо індивідуально для вас",
            "bullet4": "<b>Підготуйтеся до цього.</b> Пригадайте, які ліки приймалися, як часто виникають судоми, як вони протікають і яка їх природа",
            "bullet5": "Лікар складе цифрову документацію вашого випадку у вигляді структурованого інтерв’ю. Цифрові дані, організовані таким чином, можуть бути проаналізовані нашим роботом Ictal, щоб запропонувати діагноз і терапію під час візиту 1",
            "bullet6": "Тривалість <b>до 60 хвилин</b>",
            "bullet7": "Ви можете вибрати тип відвідування - <b>онлайн або стаціонарний</b>"
          },
          "visit1": {
            "bulletsTitle": "Що таке візит 1?",
            "bullet1": "Це візит до клінічного спеціаліста з нейросфери на ваш вибір",
            "bullet2": "Лікар побачить результати Опитування 0, результати аналізу нашого робота Ictal і додані вами матеріали, що дозволить йому ефективно підтвердити діагноз і терапію",
            "bullet3": "При необхідності лікар запропонує вам додаткові дослідження",
            "bullet4": "Лікар запропонує вам комплексний план лікування",
            "bullet5": "Тривалість <b>до 30 хвилин</b>",
            "bullet6": "Ви можете вибрати тип відвідування - <b> онлайн чи стаціонарний</b>"
          },
          "vns": {
            "title": "Що таке відвідування VNS?",
            "description": "Під час відвідування VNS ваш лікар перевіряє ваші поточні налаштування. На основі історії зареєстрованих судом він або вона вибирає відповідну терапію VNS. Лікар під час візиту змінює параметри стимуляції за допомогою комп’ютера, конфігураційного програмного забезпечення та пульта для програмування. Лікар може зменшити або збільшити струм і збільшити або зменшити час бездіяльності пристрою. Візит триває 15 хвилин.",
            "bulletsTitle": "Коли записатися на прийом до VNS?",
            "bullet1": "При ВНС терапія передбачає поступову зміну поточних параметрів",
            "bullet2": "Коли пацієнт помічає значне збільшення кількості судом",
            "bullet3": "Коли пацієнт помічає значне погіршення самопочуття і самопочуття",
            "bullet4": "Коли пацієнт помічає, що імплантований стимулятор більше не функціонує, тобто більше не посилає імпульси",
            "bullet5": "Перед проходженням будь-яких медичних тестів, які можуть вплинути або на які впливатиме пристрій VNS, наприклад, магнітно-резонансна томографія (МРТ)"
          },
          "dietician": {
            "title": "Хто такий дієтолог?",
            "description": "Фахівець, який закінчив вищу освіту за медичним або дієтологічним (сільськогосподарським) профілем. Володіє достатніми знаннями для проведення дієт у різних груп пацієнтів, а також при складанні рекомендацій враховує, серед іншого, стать, вік, вагу та зріст, захворювання, у тому числі алергії, уподобання пацієнта. Тому він створює індивідуальний план харчування для кожного пацієнта і контролює його. Залежно від результатів тесту або інформації, отриманої від пацієнта, він вносить необхідні зміни. Тому тісна співпраця з пацієнтом та його лікарем надзвичайно важлива.",
            "bulletsTitle": "Коли звертатися до дієтолога?",
            "bullet1": "Коли лікар Neurosphere запропонував лікування кетогенною дієтою",
            "bullet2": "Якщо ви думаєте, що кетогенна дієта може бути варіантом лікування у вашому випадку (ПРИМІТКА - візит до лікаря також необхідний, якщо ви пацієнт Neurospeha, запитайте свого лікаря про цей варіант лікування, якщо ви ще не пацієнт Neurospeha , записатися на прийом НУЛЬ)",
            "bullet3": "Коли ви дотримуєтесь кетогенної дієти і хочете перевірити своє поточне лікування (ПРИМІТКА - візит до лікаря також необхідний)",
            "bullet4": "Коли ви перебуваєте на кетогенній дієті - візит на огляд (дієтолог повідомить вас про найкращий час для запису під час візиту)",
            "bullet5": "Коли ви хочете перевірити свій поточний раціон"
          },
          "neurologist": {
            "title": "Хто такий невропатолог?",
            "description": "Фахівець, який спостерігає за реакціями організму пацієнта, досліджує функції нервової системи, а також перевіряє реакції організму людини на подразники. Займається захворюваннями периферичної та центральної нервової системи. Невролог зосереджується насамперед на захворюваннях, які викликані процесами, що руйнують нервову систему людини. Він обстежує основи неврологічних захворювань, що виникають, ставить діагноз і призначає відповідне лікування. Він займається лікуванням таких захворювань, як радикуліт, дегенерація хребта, епілепсія, хвороба Паркінсона, хвороба Альцгеймера, хорея Хантінгтона, інсультні та постінсультні стани, мігрень та інші головні болі.",
            "bulletsTitle": "Коли звертатися до невролога?",
            "bullet1": "Коли виникають непритомність і втрата свідомості",
            "bullet2": "Коли різко погіршується пам'ять і виникають проблеми з концентрацією уваги",
            "bullet3": "Коли у вас проблеми зі сном",
            "bullet4": "Коли виникають проблеми з рівновагою та координацією",
            "bullet5": "Якщо з’являється поколювання в кінцівках разом із порушенням відчуття",
            "bullet6": "Якщо є хронічний біль, пов’язаний із запамороченням"
          },
          "geneticist": {
            "title": "Хто такий генетик?",
            "description": "Клінічний генетик – це лікар, завданням якого є визначити, чи є захворювання спадковим і який механізм призвів до успадкування аномалій. Генетик також визначає ймовірність таких же дефектів або захворювань у наступних поколіннях. Його метою є також виявлення членів сім’ї хворої людини, у яких може розвинутися спадкове захворювання. Роль генетики також полягає в тому, щоб інформувати сім’ю про варіанти профілактики та лікування захворювання та надавати їм генетичне консультування. Важливим елементом є також проведення спеціалізованого лікування спадкового захворювання.",
            "bulletsTitle": "Коли звертатися до генетика?",
            "bullet1": "Коли у вашій родині є захворювання з відомими або підозрюваними генетичними причинами",
            "bullet2": "Коли ви хочете зробити тест WES",
            "bullet3": "Коли ви хочете виключити вроджене захворювання, яке може бути причиною епілепсії",
            "bullet4": "Коли ви плануєте вагітність і хочете дізнатися, чи може ваша дитина також хворіти на епілепсію"
          },
          "absent": {
            "title": "Що таке ясна консультація?",
            "description": "Заочна консультація – це вид медичного візиту, який обраний фахівець Neurosphere проводить без присутності пацієнта. Відсутна консультація спрямована на аналіз медичної документації (виписні карти, результати генетичних досліджень та інше) або додаткове обстеження (ЕЕГ, МРТ та інші). Фахівець з нейросфери проаналізує документацію, зробить відповідну записку та рекомендації для пацієнта. Факт його проведення та його результат будуть зберігатися в архіві відвідувань пацієнта. ПРИМІТКА: заочна консультація триває 30 хвилин і проводиться без участі пацієнта.",
            "bulletsTitle": "Коли я записуюся на заочну консультацію?",
            "bullet1": "Вам буде направлено записатися на заочну консультацію під час візиту 1 або пізніше - якщо лікар вважає таку консультацію доцільною. Його метою є проведення додаткового клінічного аналізу, який виходить за межі часових рамок або вимагає консультації з іншим лікарем (наприклад: виявляється, наприклад, що необхідно проконсультуватися з вибраним фахівцем на метаболічний тест або кілька МРТ-обстежень протягом слід проаналізувати період у кілька років).",
            "bullet2": "Як правило, протягом 30-хвилинної консультації клінічний спеціаліст має можливість побачити два допоміжні тести з описом."
          },
          "metabolic": {
            "title": "Що таке метаболічна консультація?",
            "description": "Метаболічна консультація стосується рідкісних захворювань – вроджених дефектів обміну речовин, які є генетичними захворюваннями. Діагностика метаболічних захворювань в основному включає численні обстеження та лабораторні дослідження, часто підкріплені оглядами спеціалістів.",
            "bulletsTitle": "Коли консультуватися?",
            "bullet1": "Варто прийти на метаболічну консультацію, якщо є підозра на захворювання при генетичних дослідженнях, або якщо тести: тандемна мас-спектрометрія крові (тандем MS, MS / MS) або профіль органічної кислоти сечі (GC-MS тест) неправильний.",
            "bullet2": "Деякі захворювання, пов’язані з епілепсією, можуть мати метаболічні основи, напр. дефіцит піруватдегідрогенази (PDHD), дефіцит транспортера глюкози в мозок (GLUT-1 DS) або окремі мітохондріальні захворювання."
          },
          "veegOnStandby": {
            "title": "Що таке обстеження VEEG під час неспання?",
            "description": "VEEG – це запис у режимі реального часу синхронізованої ЕЕГ та відео. Для правильного діагнозу лікар повинен знати, що відбувається з пацієнтом під час судом. Важливі всі деталі: в яку сторону повертається голова, в яку сторону «бігають очні яблука», де виникають судоми і т. д. Відеометричне дослідження дозволяє лікарю побачити пацієнта в цей відповідальний для діагностики захворювання момент. Дослідження проводять у спеціально підготовленій лабораторії. Огляд наяву проводиться вдень. Пацієнт за вказівкою лікаря вирішує, скільки має тривати обстеження. У кабінеті для огляду пацієнт має крісло та ліжко. Під час огляду пацієнт може користуватися туалетом, будучи прив’язаним до апарату ЕЕГ. Після огляду пацієнт отримує компакт-диск із записом. Опис обстеження входить у вартість.",
            "bulletsTitle": "Як підготуватися до іспиту?",
            "bullet1": "У хворого повинна бути чиста голова",
            "bullet2": "Перед оглядом не можна наносити на волосся гель або лак",
            "bullet3": "Приймайте ліки за призначенням лікаря",
            "bullet4": "Не проводьте депривацію сну без домовленостей з лікарем - хворий засинає"
          },
          "veegDuringSleep": {
            "title": "Що таке обстеження VEEG під час сну?",
            "description": "VEEG – це запис у режимі реального часу синхронізованої ЕЕГ та відео. Для правильного діагнозу лікар повинен знати, що відбувається з пацієнтом під час судом. Важливі всі деталі: в яку сторону повертається голова, в яку сторону «бігають очні яблука», де виникають судоми і т. д. Відеометричне дослідження дозволяє лікарю побачити пацієнта в цей відповідальний для діагностики захворювання момент. Дослідження проводять у спеціально підготовленій лабораторії. Огляд сну проводиться ввечері або вночі. Скільки повинно тривати обстеження, пацієнт вирішує за вказівками лікаря. У кабінеті для огляду пацієнт має крісло та ліжко. Пацієнт може користуватися туалетом під час обстеження, будучи прив’язаним до приладу ЕЕГ. Великі діти і дорослі лежать одні на ліжку, маленькі діти з супроводжуючим, кімната очікування знаходиться в розпорядженні супроводжуючого. Після огляду пацієнт отримує компакт-диск із записом. Опис обстеження входить у вартість.",
            "bulletsTitle": "Як підготуватися до іспиту?",
            "bullet1": "У хворого повинна бути чиста голова",
            "bullet2": "Перед оглядом не можна наносити на волосся гель або лак",
            "bullet3": "Приймайте ліки за призначенням лікаря",
            "bullet4": "Не проводьте депривацію сну без домовленостей з лікарем - хворий засинає",
            "bullet5": "Не завадить взяти з дому улюблену подушку"
          },
          "veegNight": {
            "title": "Що таке тест VEEG?",
            "description": "VEEG – це запис у режимі реального часу синхронізованої ЕЕГ та відео. Для правильного діагнозу лікар повинен знати, що відбувається з пацієнтом під час судом. Важливі всі деталі: в яку сторону повертається голова, в яку сторону «бігають очні яблука», де виникають судоми і т. д. Відеометричне дослідження дозволяє лікарю побачити пацієнта в цей відповідальний для діагностики захворювання момент. Дослідження проводять у спеціально підготовленій лабораторії. У кабінеті пацієнта є крісло та ліжко. Ми починаємо обстеження з моменту, коли пацієнт засинає, потім проходимо окремі фази і нарешті фіксуємо момент пробудження. Пацієнт має можливість користуватися туалетом під час огляду, залишаючись прикріпленим до ЕЕГ апарату. Великі діти і дорослі лежать одні на ліжку, маленькі діти з вихователем, є кімната очікування. Після огляду пацієнт отримує компакт-диск із записом. Опис обстеження входить у вартість.",
            "bulletsTitle": "Як підготуватися до іспиту?",
            "bullet1": "У хворого повинна бути чиста голова",
            "bullet2": "Перед оглядом не можна наносити на волосся гель або лак",
            "bullet3": "Приймайте ліки за призначенням лікаря",
            "bullet4": "Не проводьте депривацію сну без домовленостей з лікарем - хворий засинає",
            "bullet5": "Не завадить взяти з дому улюблену подушку"
          },
          "veegDay": {
            "title": "Що таке 24-годинний тест VEEG?",
            "description": "Тест VEEG – це запис у режимі реального часу синхронізованого ЕЕГ та відеодослідження. Для правильного діагнозу лікар повинен знати, що відбувається з пацієнтом під час судом. Важливі всі деталі: в яку сторону повертається голова, в яку сторону «бігають очні яблука», де виникають судоми і т. д. Відеометричне дослідження дозволяє лікарю побачити пацієнта в цей відповідальний для діагностики захворювання момент. Дослідження проводять у спеціально підготовленій лабораторії. У кабінеті пацієнта є крісло та ліжко. Зазвичай обстеження починається з нічного огляду, а потім ми спостерігаємо за пацієнтом протягом дня. Під час обстеження пацієнт має можливість користуватися туалетом, залишаючись прикріпленим до апарату ЕЕГ. Харчування входить у вартість обстеження. Великі діти і дорослі лежать одні на ліжку, маленькі діти з супроводжуючим, є кімната очікування. Після огляду пацієнт отримує компакт-диск із записом. Опис обстеження входить у вартість.",
            "bulletsTitle": "Як підготуватися до іспиту?",
            "bullet1": "У пацієнта повинна бути чиста голова",
            "bullet2": "Перед оглядом не можна наносити на волосся гель або лак",
            "bullet3": "Приймайте ліки за призначенням лікаря",
            "bullet4": "Не проводьте депривацію сну без домовленостей з лікарем - хворий засинає",
            "bullet5": "Не завадить взяти з дому улюблену подушку",
            "bullet6": "Додаткову інформацію можна домовитися з техніком, зателефонувавши на реєстрацію хірургії +48 609 463 191"
          },
          "eegConsultation": {
            "title": "Що таке ЕЕГ дослідження?",
            "description": "Стандартне дослідження ЕЕГ проводиться під час неспання пацієнта. Технік вибирає відповідний ковпачок і розміщує електроди на голові пацієнта. Для якості обстеження важливо, щоб вони якомога ближче прилягали до шкіри голови. Сам огляд займає близько 20 хвилин. Відразу після обстеження пацієнт може повернутися до нормальної діяльності. Після огляду пацієнт отримує компакт-диск із записом. Опис обстеження входить у вартість.",
            "bulletsTitle": "Як підготуватися до іспиту?",
            "bullet1": "У пацієнта повинна бути чиста голова",
            "bullet2": "Перед оглядом не можна наносити на волосся гель або лак",
            "bullet3": "Приймайте ліки за призначенням лікаря",
            "bullet4": "Не проводьте депривацію сну без домовленостей з лікарем - хворий засинає"
          },
          "sleepDisturbance": {
            "title": "Що таке консультація з приводу порушення сну?",
            "description": "Порушення сну можуть бути первинними (внаслідок порушень самого сну), а також бути симптомом захворювань інших систем. Іноді показана консультація психіатра. Під час візиту буде проведено детальну бесіду, яка охоплюватиме перебіг ночі та розклад дня. Для такого візиту варто підготувати щоденник сну, який охоплює не менше 5 днів і записувати всі тривожні епізоди, що відбуваються вночі, домашніми методами. Після консультації буде оцінено, фізіологічна проблема чи ні. Якщо діагностовано розлад сну, вам запропонують лікування - лікування або направлення до фахівця, який займається основною проблемою.",
            "bulletsTitle": "Коли консультуватися?",
            "bullet1": "Якщо ви сонливі або дратівливі протягом дня",
            "bullet2": "Якщо ви відчуваєте безсоння або надмірну сонливість, викликану емоційними факторами",
            "bullet3": "Якщо спостерігаються порушення добового ритму сну і пробудження",
            "bullet4": "Якщо ви хропете під час сну"
          },
          "regularVisitEegPackage": {
            "title": "Що таке регулярне відвідування + пакет ЕЕГ?",
            "description": "ЕЕГ – стандартний тест для оцінки біоелектричної активності головного мозку, на підставі якого оцінюється правильність його роботи. Обстеження займає близько 20 хвилин. Після обстеження пацієнт йде на огляд. РЕГУЛЯРНЕ стаціонарне відвідування - кожне наступне відвідування. Триває до 30 хвилин. Під час візиту лікар аналізує проведене дослідження ЕЕГ, встановлює остаточний діагноз і аналізує дані, зібрані в системі. Пацієнт отримує компакт-диск із записом ЕЕГ та рекомендаціями з чергового візиту.",
            "bulletsTitle": "Коли записуватися на пакет?",
            "bullet1": "ЕЕГ є основним тестом у діагностиці епілепсії",
            "bullet2": "Пацієнт, який страждає на епілепсію, повинен щорічно проходити ЕЕГ-обстеження і регулярно відвідувати епілептолога.",
            "bullet3": "Якщо ви плануєте стаціонарний візит до лікаря, варто провести ЕЕГ для оцінки біоелектричної активності головного мозку.",
            "bullet4": "Ви не відчули поліпшення або погіршення стану свого здоров'я, хочете поговорити з лікарем про зміну лікування"
          },
          "adhdPackage": {
            "title": "Як діагностується СДУГ?",
            "description": "Діагностика РДУГ складається з 2-х зустрічей з нейропсихологом по 1,5 години кожна, під час яких проводиться клінічне співбесіда та нейропсихологічні тести. Після зустрічі психолог дає висновок і рекомендує, до кого звернутися пацієнту, чи до невролога (30 хвилин), чи до психіатра (45 хвилин). Під час відповідного візиту пацієнт отримає діагноз і рекомендації щодо подальшого лікування (медикаментозне лікування або психологічна терапія).",
            "bulletsTitle": "Коли підписуватися на пакет ADHD?",
            "bullet1": "Якщо виникають труднощі з організацією часу та завдань. Вам важко починати і завершувати проекти, планувати і підтримувати порядок",
            "bullet2": "Якщо є емоційні труднощі, ви схильні до перепадів настрою, розчарування та труднощів з контролем своїх емоцій. Ви відчуваєте сильну реакцію на стресові ситуації",
            "bullet3": "Коли ви помічаєте в собі міжособистісні труднощі. Ви неспокійні, імпульсивні, вам важко брати до уваги точку зору інших людей",
            "bullet4": "Якщо вам важко оцінити час, необхідний для виконання завдання"
          },
          "standardVisit": {
            "title": "Що таке звичайний візит?",
            "description": "РЕГУЛЯРНЕ відвідування – кожне наступне відвідування. Триває до 30 хвилин. Під час візиту лікар збирає бесіду та аналізує календар подій пацієнта. Лікар ознайомиться з медичною документацією пацієнта і може запропонувати додаткові обстеження. Після кожного візиту пацієнт отримує рекомендації з візиту, план терапії та план подальшого догляду. Пацієнт може обрати тип візиту – онлайн чи стаціонарний.",
            "bulletsTitle": "Як виглядає звичайний візит?",
            "bullet1": "Обговорюючи поточний стан здоров'я - згадайте дату останнього нападу і самопочуття - заповніть календар подій",
            "bullet2": "Ознайомлення з результатами останніх аналізів - якщо ви нещодавно проводили аналізи, переконайтеся, що всі результати додані до медичної документації",
            "bullet3": "Обговорення подальшої терапії",
            "bullet4": "Складання комплексного плану догляду",
            "bullet5": "Видача рецепта, направлення, лікарняного, довідки"
          },
          "standardVisit15": {
            "title": "Що таке звичайний візит?",
            "description": "РЕГУЛЯРНЕ відвідування – кожне наступне відвідування. Триває до 30 хвилин. Під час візиту лікар збирає бесіду та аналізує календар подій пацієнта. Лікар ознайомиться з медичною документацією пацієнта і може запропонувати додаткові обстеження. Після кожного візиту пацієнт отримує рекомендації з візиту, план терапії та план подальшого догляду. Пацієнт може обрати тип візиту – онлайн чи стаціонарний.",
            "bulletsTitle": "Як виглядає звичайний візит?",
            "bullet1": "Обговорюючи поточний стан здоров'я - згадайте дату останнього нападу і самопочуття - заповніть календар подій",
            "bullet2": "Ознайомлення з результатами останніх аналізів - якщо ви нещодавно проводили аналізи, переконайтеся, що всі результати додані до медичної документації",
            "bullet3": "Обговорення подальшої терапії",
            "bullet4": "Складання комплексного плану догляду",
            "bullet5": "Видача рецепта, направлення, лікарняного, довідки"
          },
          "caseConference": {
            "title": "Що таке кейс-конференція?",
            "description": "Медична конференція між медичними спеціалістами та медичними партнерами Neurosphery – це колективна зустріч для обговорення клінічного випадку пацієнта та визначення оптимального плану лікування. Консультація організовується в ситуаціях, коли потрібна допомога або додаткові спеціальні знання для досягнення найкращих результатів лікування. Це різновид міждисциплінарної медичної конференції, під час якої відбувається обмін знаннями та думками між спеціалістами."
          },
          "mmpi2Package": {
            "title": "Що таке тест особистості MMPI-2?",
            "description": "Ми дозволяємо діагностувати розлади особистості за допомогою найширшого та універсального інструменту клінічної оцінки, доступного в Польщі - Minnesota Multidimensional Personality Inventory (повна адаптація MMPI-2 була випущена в 2012 році). Тест містить десять контрольних шкал, за допомогою яких можна описати достовірність отриманого профілю результату та обґрунтувати їх клінічну інтерпретацію. Контрольні шкали також можуть інформувати діагноста про мотивацію обстежуваного, рівень адаптації та можливі механізми захисту.",
            "bulletsTitle": "Що таке тест особистості MMPI-2?",
            "bullet1": "Сам перелік можна використовувати в клінічному описі особистісних проблем, але він також може бути корисним для ідентифікації людей, які не підходять для виконання конкретної професійної функції. Його можна використовувати для діагностики дорослих, які отримали принаймні 6 років формальної освіти. Тест повинен проходити під наглядом психолога. На проведення і виконання тесту необхідно виділити приблизно 90 хвилин. Виконуючи тест, ви повинні звернутися до 567 тверджень, вибравши ІСТИННО/НЕПРАВИЛЬНО. Завершенню самого тесту має передувати зустріч, приблизно 50 хвилин, щоб зібрати дані з інтерв’ю та представити тест особі, яку тестують. Протягом тижня, після завершення візиту та проходження тесту, особа, яка обстежується, отримає звіт із узагальненням отриманих результатів."
          },
          "cbtPackage": {
            "title": "Що таке КПТ терапія безсоння?",
            "description": "Когнітивно-поведінкова терапія (КПТ) для лікування безсоння вважається першим засобом лікування для людей, які борються з безсонням, рекомендована такими органами, як Національні інститути здоров’я (NIH), Національний інститут здоров’я та передового догляду (NICE) та Американський коледж. лікарів. CBT-I є ефективною альтернативою снодійним, пропонуючи довгострокові рішення шляхом зміни звичок сну та мислення. Весь процес CBT-I включає п’ять сеансів протягом шести тижнів, але поліпшення зазвичай можна помітити раніше. Заняття можуть проводитися стаціонарно або онлайн. Близько 75% людей з хронічним безсонням відчувають значне поліпшення сну, а 90% зменшують або повністю припиняють прийом снодійних.",
            "bulletsTitle": "Терапія CBT-I рекомендована людям, які:",
            "bullet1": "Вони відчувають хронічне безсоння: проблеми зі сном виникають принаймні тричі на тиждень протягом принаймні трьох місяців.",
            "bullet2": "Важко заснути: вони витрачають більше 30 хвилин, намагаючись заснути вночі.",
            "bullet3": "Прокидання вночі: вони регулярно прокидаються посеред ночі і їм важко заснути більше 30 хвилин.",
            "bullet4": "Через це вони почуваються втомленими та дратівливими протягом дня: безсоння впливає на їх щоденне самопочуття та якість життя.",
            "bullet5": "Вони шукають альтернативу снодійним: вони хочуть уникнути залежності від фармакотерапії та шукають довгострокові природні рішення."
          },
          adhdConsultation: {
              title: 'Чому воно того варте?',
              description: 'Індивідуальний коучинг – це інтенсивна одногодинна зустріч з досвідченим тренером із СДУГ, зосереджена на ваших поточних викликах і цілях. Це не теоретичні бесіди - сесія планується, щоб дати вам конкретні практичні інструменти, які ви можете негайно застосувати. Ви отримуєте годину повної зосередженості на своїх потребах без загальних порад і «одного рішення для всіх». Ця підтримка адаптована до специфіки СДУГ і ваших індивідуальних цілей, щоб ви відразу відчули різницю у своєму житті.',
              bulletsTitle: 'Що ви отримаєте за один сеанс?',
              bullet1: '<b>Аналіз поточних викликів</b> – коуч допоможе вам зрозуміти, які труднощі виникають безпосередньо через СДУГ і як їх ефективно подолати.',
              bullet2: '<b>Практичні стратегії</b> – ви дізнаєтесь про конкретні техніки, які можете негайно почати використовувати для покращення своєї концентрації, організації та управління часом.',
              bullet3: '<b>Індивідуальний план дій</b> – ви розробите план кроків до наступного заняття, який допоможе вам залишатися мотивованим і швидко побачити результати.',
          },
          psychotherapy: {
              title: 'Що таке сеанс психотерапії?',
              description: 'Сеанс психотерапії - це зустріч пацієнта (клієнта) з психотерапевтом, метою якої є спільне вирішення емоційних, психічних або поведінкових проблем. Сесія має на меті допомогти вам зрозуміти, прийняти та змінити свої думки, емоції чи поведінку. Це може включати роботу над травмою, тривогою, депресією, стресом або проблемами у відносинах.',
              bulletsTitle: 'Коли записуватися на сеанс?',
              bullet1: 'Психотерапія рекомендована в багатьох ситуаціях, коли хтось відчуває емоційні, розумові чи соціальні труднощі, які впливають на повсякденне функціонування та якість життя. Це також може бути корисним для розвитку самосвідомості та покращення стосунків з іншими.',
          }
        },
        "visitTypeChoice": "Тип відвідування",
        "selectPreviousSlots": "Доступні місця будуть представлені після вибору попередньої зустрічі",
        "allDoctors": "Всі лікарі",
        "paymentLinkSent": "Посилання на оплату надіслано пацієнту",
        "openPaymentLink": "Відкрити платіжне посилання",
        "linkCopied": "Посилання скопійовано в буфер обміну",
        "visitPackageDescriptions": {
          "startPackage1": "Як новий пацієнт Neurophera або батько пацієнта, ви повинні записатися на прийом 1 до клінічного спеціаліста. Йому передуватиме опитування 0, яке дозволить нам збирати інформацію для ефективної діагностики та лікування. Все це за доступною ціною.",
          "startPackage2": "Виберіть лікаря-спеціаліста, який перегляне візит 1 і чи буде він онлайн чи стаціонарний. Зверніть увагу на відмінності в цінах і на місце стаціонарного відвідування.",
          "startPackage3": "Опитування 0 передує та готується до візиту 1. Воно проводитиметься з клінічним ординатором нейросфери за нашим вибором. Виберіть зручний для вас тип візиту (онлайн чи стаціонарний) та його дату. Пам’ятайте, що опитування 0 триває до 60 хвилин.",
          "startPackage4": "Здійснення оплати дозволяє нам записатися на прийом до даного лікаря для вас. Пам’ятайте, ви завжди можете змінити дату безкоштовно!"
        },
        "slotChoiceDescriptions": {
          "VISIT_1": "Відвідування 1 відбувається після опитування 0. Клінічний спеціаліст із нейросфери та тип відвідування, який ви вже вибрали на попередньому кроці. Лікар поставить діагноз і призначить відповідну терапію. Він також підготує індивідуальний план догляду. Пам’ятайте, візит 1 триває до 30 хвилин."
        },
        "calculateInstallments": "Оплата частинами",
        "priceFrom": "Ціна від",
        "payments": {
          "directPayment": "Ви можете безпечно платити за допомогою {provider}:",
          "creditCard": "Кредитна карта",
          "blik": "Blik",
          "onlineTransfer": "Онлайн переказ",
          "onlineApplication": "Онлайн заявка",
          "mobilePayments": "Мобільні платежі",
          "payLater": "Платіть пізніше"
        },
        "expand": "Розгорнути",
        "approve": "Підтвердьте",
        "cancel": "Скасувати",
        "fold": "Складіть",
        "cancelAndChangeDate": "Скасувати / змінити дату",
        "visitDuration": "Тривалість візиту",
        "durationSelectDesc1": "Обстеження може тривати з",
        "durationSelectDesc2": "до",
        "durationSelectDesc3": "годин",
        "durationSelectDesc4": "Щоб продовжити зустріч, виберіть кількість годин",
        "durationTime": "Час огляду",
        "serviceGroupDescriptions": {
          "eeg_consultation": {
            "groupSelectionTitle": "Доступні дослідження ЕЕГ/ВЕЕГ",
            "title": "Хто проводить ЕЕГ-тест?",
            "description": "Тест ЕЕГ проводиться фахівцем, що спеціалізується в цій галузі, за бажанням епілептолога, невролога або психіатра. Відповідає за планування та проведення досліджень усіх модальностей у дорослих та дітей. Дослідження проводять у стані неспання-засинання-сон. Їх також можна проводити вдома у пацієнта. Тест займає приблизно 30 хвилин.",
            "bulletsTitle": "Коли необхідний тест ЕЕГ?",
            "bullet1": "ЕЕГ-тест є основним тестом у діагностиці епілепсії",
            "bullet2": "ЕЕГ часто використовується для визначення причин порушення сну",
            "bullet3": "Може бути призначений при травмах голови",
            "bullet4": "Інші ознаки включають порушення зору, заїкання, періодичні головні болі, непритомність або втрату свідомості"
          }
        },
        "allWorkplaces": "Всі локації",
        "allTechniciansIntro": "Обстеження проведе один із наших досвідчених техніків",
        "markedAsPaid": "Візит призначений і позначений як оплачений",
        "markAsPaid": "Позначити як оплачений",
        "stage": "етап",
        "sum": "сума",
        "freeAppointmentPrice": "безкоштовно",
        "appointmentSuccess": "Wizyta została umówiona",
        "sameDateAsPrevious": "Візит має бути в той самий день, що й попередній пакетний візит",
        "refundRestrictions": "Правила скасування візиту",
        "refundRestrictionsAccept": "Я приймаю правила скасування",
        "supervisorDoctor": "візит керівника",
        "supervisorDoctorAbbr": "Керівник",
        "doctorPackageDescription": "Пакет лікарів – це візит за участю 2-х і більше лікарів. Лікар, який проводить візит, виконує всі дії, пов'язані з візитом. Спостерігаючий лікар спостерігає за візитом і може брати участь у клінічній дискусії з лікуючим лікарем і пацієнтом.",
        "specialist": "Спеціаліст в"
      },
      "paymentOutcome": {
        "title": "Підтвердження оплати",
        "loading": "Завантаження платіжних даних",
        "status": {
          "title": "Статус",
          "types": {
            "pending": {
              "title": "Обробка платежу триває",
              "subtitle": "Оплата ще не підтверджена",
              "comment": "Зачекайте хвилину, поки платіж буде завершено"
            },
            "success": {
              "title": "Послуга була оплачена",
              "subtitle": "Оплату підтвердили",
              "comment": "Дякуємо за оплату"
            },
            "error": {
              "title": "Послуга не оплачувалася",
              "subtitle": "Платіж скасовано",
              "comment": "Повторіть процедуру оплати"
            }
          }
        },
        "details": {
          "title": "Деталі",
          "date": "Дата",
          "name": "Найменування послуги",
          "doctor": "Лікар"
        }
      },
      "visitPaymentOutcome": {
        "title": "Відвідайте бронювання",
        "subtitle": "підтвердження",
        "details": "подробиці",
        "loading": "Дані про відвідування завантажуються ...",
        "card": {
          "successTitle": "Ваш візит був платним",
          "successLabel1": "Дякуємо за оплату",
          "successLabel2": "Ви будете повідомлені про візит за 24 години до цього",
          "failTitle": "Ваш візит не був оплаченим",
          "failLabel1": "Сталася помилка в процесі оплати",
          "retryPayment": "Повторити платіж",
          "toGoMessageView": "Перейдіть до перегляду повідомлень",
          "paymentPending": "Ваш платіж ще не підтверджено. Це може зайняти до 1 хвилини",
          "paymentConfirmed": "Оплата підтверджена!",
          "successLabel1Package": "Дякую! Про вас добре доглядають!"
        },
        "goHome": "Перейдіть на головну сторінку",
        "visitScope": "Обсяг візиту",
        "scopeBullets": {
          "visit0Bullet1": "Тривалість прибл. 60 хвилин",
          "visit0Bullet2": "Очолює лікар -ординатор",
          "visit0Bullet3": "Інтерв'ю з лікарем щодо медичних, біометричних та особистих даних пацієнта",
          "visit0Bullet4": "Аналіз епілепсії та супутніх захворювань",
          "visit0Bullet5": "Аналіз історії терапії",
          "visit0Bullet6": "На основі зібраних даних можна буде спочатку поставити робочий діагноз і визначити масштаб медичної проблеми пацієнта",
          "migrationVisitBullet1": "Призначено для пацієнтів, які перебували під нашою опікою до 2021 року",
          "migrationVisitBullet2": "Перевірка медичних, біометричних та особистих даних пацієнта",
          "migrationVisitBullet3": "Перехід до нової системи догляду",
          "visit1Bullet1": "Тривалість від 30 до 60 хвилин",
          "visit1Bullet2": "Аналіз ICtal® та початкове розпізнавання комп’ютера",
          "visit1Bullet3": "Початкова діагностика та вибір лікаря",
          "visit1Bullet4": "Розробка початкового плану лікування з планом обстежень та додаткових заходів",
          "visit1Bullet5": "Розробка плану клінічного комп’ютерного спостереження ICtal Move® та початкового плану комп’ютерної терапії ICtal®",
          "visit1Bullet6": "Обговорення попередніх переговорів",
          "standardVisitBullet1": "Тривалість від 30 до 45 хвилин",
          "standardVisitBullet2": "Визначення остаточного діагнозу",
          "standardVisitBullet3": "Постійний аналіз даних, зібраних у системі ICtal®",
          "standardVisitBullet4": "Можливість втручання у разі відсутності поліпшення або погіршення - поради ICtal® або ради",
          "standardVisit15Bullet1": "Тривалість 15 хвилин",
          "standardVisit15Bullet2": "Визначення остаточного діагнозу",
          "standardVisit15Bullet3": "Постійний аналіз даних, зібраних у системі ICtal®",
          "standardVisit15Bullet4": "Можливість втручання у разі відсутності поліпшення або погіршення - поради ICtal® або ради",
          "consultationBullet1": "Тривалість від 45 до 60 хвилин",
          "consultationBullet2": "Відвідування з лікарем іншої спеціалізації",
          "consultationBullet3": "Медична історія",
          "consultationBullet4": "Аналіз історії епілепсії та супутніх захворювань",
          "consultationBullet5": "Поради лікарів за спеціалізацією лікаря -консультанта",
          "messageBullet1": "Пам’ятайте, що повідомлення - це коротке текстове запитання, на яке лікар відповість якомога швидше у робочий час.",
          "messageBullet2": "У рамках плати ви можете надіслати лише одне повідомлення - питання. Коли лікар відповість, у вас є можливість поставити ще одне додаткове питання, на яке лікар відповість знову, і нитка автоматично закінчиться.",
          "messageBullet3": "Увага! Лікар має право відмовити вам у повідомленні, якщо вважає, що ваш запит підходить для регулярного відвідування. Повідомлення не призначене для допомоги у надзвичайних ситуаціях або ситуаціях, що загрожують життю!",
          "visit0Bullet7": "Ви можете вибрати тип відвідування - <b>онлайн або стаціонарний</b>"
        }
      },
      "awaitingPaymentNotAvailable": {
        "title": "Помилка бронювання візиту!",
        "subtitle": "Вибачте за незручності",
        "ageDescription": "Обраний лікар не приймає пацієнтів такого віку",
        "changeDescription": "Виберіть іншого лікаря і потім запис",
        "action": "Виберіть лікаря та дату запису"
      },
      "neuroscreen": {
        "main": {
          "title": "Клініка «Нейросфера» створює нову якість у лікуванні пацієнтів!",
          "subtitle1": "Ми ефективно поєднуємо знання та багаторічний досвід з необхідністю постійного розвитку та вдосконалення наших методів лікування!",
          "subtitle2": "Ми підготували для вас опитування, яке допоможе вибрати правильного лікаря та правильну схему лікування в клініці «Нейросфера».",
          "subtitle3": "Заповнивши анкету, лікар ознайомиться з нею перед візитом і дізнається, про яку проблему ви повідомляєте.",
          "subtitle4": "Заповнення опитування займає близько 5 хвилин.",
          "fillNeuroscreen": "Заповніть опитування",
          "fillNeuroscreenDescription": "Я хочу заповнити опитування та внести план лікування пацієнта",
          "goToReservation": "Виберіть лікаря",
          "goToReservationDescription": "Я не хочу заповнювати анкету, я хочу вибрати лікаря відразу"
        },
        "survey": {
          "title": "Neuroscreen - опитування пацієнтів Neurosphere",
          "subtitle1": "Після проходження опитування ми підкажемо вам, до якого візиту та лікаря вам варто записатися.",
          "subtitle2": "Інформація, яку ви надасте тут, буде передана вашому лікарю та допоможе вам правильно діагностувати та лікувати вас.",
          "next": "Далі",
          "prev": "Назад",
          "save": "Повне опитування",
          "form": {
            "section": {
              "HEADACHES": "Головні болі",
              "MOVEMENT_ISSUES": "Рухові розлади",
              "MEMORY_ISSUES": "Розлади пам'яті",
              "EATING_ISSUES": "Розлади харчової поведінки",
              "CONSCIOUSNESS_ISSUES": "Порушення свідомості або свідомості",
              "PSYCHOMOTOR_DEVELOPMENT_ISSUES": "Порушення психомоторного розвитку",
              "EPILEPSY": "Епілепсія і судоми",
              "SPINE_ISSUES": "Проблеми з хребтом",
              "MUTIPLE_SCLEROSIS": "Розсіяний склероз",
              "DIZZINESS": "Запаморочення",
              "AUTISM": "Спектр аутизму",
              "OTHER_SYMPTOMS": "Інші симптоми"
            },
            "adult": {
              "field": {
                "headachesGeneral": "Ви відчуваєте головний біль?",
                "headaches1": "У вас постійний тупий головний біль?",
                "headaches2": "Ви відчуваєте пульсуючий біль, зазвичай з одного боку голови?",
                "headaches3": "Ваші головні болі супроводжуються чутливістю до світла, звуку, нудотою або блювотою?",
                "headaches4": "Чи відчуваєте ви розлади зору, такі як спалахи світла або плями перед очима, до того, як почнеться головний біль?",
                "headaches5": "Коли у вас болить голова, ви відчуваєте почервоніння очей, сльозотечу або набряк повік на боці болю, чи закладений ніс?",
                "movementGeneral": "Ви помітили тремтіння кінцівок, ригідність м’язів або труднощі при русі?",
                "movement1": "Ви відчуваєте тремтіння в руках або інших частинах тіла?",
                "movement2": "Чи посилюється тремор у спокої?",
                "movement3": "Ви відчуваєте скутість м'язів, що ускладнює рух?",
                "movement4": "Ви помітили уповільнення своєї повсякденної діяльності?",
                "movement5": "Чи помічали ви будь-які проблеми з ходьбою, наприклад, робити дрібні кроки або несподівані падіння?",
                "memoryIssuesGeneral": "Вам важко запам’ятовувати нову інформацію, знаходити слова чи орієнтуватися в просторі?",
                "memoryIssues1": "Вам важко згадати останні події?",
                "memoryIssues2": "Ви коли-небудь забували імена, дати або вам важко знаходити потрібні слова під час розмови?",
                "memoryIssues3": "Вам все частіше потрібна допомога в повсякденних справах, таких як керування побутовою технікою, управління фінансами, планування?",
                "memoryIssues4": "Вам важко орієнтуватися в місцях, які ви раніше знали?",
                "memoryIssues5": "Чи помічаєте ви зміни у своїй поведінці, наприклад, соціальну замкнутість, апатію, імпульсивність або відсутність такту?",
                "epilepsyGeneral": "Чи відчували ви коли-небудь втрату свідомості, неконтрольовані конвульсії або короткі моменти припинення?",
                "epilepsy1": "Ви відчуваєте раптові незрозумілі втрати свідомості?",
                "epilepsy2": "Коли ви втрачаєте свідомість, ви відчуваєте розгубленість або відчуваєте труднощі з негайним поверненням до нормальної діяльності?",
                "epilepsy3": "Затьмарення свідомості супроводжується судомами?",
                "epilepsy4": "Чи відчуваєте ви якісь специфічні відчуття або сигнали (наприклад, «ауру») перед втратою свідомості/конвульсіями?",
                "epilepsy5": "Чи бувають моменти, коли ви раптом перестаєте відповідати і на короткий час «завмираєте»?",
                "spineIssuesGeneral": "Ви відчуваєте хронічний або гострий біль у спині чи шиї?",
                "spineIssues1": "Ви страждаєте від хронічного болю в спині чи шиї?",
                "spineIssues2": "Ви відчуваєте оніміння в руках або ногах?",
                "spineIssues3": "Ви помітили втрату сили в руках або ногах?",
                "spineIssues4": "Вам важко контролювати роботу сечового міхура чи кишечника?",
                "spineIssues5": "Чи іррадіює біль від хребта до інших частин тіла?",
                "mutipleSclerosisGeneral": "Чи помітили ви відчуття поколювання, оніміння або інші зміни сенсорних відчуттів, які можуть виникати в різних частинах тіла?",
                "mutipleSclerosis1": "Ви відчуваєте несподівані періоди слабкості в кінцівках або труднощі при ходьбі?",
                "mutipleSclerosis2": "У вас проблеми з рівновагою чи координацією?",
                "mutipleSclerosis3": "Чи відчуваєте ви раптові епізоди розладів зору, такі як подвійне бачення або розмитість зору?",
                "mutipleSclerosis4": "Ви відчуваєте незвичну втому, не пов’язану з фізичними вправами?",
                "mutipleSclerosis5": "Ви відчуваєте біль і судоми в м'язах?",
                "dizzinessGeneral": "Ви відчуваєте запаморочення або проблеми з рівновагою?",
                "dizziness1": "Запаморочення з'являється раптово і без попередження?",
                "dizziness2": "Ви також відчуваєте нудоту або блювоту, коли відчуваєте запаморочення?",
                "dizziness3": "Чи стає запаморочення сильнішим, коли ви змінюєте положення тіла, наприклад встаєте або нахиляєте голову?",
                "dizziness4": "Чи пов'язане виникнення запаморочення з болями у вухах або зміною слуху?",
                "dizziness5": "Запаморочення супроводжується шумом у вухах або відчуттям повноти у вухах?",
                "otherSymptomsGeneral": "Чи є у вас якісь інші симптоми, які не підходять до категорій вище?",
                "otherSymptoms1": "Ви відчуваєте труднощі з ковтанням або часто задихаєтесь під час їжі?",
                "otherSymptoms2": "У вас проблеми зі сном, наприклад безсоння або надмірна денна сонливість?",
                "otherSymptoms3": "Чи відчуваєте ви періодичні труднощі з концентрацією або «мозковий туман», який заважає вам зосередитися на завданнях?",
                "otherSymptoms4": "Чи помічали ви, що хропіння починає або посилюється під час сну?",
                "otherSymptoms5": "Чи відчуваєте ви іноді раптову слабкість в одній частині тіла або на одній стороні?"
              }
            },
            "child": {
              "field": {
                "headachesGeneral": "У вашої дитини болить голова?",
                "headaches1": "У вашої дитини постійний тупий головний біль?",
                "headaches2": "Це пульсуючий біль, зазвичай односторонній?",
                "headaches3": "Чи супроводжується біль підвищеною чутливістю до світла та звуку, нудотою та блюванням?",
                "headaches4": "Чи відчуває дитина розлади зору, такі як спалахи світла або плями перед очима, до початку головного болю?",
                "headaches5": "Коли у вас болить голова, ви відчуваєте почервоніння очей, сльозотечу або набряк повік на боці болю, чи закладений ніс?",
                "movementGeneral": "Чи є у вашої дитини рухові порушення: тремор, скутість, парези?",
                "movement1": "У вашої дитини тремор?",
                "movement2": "Чи виникає цей тремор у спокої?",
                "movement3": "Це тремтіння відбувається під час руху?",
                "movement4": "У вашої дитини ригідність кінцівок посилюється?",
                "movement5": "Моторний розвиток вашої дитини не прогресує або регресує?",
                "epilepsyGeneral": "У вашої дитини судоми?",
                "epilepsy1": "Чи повторюються напади?",
                "epilepsy2": "Судоми відрізняються чи схожі?",
                "epilepsy3": "Чи виникають судоми під час неспання?",
                "epilepsy4": "Чи виникають судоми під час сну (вночі чи вдень)?",
                "epilepsy5": "Чи судоми генералізовані?",
                "epilepsy6": "Чи є напади вогнищевими, тобто не все тіло, напр. одну чи дві кінцівки чи одну половину тіла?",
                "epilepsy7": "Чи ритмічні напади?",
                "consciousnessIssuesGeneral": "Чи страждає дитина розладами свідомості та/або втратою свідомості?",
                "consciousnessIssues1": "У дитини порушення свідомості?",
                "consciousnessIssues2": "Чи відбувається повна втрата свідомості дитини при падінні?",
                "consciousnessIssues3": "Чи відчуває ваша дитина зависання або думки?",
                "consciousnessIssues4": "Чи тривають стани порушення свідомості кілька секунд?",
                "consciousnessIssues5": "Чи тривають стани порушення свідомості довше 30 секунд?",
                "eatingIssuesGeneral": "У вашої дитини є розлад харчової поведінки?",
                "eatingIssues1": "У вашої дитини патологічна відсутність апетиту?",
                "eatingIssues2": "У вашої дитини патологічний надмірний апетит?",
                "autismGeneral": "Чи є у дитини особливості або діагноз розлад спектру аутизму?",
                "autism1": "Чи є у дитини розлад спектру аутизму?",
                "autism2": "Дитина підозрює аутизм?",
                "psychomotorDevelopmentIssuesGeneral": "Чи є у дитини порушення психомоторного розвитку?",
                "psychomotorDevelopmentIssues1": "Чи встановлено та підтверджено лікарем/медичним центром порушення психомоторного розвитку?",
                "psychomotorDevelopmentIssues2": "Порушення психомоторного розвитку лише підозрюють, а не підтверджують?"
              }
            }
          }
        },
        "rating": {
          "title": "Дякуємо за заповнення опитування!",
          "subtitle": "Нейротермінальна система підкаже вам правильного лікаря для вашого захворювання",
          "showMore": "Показати більше",
          "diagnosis": {
            "HEADACHES": "<b>Головний біль — це складна неврологічна проблема,</b> яка може мати десятки різних форм і інтенсивності. <b>Ці нездужання можуть бути симптомом багатьох захворювань</b> не тільки в області неврології, а й в інших областях медицини. Вкрай важливо, щоб діагностикою та лікуванням головного болю займався невролог - цефалологія, наука, що займається цим питанням. <br/><b>Центр «Нейросфера» підходить до діагностики та лікування головного болю індивідуально та командно.</b>",
            "MOVEMENT_ISSUES": "<b>Рухові розлади — це група неврологічних розладів, які проявляються різними проблемами з рухом.</b> Це можуть бути м’язова слабкість і парези, а також тремор, скутість або спастичність. Цей широкий спектр симптомів є наслідком пошкодження або розладів у функціонуванні нервової системи. <b>При появі будь-яких тривожних симптомів важливо звернутися до фахівця,</b> оскільки рання діагностика та відповідне лікування є ключовими для покращення якості життя пацієнта. <br/><b>Neurosphera здатна точно діагностувати причину проблем і підібрати відповідні методи лікування.</b>",
            "MEMORY_ISSUES": "<b>Порушення пам'яті часто пов'язані з деменцією, де вони є одним із основних симптомів.</b> Однак проблеми з пам'яттю можуть виникнути в будь-якому віці та бути сигналом різних захворювань, від легких до серйозних. Тому так важливо при появі тривожних симптомів <b>звернутися до фахівця</b>, який всебічно оцінить роботу пам’яті та діагностує можливі причини. <br/><b>У центрі «Нейросфера» пацієнт має можливість обрати лікаря-нейропсихолога, який проведе відповідні обстеження та встановить причину захворювання, та невролога, який підбере для пацієнта відповідну терапію.</ b>",
            "EATING_ISSUES": "<b>Розлади харчової поведінки - складні проблеми зі здоров'ям, які вимагають комплексного підходу.</b> У їх лікуванні беруть участь різні спеціалісти, такі як дієтологи, неврологи та психіатри. Хоча розлади харчової поведінки часто є симптомом більш глибоких психічних або фізичних проблем, <b>правильно підібрана дієта та дієтологічна терапія можуть бути важливим елементом лікування,</b> впливаючи як на фізичне, так і на психічне здоров'я пацієнта. <br/><b>Центр «Нейросфера» пропонує можливість діагностики проблеми харчових розладів у невролога або дієтолога, який складе для пацієнта грамотну дієту.</b>",
            "CONSCIOUSNESS_ISSUES": "<b>Порушення свідомості та обізнаності - це стани, які завжди повинні викликати занепокоєння та спонукати до термінової консультації невролога.</b> Вони часто є першою ознакою серйозного неврологічного захворювання або іншого серйозного розладу здоров'я. Щоб точно визначити причину цих недуг, <b>необхідно провести додаткові дослідження, такі як ЕЕГ (електроенцефалографія) і МРТ (магнітно-резонансна томографія).</b> Ці методи діагностики дозволяють оцінити електричну активність мозку. і детальна візуалізація його структур, що є вирішальним для постановки правильного діагнозу та призначення відповідного лікування. <br/><b>Центр «Нейросфера» підходить до діагностики та терапії індивідуально та командно. Клініка пропонує можливість проведення ЕЕГ-дослідження.</b>",
            "PSYCHOMOTOR_DEVELOPMENT_ISSUES": "<b>Порушення психомоторного розвитку у дітей є складною клінічною проблемою, яка потребує фахового та комплексного підходу.</b> Через різноманітність симптомів та можливих причин діагностику та терапію має проводити мультидисциплінарна команда спеціалістів. <br/><b>Центр «Нейросфера» пропонує комплексну допомогу, що дозволяє проводити широкий спектр обстежень і консультацій у різних спеціалістів.</b> Це дозволяє точно визначити причини розладів і скласти відповідну терапевтичну програму. Рання діагностика та терапія мають вирішальне значення для оптимального розвитку дитини.",
            "EPILEPSY": "<b>Лікування епілепсії в центрі Neurosphera є нашою спеціальністю.</b> Ми маємо 25-річний досвід діагностики та терапії цього захворювання, що робить нас одним із провідних центрів у Польщі. <b>Наша команда, що складається з 20 спеціалістів, використовує новітні діагностичні засоби,</b> у тому числі передові системи на основі штучного інтелекту, щоб надавати пацієнтам комплексну допомогу. Завдяки цьому ми можемо ефективно діагностувати та лікувати навіть найскладніші випадки епілепсії, пропонуючи нашим пацієнтам найвищий рівень медичної допомоги.",
            "SPINE_ISSUES": "Біль у хребті та спині є проблемою багатьох людей і може мати різні причини. <b>Це стан вимагає комплексного підходу, поєднання знань і досвіду неврологів і нейрохірургів.</b> <br/><b>У центрі «Нейросфера» є спеціалізована команда, яка займається діагностикою та лікування цього виду недуги.</b> Завдяки великим знанням і досвіду наших фахівців і наявності сучасних методів діагностики ми можемо точно визначити причину болю і запропонувати оптимальне лікування. <b>Наш мультидисциплінарний підхід дозволяє провести комплексну оцінку пацієнта та розробити індивідуальний план лікування.</b>",
            "MUTIPLE_SCLEROSIS": "<b>Розсіяний склероз — це хронічне неврологічне захворювання, перебіг якого дуже індивідуальний і може суттєво відрізнятися між пацієнтами.</b> Діагностика РС часто є проблемою через різноманітність симптомів, які можуть імітувати інші захворювання. <b>Ефективне лікування розсіяного склерозу вимагає спеціалізованого підходу та співпраці багатьох спеціалістів.</b> Терапія має бути адаптована до індивідуальних потреб кожного пацієнта та включати як фармакологічне, так і немедикаментозне лікування. <b>Команда досвідчених неврологів має важливе значення для правильної діагностики та комплексної терапії людей з РС.</b>",
            "DIZZINESS": "<b>Запаморочення - це стан, який найчастіше є симптомом, а не самостійним захворюванням.</b> Воно може бути викликано різними факторами, як ларингологічними (наприклад, порушення рівноваги), так і неврологічними (наприклад, ураження центральної нервової системи). <br/><b>Пацієнти із запамороченнями часто звертаються до невролога, і ми в Центрі «Нейросфера» чудово готові прийняти таких пацієнтів.</b> Ми пропонуємо комплексну діагностику, яка дозволяє точно визначити причину симптомів. <b>У деяких випадках може знадобитися консультація нейрохірурга, особливо коли запаморочення пов'язане з проблемами хребта.</b> Завдяки мультидисциплінарному підходу ми можемо надавати нашим пацієнтам ефективну допомогу.",
            "AUTISM": "<b>Розлад спектру аутизму — це складний розлад нервової системи, який включає труднощі соціального спілкування, соціальної взаємодії, а також обмежені та повторювані моделі поведінки.</b> Аутизм може приймати різні форми, від легкого до важкого, і часто виникає разом із інші розлади. <b>Як типовий, так і атиповий дитячий аутизм потребують спеціалізованої діагностики та комплексного терапевтичного підходу.</b> <br/><b>У центрі «Нейросфера» працює досвідчена команда фахівців, які займаються діагностикою та лікуванням аутизм та його ускладнення.</b> Ми пропонуємо широкий спектр послуг, адаптованих до індивідуальних потреб кожного пацієнта.",
            "OTHER_SYMPTOMS": "<b>Неврологічні симптоми можуть бути надзвичайно різноманітними і часто неоднозначними.</b> Окрім більш характерних симптомів, таких як оніміння або порушення рівноваги, пацієнти також повідомляють про слабкість, біль, проблеми зі сном, дратівливість і труднощі з концентрацією. <b>Цей широкий спектр симптомів може ускладнити точну діагностику, оскільки багато з них можуть вказувати на різні неврологічні стани, а іноді навіть на розлади з іншої причини.</b> <br/><b>Центр Neurosphera спеціалізується обладнання та досвідчена команда</b>, завдяки якій ми можемо провести точну діагностику та вказати причину навіть самих розмитих неврологічних симптомів."
          }
        }
      }
    },
    "error": {
      "LOGIN_CRED_MISSING": "Потрібні логін і пароль",
      "LOGIN_INVALID_CRED": "Неправильне ім'я користувача або пароль",
      "PASS_AND_CONF_NOT_EQUAL": "Пароль та підтвердження відрізняються",
      "AUTHORIZATION": "несанкціонований доступ до ресурсу, маршрутизація до сторінки входу",
      "WRONG_PROFILE": "Не вдалося ввійти через неправильно вибраний профіль"
    },
    "validation": {
      "required": "Поле обов'язкове",
      "alphanumeric": "Повинен містити лише літери та цифри",
      "email": "Невірна адреса електронної пошти",
      "invalidRange": "Недійсний діапазон",
      "nip": "Недійсний номер NIP",
      "regon": "Недійсний номер REGON",
      "pesel": "Недійсний номер PESEL",
      "max": "Допускається до {length} символів",
      "min": "Потрібно принаймні {length} символів",
      "passNonMatching": "Паролі не співпадають",
      "maxFileSize": "Файл повинен мати розмір менше {size} МБ",
      "pwz": "Недійсний номер PWZ",
      "zusCertificate": "Дозволене розширення файлу .pfx",
      "password": {
        "lowercase": "Потрібна мала літера",
        "uppercase": "Потрібна велика літера",
        "minimumChar": "Мінімум {length} символів",
        "numericChar": "Потрібен один номер",
        "specialChar": "Потрібен спеціальний символ, доступні символи!@$ \\ -_.*"
      }
    },
    "enums": Enums.uk,
    "serverErrors": ServerErrors.uk,
    "office": {
      "phone": "+48 609 463 191",
      "phoneHref": "tel:+48609463191",
      "mail": "biuro@neurosphera.pl",
      "mailHref": "mailto:biuro@neurosphera.pl"
    },
    "appointWidget": {
      "appoint": {
        "title": "Заплануйте візит",
        "visitTypeLabel": "Тип відвідування",
        "visitLocationLabel": "Розташування",
        "doctorListDisclaimer": "Остаточна ціна залежить від типу відвідування",
        "visitTypeListDisclaimer": "Остаточна ціна залежить від типу відвідування",
        "doctorListHeader": "Виберіть лікаря",
        "visitTypeListHeader": "Виберіть тип відвідування",
        "visitLocationListHeader": "Виберіть місце відвідування",
        "doctorLabel": "лікар",
        "startingPricePrefix": "від",
        "appointment": {
          "selectHeader": {
            "START_PACKAGE": "Заплануйте візит 0 і візит 1",
            "MIGRATION_VISIT": "Запланувати міграційний візит",
            "STANDARD_VISIT": "Заплануйте регулярне відвідування",
            "NEUROSURGEON_CONSULTATION": "Записатись на консультацію нейрохірургічного лікаря",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Записатись на консультацію нейропсихолога",
            "NEUROLOGOPEDIST_CONSULTATION": "Призначити консультацію логопеда (невролога).",
            "PSYCHIATRIST_CONSULTATION": "Призначте консультацію психіатра",
            "GENETICIST_CONSULTATION": "Запишіться на консультацію генетика",
            "EPILEPTOLOGIST_CONSULTATION": "Запишіться на консультацію епілептолога",
            "EEG_CONSULTATION": "Планувати ЕЕГ обстеження",
            "NEUROLOGIST_CONSULTATION": "Записатись на консультацію невролога",
            "DIETICIAN_CONSULTATION": "Запишіться на консультацію дієтолога",
            "VNS_CONSULTATION": "Заплануйте відвідування VNS",
            "ABSENT_CONSULTATION": "Призначити консультацію заочно",
            "METABOLIC_CONSULTATION": "Запишіться на консультацію з метаболізму",
            "SLEEP_DISTURBANCE_CONSULTATION": "Запишіться на консультацію з питань порушення сну",
            "ADHD_PACKAGE": "Заплануйте пакетні візити для СДУГ",
            "REGULAR_VISIT_EEG_PACKAGE": "Заплануйте регулярне відвідування епілепсії та ЕЕГ",
            ADHD_CONSULTATION: 'Коучинг із СДУГ',
            PSYCHOTHERAPY_CONSULTATION: 'Сеанс психотерапії'
          },
          "description": {
            "START_PACKAGE": "Візит 0 – це безкоштовне відвідування для збору детального інтерв’ю щодо історії хвороби пацієнта, яке передує візиту 1. Під час візиту 1 буде складено діагноз, терапія та комплексний план догляду на наступний рік. Відвідування 0 становить прибл. 1 година, візит 1 - 30 хвилин; обидва можуть відбуватися на місці або онлайн.",
            "MIGRATION_VISIT": "Міграційний візит",
            "STANDARD_VISIT": "Регулярний епілептологічний візит призначений для поточних пацієнтів із Neurosphere, які вже проходили візит 1 або міграційний візит.",
            "NEUROSURGEON_CONSULTATION": "Консультація рекомендується у разі хронічних неврологічних проблем, таких як:",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Показаний у разі:",
            "NEUROLOGOPEDIST_CONSULTATION": "Консультація логопеда (невролога).",
            "PSYCHIATRIST_CONSULTATION": "Візит для людей, які мають проблеми з психічним здоров’ям, зокрема:",
            "GENETICIST_CONSULTATION": "Показаний у разі:",
            "EPILEPTOLOGIST_CONSULTATION": "Консультація - епілептолог",
            "EEG_CONSULTATION": "Базове обстеження в діагностиці епілепсії, а також:",
            "NEUROLOGIST_CONSULTATION": "Показаний у разі:",
            "DIETICIAN_CONSULTATION": "В гості для людей:",
            "VNS_CONSULTATION": "Відвідування VNS",
            "ABSENT_CONSULTATION": "Заочна консультація",
            "METABOLIC_CONSULTATION": "Показаний у разі вроджених дефектів обміну речовин. Деякі метаболічні захворювання можуть сприяти виникненню епілепсії. Варто оцінити, чи є підозра на захворювання в генетичних тестах.",
            "SLEEP_DISTURBANCE_CONSULTATION": "Показаний у разі:",
            "ADHD_PACKAGE": "У пакет входить 2 відвідування психолога (кожне по 1,5 години) та відвідування невропатолога або психіатра. Пакет адресований людям, які помічають:",
            "REGULAR_VISIT_EEG_PACKAGE": "У пакет входить ЕЕГ і регулярне відвідування стаціонару. Пакет призначений для поточних пацієнтів Neurosphery, які вже завершили візит 1 або міграційний візит. Підпишіться на пакет, якщо:",
            "MMPI2_PACKAGE": "Ми дозволяємо діагностувати розлади особистості за допомогою найрозширенішого та універсального інструменту клінічної оцінки, доступного в Польщі – Міннесотського багатовимірного опитування особистості (повна адаптація MMPI-2 була випущена в 2012 році).",
            "CBT_PACKAGE": "Когнітивно-поведінкова терапія (КПТ) для лікування безсоння вважається першорядним лікуванням для людей, які борються з безсонням, рекомендована такими органами влади, як Національні інститути здоров’я (NIH), Національний інститут здоров’я та передового догляду (NICE) та Американський коледж лікаря.",
            ADHD_CONSULTATION: 'Індивідуальний коучинг – це інтенсивна одногодинна зустріч з досвідченим тренером із СДУГ, зосереджена на ваших поточних викликах і цілях.',
            PSYCHOTHERAPY_CONSULTATION: 'Сеанс психотерапії - це зустріч пацієнта (клієнта) з психотерапевтом, метою якої є спільне вирішення емоційних, психічних або поведінкових проблем.'
          },
          "bulletPoints": {
            "DIETICIAN_CONSULTATION": [
              "for whom the ketogenic diet is indicated by physician or is of interest",
              "who are using a ketogenic diet and want to review their current treatment",
              "they want to verify their current diet"
            ],
            "EEG_CONSULTATION": [
              "to indicate the causes of sleep disorders",
              "in head injuries and recurring headaches",
              "fainting or loss of consciousness"
            ],
            "NEUROLOGIST_CONSULTATION": [
              "fainting and loss of consciousness, problems with balance and coordination",
              "chronic headaches combined with dizziness",
              "deterioration of memory and trouble concentrating",
              "tingling in the extremities with a disturbed sensation"
            ],
            "PSYCHIATRIST_CONSULTATION": [
              "depression",
              "anxiety disorders",
              "sleep problems",
              "unnaturally elevated mood and hyperactivity"
            ],
            "GENETICIST_CONSULTATION": [
              "family history of diseases with genetic causes",
              "the exclusion of congenital disease as a cause of epilepsy",
              "assessment of the risk of a child having epilepsy in the event of a planned pregnancy",
              "before performing the WES test"
            ],
            "NEUROSURGEON_CONSULTATION": [
              "chronic pain and injuries of the spine",
              "brain tumors and neoplastic changes in the spine",
              "numbness, tingling and paresis of the limbs",
              "severe headaches and dizziness, visual and hearing disturbances",
              "consultation of magnetic resonance imaging of the head"
            ],
            "NEUROPSYCHOLOGIST_CONSULTATION": [
              "problems in normal functioning that have arisen as a result of, for example, head injuries, brain tumors, diseases that destroy nervous tissue",
              "impairment of memory or concentration",
              "speech, orientation or wayfinding difficulties",
              "visual disturbances despite normal ophthalmic results"
            ],
            "SLEEP_DISTURBANCE_CONSULTATION": [
              "daytime sleepiness or irritability",
              "sleepiness or insomnia caused by emotional factors",
              "disruption of the daily rhythm of sleep and waking up",
              "snoring while sleeping"
            ],
            "ADHD_PACKAGE": [
              "Difficulty Organizing",
              "Underestimating time",
              "interpersonal difficulties",
              "Emotional difficulties"
            ],
            "REGULAR_VISIT_EEG_PACKAGE": [
              "You haven't had an EEG done in the last year",
              "You are planning a regular stationary visit with an Egyptologist",
              "You feel a lack of improvement or deterioration of your health"
            ]
          },
          "detailsHeader": {
            "VISIT_0": "Відвідайте 0",
            "MIGRATION_VISIT": "Міграційний візит",
            "VISIT_1": "Відвідати 1",
            "START_PACKAGE": "Відвідування 0 і відвідування 1",
            "STANDARD_VISIT": "Регулярне відвідування",
            "NEUROSURGEON_CONSULTATION": "Консультація - нейрохірург",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Консультація - нейропсихолог",
            "NEUROLOGOPEDIST_CONSULTATION": "Консультація - логопеда (невролога)",
            "PSYCHIATRIST_CONSULTATION": "Консультація - психіатр",
            "GENETICIST_CONSULTATION": "Консультація - генетик",
            "EPILEPTOLOGIST_CONSULTATION": "Консультація - епілептолог",
            "EEG_CONSULTATION": "Призначте аналіз ЕЕГ",
            "NEUROLOGIST_CONSULTATION": "Консультація - невропатолог",
            "DIETICIAN_CONSULTATION": "Консультація - дієтолог",
            "VNS_CONSULTATION": "Відвідування VNS",
            "ABSENT_CONSULTATION": "Заочна консультація",
            "METABOLIC_CONSULTATION": "Метаболічна консультація",
            "SLEEP_DISTURBANCE_CONSULTATION": "Консультація з питань порушення сну",
            "ADHD_PACKAGE": "Пакет ADHD",
            "REGULAR_VISIT_EEG_PACKAGE": "Регулярне відвідування епілепсії та ЕЕГ",
            ADHD_CONSULTATION: 'Коучинг із СДУГ',
            PSYCHOTHERAPY_CONSULTATION: 'Сеанс психотерапії'
          },
          "details": {
            "VISIT_0": "Візит 0 – це підготовчий візит, під час якого проводиться глибоке, дуже детальне інтерв’ю. Візит триває прибл. 1 година. Візит 0 має бути проведений до візиту 1.",
            "MIGRATION_VISIT": "",
            "VISIT_1": "Під час візиту 1 підтверджується діагноз і складається комплексний план лікування. Візит триває прибл. 30 хвилин.",
            "STANDARD_VISIT": "Тривалість візиту близько 30 хвилин.",
            "NEUROSURGEON_CONSULTATION": "Тривалість консультації бл. 45 хвилин.",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Тривалість консультації – близько 1 години.",
            "NEUROLOGOPEDIST_CONSULTATION": "Консультація - логопеда (невролога)",
            "PSYCHIATRIST_CONSULTATION": "Тривалість консультації бл. 45 хвилин.",
            "GENETICIST_CONSULTATION": "Тривалість консультації бл. 45 хвилин.",
            "EPILEPTOLOGIST_CONSULTATION": "Консультація - епілептолог",
            "EEG_CONSULTATION": "Заплануйте оцінку ЕЕГ",
            "NEUROLOGIST_CONSULTATION": "Тривалість консультації прибл. 30 хвилин.",
            "DIETICIAN_CONSULTATION": "Тривалість консультації близько 1 години.",
            "VNS_CONSULTATION": "Відвідування VNS",
            "ABSENT_CONSULTATION": "Заочна консультація",
            "METABOLIC_CONSULTATION": "Тривалість консультації прибл. 30 хвилин.",
            "SLEEP_DISTURBANCE_CONSULTATION": "Тривалість консультації бл. 45 хвилин.",
            ADHD_CONSULTATION: 'Тривалість сеансу бл.',
            PSYCHOTHERAPY_CONSULTATION: 'Тривалість сеансу бл.'
          }
        },
        "remoteVisitDescription": "Віддалений візит через Neuroterminal. Потрібен пристрій з камерою та мікрофоном і підключеним до Інтернету",
        "details": "Деталі",
        "visitHeader": {
          "VISIT_0": "Відвідайте 0",
          "MIGRATION_VISIT": "Міграційний візит",
          "VISIT_1": "Відвідати 1",
          "START_PACKAGE": "Відвідування 0 і відвідування 1",
          "STANDARD_VISIT": "Регулярне відвідування",
          "NEUROSURGEON_CONSULTATION": "Консультація - нейрохірург",
          "NEUROPSYCHOLOGIST_CONSULTATION": "Консультація - нейропсихолог",
          "NEUROLOGOPEDIST_CONSULTATION": "Консультація - логопеда (невролога)",
          "PSYCHIATRIST_CONSULTATION": "Консультація - психіатр",
          "GENETICIST_CONSULTATION": "Консультація - генетик",
          "EPILEPTOLOGIST_CONSULTATION": "Консультація - епілептолог",
          "EEG_CONSULTATION": "Призначте аналіз ЕЕГ",
          "NEUROLOGIST_CONSULTATION": "Консультація - невропатолог",
          "DIETICIAN_CONSULTATION": "Консультація - дієтолог",
          "VNS_CONSULTATION": "Відвідування VNS",
          "ABSENT_CONSULTATION": "Заочна консультація",
          "METABOLIC_CONSULTATION": "Метаболічна консультація",
          "SLEEP_DISTURBANCE_CONSULTATION": "Консультація з питань порушення сну",
          "ADHD_PACKAGE": "Пакет ADHD",
          "REGULAR_VISIT_EEG_PACKAGE": "Регулярне відвідування епілепсії та ЕЕГ"
        },
        "instruction": {
          "START_PACKAGE": "Якщо ви не бачите дату, яка вам підходить - спробуйте вибрати іншого лікаря, тип візиту або більш ранню дату візиту 0"
        },
        "visitPriceLabel": "Ціна візиту",
        "showAllSlots": "показати більше годин",
        "hideSlots": "колапс",
        "nextLabel": "Далі",
        "validationErrorHeader": "Щоб перейти до підсумку, виберіть:",
        "ok": "Гаразд",
        "validationError": {
          "slot": "Дата",
          "location": "Розташування",
          "doctor": "лікар",
          "service": "Тип відвідування"
        },
        "chooseLabel": "Виберіть",
        "doctorMissingLabel": "лікар",
        "locationMissingLabel": "Тип відвідування",
        "andLabel": "і",
        "unavailableDoctors": "Лікарі недоступні на вибрану дату, тип та/або тип візиту",
        "ADULT": "Дорослий",
        "CHILD": "Дитина",
        "visitNotAvailable": "Вибачте, але дата, яку ви вибрали <b>{visitName}</b>, недоступна. Поверніться назад і виберіть іншу дату",
        "visitNotSaved": "Візит ще не зареєстрований. Поверніться до підсумку, щоб завершити процес бронювання зустрічі.",
        "clearSelection": "Очистити вибір",
        "visitOrder": "Це ваш перший візит до цього спеціаліста?",
        "visitOrderFirst": "Так",
        "visitOrderNext": "Немає"
      },
      "exitPoll": {
        "title": "Чому ти хочеш піти?",
        "description": "Ваша відповідь дуже важлива для нас! Завдяки цьому ми зможемо покращити наші послуги.",
        "SERVICE": "Я не знайшов послуги, яка мене цікавить",
        "DATE": "Немає відповідних дат відвідування",
        "PRICE": "Мені не подобається ціна послуги",
        "OTHER": "Інший",
        "sendResponse": "Надішліть відповідь",
        "back": "Повернутися на сайт",
        "whatService": "Яку послугу ви шукали?",
        "enterMessage": "Будь ласка, введіть своє повідомлення",
        "reasonMissing": "Вкажіть, будь ласка, причину вашої відставки"
      },
      "main": {
        "exitPopup": {
          "description": "Ви впевнені, що хочете припинити процес призначення? Ваш вибір не буде збережено.",
          "confirm": "Так, я хочу піти",
          "decline": "Ні, поверніться до призначення зустрічі"
        }
      },
      "summary": {
        "title": "Резюме",
        "grossPrice": "Загальна сума",
        "availableOnlinePayments": "Ми приймаємо онлайн платежі",
        "transfer": "Банківський переказ",
        "installments": "0% Розстрочка",
        "payment": "Оплата",
        "paymentDescription": "Щоб продовжити платіж, вкажіть свою електронну адресу. Ми надішлемо вам підсумок і посилання для оплати.",
        "descriptionExistingAccount": "Якщо у вас є обліковий запис у Neuroterminal, будь ласка, вкажіть адресу електронної пошти, яку ви використовуєте для входу.",
        "makeReservations": "Запис на зустріч",
        "makeReservation": "Запис на зустріч",
        "unexpectedError": "Під час спроби запланувати зустріч сталася неочікувана помилка. Спробуйте ще раз."
      },
      "thanku": {
        "title": "дякую!",
        "emailHasBeenSent": "Ми надіслали повідомлення з підсумком і посиланням для оплати на вказану електронну адресу:",
        "finalizeAppointment": "Перейдіть до своєї електронної пошти та <b>завершіть процес планування зустрічі</b>, натиснувши надіслане посилання для оплати. У вас є час до <b>{deadline}</b>, щоб завершити процес планування зустрічі.",
        "resendEmail": "Не отримали листа? Перевірте папку спаму або",
        "resendEmailLink": "Повторно надіслати електронний лист",
        "resendEmailDone": "Електронну пошту надіслано повторно",
        "backToNeurosphera": "Повернутися до neurosphera.pl"
      }
    },
    "server": {
      "episode": {
        "description": "Під час візиту додано епізод нападу"
      },
      "generatedepisode": {
        "description": "Під час візиту додано епізод нападу"
      }
    },
    "dates": {
      "today": "сьогодні",
      "tomorrow": "завтра"
    }
  },
  "formats": {
    "currency": {
      "style": "валюта",
      "currency": "Доларів США"
    }
  }
}