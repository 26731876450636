/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DoctorBasicDto } from '../model';
// @ts-ignore
import { DoctorDashboardAlertsDto } from '../model';
// @ts-ignore
import { DoctorDto } from '../model';
// @ts-ignore
import { DoctorFromDatabaseDto } from '../model';
// @ts-ignore
import { DoctorNameDto } from '../model';
// @ts-ignore
import { DoctorSelectionRequestPatientInfoDto } from '../model';
// @ts-ignore
import { DoctorSelectionResponseDto } from '../model';
// @ts-ignore
import { DoctorVisitSlotDto } from '../model';
// @ts-ignore
import { DoctorWorkPlaceDto } from '../model';
// @ts-ignore
import { ListResponseDtoDoctorFromDatabaseDto } from '../model';
// @ts-ignore
import { ListResponseDtoListDoctorFinanceDataDto } from '../model';
// @ts-ignore
import { MedicalServiceBaseDto } from '../model';
// @ts-ignore
import { Pageable } from '../model';
/**
 * DoctorApiControllerApi - axios parameter creator
 * @export
 */
export const DoctorApiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} requestId 
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptDoctorSelectionRequest: async (requestId: number, messageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('acceptDoctorSelectionRequest', 'requestId', requestId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('acceptDoctorSelectionRequest', 'messageId', messageId)
            const localVarPath = `/api/doctor/select/request/{requestId}/message/{messageId}/accept`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummmySlot: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/doctor/dummy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableDoctorsForPatientByMedicalService: async (patientId: number, medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getAvailableDoctorsForPatientByMedicalService', 'patientId', patientId)
            // verify required parameter 'medicalService' is not null or undefined
            assertParamExists('getAvailableDoctorsForPatientByMedicalService', 'medicalService', medicalService)
            const localVarPath = `/api/doctor/patient/{patientId}/medical-service/{medicalService}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"medicalService"}}`, encodeURIComponent(String(medicalService)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentDoctorMedicalServices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/doctor/medical-service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorDashboardAlerts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/doctor/alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorDatabaseData: async (doctorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getDoctorDatabaseData', 'doctorId', doctorId)
            const localVarPath = `/api/doctor/database/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (doctorId !== undefined) {
                localVarQueryParameter['doctorId'] = doctorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorFinances: async (pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getDoctorFinances', 'pageable', pageable)
            const localVarPath = `/api/doctor/finances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorInfo: async (doctorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorId' is not null or undefined
            assertParamExists('getDoctorInfo', 'doctorId', doctorId)
            const localVarPath = `/api/doctor/{doctorId}`
                .replace(`{${"doctorId"}}`, encodeURIComponent(String(doctorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorSelectionRequestDetails: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDoctorSelectionRequestDetails', 'id', id)
            const localVarPath = `/api/doctor/select/request/{id}/details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} doctorPriceListItemId 
         * @param {string} dateFrom 
         * @param {number} [days] 
         * @param {number} [selectedDuration] 
         * @param {boolean} [lookBack] 
         * @param {number} [workPlaceId] 
         * @param {number} [partnerId] 
         * @param {number} [visitPackageStageId] 
         * @param {number} [doctorPackageId] 
         * @param {number} [visitId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorVisitsSlots: async (doctorPriceListItemId: number, dateFrom: string, days?: number, selectedDuration?: number, lookBack?: boolean, workPlaceId?: number, partnerId?: number, visitPackageStageId?: number, doctorPackageId?: number, visitId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'doctorPriceListItemId' is not null or undefined
            assertParamExists('getDoctorVisitsSlots', 'doctorPriceListItemId', doctorPriceListItemId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getDoctorVisitsSlots', 'dateFrom', dateFrom)
            const localVarPath = `/api/doctor/visit-times`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (doctorPriceListItemId !== undefined) {
                localVarQueryParameter['doctorPriceListItemId'] = doctorPriceListItemId;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (days !== undefined) {
                localVarQueryParameter['days'] = days;
            }

            if (selectedDuration !== undefined) {
                localVarQueryParameter['selectedDuration'] = selectedDuration;
            }

            if (lookBack !== undefined) {
                localVarQueryParameter['lookBack'] = lookBack;
            }

            if (workPlaceId !== undefined) {
                localVarQueryParameter['workPlaceId'] = workPlaceId;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }

            if (visitPackageStageId !== undefined) {
                localVarQueryParameter['visitPackageStageId'] = visitPackageStageId;
            }

            if (doctorPackageId !== undefined) {
                localVarQueryParameter['doctorPackageId'] = doctorPackageId;
            }

            if (visitId !== undefined) {
                localVarQueryParameter['visitId'] = visitId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlace: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/doctor/workplace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkplaces: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/doctor/workplaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorsAvailableForPatient: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getDoctorsAvailableForPatient', 'patientId', patientId)
            const localVarPath = `/api/doctor/available/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorsNames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/doctor/name/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorsPaged: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/doctor/database/paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinanceReport: async (dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/doctor/finances/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
         * @param {'REMOTE' | 'STATIONARY' | 'ABSENT'} visitType 
         * @param {string} dateFrom 
         * @param {number} [workPlaceId] 
         * @param {number} [days] 
         * @param {boolean} [lookBack] 
         * @param {number} [selectedDuration] 
         * @param {number} [partnerId] 
         * @param {number} [visitPackageStageId] 
         * @param {number} [visitId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitsSlots: async (patientId: number, medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', visitType: 'REMOTE' | 'STATIONARY' | 'ABSENT', dateFrom: string, workPlaceId?: number, days?: number, lookBack?: boolean, selectedDuration?: number, partnerId?: number, visitPackageStageId?: number, visitId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getVisitsSlots', 'patientId', patientId)
            // verify required parameter 'medicalService' is not null or undefined
            assertParamExists('getVisitsSlots', 'medicalService', medicalService)
            // verify required parameter 'visitType' is not null or undefined
            assertParamExists('getVisitsSlots', 'visitType', visitType)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getVisitsSlots', 'dateFrom', dateFrom)
            const localVarPath = `/api/doctor/patient/{patientId}/visit-times`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (medicalService !== undefined) {
                localVarQueryParameter['medicalService'] = medicalService;
            }

            if (visitType !== undefined) {
                localVarQueryParameter['visitType'] = visitType;
            }

            if (workPlaceId !== undefined) {
                localVarQueryParameter['workPlaceId'] = workPlaceId;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (days !== undefined) {
                localVarQueryParameter['days'] = days;
            }

            if (lookBack !== undefined) {
                localVarQueryParameter['lookBack'] = lookBack;
            }

            if (selectedDuration !== undefined) {
                localVarQueryParameter['selectedDuration'] = selectedDuration;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }

            if (visitPackageStageId !== undefined) {
                localVarQueryParameter['visitPackageStageId'] = visitPackageStageId;
            }

            if (visitId !== undefined) {
                localVarQueryParameter['visitId'] = visitId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasVisitsWithPatient: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('hasVisitsWithPatient', 'patientId', patientId)
            const localVarPath = `/api/doctor/has-visit/{patientId}`
                .replace(`{${"patientId"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} requestId 
         * @param {number} messageId 
         * @param {DoctorSelectionResponseDto} doctorSelectionResponseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectDoctorSelectionRequest: async (requestId: number, messageId: number, doctorSelectionResponseDto: DoctorSelectionResponseDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('rejectDoctorSelectionRequest', 'requestId', requestId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('rejectDoctorSelectionRequest', 'messageId', messageId)
            // verify required parameter 'doctorSelectionResponseDto' is not null or undefined
            assertParamExists('rejectDoctorSelectionRequest', 'doctorSelectionResponseDto', doctorSelectionResponseDto)
            const localVarPath = `/api/doctor/select/request/{requestId}/message/{messageId}/reject`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doctorSelectionResponseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DoctorApiControllerApi - functional programming interface
 * @export
 */
export const DoctorApiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DoctorApiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} requestId 
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptDoctorSelectionRequest(requestId: number, messageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptDoctorSelectionRequest(requestId, messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dummmySlot(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorVisitSlotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dummmySlot(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableDoctorsForPatientByMedicalService(patientId: number, medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorBasicDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableDoctorsForPatientByMedicalService(patientId, medicalService, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentDoctorMedicalServices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<MedicalServiceBaseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentDoctorMedicalServices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorDashboardAlerts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorDashboardAlertsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorDashboardAlerts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorDatabaseData(doctorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorFromDatabaseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorDatabaseData(doctorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorFinances(pageable: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<ListResponseDtoListDoctorFinanceDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorFinances(pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorInfo(doctorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorInfo(doctorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorSelectionRequestDetails(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorSelectionRequestPatientInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorSelectionRequestDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} doctorPriceListItemId 
         * @param {string} dateFrom 
         * @param {number} [days] 
         * @param {number} [selectedDuration] 
         * @param {boolean} [lookBack] 
         * @param {number} [workPlaceId] 
         * @param {number} [partnerId] 
         * @param {number} [visitPackageStageId] 
         * @param {number} [doctorPackageId] 
         * @param {number} [visitId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorVisitsSlots(doctorPriceListItemId: number, dateFrom: string, days?: number, selectedDuration?: number, lookBack?: boolean, workPlaceId?: number, partnerId?: number, visitPackageStageId?: number, doctorPackageId?: number, visitId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<{ [key: string]: Array<DoctorVisitSlotDto>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorVisitsSlots(doctorPriceListItemId, dateFrom, days, selectedDuration, lookBack, workPlaceId, partnerId, visitPackageStageId, doctorPackageId, visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorWorkPlace(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<DoctorWorkPlaceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorWorkPlace(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorWorkplaces(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorWorkPlaceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorWorkplaces(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorsAvailableForPatient(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<ListResponseDtoDoctorFromDatabaseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorsAvailableForPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorsNames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<Array<DoctorNameDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorsNames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorsPaged(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<ListResponseDtoDoctorFromDatabaseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorsPaged(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFinanceReport(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFinanceReport(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
         * @param {'REMOTE' | 'STATIONARY' | 'ABSENT'} visitType 
         * @param {string} dateFrom 
         * @param {number} [workPlaceId] 
         * @param {number} [days] 
         * @param {boolean} [lookBack] 
         * @param {number} [selectedDuration] 
         * @param {number} [partnerId] 
         * @param {number} [visitPackageStageId] 
         * @param {number} [visitId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisitsSlots(patientId: number, medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', visitType: 'REMOTE' | 'STATIONARY' | 'ABSENT', dateFrom: string, workPlaceId?: number, days?: number, lookBack?: boolean, selectedDuration?: number, partnerId?: number, visitPackageStageId?: number, visitId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<{ [key: string]: Array<DoctorVisitSlotDto>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisitsSlots(patientId, medicalService, visitType, dateFrom, workPlaceId, days, lookBack, selectedDuration, partnerId, visitPackageStageId, visitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasVisitsWithPatient(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasVisitsWithPatient(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} requestId 
         * @param {number} messageId 
         * @param {DoctorSelectionResponseDto} doctorSelectionResponseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectDoctorSelectionRequest(requestId: number, messageId: number, doctorSelectionResponseDto: DoctorSelectionResponseDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectDoctorSelectionRequest(requestId, messageId, doctorSelectionResponseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DoctorApiControllerApi - factory interface
 * @export
 */
export const DoctorApiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DoctorApiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} requestId 
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptDoctorSelectionRequest(requestId: number, messageId: number, options?: any): Promise<void> {
            return localVarFp.acceptDoctorSelectionRequest(requestId, messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummmySlot(options?: any): Promise<DoctorVisitSlotDto> {
            return localVarFp.dummmySlot(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableDoctorsForPatientByMedicalService(patientId: number, medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', options?: any): Promise<Array<DoctorBasicDto>> {
            return localVarFp.getAvailableDoctorsForPatientByMedicalService(patientId, medicalService, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentDoctorMedicalServices(options?: any): Promise<Array<MedicalServiceBaseDto>> {
            return localVarFp.getCurrentDoctorMedicalServices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorDashboardAlerts(options?: any): Promise<DoctorDashboardAlertsDto> {
            return localVarFp.getDoctorDashboardAlerts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorDatabaseData(doctorId: number, options?: any): Promise<DoctorFromDatabaseDto> {
            return localVarFp.getDoctorDatabaseData(doctorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorFinances(pageable: Pageable, options?: any): Promise<ListResponseDtoListDoctorFinanceDataDto> {
            return localVarFp.getDoctorFinances(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorInfo(doctorId: number, options?: any): Promise<DoctorDto> {
            return localVarFp.getDoctorInfo(doctorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorSelectionRequestDetails(id: number, options?: any): Promise<DoctorSelectionRequestPatientInfoDto> {
            return localVarFp.getDoctorSelectionRequestDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} doctorPriceListItemId 
         * @param {string} dateFrom 
         * @param {number} [days] 
         * @param {number} [selectedDuration] 
         * @param {boolean} [lookBack] 
         * @param {number} [workPlaceId] 
         * @param {number} [partnerId] 
         * @param {number} [visitPackageStageId] 
         * @param {number} [doctorPackageId] 
         * @param {number} [visitId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorVisitsSlots(doctorPriceListItemId: number, dateFrom: string, days?: number, selectedDuration?: number, lookBack?: boolean, workPlaceId?: number, partnerId?: number, visitPackageStageId?: number, doctorPackageId?: number, visitId?: number, options?: any): Promise<{ [key: string]: Array<DoctorVisitSlotDto>; }> {
            return localVarFp.getDoctorVisitsSlots(doctorPriceListItemId, dateFrom, days, selectedDuration, lookBack, workPlaceId, partnerId, visitPackageStageId, doctorPackageId, visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkPlace(options?: any): Promise<DoctorWorkPlaceDto> {
            return localVarFp.getDoctorWorkPlace(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorWorkplaces(options?: any): Promise<Array<DoctorWorkPlaceDto>> {
            return localVarFp.getDoctorWorkplaces(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorsAvailableForPatient(patientId: number, options?: any): Promise<ListResponseDtoDoctorFromDatabaseDto> {
            return localVarFp.getDoctorsAvailableForPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorsNames(options?: any): Promise<Array<DoctorNameDto>> {
            return localVarFp.getDoctorsNames(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorsPaged(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<ListResponseDtoDoctorFromDatabaseDto> {
            return localVarFp.getDoctorsPaged(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinanceReport(dateFrom?: string, dateTo?: string, options?: any): Promise<any> {
            return localVarFp.getFinanceReport(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
         * @param {'REMOTE' | 'STATIONARY' | 'ABSENT'} visitType 
         * @param {string} dateFrom 
         * @param {number} [workPlaceId] 
         * @param {number} [days] 
         * @param {boolean} [lookBack] 
         * @param {number} [selectedDuration] 
         * @param {number} [partnerId] 
         * @param {number} [visitPackageStageId] 
         * @param {number} [visitId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisitsSlots(patientId: number, medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', visitType: 'REMOTE' | 'STATIONARY' | 'ABSENT', dateFrom: string, workPlaceId?: number, days?: number, lookBack?: boolean, selectedDuration?: number, partnerId?: number, visitPackageStageId?: number, visitId?: number, options?: any): Promise<{ [key: string]: Array<DoctorVisitSlotDto>; }> {
            return localVarFp.getVisitsSlots(patientId, medicalService, visitType, dateFrom, workPlaceId, days, lookBack, selectedDuration, partnerId, visitPackageStageId, visitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasVisitsWithPatient(patientId: number, options?: any): Promise<boolean> {
            return localVarFp.hasVisitsWithPatient(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} requestId 
         * @param {number} messageId 
         * @param {DoctorSelectionResponseDto} doctorSelectionResponseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectDoctorSelectionRequest(requestId: number, messageId: number, doctorSelectionResponseDto: DoctorSelectionResponseDto, options?: any): Promise<void> {
            return localVarFp.rejectDoctorSelectionRequest(requestId, messageId, doctorSelectionResponseDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DoctorApiControllerApi - object-oriented interface
 * @export
 * @class DoctorApiControllerApi
 * @extends {BaseAPI}
 */
export class DoctorApiControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} requestId 
     * @param {number} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public acceptDoctorSelectionRequest(requestId: number, messageId: number, options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).acceptDoctorSelectionRequest(requestId, messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public dummmySlot(options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).dummmySlot(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public getAvailableDoctorsForPatientByMedicalService(patientId: number, medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).getAvailableDoctorsForPatientByMedicalService(patientId, medicalService, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public getCurrentDoctorMedicalServices(options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).getCurrentDoctorMedicalServices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public getDoctorDashboardAlerts(options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).getDoctorDashboardAlerts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public getDoctorDatabaseData(doctorId: number, options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).getDoctorDatabaseData(doctorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public getDoctorFinances(pageable: Pageable, options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).getDoctorFinances(pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public getDoctorInfo(doctorId: number, options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).getDoctorInfo(doctorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public getDoctorSelectionRequestDetails(id: number, options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).getDoctorSelectionRequestDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} doctorPriceListItemId 
     * @param {string} dateFrom 
     * @param {number} [days] 
     * @param {number} [selectedDuration] 
     * @param {boolean} [lookBack] 
     * @param {number} [workPlaceId] 
     * @param {number} [partnerId] 
     * @param {number} [visitPackageStageId] 
     * @param {number} [doctorPackageId] 
     * @param {number} [visitId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public getDoctorVisitsSlots(doctorPriceListItemId: number, dateFrom: string, days?: number, selectedDuration?: number, lookBack?: boolean, workPlaceId?: number, partnerId?: number, visitPackageStageId?: number, doctorPackageId?: number, visitId?: number, options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).getDoctorVisitsSlots(doctorPriceListItemId, dateFrom, days, selectedDuration, lookBack, workPlaceId, partnerId, visitPackageStageId, doctorPackageId, visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public getDoctorWorkPlace(options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).getDoctorWorkPlace(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public getDoctorWorkplaces(options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).getDoctorWorkplaces(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public getDoctorsAvailableForPatient(patientId: number, options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).getDoctorsAvailableForPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public getDoctorsNames(options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).getDoctorsNames(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public getDoctorsPaged(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).getDoctorsPaged(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public getFinanceReport(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).getFinanceReport(dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION'} medicalService 
     * @param {'REMOTE' | 'STATIONARY' | 'ABSENT'} visitType 
     * @param {string} dateFrom 
     * @param {number} [workPlaceId] 
     * @param {number} [days] 
     * @param {boolean} [lookBack] 
     * @param {number} [selectedDuration] 
     * @param {number} [partnerId] 
     * @param {number} [visitPackageStageId] 
     * @param {number} [visitId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public getVisitsSlots(patientId: number, medicalService: 'VISIT_0' | 'VISIT_1' | 'STANDARD_VISIT' | 'STANDARD_VISIT_15' | 'FOLLOWUP_VISIT' | 'MESSAGE' | 'PRESCRIPTION' | 'ABSENT_CONSULTATION' | 'NEUROSURGEON_CONSULTATION' | 'NEUROPSYCHOLOGIST_CONSULTATION' | 'NEUROLOGOPEDIST_CONSULTATION' | 'PSYCHIATRIST_CONSULTATION' | 'GENETICIST_CONSULTATION' | 'EPILEPTOLOGIST_CONSULTATION' | 'NEUROLOGIST_CONSULTATION' | 'DIETICIAN_CONSULTATION' | 'VNS_CONSULTATION' | 'METABOLIC_CONSULTATION' | 'SLEEP_DISTURBANCE_CONSULTATION' | 'CASE_CONFERENCE' | 'ADHD_CONSULTATION' | 'PSYCHOTHERAPY_CONSULTATION' | 'EEG_CONSULTATION' | 'VEEG_NIGHT_CONSULTATION' | 'VEEG_DAY_CONSULTATION' | 'VEEG_ON_STANDBY_CONSULTATION_1H' | 'VEEG_ON_STANDBY_CONSULTATION_2H' | 'VEEG_ON_STANDBY_CONSULTATION_6H' | 'VEEG_DURING_SLEEP_CONSULTATION_1H' | 'VEEG_DURING_SLEEP_CONSULTATION_2H' | 'VEEG_DURING_SLEEP_CONSULTATION_6H' | 'MIGRATION_VISIT' | 'VEEG_ON_STANDBY_CONSULTATION' | 'VEEG_DURING_SLEEP_CONSULTATION' | 'PARTNER_CONSULTATION', visitType: 'REMOTE' | 'STATIONARY' | 'ABSENT', dateFrom: string, workPlaceId?: number, days?: number, lookBack?: boolean, selectedDuration?: number, partnerId?: number, visitPackageStageId?: number, visitId?: number, options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).getVisitsSlots(patientId, medicalService, visitType, dateFrom, workPlaceId, days, lookBack, selectedDuration, partnerId, visitPackageStageId, visitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public hasVisitsWithPatient(patientId: number, options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).hasVisitsWithPatient(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} requestId 
     * @param {number} messageId 
     * @param {DoctorSelectionResponseDto} doctorSelectionResponseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorApiControllerApi
     */
    public rejectDoctorSelectionRequest(requestId: number, messageId: number, doctorSelectionResponseDto: DoctorSelectionResponseDto, options?: AxiosRequestConfig) {
        return DoctorApiControllerApiFp(this.configuration).rejectDoctorSelectionRequest(requestId, messageId, doctorSelectionResponseDto, options).then((request) => request(this.axios, this.basePath));
    }
}
