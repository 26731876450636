/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DoctorAccountPracticeInfoDto
 */
export interface DoctorAccountPracticeInfoDto {
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'academicTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'bio'?: string;
    /**
     * 
     * @type {any}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'certificate'?: any;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'consultationMedicalServiceGroup'?: DoctorAccountPracticeInfoDtoConsultationMedicalServiceGroupEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'describeEeg'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'describeEmg'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'describeFnirs'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'describeMeg'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'describeMri'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'describePte'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'hasCertificate'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'mainWorkPlaceId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'professions'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'scientificResearch'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'scientificSocieties'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof DoctorAccountPracticeInfoDto
     */
    'type'?: DoctorAccountPracticeInfoDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DoctorAccountPracticeInfoDtoConsultationMedicalServiceGroupEnum {
    AbsentConsultation = 'ABSENT_CONSULTATION',
    Visit0 = 'VISIT_0',
    MigrationVisit = 'MIGRATION_VISIT',
    Visit1 = 'VISIT_1',
    FollowupVisit = 'FOLLOWUP_VISIT',
    StandardVisit = 'STANDARD_VISIT',
    Message = 'MESSAGE',
    Prescription = 'PRESCRIPTION',
    MetabolicConsultation = 'METABOLIC_CONSULTATION',
    NeurosurgeonConsultation = 'NEUROSURGEON_CONSULTATION',
    NeuropsychologistConsultation = 'NEUROPSYCHOLOGIST_CONSULTATION',
    NeurologopedistConsultation = 'NEUROLOGOPEDIST_CONSULTATION',
    PsychiatristConsultation = 'PSYCHIATRIST_CONSULTATION',
    GeneticistConsultation = 'GENETICIST_CONSULTATION',
    EpileptologistConsultation = 'EPILEPTOLOGIST_CONSULTATION',
    NeurologistConsultation = 'NEUROLOGIST_CONSULTATION',
    DieticianConsultation = 'DIETICIAN_CONSULTATION',
    VnsConsultation = 'VNS_CONSULTATION',
    EegConsultation = 'EEG_CONSULTATION',
    SleepDisturbanceConsultation = 'SLEEP_DISTURBANCE_CONSULTATION',
    CaseConference = 'CASE_CONFERENCE',
    PartnerConsultation = 'PARTNER_CONSULTATION',
    AdhdConsultation = 'ADHD_CONSULTATION',
    PsychotherapyConsultation = 'PSYCHOTHERAPY_CONSULTATION',
    StandardVisit15 = 'STANDARD_VISIT_15'
}
/**
    * @export
    * @enum {string}
    */
export enum DoctorAccountPracticeInfoDtoTypeEnum {
    Resident = 'RESIDENT',
    Specialist = 'SPECIALIST',
    Expert = 'EXPERT',
    Consultant = 'CONSULTANT',
    Assistant = 'ASSISTANT',
    InternationalConsultant = 'INTERNATIONAL_CONSULTANT',
    PartnerConsultant = 'PARTNER_CONSULTANT'
}


