/* tslint:disable */
/* eslint-disable */
/**
 * Resource server API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DoctorServicePrice } from './doctor-service-price';

/**
 * 
 * @export
 * @interface DoctorMedicalService
 */
export interface DoctorMedicalService {
    /**
     * 
     * @type {string}
     * @memberof DoctorMedicalService
     */
    'medicalService': DoctorMedicalServiceMedicalServiceEnum;
    /**
     * 
     * @type {Array<DoctorServicePrice>}
     * @memberof DoctorMedicalService
     */
    'prices': Array<DoctorServicePrice>;
}

/**
    * @export
    * @enum {string}
    */
export enum DoctorMedicalServiceMedicalServiceEnum {
    Visit0 = 'VISIT_0',
    Visit1 = 'VISIT_1',
    StandardVisit = 'STANDARD_VISIT',
    StandardVisit15 = 'STANDARD_VISIT_15',
    FollowupVisit = 'FOLLOWUP_VISIT',
    Message = 'MESSAGE',
    Prescription = 'PRESCRIPTION',
    AbsentConsultation = 'ABSENT_CONSULTATION',
    NeurosurgeonConsultation = 'NEUROSURGEON_CONSULTATION',
    NeuropsychologistConsultation = 'NEUROPSYCHOLOGIST_CONSULTATION',
    NeurologopedistConsultation = 'NEUROLOGOPEDIST_CONSULTATION',
    PsychiatristConsultation = 'PSYCHIATRIST_CONSULTATION',
    GeneticistConsultation = 'GENETICIST_CONSULTATION',
    EpileptologistConsultation = 'EPILEPTOLOGIST_CONSULTATION',
    NeurologistConsultation = 'NEUROLOGIST_CONSULTATION',
    DieticianConsultation = 'DIETICIAN_CONSULTATION',
    VnsConsultation = 'VNS_CONSULTATION',
    MetabolicConsultation = 'METABOLIC_CONSULTATION',
    SleepDisturbanceConsultation = 'SLEEP_DISTURBANCE_CONSULTATION',
    CaseConference = 'CASE_CONFERENCE',
    AdhdConsultation = 'ADHD_CONSULTATION',
    PsychotherapyConsultation = 'PSYCHOTHERAPY_CONSULTATION',
    EegConsultation = 'EEG_CONSULTATION',
    VeegNightConsultation = 'VEEG_NIGHT_CONSULTATION',
    VeegDayConsultation = 'VEEG_DAY_CONSULTATION',
    VeegOnStandbyConsultation1H = 'VEEG_ON_STANDBY_CONSULTATION_1H',
    VeegOnStandbyConsultation2H = 'VEEG_ON_STANDBY_CONSULTATION_2H',
    VeegOnStandbyConsultation6H = 'VEEG_ON_STANDBY_CONSULTATION_6H',
    VeegDuringSleepConsultation1H = 'VEEG_DURING_SLEEP_CONSULTATION_1H',
    VeegDuringSleepConsultation2H = 'VEEG_DURING_SLEEP_CONSULTATION_2H',
    VeegDuringSleepConsultation6H = 'VEEG_DURING_SLEEP_CONSULTATION_6H',
    MigrationVisit = 'MIGRATION_VISIT',
    VeegOnStandbyConsultation = 'VEEG_ON_STANDBY_CONSULTATION',
    VeegDuringSleepConsultation = 'VEEG_DURING_SLEEP_CONSULTATION',
    PartnerConsultation = 'PARTNER_CONSULTATION'
}


